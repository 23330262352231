.flatpickr-calendar.inline {
  visibility: hidden;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}
.show-calendar .flatpickr-calendar.inline {
  opacity: 1;
  visibility: visible;
  overflow: visible;
  max-height: 640px;
}
.flatpickr-calendar {
  background: transparent;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  border: 0;
  visibility: hidden;
  text-align: center;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  touch-action: manipulation;
  font-size: 14px;
  line-height: 24px;
  font-family: "Lato", sans-serif;
  color: inherit;
  border-radius: 5px;
  background: #fff;
  position: absolute;
  width: 250px;
  -webkit-box-shadow: 0 0 20px 0 rgba(12, 51, 92, 0.1);
  box-shadow: 0 0 20px 0 rgba(12, 51, 92, 0.1);
  -ms-touch-action: manipulation;
  padding: 12px 5px;
  margin-top: 5px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.flatpickr-calendar.open {
  opacity: 1;
  visibility: visible;
  overflow: visible;
  max-height: 640px;
  display: inline-block;
  z-index: 99999;
}
.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-calendar.inline {
  display: block;
  position: relative;
}
.flatpickr-calendar.static {
  position: absolute;
  top: 100%;
  left: 0;
}
.flatpickr-calendar.hasWeeks {
  width: auto;
}
.flatpickr-calendar .hasWeeks .dayContainer, .flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}
.flatpickr-calendar.showTimeInput.hasTime {
  width: 140px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 30px;
}
.flatpickr-calendar.showTimeInput.hasTime .flatpickr-innerContainer {
  border-bottom: 0;
  padding-bottom: 5px;
}
.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}
.flatpickr-calendar.hasTime {
  padding: 8px 4px 4px;
}
.flatpickr-calendar.hasTime .flatpickr-time {
  margin: 0 -4px;
  display: none;
}
.flatpickr-calendar.hasTime.showTimeInput .flatpickr-time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.flatpickr-calendar:after, .flatpickr-calendar:before {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.005);
  content: '';
}
.flatpickr-calendar:before {
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid rgba(0, 0, 0, 0.005);
}
.flatpickr-calendar:after {
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
}
.flatpickr-calendar:before, .flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px;
}
.flatpickr-calendar.rightMost:before, .flatpickr-calendar.rightMost:after {
  left: auto;
  right: 22px;
}
.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}
.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}
.flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: rgba(0, 0, 0, 0.05);
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}
.flatpickr-calendar.arrowBottom {
  margin-top: -30px;
}
.flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after {
  top: 100%;
}
.flatpickr-calendar.arrowBottom:before {
  border-top-color: rgba(0, 0, 0, 0.05);
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}
.flatpickr-calendar:focus {
  outline: 0;
}
.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}
.flatpickr-month {
  color: #3b4752;
  fill: #3b4752;
  height: 28px;
  line-height: 28px;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}
.flatpickr-prev-month, .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0px;
  line-height: 28px;
  padding: 0px calc(3.57% - 1.5px);
  z-index: 3;
}
.numInputWrapper > p {
  font-size: 14px;
  margin: 0;
}
.flatpickr-prev-month i, .flatpickr-next-month i {
  position: relative;
}
.flatpickr-prev-month.flatpickr-prev-month {
  left: 0;
}
.flatpickr-next-month.flatpickr-prev-month {
  left: 0;
  right: 0;
}
.flatpickr-next-month.flatpickr-next-month {
  right: 0;
}
.flatpickr-prev-month svg, .flatpickr-next-month svg {
  width: 12px;
  vertical-align: middle;
}
.flatpickr-prev-month svg path, .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  -o-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}
.flatpickr-prev-month:hover, .flatpickr-next-month:hover {
  color: #3b4752;
}
.flatpickr-prev-month:hover svg, .flatpickr-next-month:hover svg {
  fill: #3b4752;
}
.numInputWrapper {
  position: relative;
  height: auto;
}
.numInputWrapper input, .numInputWrapper span {
  display: inline-block;
}
.numInputWrapper input {
  width: 100%;
}
.numInputWrapper input::-ms-clear {
  display: none;
}
.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}
.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
  top: 33%;
}
.numInputWrapper span.arrowUp {
  top: 2px;
  border-bottom: 0;
}
.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(59, 71, 81, 0.6);
}
.numInputWrapper span.arrowDown {
  bottom: 2px;
}
.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(59, 71, 81, 0.6);
}
.numInputWrapper span svg {
  width: inherit;
  height: auto;
}
.numInputWrapper span svg path {
  fill: rgba(255, 255, 255, 0.5);
}
.numInputWrapper:hover span {
  opacity: 1;
}
.flatpickr-current-month {
  font-size: 16px;
  font-weight: 400;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  line-height: 28px;
  height: 28px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}
.flatpickr-current-month.slideLeft {
  -webkit-transform: translate3d(-100%, 0px, 0px);
  transform: translate3d(-100%, 0px, 0px);
  -webkit-animation: fpFadeOut 400ms ease, fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeOut 400ms ease, fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-current-month.slideLeftNew {
  -webkit-transform: translate3d(100%, 0px, 0px);
  transform: translate3d(100%, 0px, 0px);
  -webkit-animation: fpFadeIn 400ms ease, fpSlideLeftNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeIn 400ms ease, fpSlideLeftNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-current-month.slideRight {
  -webkit-transform: translate3d(100%, 0px, 0px);
  transform: translate3d(100%, 0px, 0px);
  -webkit-animation: fpFadeOut 400ms ease, fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeOut 400ms ease, fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-current-month.slideRightNew {
  -webkit-transform: translate3d(0, 0, 0px);
  transform: translate3d(0, 0, 0px);
  -webkit-animation: fpFadeIn 400ms ease, fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeIn 400ms ease, fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 400;
  font-size: 14px;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}
.flatpickr-current-month .numInputWrapper {
  width: 7ch;
  display: inline-block;
}
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #3b4752;
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #3b4752;
}
.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: 14px;
  font-family: inherit;
  font-weight: 400;
  line-height: inherit;
  height: initial;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
}
.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}
.flatpickr-current-month input.cur-year[disabled] {
  font-size: 100%;
  color: rgba(255, 255, 255, 0.5);
  background: transparent;
  pointer-events: none;
}
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(255, 255, 255, 0.5);
  background: transparent;
  pointer-events: none;
}
.flatpickr-weekdays {
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px;
}
.flatpickr-weekdaycontainer {
  display: inherit;
  width: 100%;
}
span.flatpickr-weekday {
  cursor: default;
  min-width: 32px;
  font-size: 12px;
  font-weight: 500;
  color: #3b4752;
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.dayContainer, .flatpickr-weeks {
  padding: 1px 0 0 0;
}
.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 250px;
}
.flatpickr-days:focus {
  outline: 0;
}
.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}
.flatpickr-calendar.animate .dayContainer.slideLeft {
  -webkit-animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transform: translate3d(-100%, 0px, 0px);
  transform: translate3d(-100%, 0px, 0px);
}
.flatpickr-calendar.animate .dayContainer.slideLeftNew {
  -webkit-transform: translate3d(-100%, 0px, 0px);
  transform: translate3d(-100%, 0px, 0px);
  -webkit-animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-calendar.animate .dayContainer.slideRight {
  -webkit-animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transform: translate3d(100%, 0px, 0px);
  transform: translate3d(100%, 0px, 0px);
}
.flatpickr-calendar.animate .dayContainer.slideRightNew {
  -webkit-animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #3b4752;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 35px;
  height: 28px;
  line-height: 28px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #ebf4f8;
}
.flatpickr-day.today {
  border-color: #00bbe5;
}
.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  border-radius: 5px;
  background: #00bbe5;
  color: #fff;
}
.flatpickr-day:hover, .flatpickr-day:focus {
  border-radius: 5px;
}
.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: #00bbe5;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #00bbe5;
}
.flatpickr-day.endRange.inRange {
  background: #ebf4f8;
  border-color: #ebf4f8;
  color: #3b4752;
}
.flatpickr-day.endRange.inRange.selected {
  color: #fff;
}
.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
  border-radius: 5px 0 0 5px;
}
.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange, .flatpickr-day.endRange.inRange {
  border-radius: 0 5px 5px 0;
}
.flatpickr-day.selected.startRange + .endRange, .flatpickr-day.startRange.startRange + .endRange, .flatpickr-day.endRange.startRange + .endRange {
  -webkit-box-shadow: -1px 0 0 #00bbe5;
  box-shadow: -1px 0 0 #00bbe5;
}
.flatpickr-day.today.endrange.inRange, .flatpickr-day.prevMonthDay.today.endRange.inRange, .flatpickr-day.nextMonthDay.today.endRange.inRange {
  background: #00bbe5;
}
.flatpickr-day.selected.startRange.endRange, .flatpickr-day.startRange.startRange.endRange {
  border-radius: 5px;
}
.flatpickr-day.selected.startRange.inRange {
  border-radius: 5px 0 0 5px;
}
.flatpickr-day.selected.endRange.inRange {
  border-radius: 5px;
}
.flatpickr-day.disabled {
  color: rgba(72, 72, 72, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}
.flatpickr-day.disabled:hover {
  color: rgba(72, 72, 72, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}
.flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay {
  color: rgba(72, 72, 72, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
  visibility: hidden;
  opacity: 0;
}
.flatpickr-day.notAllowed {
  color: rgba(72, 72, 72, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}
.flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(72, 72, 72, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}
.flatpickr-day.disabled {
  cursor: text !important;
}
.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -1px 0 0 #00bbe5, 1px 0 0 #00bbe5;
  box-shadow: -1px 0 0 #00bbe5, 1px 0 0 #00bbe5;
}
.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -1px 0 0 #ebf4f8, 1px 0 0 #ebf4f8;
  box-shadow: -1px 0 0 #ebf4f8, 1px 0 0 #ebf4f8;
}
.flatpickr-weekwrapper {
  display: inline-block;
  float: left;
}
.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  border-left: 1px solid rgba(72, 72, 72, 0.2);
}
.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}
.flatpickr-weekwrapper span.flatpickr-day {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(72, 72, 72, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(72, 72, 72, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}
.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  background: #fff;
}
.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 30px;
  line-height: 30px;
  max-height: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background: #fff;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
  justify-content: center;
  border-radius: 0 0 5px 5px;
}
.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}
.flatpickr-time .numInputWrapper {
  width: 50px;
  height: 30px;
}
.flatpickr-time .numInputWrapper span {
  padding: 0 3px 0 3px;
  width: 12px;
}
.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(59, 71, 82, 0.8);
}
.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(59, 71, 82, 0.8);
}
.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  cursor: pointer;
  color: #3b4752;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.flatpickr-time input.flatpickr-hour, .flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
  font-weight: 400;
}
.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}
.flatpickr-time .flatpickr-time-separator {
  height: inherit;
  display: inline-block;
  float: left;
  line-height: inherit;
  color: #3b4752;
  font-weight: bold;
  width: 5px;
  text-align: right;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  display: inline-block;
  float: left;
  line-height: inherit;
  color: #3b4752;
  font-weight: bold;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  outline: 0;
  width: 40px;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}
.flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time .flatpickr-am-pm:focus {
  background-color: rgba(172, 172, 172, 0.3);
}
@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
 }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
 }
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
 }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
 }
}
@-webkit-keyframes fpSlideLeft {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
 }
  to {
    -webkit-transform: translate3d(-100%, 0px, 0px);
    transform: translate3d(-100%, 0px, 0px);
 }
}
@keyframes fpSlideLeft {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
 }
  to {
    -webkit-transform: translate3d(-100%, 0px, 0px);
    transform: translate3d(-100%, 0px, 0px);
 }
}
@-webkit-keyframes fpSlideLeftNew {
  from {
    -webkit-transform: translate3d(100%, 0px, 0px);
    transform: translate3d(100%, 0px, 0px);
 }
  to {
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
 }
}
@keyframes fpSlideLeftNew {
  from {
    -webkit-transform: translate3d(100%, 0px, 0px);
    transform: translate3d(100%, 0px, 0px);
 }
  to {
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
 }
}
@-webkit-keyframes fpSlideRight {
  from {
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0, 0, 0px);
 }
  to {
    -webkit-transform: translate3d(100%, 0px, 0px);
    transform: translate3d(100%, 0px, 0px);
 }
}
@keyframes fpSlideRight {
  from {
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0, 0, 0px);
 }
  to {
    -webkit-transform: translate3d(100%, 0px, 0px);
    transform: translate3d(100%, 0px, 0px);
 }
}
@-webkit-keyframes fpSlideRightNew {
  from {
    -webkit-transform: translate3d(-100%, 0, 0px);
    transform: translate3d(-100%, 0, 0px);
 }
  to {
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0, 0, 0px);
 }
}
@keyframes fpSlideRightNew {
  from {
    -webkit-transform: translate3d(-100%, 0, 0px);
    transform: translate3d(-100%, 0, 0px);
 }
  to {
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0, 0, 0px);
 }
}
@-webkit-keyframes fpFadeOut {
  from {
    opacity: 1;
 }
  to {
    opacity: 0;
 }
}
@keyframes fpFadeOut {
  from {
    opacity: 1;
 }
  to {
    opacity: 0;
 }
}
@-webkit-keyframes fpFadeIn {
  from {
    opacity: 0;
 }
  to {
    opacity: 1;
 }
}
@keyframes fpFadeIn {
  from {
    opacity: 0;
 }
  to {
    opacity: 1;
 }
}
.flatpickr-calendar {
  width: 300px;
  box-sizing: border-box;
  margin-top: 16px;
  padding: 0;
}
.flatpickr-calendar:after, .flatpickr-calendar:before {
  left: 110px;
  bottom: 100%;
}
.flatpickr-calendar.showTimeInput.hasTime {
  width: 300px;
}
.flatpickr-calendar.showTimeInput.hasTime .flatpickr-innerContainer {
  padding: 0;
}
.flatpickr-calendar:before {
  border-width: 8px;
  margin: 0 -8px;
}
.flatpickr-calendar:after {
  border-width: 6px;
  margin: 0 -6px;
}
.flatpickr-calendar.arrowTop {
  margin-top: 22px;
}
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: rgba(0, 0, 0, 0.05);
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}
.flatpickr-calendar.arrowBottom {
  margin-top: -30px;
}
.flatpickr-calendar.arrowBottom:before {
  border-top-color: rgba(0, 0, 0, 0.05);
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}
.flatpickr-calendar.hasTime {
  padding: 5px 5px 18px;
}
.flatpickr-calendar.noCalendar.arrowTop {
  margin-top: 10px;
}
.flatpickr-calendar.noCalendar.arrowBottom {
  margin-top: -55px;
}
.flatpickr-calendar .flatpickr-months {
  position: relative;
}
.flatpickr-calendar .flatpickr-month, .flatpickr-calendar .flatpickr-current-month {
  height: 40px;
  line-height: 40px;
}
.flatpickr-calendar .flatpickr-current-month input.cur-year, .flatpickr-calendar .flatpickr-current-month span.cur-month {
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: inherit;
  letter-spacing: normal;
  color: #181818;
}
.flatpickr-calendar .flatpickr-prev-month svg path, .flatpickr-calendar .flatpickr-next-month svg path {
  fill: #c9c9c9;
}
.flatpickr-calendar .flatpickr-prev-month:hover svg path, .flatpickr-calendar .flatpickr-next-month:hover svg path {
  fill: #181818;
}
.flatpickr-calendar .dayContainer {
  width: 100%;
  max-width: initial;
}
.flatpickr-calendar .flatpickr-days {
  width: 290px;
}
.flatpickr-calendar .flatpickr-weekdays {
  border: 1px solid #f3f3f3;
  border-width: 1px 0;
  height: 40px;
  box-sizing: border-box;
}
.flatpickr-calendar .flatpickr-weekdays span.flatpickr-weekday {
  color: #999;
  font-size: 0;
}
.flatpickr-calendar .flatpickr-weekdays span.flatpickr-weekday:first-letter {
  font-size: 11px;
}
.flatpickr-calendar .flatpickr-day {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  height: 26px;
  width: 26px;
  line-height: 26px;
  font-size: 11px;
  text-align: center;
  color: #000;
  max-width: 26px;
  transition: all 0.1s ease;
  margin: 2px 6px;
}
.flatpickr-calendar .flatpickr-day .disabled {
  color: #999;
}
.flatpickr-calendar .flatpickr-day.today:hover, .flatpickr-calendar .flatpickr-day.today:focus, .flatpickr-calendar .flatpickr-day.today, .flatpickr-calendar .flatpickr-day.selected {
  border-radius: 50%;
  background: #4684ff;
  color: #fff;
}
.flatpickr-calendar .flatpickr-day:hover {
  background-color: rgba(70, 132, 255, 0.15);
  color: #4684ff;
}
.flatpickr-prev-month, .flatpickr-next-month {
  top: 50%;
  transform: translateY(-50%);
}
.flatpickr-input {
  width: 100%;
  font-size: 14px;
  color: #181818;
  border: none;
  padding: 0;
  height: auto;
  outline: none;
  cursor: default;
  background: transparent;
}
.flatpickr-input::placeholder {
  color: #acb7cc;
}
.flatpickr-calendar.hasTime.noCalendar {
  padding: 40px 30px 10px 20px;
}
.flatpickr-calendar.hasTime.noCalendar::before {
  display: none;
}
.flatpickr-calendar.hasTime.noCalendar::after {
  display: none;
}
.flatpickr-calendar.hasTime.noCalendar .flatpickr-time {
  justify-content: flex-start;
  position: relative;
  overflow: visible;
}
.flatpickr-calendar.hasTime.noCalendar .flatpickr-time::before {
  content: 'Select Time';
  color: #8f99ad;
  font-weight: 500;
  position: absolute;
  top: -20px;
  left: 0;
  line-height: normal;
  text-align: left;
}
.flatpickr-time .numInputWrapper {
  width: 55px;
  height: 30px;
}
.flatpickr-time .numInputWrapper span.arrowUp:after, .flatpickr-time .numInputWrapper span.arrowDown:after {
  height: 6px;
  width: 6px;
  border-style: solid;
  border-color: #181818 !important;
  left: 6px;
  transform: rotate(45deg);
  border-width: 1px 0 0 1px;
}
.flatpickr-time .numInputWrapper span.arrowDown:before {
  transform: rotate(-45deg);
}
.flatpickr-time .numInputWrapper span.arrowDown:after {
  transform: rotate(-135deg);
}
.flatpickr-time .flatpickr-am-pm {
  border-radius: 0;
}
.flatpickr-time .flatpickr-am-pm:focus {
  border-bottom: 1px solid #4684ff;
}
.flatpickr-time .flatpickr-time-separator {
  margin: 0 15px;
}
/* added */
.flatpickr-calendar.hasTime .flatpickr-time {
  display: flex;
}
.flatpickr-time {
  min-height: 55px;
  padding: 10px 0 15px;
}
.flatpickr-time input {
  position: relative;
  border-bottom: 1px solid #d3d9e5;
}
.flatpickr-time input:focus {
  border-bottom: 1px solid #4684ff;
}
.flatpickr-time .flatpickr-am-pm {
  margin-left: 35px;
  position: relative;
  width: 55px;
  border-bottom: 1px solid #d3d9e5;
}
.flatpickr-time .flatpickr-am-pm::before {
  font-size: 14px;
  content: ":";
  position: absolute;
  font-weight: 700;
  left: -18px;
  top: 0;
}
.flatpickr-time .flatpickr-am-pm.active {
  border-bottom: 1px solid #4684ff;
}
.flatpickr-time .flatpickr-am-pm:hover {
  background: transparent;
}
.flatpickr-input.active {
  border-color: #4684ff;
}
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.flatpickr-current-month {
  font-size: 13px;
  font-weight: 600;
}
.flatpickr-current-month .flatpickr-monthDropdown-months, .flatpickr-current-month .numInputWrapper {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  pointer-events: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  font-size: inherit;
  font-weight: inherit;
  background: transparent;
  border: none;
}
