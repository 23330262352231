.draftJsMentionPlugin__mention__29BEd,
.draftJsMentionPlugin__mention__29BEd:visited {
  color: #181818;
  cursor: pointer;
  display: inline-block;
  /* background: #e6f3ff; */
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 2px;
  text-decoration: none;
  /* font-weight:bold; */
}

.draftJsMentionPlugin__mention__29BEd:hover
 /* .draftJsMentionPlugin__mention__29BEd:focus { */
{
  color: #181818;
  /* background: green; */
  outline: 0; /* reset for :focus */
}

.draftJsMentionPlugin__mention__29BEd:active {
  color: #181818;
  background: #455261;
}
.draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm {
  padding: 7px 10px 3px 10px;
  transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);
}

/* .draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm:active {
  background-color: red;
} */

.draftJsMentionPlugin__mentionSuggestionsEntryFocused__3LcTd:hover {
  background-color: #f3f6fc;
}

.draftJsMentionPlugin__mentionSuggestionsEntryText__3Jobq {
  display: inline-block;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 368px;
  font-size: 14px;
  line-height: 1.43;
  /* font-size: 0.9em; */
  /* margin-bottom: 0.2em; */
}

.draftJsMentionPlugin__mentionSuggestionsEntryAvatar__1xgA9 {
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 12px;
}
.draftJsMentionPlugin__mentionSuggestions__2DWjA {
  /* border: 1px solid #eee; */
  margin-top: 1.75em;
  position: absolute;
  min-width: 240px;
  max-width: 440px;
  background: #ffffff;
  border-radius: 10px;
  /* box-shadow: 0px 4px 30px 0px rgba(220,220,220,1); */
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 2;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  box-sizing: border-box;
  -webkit-transform: scale(0);
          transform: scale(0);
}
