.flag-ele {
    width: 20px;
    height: 15px;
    margin-right: 8px;
    box-shadow: 0px 0px 1px 0px #888;
    background-image: url("https://storage.googleapis.com/front-office/images/phone-tab/flags.png");
    background-repeat: no-repeat;
    background-color: #dbdbdb;
    background-position: 20px 0;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
/* only screen and (min--moz-device-pixel-ratio: 2), */
only screen and (-o-min-device-pixel-ratio: 2 / 1),
/* only screen and (min-device-pixel-ratio: 2), */
only screen and (min-resolution: 192dpi),
only screen and (min-resolution: 2dppx) {
    .g-country-dropdown .flag-ele {
        background-image: url("https://storage.googleapis.com/front-office/images/common/flags@2x.png");
        background-size: 5652px 15px;
    }
}

.be.flag-ele {
    width: 18px;
}

.ch.flag-ele {
    width: 15px;
}

.mc.flag-ele {
    width: 19px;
}

.ne.flag-ele {
    width: 18px;
}

.np.flag-ele {
    width: 13px;
}

.va.flag-ele {
    width: 15px;
}

.flag-ele.ac {
    height: 10px;
    background-position: 0px 0px;
}

.flag-ele.ad {
    height: 14px;
    background-position: -22px 0px;
}

.flag-ele.ae {
    height: 10px;
    background-position: -44px 0px;
}

.flag-ele.af {
    height: 14px;
    background-position: -66px 0px;
}

.flag-ele.ag {
    height: 14px;
    background-position: -88px 0px;
}

.flag-ele.ai {
    height: 10px;
    background-position: -110px 0px;
}

.flag-ele.al {
    height: 15px;
    background-position: -132px 0px;
}

.flag-ele.am {
    height: 10px;
    background-position: -154px 0px;
}

.flag-ele.ao {
    height: 14px;
    background-position: -176px 0px;
}

.flag-ele.aq {
    height: 14px;
    background-position: -198px 0px;
}

.flag-ele.ar {
    height: 13px;
    background-position: -220px 0px;
}

.flag-ele.as {
    height: 10px;
    background-position: -242px 0px;
}

.flag-ele.at {
    height: 14px;
    background-position: -264px 0px;
}

.flag-ele.au {
    height: 10px;
    background-position: -286px 0px;
}

.flag-ele.aw {
    height: 14px;
    background-position: -308px 0px;
}

.flag-ele.ax {
    height: 13px;
    background-position: -330px 0px;
}

.flag-ele.az {
    height: 10px;
    background-position: -352px 0px;
}

.flag-ele.ba {
    height: 10px;
    background-position: -374px 0px;
}

.flag-ele.bb {
    height: 14px;
    background-position: -396px 0px;
}

.flag-ele.bd {
    height: 12px;
    background-position: -418px 0px;
}

.flag-ele.be {
    height: 15px;
    background-position: -440px 0px;
}

.flag-ele.bf {
    height: 14px;
    background-position: -460px 0px;
}

.flag-ele.bg {
    height: 12px;
    background-position: -482px 0px;
}

.flag-ele.bh {
    height: 12px;
    background-position: -504px 0px;
}

.flag-ele.bi {
    height: 12px;
    background-position: -526px 0px;
}

.flag-ele.bj {
    height: 14px;
    background-position: -548px 0px;
}

.flag-ele.bl {
    height: 14px;
    background-position: -570px 0px;
}

.flag-ele.bm {
    height: 10px;
    background-position: -592px 0px;
}

.flag-ele.bn {
    height: 10px;
    background-position: -614px 0px;
}

.flag-ele.bo {
    height: 14px;
    background-position: -636px 0px;
}

.flag-ele.bq {
    height: 14px;
    background-position: -658px 0px;
}

.flag-ele.br {
    height: 14px;
    background-position: -680px 0px;
}

.flag-ele.bs {
    height: 10px;
    background-position: -702px 0px;
}

.flag-ele.bt {
    height: 14px;
    background-position: -724px 0px;
}

.flag-ele.bv {
    height: 15px;
    background-position: -746px 0px;
}

.flag-ele.bw {
    height: 14px;
    background-position: -768px 0px;
}

.flag-ele.by {
    height: 10px;
    background-position: -790px 0px;
}

.flag-ele.bz {
    height: 14px;
    background-position: -812px 0px;
}

.flag-ele.ca {
    height: 10px;
    background-position: -834px 0px;
}

.flag-ele.cc {
    height: 10px;
    background-position: -856px 0px;
}

.flag-ele.cd {
    height: 15px;
    background-position: -878px 0px;
}

.flag-ele.cf {
    height: 14px;
    background-position: -900px 0px;
}

.flag-ele.cg {
    height: 14px;
    background-position: -922px 0px;
}

.flag-ele.ch {
    height: 15px;
    background-position: -944px 0px;
}

.flag-ele.ci {
    height: 14px;
    background-position: -961px 0px;
}

.flag-ele.ck {
    height: 10px;
    background-position: -983px 0px;
}

.flag-ele.cl {
    height: 14px;
    background-position: -1005px 0px;
}

.flag-ele.cm {
    height: 14px;
    background-position: -1027px 0px;
}

.flag-ele.cn {
    height: 14px;
    background-position: -1049px 0px;
}

.flag-ele.co {
    height: 14px;
    background-position: -1071px 0px;
}

.flag-ele.cp {
    height: 14px;
    background-position: -1093px 0px;
}

.flag-ele.cr {
    height: 12px;
    background-position: -1115px 0px;
}

.flag-ele.cu {
    height: 10px;
    background-position: -1137px 0px;
}

.flag-ele.cv {
    height: 12px;
    background-position: -1159px 0px;
}

.flag-ele.cw {
    height: 14px;
    background-position: -1181px 0px;
}

.flag-ele.cx {
    height: 10px;
    background-position: -1203px 0px;
}

.flag-ele.cy {
    height: 14px;
    background-position: -1225px 0px;
}

.flag-ele.cz {
    height: 14px;
    background-position: -1247px 0px;
}

.flag-ele.de {
    height: 12px;
    background-position: -1269px 0px;
}

.flag-ele.dg {
    height: 10px;
    background-position: -1291px 0px;
}

.flag-ele.dj {
    height: 14px;
    background-position: -1313px 0px;
}

.flag-ele.dk {
    height: 15px;
    background-position: -1335px 0px;
}

.flag-ele.dm {
    height: 10px;
    background-position: -1357px 0px;
}

.flag-ele.do {
    height: 14px;
    background-position: -1379px 0px;
}

.flag-ele.dz {
    height: 14px;
    background-position: -1401px 0px;
}

.flag-ele.ea {
    height: 14px;
    background-position: -1423px 0px;
}

.flag-ele.ec {
    height: 14px;
    background-position: -1445px 0px;
}

.flag-ele.ee {
    height: 13px;
    background-position: -1467px 0px;
}

.flag-ele.eg {
    height: 14px;
    background-position: -1489px 0px;
}

.flag-ele.eh {
    height: 10px;
    background-position: -1511px 0px;
}

.flag-ele.er {
    height: 10px;
    background-position: -1533px 0px;
}

.flag-ele.es {
    height: 14px;
    background-position: -1555px 0px;
}

.flag-ele.et {
    height: 10px;
    background-position: -1577px 0px;
}

.flag-ele.eu {
    height: 14px;
    background-position: -1599px 0px;
}

.flag-ele.fi {
    height: 12px;
    background-position: -1621px 0px;
}

.flag-ele.fj {
    height: 10px;
    background-position: -1643px 0px;
}

.flag-ele.fk {
    height: 10px;
    background-position: -1665px 0px;
}

.flag-ele.fm {
    height: 11px;
    background-position: -1687px 0px;
}

.flag-ele.fo {
    height: 15px;
    background-position: -1709px 0px;
}

.flag-ele.fr {
    height: 14px;
    background-position: -1731px 0px;
}

.flag-ele.ga {
    height: 15px;
    background-position: -1753px 0px;
}

.flag-ele.gb {
    height: 10px;
    background-position: -1775px 0px;
}

.flag-ele.gd {
    height: 12px;
    background-position: -1797px 0px;
}

.flag-ele.ge {
    height: 14px;
    background-position: -1819px 0px;
}

.flag-ele.gf {
    height: 14px;
    background-position: -1841px 0px;
}

.flag-ele.gg {
    height: 14px;
    background-position: -1863px 0px;
}

.flag-ele.gh {
    height: 14px;
    background-position: -1885px 0px;
}

.flag-ele.gi {
    height: 10px;
    background-position: -1907px 0px;
}

.flag-ele.gl {
    height: 14px;
    background-position: -1929px 0px;
}

.flag-ele.gm {
    height: 14px;
    background-position: -1951px 0px;
}

.flag-ele.gn {
    height: 14px;
    background-position: -1973px 0px;
}

.flag-ele.gp {
    height: 14px;
    background-position: -1995px 0px;
}

.flag-ele.gq {
    height: 14px;
    background-position: -2017px 0px;
}

.flag-ele.gr {
    height: 14px;
    background-position: -2039px 0px;
}

.flag-ele.gs {
    height: 10px;
    background-position: -2061px 0px;
}

.flag-ele.gt {
    height: 13px;
    background-position: -2083px 0px;
}

.flag-ele.gu {
    height: 11px;
    background-position: -2105px 0px;
}

.flag-ele.gw {
    height: 10px;
    background-position: -2127px 0px;
}

.flag-ele.gy {
    height: 12px;
    background-position: -2149px 0px;
}

.flag-ele.hk {
    height: 14px;
    background-position: -2171px 0px;
}

.flag-ele.hm {
    height: 10px;
    background-position: -2193px 0px;
}

.flag-ele.hn {
    height: 10px;
    background-position: -2215px 0px;
}

.flag-ele.hr {
    height: 10px;
    background-position: -2237px 0px;
}

.flag-ele.ht {
    height: 12px;
    background-position: -2259px 0px;
}

.flag-ele.hu {
    height: 10px;
    background-position: -2281px 0px;
}

.flag-ele.ic {
    height: 14px;
    background-position: -2303px 0px;
}

.flag-ele.id {
    height: 14px;
    background-position: -2325px 0px;
}

.flag-ele.ie {
    height: 10px;
    background-position: -2347px 0px;
}

.flag-ele.il {
    height: 15px;
    background-position: -2369px 0px;
}

.flag-ele.im {
    height: 10px;
    background-position: -2391px 0px;
}

.flag-ele.in {
    height: 14px;
    background-position: -2413px 0px;
}

.flag-ele.io {
    height: 10px;
    background-position: -2435px 0px;
}

.flag-ele.iq {
    height: 14px;
    background-position: -2457px 0px;
}

.flag-ele.ir {
    height: 12px;
    background-position: -2479px 0px;
}

.flag-ele.is {
    height: 15px;
    background-position: -2501px 0px;
}

.flag-ele.it {
    height: 14px;
    background-position: -2523px 0px;
}

.flag-ele.je {
    height: 12px;
    background-position: -2545px 0px;
}

.flag-ele.jm {
    height: 10px;
    background-position: -2567px 0px;
}

.flag-ele.jo {
    height: 10px;
    background-position: -2589px 0px;
}

.flag-ele.jp {
    height: 14px;
    background-position: -2611px 0px;
}

.flag-ele.ke {
    height: 14px;
    background-position: -2633px 0px;
}

.flag-ele.kg {
    height: 12px;
    background-position: -2655px 0px;
}

.flag-ele.kh {
    height: 13px;
    background-position: -2677px 0px;
}

.flag-ele.ki {
    height: 10px;
    background-position: -2699px 0px;
}

.flag-ele.km {
    height: 12px;
    background-position: -2721px 0px;
}

.flag-ele.kn {
    height: 14px;
    background-position: -2743px 0px;
}

.flag-ele.kp {
    height: 10px;
    background-position: -2765px 0px;
}

.flag-ele.kr {
    height: 14px;
    background-position: -2787px 0px;
}

.flag-ele.kw {
    height: 10px;
    background-position: -2809px 0px;
}

.flag-ele.ky {
    height: 10px;
    background-position: -2831px 0px;
}

.flag-ele.kz {
    height: 10px;
    background-position: -2853px 0px;
}

.flag-ele.la {
    height: 14px;
    background-position: -2875px 0px;
}

.flag-ele.lb {
    height: 14px;
    background-position: -2897px 0px;
}

.flag-ele.lc {
    height: 10px;
    background-position: -2919px 0px;
}

.flag-ele.li {
    height: 12px;
    background-position: -2941px 0px;
}

.flag-ele.lk {
    height: 10px;
    background-position: -2963px 0px;
}

.flag-ele.lr {
    height: 11px;
    background-position: -2985px 0px;
}

.flag-ele.ls {
    height: 14px;
    background-position: -3007px 0px;
}

.flag-ele.lt {
    height: 12px;
    background-position: -3029px 0px;
}

.flag-ele.lu {
    height: 12px;
    background-position: -3051px 0px;
}

.flag-ele.lv {
    height: 10px;
    background-position: -3073px 0px;
}

.flag-ele.ly {
    height: 10px;
    background-position: -3095px 0px;
}

.flag-ele.ma {
    height: 14px;
    background-position: -3117px 0px;
}

.flag-ele.mc {
    height: 15px;
    background-position: -3139px 0px;
}

.flag-ele.md {
    height: 10px;
    background-position: -3160px 0px;
}

.flag-ele.me {
    height: 10px;
    background-position: -3182px 0px;
}

.flag-ele.mf {
    height: 14px;
    background-position: -3204px 0px;
}

.flag-ele.mg {
    height: 14px;
    background-position: -3226px 0px;
}

.flag-ele.mh {
    height: 11px;
    background-position: -3248px 0px;
}

.flag-ele.mk {
    height: 10px;
    background-position: -3270px 0px;
}

.flag-ele.ml {
    height: 14px;
    background-position: -3292px 0px;
}

.flag-ele.mm {
    height: 14px;
    background-position: -3314px 0px;
}

.flag-ele.mn {
    height: 10px;
    background-position: -3336px 0px;
}

.flag-ele.mo {
    height: 14px;
    background-position: -3358px 0px;
}

.flag-ele.mp {
    height: 10px;
    background-position: -3380px 0px;
}

.flag-ele.mq {
    height: 14px;
    background-position: -3402px 0px;
}

.flag-ele.mr {
    height: 14px;
    background-position: -3424px 0px;
}

.flag-ele.ms {
    height: 10px;
    background-position: -3446px 0px;
}

.flag-ele.mt {
    height: 14px;
    background-position: -3468px 0px;
}

.flag-ele.mu {
    height: 14px;
    background-position: -3490px 0px;
}

.flag-ele.mv {
    height: 14px;
    background-position: -3512px 0px;
}

.flag-ele.mw {
    height: 14px;
    background-position: -3534px 0px;
}

.flag-ele.mx {
    height: 12px;
    background-position: -3556px 0px;
}

.flag-ele.my {
    height: 10px;
    background-position: -3578px 0px;
}

.flag-ele.mz {
    height: 14px;
    background-position: -3600px 0px;
}

.flag-ele.na {
    height: 14px;
    background-position: -3622px 0px;
}

.flag-ele.nc {
    height: 10px;
    background-position: -3644px 0px;
}

.flag-ele.ne {
    height: 15px;
    background-position: -3666px 0px;
}

.flag-ele.nf {
    height: 10px;
    background-position: -3686px 0px;
}

.flag-ele.ng {
    height: 10px;
    background-position: -3708px 0px;
}

.flag-ele.ni {
    height: 12px;
    background-position: -3730px 0px;
}

.flag-ele.nl {
    height: 14px;
    background-position: -3752px 0px;
}

.flag-ele.no {
    height: 15px;
    background-position: -3774px 0px;
}

.flag-ele.np {
    height: 15px;
    background-position: -3796px 0px;
}

.flag-ele.nr {
    height: 10px;
    background-position: -3811px 0px;
}

.flag-ele.nu {
    height: 10px;
    background-position: -3833px 0px;
}

.flag-ele.nz {
    height: 10px;
    background-position: -3855px 0px;
}

.flag-ele.om {
    height: 10px;
    background-position: -3877px 0px;
}

.flag-ele.pa {
    height: 14px;
    background-position: -3899px 0px;
}

.flag-ele.pe {
    height: 14px;
    background-position: -3921px 0px;
}

.flag-ele.pf {
    height: 14px;
    background-position: -3943px 0px;
}

.flag-ele.pg {
    height: 15px;
    background-position: -3965px 0px;
}

.flag-ele.ph {
    height: 10px;
    background-position: -3987px 0px;
}

.flag-ele.pk {
    height: 14px;
    background-position: -4009px 0px;
}

.flag-ele.pl {
    height: 13px;
    background-position: -4031px 0px;
}

.flag-ele.pm {
    height: 14px;
    background-position: -4053px 0px;
}

.flag-ele.pn {
    height: 10px;
    background-position: -4075px 0px;
}

.flag-ele.pr {
    height: 14px;
    background-position: -4097px 0px;
}

.flag-ele.ps {
    height: 10px;
    background-position: -4119px 0px;
}

.flag-ele.pt {
    height: 14px;
    background-position: -4141px 0px;
}

.flag-ele.pw {
    height: 13px;
    background-position: -4163px 0px;
}

.flag-ele.py {
    height: 11px;
    background-position: -4185px 0px;
}

.flag-ele.qa {
    height: 8px;
    background-position: -4207px 0px;
}

.flag-ele.re {
    height: 14px;
    background-position: -4229px 0px;
}

.flag-ele.ro {
    height: 14px;
    background-position: -4251px 0px;
}

.flag-ele.rs {
    height: 14px;
    background-position: -4273px 0px;
}

.flag-ele.ru {
    height: 14px;
    background-position: -4295px 0px;
}

.flag-ele.rw {
    height: 14px;
    background-position: -4317px 0px;
}

.flag-ele.sa {
    height: 14px;
    background-position: -4339px 0px;
}

.flag-ele.sb {
    height: 10px;
    background-position: -4361px 0px;
}

.flag-ele.sc {
    height: 10px;
    background-position: -4383px 0px;
}

.flag-ele.sd {
    height: 10px;
    background-position: -4405px 0px;
}

.flag-ele.se {
    height: 13px;
    background-position: -4427px 0px;
}

.flag-ele.sg {
    height: 14px;
    background-position: -4449px 0px;
}

.flag-ele.sh {
    height: 10px;
    background-position: -4471px 0px;
}

.flag-ele.si {
    height: 10px;
    background-position: -4493px 0px;
}

.flag-ele.sj {
    height: 15px;
    background-position: -4515px 0px;
}

.flag-ele.sk {
    height: 14px;
    background-position: -4537px 0px;
}

.flag-ele.sl {
    height: 14px;
    background-position: -4559px 0px;
}

.flag-ele.sm {
    height: 15px;
    background-position: -4581px 0px;
}

.flag-ele.sn {
    height: 14px;
    background-position: -4603px 0px;
}

.flag-ele.so {
    height: 14px;
    background-position: -4625px 0px;
}

.flag-ele.sr {
    height: 14px;
    background-position: -4647px 0px;
}

.flag-ele.ss {
    height: 10px;
    background-position: -4669px 0px;
}

.flag-ele.st {
    height: 10px;
    background-position: -4691px 0px;
}

.flag-ele.sv {
    height: 12px;
    background-position: -4713px 0px;
}

.flag-ele.sx {
    height: 14px;
    background-position: -4735px 0px;
}

.flag-ele.sy {
    height: 14px;
    background-position: -4757px 0px;
}

.flag-ele.sz {
    height: 14px;
    background-position: -4779px 0px;
}

.flag-ele.ta {
    height: 10px;
    background-position: -4801px 0px;
}

.flag-ele.tc {
    height: 10px;
    background-position: -4823px 0px;
}

.flag-ele.td {
    height: 14px;
    background-position: -4845px 0px;
}

.flag-ele.tf {
    height: 14px;
    background-position: -4867px 0px;
}

.flag-ele.tg {
    height: 13px;
    background-position: -4889px 0px;
}

.flag-ele.th {
    height: 14px;
    background-position: -4911px 0px;
}

.flag-ele.tj {
    height: 10px;
    background-position: -4933px 0px;
}

.flag-ele.tk {
    height: 10px;
    background-position: -4955px 0px;
}

.flag-ele.tl {
    height: 10px;
    background-position: -4977px 0px;
}

.flag-ele.tm {
    height: 14px;
    background-position: -4999px 0px;
}

.flag-ele.tn {
    height: 14px;
    background-position: -5021px 0px;
}

.flag-ele.to {
    height: 10px;
    background-position: -5043px 0px;
}

.flag-ele.tr {
    height: 14px;
    background-position: -5065px 0px;
}

.flag-ele.tt {
    height: 12px;
    background-position: -5087px 0px;
}

.flag-ele.tv {
    height: 10px;
    background-position: -5109px 0px;
}

.flag-ele.tw {
    height: 14px;
    background-position: -5131px 0px;
}

.flag-ele.tz {
    height: 14px;
    background-position: -5153px 0px;
}

.flag-ele.ua {
    height: 14px;
    background-position: -5175px 0px;
}

.flag-ele.ug {
    height: 14px;
    background-position: -5197px 0px;
}

.flag-ele.um {
    height: 11px;
    background-position: -5219px 0px;
}

.flag-ele.un {
    height: 14px;
    background-position: -5241px 0px;
}

.flag-ele.us {
    height: 11px;
    background-position: -5263px 0px;
}

.flag-ele.uy {
    height: 14px;
    background-position: -5285px 0px;
}

.flag-ele.uz {
    height: 10px;
    background-position: -5307px 0px;
}

.flag-ele.va {
    height: 15px;
    background-position: -5329px 0px;
}

.flag-ele.vc {
    height: 14px;
    background-position: -5346px 0px;
}

.flag-ele.ve {
    height: 14px;
    background-position: -5368px 0px;
}

.flag-ele.vg {
    height: 10px;
    background-position: -5390px 0px;
}

.flag-ele.vi {
    height: 14px;
    background-position: -5412px 0px;
}

.flag-ele.vn {
    height: 14px;
    background-position: -5434px 0px;
}

.flag-ele.vu {
    height: 12px;
    background-position: -5456px 0px;
}

.flag-ele.wf {
    height: 14px;
    background-position: -5478px 0px;
}

.flag-ele.ws {
    height: 10px;
    background-position: -5500px 0px;
}

.flag-ele.xk {
    height: 15px;
    background-position: -5522px 0px;
}

.flag-ele.ye {
    height: 14px;
    background-position: -5544px 0px;
}

.flag-ele.yt {
    height: 14px;
    background-position: -5566px 0px;
}

.flag-ele.za {
    height: 14px;
    background-position: -5588px 0px;
}

.flag-ele.zm {
    height: 14px;
    background-position: -5610px 0px;
}

.flag-ele.zw {
    height: 10px;
    background-position: -5632px 0px;
}


i.flag-ele {
    position: absolute;
    right: 2px;
    bottom: 23px;
}
