/*amdin-wrp css code here **************************/
.admin-assess-switch code > svg,
.admin-assess-switch i > svg,
.admin-access-panel > div > div svg,
.admin-access-panel > div > div img,
.onboardHolder .left-side > div,
.onboardHolder .right-side picture
 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.admin-assess-switch.close code > svg:first-child,
.admin-assess-switch.open code > svg:nth-child(2) {
  display: none;
}

.amdin-wrp {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background-color: #f2f7fb;
}

.admin-header a.chatsupport-logo {
  float: left;
  margin: 45px 0px 0px 50px;
}

.admin-header a.chatsupport-logo > img {
  width: 160px;
}

.admin-header > div {
  float: right;
  text-align: right;
  margin: 45px 50px 0px 0px;
}

.admin-header > div p {
  font-style: 14px;
  color: #040404;
  margin: 0px 0px 8px;
}

.admin-header > div u {
  color: #040404;
  opacity: 0.5;
  font-size: 12px;
  cursor: pointer;
}

.admin-access-panel {
  text-align: center;
  margin: -125px 0px 0px -285px;
  position: absolute;
  left: 50%;
  top: 50%;
}

.admin-access-panel h4 {
  font-size: 22px;
  opacity: 0.5;
  color: #040404;
  text-transform: uppercase;
}

.admin-access-panel h2 {
  font-size: 42px;
  color: #040404;
  margin: 13px 0px 0px;
}

.admin-access-panel > div {
  border-radius: 5px;
  width: 570px;
  margin: 40px auto 0px;
  position: relative;
}

.admin-access-panel > div > div {
  height: 60px;
  width: 66px;
  position: absolute;
  border-radius: 5px;
  background-color: #4695d9;
  cursor: pointer;
  top: 0;
  right: 0;
}

.admin-access-panel > div > p {
  display: none;
}
.admin-access-panel > div.error-field > p {
  position: absolute;
  color: #e67777;
  margin: -20px 0px 0px 5px;
  text-align: left;
  font-size: 14px;
  display: block;
}

.admin-access-panel > div.error-field input {
  background-color: #fbe8e5;
  border: 1px solid #fbe8e5;
}

.admin-access-panel > div input {
  width: 565px;
  padding: 16px 100px 16px 30px;
  border-radius: 5px;
  border: 1px solid #d0d0d0;
  font-size: 22px;
}

.admin-access-panel > div i:before {
  content: "\f002";
  font-family: "Font Awesome 5 Regular";
  background-color: #4695d9;
  width: 66px;
  height: 60px;
  position: absolute;
  right: 0px;
  border-radius: 5px;
  font-style: normal;
  line-height: 60px;
  color: #ffffff;
  cursor: pointer;
}

.admin-access-panel > div i.loading:before {
  content: "";
  background: #4695d9 url(../images/admin-loading-icon.gif) 17px 15px no-repeat;
}

.admin-access-panel > div ul {
  position: absolute;
  top: 58px;
  left: 3px;
  right: 0px;
  background-color: #ffffff;
  border-radius: 0px 0px 5px 5px;
  border: 1px solid #d0d0d0;
  padding: 10px 30px;
  z-index: 1;
  display: none;
  overflow: auto;
  min-height: auto;
  max-height: 210px;
}

.admin-access-panel > div ul li {
  text-align: left;
  font-size: 22px;
  color: #040404;
  padding: 20px 0px;
  cursor: pointer;
}

.admin-access-panel > div ul li img {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  float: left;
  margin: -6px 24px 0px 0px;
  object-fit: cover;
}
.admin-access-panel > div ul li img.df-logo {
  background-color: #d9dee4;
  border: 1px solid #e6eaee;
  box-shadow: none;
}

.admin-access-panel > div ul.email-access li img {
  display: none;
}
.admin-access-panel > div ul.email-access li:before {
  content: "\f0e0";
  font-family: "Font Awesome 5 Solid";
  margin: 0px 24px 0px 0px;
  color: #cbcbcb;
}

.admin-access-panel > div span {
  font-size: 16px;
  text-transform: uppercase;
  color: #040404;
  opacity: 0.5;
  display: block;
  margin: 20px 0px;
}

.admin-access-panel > div a {
  color: #4695d9;
  font-size: 16px;
  display: initial;
}

/*Placeholder code here -----------------------------------------------------------------*/
.admin-access-panel input::-webkit-input-placeholder {
  /* Chrome */
  color: rgba(4, 4, 4, 0.2);
}
.admin-access-panel input:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(4, 4, 4, 0.2);
}
.admin-access-panel input::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(4, 4, 4, 0.2);
  opacity: 1;
}
.admin-access-panel input:-moz-placeholder {
  /* Firefox 4 - 18 */
  color: rgba(4, 4, 4, 0.2);
  opacity: 1;
}

.admin-assess-switch span {
  color: rgba(251, 251, 251, 0.5);
  font-size: 16px;
  padding: 18px 15px 18px 42px;
  display: inline-block;
  border: 1px solid rgba(251, 251, 251, 0.2);
  border-width: 0px 1px 0px 0px;
}

.admin-assess-switch label {
  color: #fff;
  font-weight: 600;
  margin: 0 0 0 14px;
  font-size: 16px;
  width: 136px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  line-height: normal;
  display: inline-block;
  vertical-align: sub;
}

.admin-assess-switch i {
  width: 52px;
  height: 52px;
  background-color: #e36c6d;
  border-radius: 100%;
  position: absolute;
  right: 0px;
  font-style: normal;
  cursor: pointer;
}

.admin-assess-switch code {
  position: absolute;
  width: 52px;
  height: 52px;
  border-radius: 100%;
  background-color: #202a3d;
  right: 26px;
  bottom: 0;
  cursor: pointer;
  z-index: 1;
}

/* Project switch css starts here */

.select_project {
  background-color: #fafbfc;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  overflow: auto;
  /* width: 100%;
    height:100%; */
}

.header {
  display: block;
  position: fixed;
  width: 100%;
  /* padding-top: 64px; */
  box-sizing: border-box;
  background: #fafbfc;
  z-index: 1;
  padding: 64px 0px 20px;
}

.header img {
  display: block;
  margin: auto;
  width: 160px;
}

.projects-list {
  margin: 160px auto 0px;
  width: 577px;
  overflow: auto;
}

.projects-list li {
  height: 100px;
  background-color: #fff;
  margin-bottom: 12px;
  border: 1.3px solid #e6eaee;
  border-radius: 4px;
}

.projects-list div {
  margin: 20px;
}

.projects-list div img {
  height: 56px;
  width: 56px;
  border-radius: 100%;
  object-fit: contain;
  border: 1px solid #e6eaee;
  background-color: #d9dee4;
}

.projects-list .container label {
  font-size: 16px;
  font-weight: 600;
  color: #251b18;
  font-style: normal;
  font-stretch: normal;
  line-height: 3.5;
  letter-spacing: normal;
  display: inline-block;
  vertical-align: top;
  margin-left: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 300px;
}

.projects-list .container a {
  float: right;
  border: 1px solid #4695d9;
  color: #4695d9;
  padding: 9px 22px 9px 22px;
  border-radius: 2px;
  margin-top: 12px;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
}

.projects-list .container a:hover {
  background-color: #1585d8;
  color: #fff;
}

/* Project switch css Ends here */

.admin-assess-switch span.active:before {
  content: "";
  background-color: #78d700;
}
.admin-assess-switch span.inactive:before {
  content: "";
  background-color: #ee0529;
}
.admin-assess-switch span.broken:before {
  content: "";
  background-color: #ff9400;
}
.admin-assess-switch span.invalid:before {
  content: "";
  background-color: #acb7cc;
}
.admin-assess-switch.admin-view {
  right: 228px;
}
.admin-assess-switch.admin-view.admin-view-chat {
  right: 420px;
}

.admin-assess-switch {
  position: fixed;
  height: 52px;
  width: 52px;
  right: 20px;
  bottom: 22px;
  overflow: hidden;
  z-index: 2;
  transition: width 0.4s linear;
}

.admin-assess-switch > div {
  background-color: #202a3d;
  border-radius: 30px;
}

.admin-assess-switch.close > div {
  right: -330px;
  position: absolute;
  overflow: hidden;
  opacity: 0;
  transition: all 0.4s ease;
  bottom: 0px;
  z-index: 1;
}

.admin-assess-switch.open > div {
  right: 60px;
  overflow: visible;
  opacity: 1;
  transition: all 0.4s ease;
  width: 363px;
  position: absolute;
  bottom: 0px;
  z-index: 9;
}

.admin-assess-switch figure {
  position: absolute;
  width: 52px;
  height: 52px;
  background-color: #fafbfc;
  right: -26px;
  bottom: 0px;
  cursor: pointer;
  z-index: 9;
}

.admin-assess-switch.open {
  width: 423px;
  transition: width 0.1s linear;
}
.admin-assess-switch span:before {
  width: 10px;
  height: 10px;
  content: "";
  background-color: #03c599;
  border-radius: 100%;
  position: absolute;
  left: 20px;
  top: 50%;
  margin: -4px 0px 0px 0px;
}

@-moz-document url-prefix() {
  .admin-assess-switch span:before {
    margin: -5px 0px 0px 0px;
  }
}

/* preview chat pannel styling */

.preview-chat-body .chat-from {
  text-align: left;
  margin: 13px 10px;
  border-radius: 30px;
  position: relative;
  display: block;
  /*min-height: 40px;*/
}

.preview-chat-body .chat-from > img {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin: 0;
  position: absolute;
  bottom: 0;
  object-fit: cover;
  z-index: 1;
  margin: 0px;
}

.preview-chat-body .chat-from.chat-first > div.txt {
  margin-left: 10px;
  max-width: 150px;
}

.preview-chat-body .chat-from > div.txt:before {
  content: "";
  width: 15px;
  height: 12px;
  background-color: #f5f6f7;
  position: absolute;
  left: -7px;
  bottom: 0;
}

.preview-chat-body .chat-from > div.txt:after {
  content: "";
  width: 15px;
  height: 17px;
  background-color: #fff;
  position: absolute;
  left: -15px;
  bottom: -2px;
  border-radius: 0 0 50px 0;
}

.preview-chat-body .chat-from.chat-join div {
  background-color: #fff;
  padding: 0px 0;
  color: #3c4859;
  -webkit-text-fill-color: #3c4859;
  margin: 0 auto;
  display: block;
  text-align: center;
  opacity: 0.5;
  font-size: 8px;
  font-family: Lato, sans-serif;
  max-width: 200px;
}

.preview-chat-body .chat-from > div {
  display: inline-block;
  margin: 0 0 0 30px;
  position: relative;
  padding: 5px 10px 5px;
  background-color: rgba(68, 82, 102, 0.05);
  border-radius: 10px;
  color: #181818;
  -webkit-text-fill-color: #181818;
  line-height: 1.43;
  font-size: 9px;
  min-width: auto;
  max-width: 200px;
  word-wrap: break-word;
}

.preview-chat-body .chat-from > div small {
  position: absolute;
  bottom: -17px;
  left: 70px;
  font-size: 10px;
  color: #3c4859;
  -webkit-text-fill-color: #3c4859;
  opacity: 0.5;
  display: none;
  font-family: Lato, sans-serif;
  font-weight: 600;
}

.preview-chat-body .chat-from.chat-first > div small {
  left: 27px;
}

.preview-chat-body .chat-from > small {
  position: absolute;
  bottom: -8px;
  left: 46px;
  font-size: 5px;
  color: #3c4859;
  -webkit-text-fill-color: #3c4859;
  opacity: 0.5;
  display: none;
  font-family: "Lato", sans-serif;
  font-weight: 600;
}
.preview-chat-body .chat-to > small {
  position: absolute;
  bottom: -9px;
  right: 24px;
  font-size: 6px;
  color: rgba(60, 72, 89, 0.5);
  -webkit-text-fill-color: rgba(60, 72, 89, 0.5);
  display: none;
  font-family: "Lato", sans-serif;
  font-weight: 600;
}
.preview-chat-body .chat-from:hover > small,
.preview-chat-body .chat-to:hover > small {
  display: block;
}
.preview-chat-body .chat-to > small i {
  font-size: 11px;
  position: absolute;
  color: #7f8fa4;
  -webkit-text-fill-color: #7f8fa4;
  margin: 2px 0 0 3px;
  font-style: normal;
  padding: 2px;
  background-size: 4px !important;
}

.preview-chat-body .chat-to > small i.read-icon {
  background: url(https://storage.googleapis.com/livesupport/chat/images/read.svg)
    no-repeat;
}

.preview-chat-body .chat-to {
  text-align: right;
  margin: 13px 10px;
  border-radius: 30px;
  position: relative;
  display: inherit;
}

.preview-chat-body .chat-to > div {
  display: inline-block;
  margin: 0;
  position: relative;
  padding: 5px 10px 5px;
  background-color: rgba(63, 92, 134, 0.14);
  border-radius: 10px;
  color: #181818;
  -webkit-text-fill-color: #181818;
  line-height: 1.43;
  font-size: 9px;
  text-align: left;
  min-width: auto;
  max-width: 140px;
  word-wrap: break-word;
  margin-right: 7px;
}

.preview-chat-body .chat-to > div:before {
  content: "";
  width: 15px;
  height: 12px;
  background-color: #e4e8ee;
  position: absolute;
  right: -7px;
  bottom: 0;
}

.preview-chat-body .chat-to > div:after {
  content: "";
  width: 15px;
  height: 17px;
  background-color: #fff;
  position: absolute;
  right: -15px;
  bottom: -2px;
  border-radius: 0 0 0 50px;
}

.preview-chat-panel {
  width: 200px;
  margin: 0;
  -webkit-box-shadow: 0 5px 40px rgba(0, 0, 0, 0.16) !important;
  box-shadow: 0 5px 40px rgba(0, 0, 0, 0.16) !important;
  border-radius: 6px;
  opacity: 1;
  background-color: #fff;
  position: absolute;
  /* right: 74px; */
  /* left:1165px; */
  left: 700px;
  margin: 0px;
  top: 165px;
  /* height:200px; */
  /* max-height: calc(100vh - 95px); */
}

.preview-chat-panel:before {
  content: "";
  background: url(https://storage.googleapis.com/livesupport/chat/images/expanded-preview-arrow.svg)
    no-repeat;
  width: 300px;
  height: 95px;
  position: absolute;
  top: -105px;
  background-size: contain;
}

.preview-chat-panel.hide-chat-view:before {
  display: none;
}

.preview-chat-panel > code.widget-icon {
  height: 43px;
  width: 43px;
  display: inline-block;
  text-align: center;
  line-height: 43px;
  cursor: pointer;
  position: absolute;
  left: 170px;
  top: 324px;
  z-index: 1;
  border-radius: 50px;
  user-select: none;
  background-image: url(https://assets.chatsupport.co/website/stag/images/chatsupport-icon-logo.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
}
.preview-chat-panel > code.widget-icon img {
  width: 43px;
  height: 43px;
  border-radius: 100%;
  /* border: 2px solid #6598fe; */
  object-fit: cover;
  background-color: #ffffff;
}

/* .preview-chat-panel > code.widget-icon:before{
    content: "";
    background: url(https://storage.googleapis.com/livesupport/chat/images/normal-preview-arrow.svg) no-repeat;
    width: 300px;
    height: 140px;
    position: absolute;
    bottom: 56px;
    right: 25px;
    pointer-events: none;
} */

.preview-chat-panel code span {
  /* font-family: "Lato", sans-serif;
    position: absolute;
    font-size: 9px;
    color: #3c4859;
    white-space: nowrap;
    right: 12px;
    bottom: -4px;
    border-radius: 20px;
    line-height: 36px;
    background: url(https://storage.googleapis.com/livesupport/chat/images/widget-speech-bubble.svg) 0 0 no-repeat;
    width: 112px;
    height: 37px;
    text-align: center;
    background-size: 80px; */
  font-family: "Lato", sans-serif;
  position: absolute;
  font-size: 9px;
  color: #3c4859;
  white-space: nowrap;
  right: 62px;
  background: url("https://storage.googleapis.com/livesupport/chat/images/bubble-middle.svg")
    0 -18px repeat-x;
  height: 40px;
  line-height: 40px;
  background-size: 20px;
  bottom: -4px;
  padding: 0px 0px 0px 2px;
}

.preview-chat-panel code span:before {
  content: "";
  position: absolute;
  background: url("https://storage.googleapis.com/livesupport/chat/images/bubble-left.svg")
    0 -18px no-repeat;
  background-size: 20px;
  width: 30px;
  height: 40px;
  left: -20px;
}

.preview-chat-panel code span:after {
  content: "";
  position: absolute;
  background: url("https://storage.googleapis.com/livesupport/chat/images/bubble-right.svg")
    0 -18px no-repeat;
  background-size: 20px;
  width: 30px;
  height: 40px;
  right: -30px;
}

.preview-chat-panel code i {
  position: absolute;
  width: 43px;
  height: 43px;
  border: 2px solid #6598fe;
  border-radius: 100%;
  left: 0;
  top: 0;
}

.preview-chat-panel code span b {
  /* margin-left: -31px; */
  color: #3c4859;
  position: relative;
  /* padding-right: 2px; */
  font-weight: normal;
}

/*.preview-chat{
    width: 350px;
}*/

.preview-chat-heading {
  position: relative;
  min-height: 40px;
  color: #000;
  -webkit-text-fill-color: #000;
  border-radius: 6px 6px 0px 0px;
  border: 1px solid #eef0f1;
  border-width: 0 0 1px 0;
  background: #fff
    url(https://storage.googleapis.com/livesupport/chat/images/widget-tree.svg)
    130px 10px no-repeat;
  background-size: 60px;
}

.preview-chat-heading code {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 9px;
  font-size: 14px;
  line-height: 6px;
  color: #a1a1a1;
  -webkit-text-fill-color: #a1a1a1;
}

.preview-chat-heading h2 {
  font-size: 15px;
  margin: 0;
  color: #181818;
  -webkit-text-fill-color: #181818;
  font-family: Lato, sans-serif;
  font-weight: 700;
  line-height: 1.35;
  padding: 10px 0 10px 10px;
  height: 61px;
  vertical-align: middle;
  display: table-cell;
  width: 120px;
  word-break: break-word;
}
.preview-chat-heading h2 span {
  color: #6598fe;
  -webkit-text-fill-color: #6598fe;
}

/* .preview-chat-heading h2:after {
    content: "";
    width: 2px;
    height: 2px;
    border-radius: 100%;
    background-color: #6598fe;
    margin: 11px 0px 0px -3px;
    position: absolute;
} */
.preview-chat-heading h2.hide-dot:after {
  display: none;
}
.preview-chat-body {
  /* max-height: calc(100vh - 332px); */
  height: 255px;
  overflow-y: auto;
}

.preview-chat-text {
  min-height: 48px;
  border-radius: 0px 0px 6px 6px;
  border: 1px solid #eef0f1;
  border-width: 1px 0px 0px;
  position: relative;
}

.preview-chat-text textarea {
  cursor: not-allowed;
  background-color: #fff;
  bottom: 0;
  left: 0;
  color: rgba(0, 0, 0, 0.3);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.3);
  resize: none;
  border: none;
  font-size: 10px;
  /* line-height: 1.32; */
  width: 100%;
  margin: 0px 0px -4px 0px;
  border-radius: 0px 0px 6px 6px;
  height: 50px;
  max-height: 24px;
  min-height: 24px;
  /* padding: 20px 90px 20px 20px; */
  padding: 7px 40px 6px 10px;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: Lato, sans-serif;
  -webkit-transition: background-color ease 0.2s, -webkit-box-shadow ease 0.2s;
  transition: background-color ease 0.2s, -webkit-box-shadow ease 0.2s;
  transition: background-color ease 0.2s, box-shadow ease 0.2s;
  transition: background-color ease 0.2s, box-shadow ease 0.2s,
    -webkit-box-shadow ease 0.2s;
}

.preview-chat-composer {
  position: absolute;
  right: 0px;
  z-index: 1;
  bottom: 0px;
  height: 24px;
  left: 0px;
  background-color: #ffffff;
  border-radius: 0px 0px 30px 30px;
}

.preview-chat-composer i.attachment {
  /* border: 0;
    padding: 0;
    margin: -5px 50px 0 0px;
    cursor: pointer;
    background-color: transparent;
    float: right;*/
  position: absolute;
  left: 10px;
  margin: 3px 0 0;
  cursor: not-allowed;
  background: url(https://storage.googleapis.com/livesupport/chat/images/chat-support-attachment-icon-new.svg)
    center no-repeat;
  width: 6px;
  height: 12px;
  background-size: 6px !important;
}

/* .preview-chat-composer i.attachment:before{
    content: "";
    cursor: not-allowed;
    opacity: .6;
    background: url(https://storage.googleapis.com/livesupport/chat/images/chat-support-attachment-icon.svg) center no-repeat;
    display: block;
    width: 30px;
    height: 30px;
} */

.preview-chat-composer i.send-msg {
  position: absolute;
  right: 0;
  width: 18px;
  height: 18px;
  margin: 0px 10px 0px 0px;
  border-radius: 100%;
  cursor: not-allowed;
  background: #6499fe
    url(https://storage.googleapis.com/livesupport/chat/images/send-btn.svg)
    center 5px no-repeat;
  background-size: 10px !important;
}

.popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  padding: 0 30px;
}
.default-popup {
  width: 470px;
}

.email-popup div.formField {
  padding: 3px 20px;
  margin: 0px;
  border: 0px;
}

.email-popup div.formField input {
  width: 100%;
}

.email-popup div.formField textarea {
  width: 100% !important;
  height: 77px !important;
}

.email-popup div.formField a.add-link {
  font-size: 12px;
  text-decoration: none;
  color: #4695d9;
  margin-bottom: 20px;
  display: inline-block;
}

.email-popup div.formField div.inputTxt code:before {
  content: "\f06a";
  font-family: "FontAwesome";
  right: 15px;
  top: 15px;
  color: #cf0000;
  font-size: 13px;
  position: absolute;
}

.email-popup div.formField div.inputTxt code:after {
  content: "You've entered an invalid EmailID";
  padding: 8px;
  border: 1px solid #e6eaee;
  background-color: #ffffff;
  font-size: 12px;
  color: #cf0000;
  box-shadow: 0 2px 4px 0 rgba(202, 202, 202, 0.5);
  position: absolute;
  right: 5px;
  top: 30px;
  z-index: 1;
  border-radius: 3px;
  display: none;
}

.email-popup div.formField div.inputTxt code:hover:after {
  display: block;
}

/* Manage profile css starts here  */

.manage_profile {
  width: 581px;
  height: auto;
  box-shadow: 0 2px 4px 0 rgba(241, 241, 241, 0.5);
  background-color: #ffffff;
  margin: 30px 520px 30px 350px;
}

.content-header {
  width: 521px;
  line-height: 59px;
  height: 59px;
  background-color: #ffffff;
  margin: auto;
  border-bottom: 1px solid #e1e4e6;
}

.content-header p {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #354052;
}

.content-header a {
  float: right;
  color: #4695d9;
  margin-top: 2px;
  font-size: 16px;
  cursor: pointer;
}

.manage-project-list {
  padding: 0px 0px 24px;
}

.manage-project-list li {
  box-sizing: border-box;
  padding: 24px 30px 0px;
}

.manage-project-list li div img {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  border: 1px solid #d8dce1;
  object-fit: contain;
  background-color: #d9dee4;
}

.manage-project-list li div h5 {
  display: inline-block;
  vertical-align: top;
  margin-left: 20px;
  font-size: 16px;
  margin-top: 16px;
  color: #354052;
}

.manage-project-list li div button {
  float: right;
  margin: 7px 0px;
  padding: 8px 10px 7px;
  min-width: 120px;
  border: 1px solid #1585d8;
  background-color: #ffffff;
  color: #1585d8;
  font-size: 14px;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.36;
}

.manage-project-list li.active::before {
  content: "";
  height: 22px;
  width: 4px;
  background-color: #4695d9;
  display: block;
  float: left;
  margin-top: 13px;
  margin-left: -30px;
}

.loading-bg {
  background-color: #000000;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 3;
  opacity: 0.5;
  /* display: none; */
}
.loading-data {
  width: 370px;
  height: 200px;
  background-color: #ffffff;
  position: fixed;
  z-index: 4;
  box-shadow: 0 0 5px 0 rgba(241, 241, 241, 0.5);
  text-align: center;
  top: 50%;
  left: 50%;
  margin: -100px 0px 0px -185px;
  /* display:none; */
}

.loading-data img {
  margin: 60px 0px 20px 0px;
  width: 50px;
}

.loading-data p {
  opacity: 0.5;
  color: #251b18;
}

.overflow-col3 .cs-user-details{
  height: calc(100vh - 50px);
}

.overflow-col3 .cs-typing-footer div.more-icon-dwn {
  left: 48px;
}

/* react tool tip code */

.__react_component_tooltip.type-dark {
    font-family: 'Lato', sans-serif;
    box-shadow: 0 2px 8px 5px rgba(0, 0, 0, 0.14) !important;
    background-color: #000000 !important;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.14) !important;
}

.__react_component_tooltip.type-dark.place-bottom:after {
    border-bottom-color: #000000  !important; 
}
.__react_component_tooltip.type-dark.place-right:after {
    border-right-color: #000000  !important; 
}

.__react_component_tooltip.type-dark.place-top:after {
    border-top-color: #000000  !important; 
}
.__react_component_tooltip.type-dark.place-left:after {
    border-left-color: #000000  !important; 
}

.__react_component_tooltip.show {
    opacity: 1 !important;
}

.__react_component_tooltip p {
    font-family: 'Lato', sans-serif !important;
    color: #404c5c; 
}

.__react_component_tooltip {
    padding:6px 16px;
    line-height: 17px;
    text-align: center;
    font-size: 13px !important;
}

.error-popup
{
    width : 380px;
}


/*login code here -----------------------------------------------------------------*/
.loginWrp {
  position: absolute;
  background-color: #ffffff;
  width: 100%;
  height: 100vh;
  /*padding: 0px 0px 120px 0px;
        text-align: center;*/
  font-family: "metropolis", sans-serif;
}

.loginLogo {
  margin: 22px 0px 0px 22px;
  display: inline-block;
  text-align: left;
  /* background-size: 170px;
  padding: 0px 0px 0px 40px; */
  }

  .loginLogo > img{
    width: 160px;
    height: 45px;
  }

.loginGoogleBtn {
  color: #4685ff;
  border: solid 2px #4685ff;
  font-size: 18px;
  cursor: pointer;
  background: url("../images/icon-google-plus-colored.png") 12px 13px no-repeat;
  padding: 10px 55px 10px 70px;
  font-family: "EuclidCircularB", sans-serif;
  font-weight: 500;
  opacity: 1;
  border-radius: 25px;
  background-size: 18px;
  width: 100%;
  height: 46px;
}

/*or code here -----------------------------------------------------------------*/
.or {
  border: 1px solid #7a879c;
  border-width: 1px 0px 0px 0px;
  width: 310px;
  position: relative;
  left: 50%;
  margin: 60px 0px 60px -155px;
}

.or span {
  text-transform: uppercase;
  font-size: 14px;
  color: #7a879c;
  position: absolute;
  margin: -13px 0px 0px -25px;
  background-color: #fff;
  padding: 5px 7px;
  font-family: "EuclidCircularB", sans-serif;
  letter-spacing: 1px;
  left:50%;
}

.signinHolder,
.ForgotHolder {
  width: 390px;
  padding: 40px 25px;
  box-shadow: 0 6px 18px 0 rgba(219, 227, 233, 0.5);
  border-radius: 6px;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -300px 0px 0px -195px;
}

.resetPwdHolder {
  top: 50%;
  position: relative;
  transform: translateY(-270px);
  padding: 110px 0px 0px;
  text-align: center;
}

.resetPwdHolder span {
  font-family: "EuclidCircularB", sans-serif;
}

.ForgotHolder {
    top: 50%;
    position: relative;
    transform: translateY(-257px);
    margin: 0px 0px 0px -195px;
}

.ForgotHolder > span {    
  padding: 0px 0px 30px 0px;    
  display: inherit;    
  font-size: 16px;    
  line-height: 1.43;
  font-family: "EuclidCircularB", sans-serif;
}

.ForgotHolder h2,
.resetPwdHolder h2
 {
  margin-bottom: 10px !important;
}

.signinHolder h2,
.ForgotHolder h2,
.resetPwdHolder h2 {
  color: #38414d;
  font-size: 30px;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 60px;
  font-family: "EuclidCircularB", sans-serif;
}

.signinHolder h2 span,
.ForgotHolder h2 span,
.resetPwdHolder h2 span {
  color: #4685ff;
}

/*inputGroup code here -----------------------------------------------------------------*/
.inputGroup {
  margin: 0px 0px 16px 0px;
  text-align: left;
}

.inputGroup label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #38414d;
  margin: 0px 0px 6px 0px;
}

.inputGroup input {
  width: 100%;
  outline: 0;
  padding: 13px 20px;
  border-radius: 6px;
  background-color: #eef2f5;
  border: 1px solid #e2e6e9;
  font-size: 18px;
  color: #38414d;
  font-family: "EuclidCircularB", sans-serif;
}

.inputGroup input:focus {
  border-color: #90a2bd;
}

.inputGroup.inputError input,.inputGroup-new.inputError input {
  border: 1px solid #f7694e;
}

.inputGroup p {
  display: none;
}

.inputGroup.inputError p,.inputGroup-new.inputError p {
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: #f7694e;
  margin: 3px 0px 0px;
  line-height: 1.62;
}

.forgotPass {
  text-decoration: none;
  font-size: 13px;
  font-weight: 500;
  color: #4685ff;
  text-align: right;
  display: block;
  line-height: 20px;
}

/*btnLogin code here -----------------------------------------------------------------*/
.btnLogin {
  width: 100%;
  outline: 0;
  background-color: #ffffff;
  color: #4685ff;
  border: solid 2px #4685ff;
  font-size: 18px;
  cursor: pointer;
  padding: 10px 55px;
  font-family: "EuclidCircularB", sans-serif;
  font-weight: 500;
  opacity: 1;
  border-radius: 25px;
  background-size: 18px;
  margin: 14px 0px;
  height: 46px;
}

.btnLogin:hover,
.btnLogin:focus {
  background-color: #4685ff;
  color: #ffffff;
}

.btnDisable {
  opacity: 0.5;
}
.btnDisable:hover,
.btnDisable:focus {
  background-color: #2bacef !important;
  cursor: default;
}

/*btnLdntAccountogin code here -----------------------------------------------------------------*/
.dntAccount {
  font-size: 18px;
  color: #344e68;
  margin: 20px 0px 0px;
  font-weight: 500;
  display: block;
  font-family: "EuclidCircularB", sans-serif;
}

.dntAccount a,
.resetPwdHolder a {
  text-decoration: none;
  color: #4685ff;
  font-weight: 500;
  font-family: "EuclidCircularB", sans-serif;
}

/* inputgroup-new code */

.inputGroup-new input {
  display: block;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  padding: 10px;
  width: 100%;
  background: #F6FBFD;
  border: 1px solid #BFC8CF;
  border-radius: 4px;
}

.inputGroup-new label {
  display: block;
  margin-bottom: 10px;
}

/* cs-btn-new code */

.cs-btn-new {
  font-family: "Lato", sans-serif;
  padding: 12px 20px;
  background-color: #2355F3;
  border-radius: 40px;
  color: white;
  transition: all 0.2s ease;
}

.cs-btn-new:hover, .cs-btn-new:focus {
  background-color: #1F43DC;
}

/*onboardHolder code here -----------------------------------------------------------------*/

.onboardHolder {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}

.onboardHolder h2 {
  font-family: "Work Sans", sans-serif;
  font-size: 42px;
  line-height: 48px;
  font-weight: 700;
  margin-bottom: 30px;
}

.onboardHolder .inputGroup-new {
  position: relative;
  margin-bottom: 30px;
}

.onboardHolder .inputGroup-new.inputError p {
  position: absolute;
}

.onboardHolder .inputGroup-new input {
  transition: border 0.2s ease-in;
}

.onboardHolder .inputGroup-new:not(.inputError) input:focus {
  border-color: #2355F3;
  box-shadow: 0 0 10px -4px rgb(35 85 243);
}

.onboardHolder .left-side {
  width: 55%;
  position: relative;
  padding: 40px;
}

.onboardHolder .btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
}

.onboardHolder .cs-btn-new {
   margin-left: 33px;
}

.onboardHolder .left-side a {
  text-decoration: none;
}

.onboardHolder .left-side > a img {
  width: 175px;
}

.onboardHolder .left-side small {
  display: block;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  line-height: 24px;
}

.onboardHolder .left-side > div {
  max-width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.onboardHolder .right-side {
  width: 45%;
  background: linear-gradient(270deg, #152A82 0%, #4A88FC 233.78%);
  position: relative;
}

.onboardHolder .right-side picture img {
  max-width: 340px;
  height: 420px;
}

.onboardHolder footer {
  background: #111827;
  color: white;
  flex-basis: 100%;
  padding: 0 40px;
  height: 52px;
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
}

.onboardHolder footer nav {
  display: flex;
  align-items: center;
  width: 100%;
}

.onboardHolder footer a {
  font-family: "Lato", sans-serif;
  font-size: 13px;
  text-decoration: none;
  color: inherit;
}

.onboardHolder footer a:nth-child(-n + 4):not(:first-child) {
  margin-left: 24px;
}

.onboardHolder footer a:last-child {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.onboardHolder footer span {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #2355F3;
  transition: transform 0.4s cubic-bezier(0.5, 0, 0.1, 1);
  transform: scale(0.375);
  margin-left: 5px;
}

.onboardHolder footer span:hover {
  transform: scale(1);
}

.onboardHolder footer span::before {
  content: "";
  display: block;
  background: url('https://assets.chatsupport.co/website/images/pale-blue-dot.webm');
  background-size: cover;
  width: 32px;
  height: 32px;
  opacity: 0;
  transition: opacity .4s linear;
}

.onboardHolder footer span:hover::before {
  opacity: 1;
}

.capitalized-case {
  text-transform: capitalize;
}