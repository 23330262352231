@charset "utf-8";

/*Lato font added code here -----------------------------------------------------------------*/
@font-face {
  font-family: "Lato";
  src: url("https://storage.googleapis.com/front-office/global-styles/fonts/Lato-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("https://storage.googleapis.com/front-office/global-styles/fonts/Lato-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("https://storage.googleapis.com/front-office/global-styles/fonts/Lato-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("https://storage.googleapis.com/front-office/global-styles/fonts/Lato-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("https://storage.googleapis.com/front-office/global-styles/fonts/Lato-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("https://storage.googleapis.com/front-office/global-styles/fonts/Lato-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "American Typewriter";
  src: url("https://storage.googleapis.com/front-office/global-styles/fonts/FontsFree-Net-american-typewriter-1.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Work Sans";
  src: url("https://storage.googleapis.com/front-office/global-styles/fonts/WorkSans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Work Sans";
  src: url("https://storage.googleapis.com/front-office/global-styles/fonts/WorkSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Work Sans";
  src: url("https://storage.googleapis.com/front-office/global-styles/fonts/WorkSans-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}


/* common code start here ****************/
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-display: swap;
  scrollbar-width: none;
}

body {
  font-family: "Lato", sans-serif;
}

.cs-conversation-wrp,
.cs-menu,
.cs-popup-wrp .cs-conversation-wrp input[type="text"],
.cs-conversation-wrp textarea,
.cs-user-history,
.cs-incoming-chat,
.input-form-button button,
a,
p,
label,
.cs-input-dwn>ul li,
.cs-profile figure ul li,
.cs-radio-form,
.cs-profile-map span,
.cs-dwn ul li span,
.cs-popup header cite,
main,
code {
  font-family: "Lato", sans-serif;
}

.cs-overflow-inherit {
  overflow: inherit !important;
}

/*Placeholder code here -----------------------------------------------------------------*/
.cs-conversation-wrp ::-webkit-input-placeholder {
  color: var(--txt-gray-color);
}

.cs-conversation-wrp :-ms-input-placeholder {
  color: var(--txt-gray-color);
}

.cs-conversation-wrp ::-moz-placeholder {
  color: var(--txt-gray-color);
  opacity: 1;
}

.cs-conversation-wrp :-moz-placeholder {
  color: var(--txt-gray-color);
  opacity: 1;
}

.cs-settings-wrp ::-webkit-input-placeholder {
  color: #acb7cc;
}

.cs-settings-wrp :-ms-input-placeholder {
  color: #acb7cc;
}

.cs-settings-wrp ::-moz-placeholder {
  color: #acb7cc;
  opacity: 1;
}

.cs-settings-wrp :-moz-placeholder {
  color: #acb7cc;
  opacity: 1;
}

/* scrollbar display none code here -----------------------------------------------------------------*/
::-webkit-scrollbar {
  display: none;
}

.cs-proactive-list::-webkit-scrollbar {
  display: none;
}

.cs-typing-area::-webkit-scrollbar {
  display: none;
}

.public-DraftEditor-content::-webkit-scrollbar {
  display: none;
}

.progress {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: contentLoaderAnim;
  animation-timing-function: linear;
  background: #f4f4f4;
  background: linear-gradient(to right, #f4f4f4, #f0f0f0);
  background-size: 800px 104px;
  position: relative;
  min-height: 18px;
  width: auto;
  display: block;
  border-radius: 4px;
  z-index: 1;
}

@keyframes contentLoaderAnim {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

/*cs-loading-circle code start here *************/
.cs-loading-circle {
  width: 20px;
  height: 20px;
  position: absolute;
  display: inline-block;
  margin: -2px 0px 0px -30px;
}

.cs-loading-circle .cs-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.cs-loading-circle .cs-circle:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: var(--white-color);
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.cs-loading-circle .cs-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.cs-loading-circle .cs-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.cs-loading-circle .cs-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.cs-loading-circle .cs-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.cs-loading-circle .cs-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.cs-loading-circle .cs-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.cs-loading-circle .cs-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.cs-loading-circle .cs-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.cs-loading-circle .cs-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.cs-loading-circle .cs-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.cs-loading-circle .cs-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.cs-loading-circle .cs-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.cs-loading-circle .cs-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.cs-loading-circle .cs-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.cs-loading-circle .cs-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.cs-loading-circle .cs-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.cs-loading-circle .cs-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.cs-loading-circle .cs-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.cs-loading-circle .cs-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.cs-loading-circle .cs-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.cs-loading-circle .cs-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.cs-loading-circle .cs-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {

  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {

  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}

/* common code start here ****************/
cite.available {
  background-color: var(--available-color);
}

cite.away {
  background-color: var(--away-color);
}

cite.busy {
  background-color: var(--busy-color);
}

cite.offline {
  background-color: var(--offline-color);
}

cite.calander-icon {
  background: url("../images/calander-icon.svg") center no-repeat;
}

cite.windows-icon {
  background: url("../images/windows-icon.svg") center no-repeat;
}

cite.ios-icon {
  background: url("../images/ios-icon.svg") center no-repeat;
}

cite.androids-icon {
  background: url("../images/android-icon.svg") center no-repeat;
}

cite.tags-icon {
  background: url("../images/tag-icon.svg") center no-repeat;
}

cite.copy-link-icon {
  background: url("../images/link-icon.svg") center no-repeat;
}

/* cite.clock-icon {
  background: url("/clock-icon.svg") center no-repeat;
} */

cite.filter-icon {
  background: url("../images/filter-icon.svg") center no-repeat;
}

cite.resolved-icon {
  background: url("../images/resolved-icon.svg") center no-repeat;
}

cite.send-icon {
  background: url("../images/send-icon.svg") no-repeat;
}

cite.delivered-icon {
  background: url("../images/delivered-icon.svg") no-repeat;
}

cite.read-icon {
  background: url("../images/read-icon.svg") no-repeat;
}

cite.location-icon {
  background: url("../images/location-icon.svg") center center no-repeat;
}

cite.location-active-icon {
  background: url("../images/location-active-icon.svg") center center no-repeat;
}

cite.clock-icon {
  background: url("../images/clock-icon.svg") center center no-repeat;
}

cite.clock-active-icon {
  background: url("../images/clock-active-icon.svg") center center no-repeat;
}

cite.atsymbol-icon {
  background: url("../images/atsymbol-icon.svg") center center no-repeat;
}

cite.atsymbol-active-icon {
  background: url("../images/atsymbol-active-icon.svg") center center no-repeat;
}

cite.phone-icon {
  background: url("../images/phone-icon.svg") center center no-repeat;
}

cite.phone-active-icon {
  background: url("../images/phone-active-icon.svg") center center no-repeat;
}

cite.workspace-icon {
  background: url("../images/building-icon.svg") center center no-repeat;
}

cite.workspace-active-icon {
  background: url("../images/building-active-icon.svg") center center no-repeat;
}

cite.video-icon {
  background: url("../images/video-icon.svg") center no-repeat;
}

cite.video-active-icon,
.more-icon-btn-dwn ul li:hover cite.video-icon {
  background: url("../images/video-active-icon.svg") center no-repeat;
}

cite.notes-icon {
  background: url("../images/notes-icon.svg") center no-repeat;
}

cite.notes-active-icon,
.more-icon-btn-dwn ul li:hover cite.notes-icon {
  background: url("../images/notes-active-icon.svg") center no-repeat;
}

cite.resolve-icon {
  background: url("../images/resolve-icon.svg") center no-repeat;
}

cite.resolve-active-icon,
.more-icon-btn-dwn ul li:hover cite.resolve-icon {
  background: url("../images/resolve-active-icon.svg") center no-repeat;
}

cite.email-icon {
  background: url("../images/email-icon.svg") center no-repeat;
}

cite.email-active-icon,
.more-icon-btn-dwn ul li:hover cite.email-icon {
  background: url("../images/email-active-icon.svg") center no-repeat;
}

.cs-logo { position: relative; }
.cs-logo a{ cursor:default !important; }

.cs-logo img{
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -17px;
  width: 34px;
  height: 34px;
  object-fit: cover;
}

.cs-people-icon {
  background: url("../images/people_icon.png") center center no-repeat;
}

.cs-people-icon.active,
.cs-people-icon:hover {
  background: url("../images/people-active-icon.svg") center center no-repeat;
}

.cs-chat-icon {
  background: url("../images/Chat-Icon.svg") center center no-repeat;
}

.cs-chat-icon.active,
.cs-chat-icon:hover {
  background: url("../images/chat-active-icon.svg") center center no-repeat;
}

.cs-staf-icon {
  background: url("../images/group-icon.svg") center center no-repeat;
}

.cs-staf-icon.active,
.cs-staf-icon:hover {
  background: url("../images/group-active-icon.svg") center center no-repeat;
}

.cs-settings-icon {
  background: url("../images/Settings-Icon.svg") center center no-repeat;
}

.cs-settings-icon.active,
.cs-settings-icon:hover {
  background: url("../images/settings-active-icon.svg") center center no-repeat;
}

.cs-account-icon {
  background: url("../images/account-icon.svg") center center no-repeat;
}

.cs-account-icon.active,
.cs-account-icon:hover {
  background: url("../images/account-active-icon.svg") center center no-repeat;
}

/*cs-scrollbar code start here **********************/
.cs-chat-list::-webkit-scrollbar {
  display: none;
}

/* Track */
.cs-chat-list::-webkit-scrollbar-track {
  display: none;
}

/* Handle */
.cs-chat-list::-webkit-scrollbar-thumb {
  display: none;
}

/*cs-popupbg css code start here ****************/
.cs-popupbg {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #202a3d;
  z-index: 5;
  opacity: 0.7;
}

.cs-popupbg.incoming {
  z-index: 120;
}


/*cs-input-field code start here ***************************/
.cs-input-field {
  width: 280px !important;
  position: relative;
  margin: 0px 0px 40px !important;
}

.cs-input-field label {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: #8f99ad;
  padding: 0px;
  display: block;
}

.cs-input-field input,
.cs-input-field>p,
.cs-input-field>font {
  font-family: "Lato", sans-serif;
  display: block;
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  color: #181818;
  padding: 8px 0px;
  border: 1px solid #d3d9e5;
  border-width: 0px 0px 1px;
  cursor: auto;
  width: 100%;
  margin: 0px;
  transition: border-color 0.3s ease-out;
  -webkit-transition: border-color 0.3s ease-out;
  /* For Webkit browser(Chrome, Safari...)*/
  -moz-transition: border-color 0.3s ease-out;
  /* For Mozilla browser */
  -ms-transition: border-color 0.3s ease-out;
  /* For Opera browser */
  -o-transition: border-color 0.3s ease-out;
  /* For Microsoft browser */
}

.cs-input-field.cs-input-phone input {
  padding: 8px 20px 8px 0px;
}

.cs-input-field>font {
  line-height: 1.5;
}

.cs-input-field input:focus,
.cs-input-field>p:focus {
  border-color: #d3d9e5;
}

.cs-input-field>a {
  position: absolute;
  right: 0px;
  top: 50%;
  cursor: pointer;
  width: 14px;
  height: 16px;
  display: none;
}

.cs-input-field:hover>a {
  display: block;
}

.cs-input-field>a svg {
  width: 14px;
  height: 16px;
}

.cs-input-field>span svg {
  position: absolute;
  bottom: 1px;
  width: 13px;
  left: 0px;
  height: 13px;
}

.cs-input-field>span {
  font-size: 12px;
  line-height: 1.33;
  font-weight: 600;
  padding: 6px 0px 0px 20px;
  position: absolute;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: none;
}

.cs-input-field.cs-alert-field>span,
.cs-input-field.cs-error-field>span {
  display: block;
}

.cs-input-field.cs-alert-field>span {
  color: #ff9400;
}

.cs-input-field.cs-alert-field>span svg {
  fill: #ff9400;
}

.cs-input-field.cs-error-field>span {
  color: #df0d29;
}

.cs-input-field.cs-error-field>span svg {
  fill: #df0d29;
}

.cs-input-form.cs-active-field input
.cs-input-field.cs-active-field input,
.cs-input-field.cs-active-field>p,
.cs-input-field.open>input,
.cs-input-field.open>font {
  border-color: var(--PaleBlueAW);
}

.cs-input-form.cs-active-field label,
.cs-input-field.cs-active-field label {
  color: var(--PaleBlueAW);
}

.cs-input-field.cs-alert-field input,
.cs-input-field.cs-alert-field>p {
  border-color: #ff9400;
}

.cs-input-field.cs-error-field input,
.cs-input-field.cs-error-field>p {
  border-color: #df0d29;
}

.cs-input-field.cs-disabled-field input,
.cs-input-field.cs-disabled-field p {
  /* color: #8f99ad; */
  cursor: not-allowed;
  background-color: transparent;
}

.cs-input-field.cs-disabled-field input {
  padding-right: 20px;
}

.cs-input-field.cs-disabled-field>svg {
  display: block;
  margin: 0px 0px 0px;
  width: 18px;
  height: 18px;
  cursor: not-allowed;
  position: absolute;
  right: 0px;
  top: 50%;
}

.cs-input-inlineblock-field .cs-input-field {
  display: inline-block;
  margin: 0px 40px 40px 0px !important;
}

/* .cs-input-inlineblock-field .cs-input-field:nth-child(2){
  margin: 0px !important;
} */

.cs-input-field.public input,
.cs-input-field.private input {
  padding: 8px 50px 8px 0px;
}

.cs-input-field.public:after,
.cs-input-field.private:after {
  content: "Public";
  position: absolute;
  right: 0px;
  font-size: 14px;
  color: var(--txt-gray-color);
  bottom: 12px;
}

.cs-input-field.private:after {
  content: "Private";
}

.cs-input-inlineblock-field {
  /* margin: 0px 0px 40px; */
  width: 650px;
}

.cs-input-field.open>div {
  display: block;
}

.cs-input-field>div {
  display: none;
  width: 500px;
  position: absolute;
  padding: 12px 24px 0px;
  background-color: var(--white-color);
  border-radius: 4px;
  box-shadow: 0 3px 4px 0 rgba(10, 31, 68, 0.1),
    0 0 1px 0 rgba(10, 31, 68, 0.08);
  margin: 0px 0px 10px 0px;
}

.cs-input-field>div small {
  font-size: 14px;
  line-height: 1.71;
  padding: 0px 0px 14px;
  display: block;
  color: #727c8f;
}

.cs-input-field>div p {
  font-size: 14px;
  line-height: 1.43;
  color: var(--txt-black-color);
  padding: 0px 0px 14px;
}

.cs-input-field.input-caps input,
.cs-caps-txt {
  text-transform: capitalize;
}

/*cs-dropdwn code start here ***************************/
.cs-dropdwn {
  width: 280px;
  position: relative;
  margin: 0px 0px 40px;
}

.cs-dropdwn>label {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: #8f99ad;
  display: block;
  padding: 0px;
  margin: 0px !important;
}

.cs-dropdwn>span {
  padding: 6px 0px;
  display: block;
  color: #727c8f;
  font-size: 14px;
  line-height: normal;
  white-space: nowrap;
}

.cs-dropdwn>p {
  font-size: 16px !important;
  line-height: normal !important;
  color: #181818 !important;
  padding: 8px 25px 8px 0px !important;
  border: 1px solid #d3d9e5;
  border-width: 0px 0px 1px;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
}

.cs-dropdwn>svg {
  position: absolute;
  bottom: 14px;
  width: 14px;
  height: 7px;
  right: 6px;
  cursor: pointer;
  pointer-events: none;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.cs-dropdwn.open>svg {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.cs-dropdwn>div {
  border-radius: 4px;
  box-shadow: 0 3px 4px 0 rgba(10, 31, 68, 0.1),
    0 0 1px 0 rgba(10, 31, 68, 0.08);
  background-color: var(--white-color);
  display: none;
  z-index: 2;
  position: absolute;
  left: 0px;
  right: 0px;
  margin: 0px 0px 10px 0px;
}

.cs-add-prompt .cs-dropdwn>div ul {
  max-height: 120px;
}

.cs-dropdwn>div ul {
  margin: 8px 0px;
  min-height: auto;
  max-height: 120px;
  overflow: auto;
}

.cs-dropdwn li a {
  font-size: 14px;
  line-height: 1.43;
  color: #181818;
  padding: 10px 24px;
  text-decoration: none;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  cursor: pointer;
}

.cs-dropdwn li a:hover,
.cs-dropdwn li a.select {
  color: var(--white-color);
  background-color: var(--BlueAW);
}

.cs-dropdwn.open>p,
.cs-dropdwn.focus>p {
  border-color: var(--BlueAW);
}

.cs-dropdwn.open>div {
  display: block;
}

.cs-dropdwn.open>label,
.cs-dropdwn.focus>label {
  color: var(--PaleBlueAW);
}

.cs-dropdwn>span {
  font-size: 12px;
  line-height: 1.33;
  font-weight: 600;
  padding: 6px 0px 0px 20px;
  position: absolute;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: none;
}

.cs-dropdwn>span svg {
  position: absolute;
  bottom: 1px;
  width: 13px;
  left: 0px;
  height: 13px;
}

.cs-dropdwn.cs-error-field>span {
  display: block;
  color: #df0d29;
}

.cs-dropdwn.cs-error-field>span svg {
  fill: #df0d29;
}

.cs-dropdwn.cs-error-field>p {
  border-color: #df0d29;
}

.cs-dropdwn .cs-porject-clip,
.cs-dropdwn .cs-porject-more {
  margin: 2px 5px 0px 0px;
}

.cs-dropdwn .date-field .flatpickr-calendar {
  margin: -42px 0px 0px -305px;
  top: inherit !important;
  left: inherit !important;
  right: inherit !important;
  border: inherit !important;
}

.cs-dropdwn .date-field .flatpickr-calendar:after,
.cs-dropdwn .date-field .flatpickr-calendar:before {
  display: none !important;
}

.cs-dropdwn .date-field .flatpickr-input,
.cs-dropdwn .date-field input {
  font-size: 16px;
  width: 100%;
  color: #181818;
  border: none;
  padding: 0;
  height: auto;
  outline: none;
  cursor: default;
  background: transparent;
  display: none;
}

/*cs-dropdwn-search-txt code start here ***************/
.cs-dropdwn-search-txt>div ul {
  max-height: 120px;
}

.cs-dropdwn-search-txt>div>div {
  margin: 12px 16px 0px;
  position: relative;
}

.cs-dropdwn-search-txt>div>div input {
  border: 1px solid #e6e9f0;
  background-color: var(--white-color);
  border-radius: 16px;
  line-height: 12px;
  padding: 9px 30px 9px 15px;
  width: 100%;
}

.cs-dropdwn-search-txt>div>div>svg {
  width: 12px;
  height: 12px;
  position: absolute;
  right: 12px;
  top: 50%;
  cursor: pointer;
  margin: -6px 0px 0px 0px;
}

.cs-dropdwn-search-txt>div>div>span {
  font-size: 12px;
  padding: 0px 0px 12px;
  text-align: center;
  color: #181818;
  display: block;
}

/* .cs-dropdwn-search-txt li a{
    padding: 14px 24px 14px 24px;
} */

.cs-dropdwn-search-txt li a:hover,
.cs-dropdwn-checkbox li a:hover {
  background-color: var(--BlueAW);
  color: var(--white-color);
}

/* .cs-dropdwn-search-txt li a figure{
    position: absolute;
    width: 30px;
    height: 30px;
    left: 16px;
    top: 9px;
    border-radius: 100%;
    background-color: #6699ff;
    background-image: url("/user-white-icon.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 12px;
}
.cs-dropdwn-search-txt li a figure img{
    width: 30px;
    height: 30px;
    border-radius: 100%;
    object-fit:cover;
} */

/*cs-dropdwn-search code start here ***************/
.cs-dropdwn-search>div ul {
  max-height: 144px;
}

.cs-dropdwn-search>div>div {
  margin: 12px 16px 0px;
  position: relative;
}

.cs-dropdwn-search>div>div input {
  border: 1px solid #e6e9f0;
  background-color: var(--white-color);
  border-radius: 16px;
  line-height: 12px;
  padding: 9px 30px 9px 15px;
  width: 100%;
}

.cs-dropdwn-search>div>div>svg {
  width: 12px;
  height: 12px;
  position: absolute;
  right: 12px;
  top: 50%;
  cursor: pointer;
  margin: -6px 0px 0px 0px;
}

/* .cs-dropdwn-search input{
    border: 1px solid #e6e9f0;
    background-color: var(--white-color);
    border-radius: 16px;
    line-height: 12px;
    padding: 9px 30px 9px 15px;
    width: 183px;
    margin: 12px 16px 4px;
} */

/* .cs-dropdwn-search > div > svg{
    width: 12px;
    height: 12px;
    position: absolute;
    right: 27px;
    top: 22px;
    cursor: pointer;
} */

.cs-dropdwn-search>div span {
  font-size: 12px;
  padding: 0px 0px 12px;
  text-align: center;
  color: #181818;
  display: block;
}

.cs-dropdwn-search li a {
  padding: 14px 24px 14px 56px;
}

.cs-dropdwn-search li a:hover,
.cs-dropdwn-checkbox li a:hover,
.cs-dropdwn-checkbox li a.select {
  background-color: #f0f2f5;
  color: #181818;
}

.cs-dropdwn-search li a figure {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 16px;
  top: 9px;
  border-radius: 100%;
  background-color: #6699ff;
  background-image: url("../images/user-white-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 12px;
}

.cs-dropdwn-search li a figure img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  object-fit: cover;
}

/*cs-dropdwn-checkbox code start here ***************/
.cs-dropdwn-checkbox li a {
  padding: 10px 24px 10px 46px;
}

.cs-dropdwn-checkbox li a cite {
  position: absolute;
  width: 16px;
  height: 16px;
  left: 16px;
  top: 12px;
  border-radius: 100%;
  background-color: var(--white-color);
  border: 1px solid #d3d9e5;
}

.cs-dropdwn-checkbox li a.active cite {
  background-color: #6699ff;
  border-color: #6699ff;
}

.cs-dropdwn-checkbox li a cite svg {
  width: 8px;
  height: 9px;
  position: absolute;
  left: 3px;
  top: 3px;
  fill: var(--white-color);
}

.cs-dropdwn-checkbox li a.active cite svg {
  fill: var(--white-color);
}

/*cs-dropdwn-checkbox-sub styles code start here ***************************/
.cs-dropdwn-checkbox-sub {
  width: 216px;
  left: -219px;
  top: -10px;
  border-radius: 4px;
  box-shadow: 0 3px 4px 0 rgba(10, 31, 68, 0.15),
    0 0 4px 0 rgba(10, 31, 68, 0.08);
  background-color: var(--white-color);
  display: none;
  z-index: 2;
  position: absolute;
  margin: 0px 0px 10px 0px;
}

.cs-dropdwn-checkbox-sub ul {
  margin: 8px 0px;
  min-height: auto;
  max-height: calc(100vh - 205px);
  overflow: auto;
}

.cs-dropdwn-checkbox-sub li>a {
  font-size: 14px;
  line-height: 1.43;
  color: #181818;
  padding: 10px 24px 10px 46px;
  text-decoration: none;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.cs-dropdwn-checkbox-sub li a:hover {
  background-color: #f0f2f5;
}

.cs-dropdwn-checkbox-sub li a.active cite {
  background-color: #6699ff;
  border-color: #6699ff;
}

.cs-dropdwn-checkbox-sub li a cite {
  position: absolute;
  width: 16px;
  height: 16px;
  left: 16px;
  top: 12px;
  border-radius: 3px;
  background-color: var(--white-color);
  border: 1px solid #d3d9e5;
}

.cs-dropdwn-checkbox-sub li a cite svg {
  width: 8px;
  height: 9px;
  position: absolute;
  left: 3px;
  top: 3px;
  fill: var(--white-color);
  margin: 0px;
}

.cs-dropdwn-checkbox-sub>p {
  font-size: 12px;
  line-height: 1.67;
  color: #181818;
  padding: 0px 16px;
}

.cs-dropdwn-checkbox-sub>p.no-found {
  text-align: center;
  opacity: 0.5;
}

.cs-dropdwn-checkbox-sub>div {
  padding: 20px 16px;
  position: relative;
}

.cs-dropdwn-checkbox-sub>div>input {
  font-size: 12px;
  line-height: 1.67;
  border-radius: 16px;
  border: 1px solid #e6e9f0;
  padding: 6px 34px 6px 14px;
  width: 100%;
}

.cs-dropdwn-checkbox-sub>div>svg {
  position: absolute;
  right: 27px;
  top: 30px;
  cursor: pointer;
  width: 13px;
  height: 13px;
}

/*cs-button styles code start here ***************************/
.cs-btn-xs {
  padding: 6px 8px 7px !important;
  min-width: 96px !important;
  font-size: 12px !important;
  height: 30px !important;
}

.cs-btn-lg {
  padding: 12px !important;
  /*min-width: 110px !important;*/
  font-size: 14px !important;
  height: 42px;
}

.cs-remove-btn {
  background-color: #df0d29;
  border-color: #df0d29;
}

.cs-remove-btn:hover {
  background-color: #bb1717;
  border-color: #bb1717;
}

.cs-remove-btn:focus {
  background-color: #811111;
  border-color: #811111;
}

.cs-btn {
  position: relative;
  height: 36px;
  line-height: normal;
  border-radius: 6px;
  color: var(--white-color);
  font-weight: 600;
  min-width: 98px;
  border: 1px solid var(--BlueAW);
  font-size: 14px;
  padding: 8px 10px 9px;
  cursor: pointer;
  text-transform: capitalize;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease-out;
  -webkit-transition: background-color 0.3s ease-out;
  /* For Webkit browser(Chrome, Safari...)*/
  -moz-transition: background-color 0.3s ease-out;
  /* For Mozilla browser */
  -ms-transition: background-color 0.3s ease-out;
  /* For Opera browser */
  -o-transition: background-color 0.3s ease-out;
  /* For Microsoft browser */
}

.cs-primary-btn {
  background-color: var(--BlueAW);
}

.cs-primary-btn:hover,
.cs-primary-btn:focus {
  background-color: var(--BlueAw-hover);
}

.cs-secondary-btn {
  background-color: var(--white-color);
  color: var(--BlueAW);
}

.cs-disabled-btn {
  background-color: #acb7cc;
  border: 1px solid #acb7cc;
  color: var(--white-color);
  cursor: not-allowed;
}

.cs-tertiary-btn {
  background-color: #e0ebff;
  border: 1px solid #e0ebff;
  color: #181818;
}

.cs-decline-btn {
  background-color: var(--white-color);
  border: 1px solid var(--white-color);
  color: #727c8f;
  transition: color 0.3s ease-out;
  -webkit-transition: color 0.3s ease-out;
  /* For Webkit browser(Chrome, Safari...)*/
  -moz-transition: color 0.3s ease-out;
  /* For Mozilla browser */
  -ms-transition: color 0.3s ease-out;
  /* For Opera browser */
  -o-transition: color 0.3s ease-out;
  /* For Microsoft browser */
}

.cs-decline-btn:hover,
.cs-decline-btn:focus {
  color: #202a3d;
}

.cs-btn .btn-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -10px 0px 0px -10px;
}

.cs-input-button {
  margin: 40px 0px 0px 0px;
}

.cs-input-run-btn {
  position: absolute;
  top: -35px;
  left: 440px;
}

.cs-email-send {
  position: absolute;
  bottom: 54px;
  left: 440px;
}

.cs-email-sendagain {
  position: absolute;
  left: 440px;
  bottom: 0px;
}

.cs-btn-bottom {
  position: fixed;
  right: 0px;
  padding: 30px 60px !important;
  bottom: 0px;
  background-color: #ffffff;
  /* left: 384px; */
  left: 900px;
  z-index: 1;
}

.cs-btn-bottom:before {
  content: "";
  position: absolute;
  right: 0px;
  left: -600px;
  height: 80px;
  background-image: linear-gradient(to bottom,
      rgba(255, 255, 255, 0),
      #ffffff 12%);
  bottom: 0px;
}

.cs-btn-fixed {
  margin-bottom: 80px !important;
}

.cs-btn.cs-secondary-btn .btn-spinner {
  border: 2px solid var(--BlueAW);
  border-top-color: rgba(255, 255, 255, 0.18);
}

.btn-spinner {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 8);
  border-radius: 50%;
  border-top-color: rgba(255, 255, 255, 0.18);
  display: block;
  animation: spin 0.8s ease-out infinite;
  -webkit-animation: spin 0.8s ease-out infinite;
  margin: 0 auto;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

/*cs-popup-box css code start here ****************/
.cs-popup-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.3);
  border-radius: 8px;
  background-color: var(--white-color);
  min-width: 600px;
  max-width: 80vw;
  z-index: 601;
}

.cs-popup-box header {
  position: relative;
  padding: 29px 0px 7px;
}

.cs-popup-box header>figure {
  width: 36px;
  height: 36px;
  position: absolute;
  top: 20px;
  left: 30px;
}

.cs-popup-box header h2 {
  font-size: 16px;
  line-height: 1.31;
  color: var(--txt-black-color);
  font-weight: bold;
  padding: 0px 0px 0px 80px;
}

.cs-popup-box header cite {
  font-size: 30px;
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  text-align: center;
  line-height: 14px;
  color: #acb7cc;
  right: 24px;
  top: 50%;
  cursor: pointer;
  margin: 2px 0px 0px;
}

.cs-popup-box header cite svg {
  width: 16px;
  height: 16px;
}

.cs-popup-box footer {
  position: relative;
  min-height: 68px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 30px;
}

.cs-popup-box footer a.cs-primary-btn {
  min-width: 132px;
}

.cs-popup-box .cs-popup-input-wrp {
  padding: 46px 30px;
}

/* .cs-popup-box .cs-popup-input {
} */

.cs-popup-box .cs-popup-input label {
  font-size: 14px;
  color: #40495b;
  display: block;
  line-height: normal;
}

.cs-popup-box .cs-popup-input input {
  font-size: 16px;
  border: 1px solid #e6e9f0;
  border-width: 0px 0px 1px;
  width: 100%;
  padding: 10px 0px;
}

.cs-popup-box .cs-btn {
  border-radius: 4px;
}

/*cs-popup-model css code start here ****************/
.cs-popup-model {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.3);
  border-radius: 4px;
  background-color: var(--white-color);
  width: 90%;
  z-index: 100;
}

.cs-popup-model:not(.campaign-popup,.cs-popup-edit-team,.email-popup,.import-popup) {
  max-width: 600px;
}

.campaign-popup {
  max-width: 900px;
}

.cs-popup-edit-team {
  max-width: 980px;
}

.email-popup {
  max-width: 343px;
}

.cs-popup-model.popup-new.email-popup > header > h2 {
  padding: 0 0 0 20px;
}

.cs-popup-model.popup-new.email-popup > header > cite {
  right: 20px;
}

.import-popup {
  max-width: 960px;
}



.cs-popup-model header {
  background-color: #202a3d;
  border-radius: 4px 4px 0px 0px;
  position: relative;
  padding: 9px 0px;
}

.cs-popup-model header h2 {
  font-size: 18px;
  line-height: 2;
  color: var(--white-color);
  font-weight: 500;
  padding: 0px 0px 0px 30px;
}

.cs-popup-model header cite {
  font-size: 17px;
  position: absolute;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.07);
  text-align: center;
  line-height: 22px;
  color: var(--white-color);
  right: 30px;
  top: 17px;
  cursor: pointer;
}

.cs-popup-model header cite svg {
  width: 22px;
  height: 22px;
}

.cs-popup-model footer {
  /* min-height: 68px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 30px; */
  position: relative;
  min-height: 68px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 30px;
  /* height: inherit;
    width: inherit;
    border: inherit;
    border-width: inherit;
    bottom: inherit;
    background-color: inherit;
    border-radius: 0px 0px 4px 4px; */
}

/* .cs-popup-model.cs-popup-edit-team footer{
    position: absolute;
    bottom: 0px;
    right: 0px;
    left: 0px;
}  */

.cs-model-msg {
  padding: 30px;
  min-height: 100px;
  line-height: 1.5;
  color: #727c8f;
  font-size: 16px;
}

.cs-popup-model.popup-new header h2 {
  font-size: 18px;
  line-height: 2;
  color: #181818;
  font-weight: bold;
  text-transform: capitalize;
}

.cs-popup-model.popup-new.htmlCode-popup header h2 {
  text-transform: initial;
}

.cs-popup-model.popup-new header cite {
  background: transparent;
  width: 16px;
  height: 16px;
  font-size: 25px;
  line-height: 12px;
  color: #acb7cc;
  top: 12px;
}

.cs-popup-model.popup-new {
  border-radius: 8px;
}

@media screen and (max-height: 640px) {
  .popup-new-content:not(.htmlCode-popup > .popup-new-content) {
    min-height: 300px;
    max-height: 300px;
    overflow: auto !important;
  }
}

.cs-popup-segment>section {
  padding: 30px 30px 0px;
}

.cs-popup-segment .cs-input-field {
  width: 100% !important;
}

.cs-popup-model.popup-new header h2 svg {
  /* width: 36px;
    height: 36px;
    background-color: #e0ebff;
    border-radius: 100%;
    float: left;
    margin: 0px 14px 0px 0px;
    padding:10px; */
  width: 36px;
  height: 36px;
  float: left;
  margin: 0px 14px 0px 0px;
}


/*cs-popup css code start here ****************/
.cs-popup-cropping {
  width: 410px;
  min-width: 410px;
  min-height: 530px;
}

.cs-crop-widget {
  margin: 30px 30px 0px;
  height: 350px;
}


.cs-popup-edit-team header:before {
  content: "";
  position: absolute;
  top: 178px;
  right: 0px;
  left: 250px;
  height: 20px;
  background-image: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0));
  z-index: 1;
  pointer-events: none;
}

.cs-popup-edit-team footer {
  padding: 0px 40px 40px;
}

.cs-popup-edit-team footer:before {
  content: "";
  position: absolute;
  top: -19px;
  right: 0px;
  left: 250px;
  height: 20px;
  background-image: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0));
  z-index: 1;
  pointer-events: none;
}

.cs-popup-edit-team figure.cs-edit-prof {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  position: absolute;
  z-index: 1;
  left: 50px;
  top: 100px;
  border: 6px solid var(--white-color);
  background-color: #b1b5bc;
  background-image: url("../images/user-white-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60px;
}

.cs-popup-edit-team figure.cs-edit-prof img {
  border-radius: 100%;
  width: 138px;
  height: 138px;
  object-fit: cover;
  background-color: #ffffff;
}

.cs-popup-edit-team figure.cs-edit-prof cite {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 100%;
  text-align: center;
  font-size: 50px;
  z-index: 1;
  line-height: 138px;
  font-family: "Lato", sans-serif;
  color: var(--white-color);
}

.cs-popup-delete {
  width: 600px;
}

.cs-edit-team-profile {
  width: 250px;
  border: 1px solid var(--border-color);
  border-width: 0px 1px 0px 0px;
  position: absolute;
  left: 0px;
  top: 176px;
  bottom: 0px;
  overflow: auto;
}

.cs-edit-team-profile h3 {
  text-align: center;
  font-size: 18px;
  color: var(--txt-black-color);
  padding: 100px 0px 8px;
  font-weight: 500;
  line-height: normal;
}

.cs-edit-team-profile h4 {
  text-align: center;
  font-size: 16px;
  color: var(--txt-gray-color);
  line-height: normal;
}

.cs-edit-team-profile ul.cs-navigation-list {
  /*height: 393px;*/
  height: inherit;
  /*height: auto;*/
}

.cs-popup-team-info {
  padding: 40px;
  margin: 0px 0px 0px 250px;
  min-height: 380px;
  max-height: 380px;
  overflow: auto;
}

.cs-popup-team-info .cs-widget-config {
  margin: 0px 0px 28px 0px;
}

.campaign-popup .popup-new-content>div {
  min-height: 410px;
}

@media screen and (max-height: 640px) {
  .cs-popup-team-info {
    min-height: 280px;
    max-height: 280px;
    overflow: auto !important;
  }

  .campaign-popup .popup-new-content {
    min-height: 300px;
    max-height: 300px;
    overflow: auto !important;
  }

  .campaign-popup .popup-new-content>div {
    min-height: 300px;
  }
}

.cs-popup-team-info .cs-input-field {
  width: 246px !important;
}

.cs-popup-team-info .cs-widget-config .cs-dropdwn,
.cs-popup-team-info .cs-widget-config>div {
  width: 254px !important;
}

.cs-popup-team-info .cs-widget-config .cs-dropdwn {
  position: absolute;
  margin: 0px 0px 0px 80px;
  top: 17px;
}

.cs-popup-team-info div.cs-widget-config:last-child {
  margin: 0px;
}

.cs-popup-team-info .cs-popup-team-wrk-hours>.cs-dropdwn label {
  font-size: 16px;
  line-height: 1.5;
  color: var(--txt-black-color);
}

.cs-popup-team-info .cs-popup-team-wrk-hours>.cs-dropdwn {
  width: 325px;
}

.cs-popup-team-wrk-hours li {
  padding: 0px 0px 3px;
}

.cs-popup-team-wrk-hours li div:nth-child(2) {
  margin: 0px 60px 0px 0px;
}

.cs-popup-team-wrk-hours li div:nth-child(2):before {
  content: "To";
  font-size: 14px;
  color: #8f99ad;
  font-family: "Lato", sans-serif;
  position: absolute;
  right: -35px;
  top: 11px;
}

.cs-popup-team-wrk-hours li .cs-checkbox-field {
  padding: 8px 0px;
  float: left;
  width: 175px !important;
}

.cs-popup-team-wrk-hours li .cs-checkbox-field,
.cs-popup-team-wrk-hours li .cs-dropdwn {
  width: 150px;
  display: inline-block;
  margin: 0px;
}

.cs-popup-team-wrk-hours li .cs-checkbox-field label {
  font-size: 16px;
}

.cs-popup-team-wrk-hours li .cs-checkbox-field label cite {
  width: 18px;
  height: 18px;
  top: 0px;
}

.cs-popup-team-wrk-hours li .cs-checkbox-field label svg {
  top: 6px;
}

/*.cs-popup-team-wrk-hours li > div:nth-child(3){
  display: inline-block;
  margin: 0px 20px;
  font-size: 14px;
  line-height: 1.71;
  color: #8f99ad;
}*/

.cs-popup-team-wrk-hours .cs-working-hours-form {
  position: relative;
  width: 540px;
}

.cs-popup-team-wrk-hours .cs-working-hours-form>a {
  position: absolute;
  right: -70px;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  color: #acb7cc;
  text-decoration: none;
  top: 10px;
  cursor: pointer;
}

.cs-popup-team-wrk-hours .cs-working-hours-form li.disable .cs-dropdwn>p,
.cs-placeholder-txt,
.cs-dropdwn.cs-placeholder-txt p {
  color: #acb7cc !important;
}

/*cs-popup css code start here ****************/
.cs-popup-new-prompt {
  width: 600px;
}

.cs-add-prompt {
  /* padding: 30px 30px 0px;
    min-height: 100px;
    max-height: 700px; */
  /* overflow: auto; */
}

.cs-add-prompt .cs-input-field {
  width: 100% !important;
}

/*cs-popup css code start here ****************/
.cs-popup {
  z-index: 3;
  background-color: var(--white-color);
  box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.3);
  border-radius: 4px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  max-width: 90vw;
}

.cs-popup header {
  background-color: #202a3d;
  border-radius: 4px 4px 0px 0px;
  position: relative;
  padding: 9px 0px;
}

.cs-popup header h2 {
  font-size: 18px;
  line-height: 2;
  color: var(--white-color);
  font-weight: 500;
  padding: 0px 0px 0px 30px;
}

.cs-popup header cite {
  font-size: 17px;
  position: absolute;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.07);
  text-align: center;
  line-height: 22px;
  color: var(--white-color);
  right: 30px;
  top: 17px;
  cursor: pointer;
}

.cs-popup header cite svg {
  width: 22px;
  height: 22px;
}

.cs-popup>div {
  padding: 30px;
}

/*.cs-popup > div div.cs-input-field > label,
.cs-popup > div div.cs-dropdwn > label{
    padding: 40px 0px 0px;
}*/

.cs-popup>div>p {
  font-size: 16px;
  line-height: 1.5;
  color: #162d3d;
  margin: 0px 0px 90px 0px;
}

.cs-popup-container {
  /*display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;*/
}

.cs-popup .input-form-button {
  margin: 46px 0px 0px 0px;
}

/*.cs-popup .cs-input-field{
  width: 100%;
}*/

.customize-popup {
  width: 636px;
  margin: 0px 0px 0px -318px;
}

.cs-popup div.cs-input-field {
  width: 100% !important;
}

.cs-popup .cs-input-inlineblock-field .cs-input-field {
  width: 280px !important;
}

/* css customize-style-popup code start here ****************/
.customize-style-popup {
  width: 950px;
  margin: 0px 0px 0px -475px;
}

.customize-style-popup .cs-choos-widget {
  width: 480px;
}

.customize-style-popup .cs-choos-widget ul li:nth-child(4),
.customize-style-popup .cs-choos-widget ul li:last-child {
  margin: 24px 0px 0px 0px;
}

.customize-style-popup .cs-choos-widget ul li {
  margin: 24px 72px 0px 0px;
}

.customize-style-popup .cs-choos-widget ul li figure,
.customize-style-popup .cs-choos-widget ul li figure img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 4px solid #161e2c;
  position: relative;
  background-color: #161e2c;
  background-image: url("../images/chatsupport-white-logo-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.customize-style-popup .cs-choos-widget ul li figure img {
  border: 0px;
  width: 46px;
  height: 46px;
  position: absolute;
  left: -2px;
  top: -2px;
  object-fit: cover;
}

.customize-style-popup a.btn-continue {
  position: absolute;
  right: 30px;
  bottom: 30px;
  padding: 10px 10px;
  text-decoration: none;
  background-color: var(--BlueAW);
  border-radius: 3px;
  color: var(--white-color);
  font-size: 14px;
  min-width: 110px;
  text-align: center;
  line-height: normal;
}

.customize-style-popup>div>div:nth-child(2) {
  margin: 0px;
}

.customize-style-popup .cs-btn {
  position: absolute;
  right: 30px;
  bottom: 30px;
}

/* cs-edit-profile-popup code start here ****************/
.cs-edit-profile-popup {
  width: 980px;
  /*height: 700px;*/
  /*margin: 0px 0px 0px -490px;*/
}

.cs-edit-profile-popup figure.cs-edit-prof {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  position: absolute;
  z-index: 1;
  left: 50px;
  top: 100px;
  border: 6px solid var(--white-color);
  background-color: #b1b5bc;
  background-image: url("../images/user-white-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60px;
}

.cs-edit-profile-popup figure.cs-edit-prof img {
  border-radius: 100%;
  width: 138px;
  height: 138px;
  object-fit: cover;
}

.cs-edit-profile-popup>div:nth-child(2)>ul {
  height: 200px;
}

.cs-edit-team {
  position: absolute;
  top: 178px;
  left: 250px;
  right: 0px;
  /*padding: 40px;*/
  bottom: 0px;
  /*min-height: 100px;
    max-height: 400px;*/
  /*overflow: auto;*/
}

.cs-edit-team .edit-workhours,
.cs-edit-team .edit-permission {
  min-height: 100px;
  max-height: 385px;
  overflow: auto;
  padding: 40px 40px 0px;
}

.cs-edit-team .edit-permission div.cs-widget-config:last-child {
  margin: 0px;
}

.cs-edit-team .cs-widget-config>div {
  width: 280px;
}

/*.cs-edit-team .cs-widget-config > input[type="text"],
.cs-edit-team .cs-widget-config div.dwn-arrow{
    left: 340px;
}*/

.cs-edit-team .cs-widget-config div.cs-dropdwn {
  width: 254px;
  margin: -20px 0px 0px 0px;
  position: absolute;
  left: 340px;
  top: 50%;
  cursor: pointer;
}

.cs-popup .input-form-button {
  /*position: absolute;*/
  /*bottom: 40px;
    right: 40px;*/
  /*padding: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;*/
  position: absolute;
  bottom: 40px;
  right: 40px;
  left: 40px;
  background-color: var(--white-color);
}

/* css cs-variables code start here ****************/
:root {
  --menu-bgcolor: #202a3d;
  --menu-iconcolor: #939eae;
  --white-color: #ffffff;

  --BlueAW: #4684ff;
  --BlueAw-hover: #3d73de;
  --PaleBlueAW: #6699ff;

  --border-color: #efefef;
  --user-bgcolor: #1e629f;
  --group-bgcolor: #202a3d;
  --chat-listhover-bgcolor: #f3f6fc;

  --txt-black-color: #181818;
  --txt-gray-color: #8f99ad;

  --available-color: #78d700;
  --away-color: #f4b622;
  --offline-color: #939eae;
  --busy-color: #df202a;

  --gray-color: 32, 42, 61;
  /* Syntex code: background-color:rgba(var(--gray-color),1)*/
}

/* cs-menu css code start here ****************/
.cs-menu {
  position: fixed;
  width: 64px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  background-color: var(--menu-bgcolor);
  z-index: 4;
}

.cs-menu li {
  height: 60px;
  text-align: center;
}

.cs-menu li a {
  width: 64px;
  height: 60px;
  vertical-align: middle;
  display: table-cell;
  text-decoration: none;
  cursor:pointer;
}

.cs-menu li a:hover {
  transition: background 0.5s ease-out;
  -webkit-transition: background 0.5s ease-out;
  /* For Webkit browser(Chrome, Safari...)*/
  -moz-transition: background 0.5s ease-out;
  /* For Mozilla browser */
  -ms-transition: background 0.5s ease-out;
  /* For Opera browser */
  -o-transition: background 0.5s ease-out;
  /* For Microsoft browser */
}

.cs-menu li a.active {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.cs-menu li a.setup-icon {
  position: absolute;
  bottom: 105px;
}

.cs-menu li a.setup-icon svg {
  margin: 20px 0px 0px 0px;
}
.cs-menu li.cs-logo a>svg {
  width: 50px;
  height: 50px;
  margin: 7px 0px 0px -5px;
}
.cs-menu li a>svg {
  width: 24px;
  height: 21px;
  fill: #939eae;
}

.cs-menu li a.pro-icon svg {
  height: 31px;
}

/* .cs-menu li a.active > svg,
.cs-menu li a > svg:hover{
    fill:var(--white-color);
} */

.cs-menu li a>svg:nth-child(2) {
  position: absolute;
  left: 50%;
  margin: 0px 0px 0px -12px;
  opacity: 0;
  transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
}

.cs-menu li a.active>svg:nth-child(1) {
  opacity: 0;
}

.cs-menu li a.active>svg:nth-child(2) {
  opacity: 1;
}

.cs-menu li a.setup-icon.active>svg:nth-child(2) {
  margin: 20px 0px 0px -12px;
}

/*cs-user-control css code start here ****************/
.cs-user-control {
  position: absolute;
  bottom: 25px;
  left: 0px;
}

.cs-user-profile {
  width: 64px;
  height: 78px;
  box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.76);
  background-color: #161e2c;
  text-align: center;
}

.cs-user-profile figure {
  position: relative;
  width: 35px;
  height: 35px;
  margin: 22px auto 0px;
  display: inline-block;
  background-image: url("../images/user-white-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 14px;
  background-color: #374051;
  border-radius: 100%;
}

.cs-user-profile figure img {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  border-radius: 100%;
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.cs-user-profile cite {
  padding: 4px;
  border-radius: 100%;
  border: 2px solid #161e2c;
  z-index: 1;
  position: absolute;
  bottom: 19px;
  right: 14px;
}

.cs-user-status-list {
  position: absolute;
  padding: 20px;
  width: 310px;
  bottom: 0px;
  left: 64px;
  min-height: 78px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
  border-radius: 0px 11px 11px 0px;
  background-color: var(--white-color);
}

.cs-user-status-list.open {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.25);
}

.cs-user-status-list.open:before,
.cs-user-status-list.open:after {
  content: "";
  width: 0;
  height: 0;
  border: 8px solid;
  position: absolute;
  margin-left: -8px;
  z-index: 1;
  bottom: 30px;
}

.cs-user-status-list.open:before {
  border-color: transparent #ebeced transparent transparent;
  left: -8px;
}

.cs-user-status-list.open:after {
  border-color: transparent #ffffff transparent transparent;
  left: -7px;
}

.cs-user-status-list.open ul {
  display: block;
}

.cs-user-status-list ul {
  margin: 0px;
  position: relative;
  display: none;
}

.cs-user-status-list ul li {
  text-align: left;
  padding: 12px 44px;
  border-radius: 3px;
  background-color: #f6f7f9;
  border: 1px solid #f6f7f9;
  margin: 0px 0px 6px 0px;
  cursor: pointer;
  position: relative;
  height: 63px;
  transition: background-color 0.5s, border-color 0.5s;
  -webkit-transition: background-color 0.5s, border-color 0.5s;
  /* For Webkit browser(Chrome, Safari...)*/
  -moz-transition: background-color 0.5s, border-color 0.5s;
  /* For Mozilla browser */
  -ms-transition: background-color 0.5s, border-color 0.5s;
  /* For Opera browser */
  -o-transition: background-color 0.5s, border-color 0.5s;
  /* For Microsoft browser */
}

.cs-user-status-list ul li:hover,
.cs-user-status-list ul li.active {
  background-color: var(--white-color);
  border: 1px solid var(--BlueAW);
}

.cs-user-status-list ul li:last-child {
  margin: 0px;
}

.cs-user-status-list ul li.active p {
  color: var(--BlueAW);
}

.cs-user-status-list ul li cite {
  padding: 4px;
  border-radius: 100%;
  position: absolute;
  left: 22px;
  top: 18px;
}

.cs-user-status-list ul li p {
  font-size: 15px;
  color: var(--txt-black-color);
  line-height: normal;
  padding: 0px 0px 3px;
}

.cs-user-status-list ul li span {
  font-size: 13px;
  color: var(--txt-gray-color);
  line-height: normal;
}

.cs-user-status-list.open>div {
  position: relative;
  padding: 0px 20px 20px 53px;
  margin: 0px 0px 20px 0px;
}

.cs-user-status-list.open>div:after {
  content: "";
  position: absolute;
  height: 1px;
  background-color: var(--border-color);
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.cs-user-status-list.open>div figure {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  position: absolute;
  left: 0px;
  background-image: url("../images/user-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 14px;
  background-color: #374051;
  display: block;
}

.cs-user-status-list.open>div figure img {
  border-radius: 100%;
  width: 35px;
  height: 35px;
  display: block;
  object-fit: cover;
}

.cs-user-status-list.open>div>cite {
  padding: 4px;
  border-radius: 100%;
  z-index: 1;
  position: absolute;
  top: 26px;
  left: 26px;
  border: 1px solid var(--white-color);
}

.cs-user-status-list>div {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding: 20px;
}

.cs-user-status-list>div figure,
.cs-user-status-list>div figure img,
.cs-user-status-list.open>div>i {
  display: none;
}

.cs-user-status-list div.offline-status:before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 3px;
  height: 4px;
  background-color: #f2c241;
  border-radius: 0px 0px 11px 0px;
}

.cs-user-status-list div>p {
  font-size: 15px;
  font-weight: 600;
  line-height: normal;
  padding: 0px 0px 3px;
  color: var(--txt-black-color);
}

.cs-user-status-list div>span {
  font-size: 14px;
  line-height: normal;
  color: var(--txt-gray-color);
}

.cs-user-status-list div>span u {
  color: var(--BlueAW);
  cursor: pointer;
  padding: 0px 0px 0px 7px;
  position: relative;
}

.cs-user-status-list div>span u:before {
  content: ".";
  position: absolute;
  left: 0px;
  color: var(--txt-gray-color);
  font-size: 14px;
}

.cs-user-status-list div>i {
  padding: 5px 6px;
  background: url("../images/arrow-icon.svg") center center no-repeat;
  position: absolute;
  right: 20px;
  bottom: 33px;
  z-index: 1;
  cursor: pointer;
}

.cs-user-status-list.open div>i {
  cursor: auto;
}

.cs-user-status-list.open div>i {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.cs-user-status-list div>i {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
/*cs-my-conversations css code start here ****************/
.cs-my-conversations {
  width: 320px;
  position: absolute;
  bottom: 0px;
  top: 0px;
  left: 0px;
  border: 1px solid var(--border-color);
  border-width: 0px 1px 0px 0px;
  z-index: 0;
  background-color: var(--white-color);
  height: calc(100vh - 0px);
}

.cs-my-conversations header {
  position: relative;
  border: 1px solid var(--border-color);
  border-width: 0px 0px 1px 0px;
  padding: 19px 20px 18px;
}

.cs-my-conversations header svg.search-close-icon {
  padding: 5px;
  position: absolute;
  border-radius: 100%;
  background-color: #f0f2f5;
  cursor: pointer;
  right: 23px;
  top: 51px;
  width: 20px;
  height: 20px;
}

.cs-my-conversations header svg.search-icon {
  position: absolute;
  right: 20px;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  top: 20px;
  cursor: pointer;
  background-color: var(--BlueAW);
  fill: var(--white-color);
}

.cs-my-conversations header > code{
  width: 25px;
  height: 25px;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.cs-my-conversations header > code svg{
  width: 25px;
  height: 25px;
  display: block;
}

.cs-my-conversations header h2 {
  font-size: 26px;
  font-weight: 700;
  line-height: 1;
}

.cs-my-conversations header span {
  font-size: 14px;
  line-height: 1.36;
  padding: 6px 30px 0px 0px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-height: 25px;
}

.cs-my-conversations header span > div {
  display: flex;
  align-items: center;
}


.cs-my-conversations header span b {
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  font-weight: 600;
  margin-left: 5px;
}

.full-scroll {
  height: calc(100vh - 98px) !important;
}

.full-scroll:after {
  bottom: 0px !important;
}

.cs-my-conversations .cs-chat-list.scroll:before,
.cs-navigation-list.cs-my-team-list.scroll:before {
  content: "";
  position: sticky;
  display: inline-block;
  width: 100%;
  height: 20px;
  top: 0;
  left: 0;
  background-image: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0));
  z-index: 1;
  pointer-events: none;
}

.cs-my-conversations .cs-chat-list.scroll-top:before,
.cs-navigation-list.cs-my-team-list.scroll-top:before {
  display: none;
}

.cs-my-conversations .cs-chat-list.scroll:after,
.cs-navigation-list.cs-my-team-list.scroll:after {
  content: "";
  position: fixed;
  bottom: 104px;
  right: 322px;
  left: 64px;
  height: 20px;
  background-image: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0));
  z-index: 1;
  pointer-events: none;
  width: 319px;
}

.cs-my-conversations .cs-chat-list.scroll-bottom:after,
.cs-navigation-list.cs-my-team-list.scroll-bottom:after {
  display: none;
}

.cs-my-conversations .cs-chat-list li {
  position: relative;
  padding: 15px 20px 15px 68px;
  cursor: pointer;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  /* For Webkit browser(Chrome, Safari...)*/
  -moz-transition: background-color 0.5s;
  /* For Mozilla browser */
  -ms-transition: background-color 0.5s;
  /* For Opera browser */
  -o-transition: background-color 0.5s;
  /* For Microsoft browser */
}

.cs-my-conversations .cs-chat-list li:hover,
.cs-my-conversations .cs-chat-list li.active,
.cs-my-conversations .cs-chat-lineup li:hover,
.cs-my-conversations .cs-chat-lineup li.active {
  background-color: var(--chat-listhover-bgcolor);
}

.cs-my-conversations .cs-chat-list li.active:after,
.cs-my-conversations .cs-chat-lineup li.active:after {
  content: "" !important;
  position: absolute !important;
  right: 0px !important;
  top: 0px !important;
  bottom: 0px !important;
  width: 2px !important;
  background-color: var(--BlueAW) !important;
  opacity: 1 !important;
  left: inherit !important;
}

.cs-my-conversations .cs-chat-list li:before,
.cs-my-conversations .cs-chat-lineup li:before {
  content: "";
  height: 1px;
  background-color: #f5f5f5;
  position: absolute;
  bottom: 0px;
  left: 20px;
  right: 20px;
}

.cs-my-conversations .cs-chat-list li.resolved:after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 1px;
  background-color: var(--white-color);
  opacity: 0.5;
  z-index: 1;
  width: inherit;
}

.cs-my-conversations .cs-chat-list li.new-chat p,
.cs-my-conversations .cs-chat-list li.new-chat p code,
.cs-my-conversations .cs-chat-list li.new-chat span,
.cs-my-conversations .cs-chat-list li.new-chat label,
.cs-my-conversations .cs-chat-list li.new-chat small {
  font-weight: bold;
}

.cs-my-conversations .cs-chat-list li figure,
.cs-my-conversations .cs-chat-list li figure.cs-chat-group {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  position: absolute;
  left: 20px;
  background-image: url("../images/user-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 14px;
  background-color: var(--user-bgcolor);
}

.cs-my-conversations .cs-chat-list li figure.cs-chat-group {
  background-image: url("../images/group-active-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  background-color: var(--group-bgcolor);
}

.cs-my-conversations .cs-chat-list li figure img {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  object-fit: cover;
}

.cs-my-conversations .cs-chat-list li cite {
  padding: 3px;
  border-radius: 100%;
  z-index: 1;
  position: absolute;
  top: 40px;
  left: 45px;
  border: 1px solid var(--white-color);
}

.cs-my-conversations .cs-chat-list li label,
.cs-my-conversations .cs-chat-list li p,
.cs-my-conversations .cs-chat-list li span {
  line-height: normal;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0px 75px 0px 0px;
  color: var(--txt-black-color);
}

.cs-my-conversations .cs-chat-list li label {
  font-size: 15px;
  cursor: pointer;
  font-weight: 500;
  text-transform: capitalize;
}

.cs-my-conversations .cs-chat-list li p {
  font-size: 13px;
  padding: 6px 0px;
  margin: 0px 25px 0px 0px;
  font-weight: 500;
}

.cs-my-conversations .cs-chat-list li p code {
  font-size: 13px;
  font-family: "Lato", sans-serif;
  line-height: normal;
  color: var(--txt-black-color);
}

.cs-my-conversations .cs-chat-list li span {
  font-size: 13px;
  font-weight: 500;
  color: var(--txt-gray-color);
}

.cs-my-conversations .cs-chat-list li small {
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  right: 20px;
  top: 17px;
  color: var(--txt-black-color);
}

.cs-my-conversations .cs-chat-list li > svg{
  position: absolute;
  right: 20px;
  bottom: 13px;
}

.cs-my-conversations .cs-chat-list li>code {
  font-family: "Lato", sans-serif;
  position: absolute;
  font-size: 12px;
  font-weight: bold;
  color: var(--white-color);
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #606770;
  text-align: center;
  line-height: 20px;
  bottom: 15px;
  right: 20px;
}

.cs-my-conversations .cs-chat-list li.active > svg { fill: var(--txt-black-color); }

.cs-user-conversation.conversation-scroll,
.cs-user-conversation.conversation-scroll.scroll:after {
  bottom: 0px !important;
}

.cs-my-conversations .cs-navigation-list {
  height: calc(100vh - 225px);
  margin: 20px 0px 0px;
}

.cs-my-conversations.people-search header {
  padding: 31px 20px;
}

.cs-my-conversations.people-search header svg.search-icon {
  top: 50%;
  margin: -12px 0px 0px;
}

.cs-my-conversations.people-search>label {
  font-size: 14px;
  line-height: 1.36;
  font-weight: 600;
  color: #8f99ad;
  margin: 20px 20px 0px;
  display: block;
  text-transform: uppercase;
}

.cs-conversation.cs-people-wrp header>div.my-team-more-btn {
  top: 28px;
  right: 30px;
}

.cs-conversation.cs-people-wrp header>div.my-team-more-btn ul.edit-delete-container {
  width: 176px;
  margin: 0px 0px 0px -148px;
}

.cs-conversation.cs-people-wrp header>div.my-team-more-btn ul.edit-delete-container:before,
.cs-conversation.cs-people-wrp header>div.my-team-more-btn ul.edit-delete-container:after {
  margin: 0px 0px 0px 52px;
}

.cs-conversation.cs-people-wrp header>div.cs-people-header label {
  font-size: 18px;
  line-height: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: var(--txt-black-color);
  font-weight: 600;
  border: 1px solid #ffffff;
  border-width: 0px 0px 2px;
  min-width: auto;
  max-width: 280px;
  position: relative;
  padding: 0px 20px 0px 0px;
  /*padding: 1px 0px;
    margin: -4px 0px 3px 0px;*/
}

.cs-conversation.cs-people-wrp header>div.cs-people-header label>svg {
  width: 12px;
  height: 12px;
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 50%;
  margin: -6px 0px 0px;
  opacity: 0.6;
  display: none;
}

.cs-conversation.cs-people-wrp header>div.cs-people-header label.label-edit:hover svg {
  display: block;
}

.cs-conversation.cs-people-wrp header>div.cs-people-header label.label-edit>svg:hover {
  opacity: 1;
}

.cs-conversation.cs-people-wrp header>div.cs-people-header>input {
  font-size: 18px;
  line-height: normal;
  display: block;
  color: var(--txt-black-color);
  border: 1px solid #6699ff;
  border-width: 0px 0px 1px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  margin: -4px 0px 3px 0px;
}

.cs-conversation.cs-people-wrp header>div.cs-people-header p {
  font-size: 14px;
  line-height: 1.36;
  font-weight: 600;
  color: var(--txt-gray-color);
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.cs-conversation.cs-people-wrp header>div.cs-people-header p>em {
  margin: 12px 0px 0px 0px;
  width: 160px;
}

.cs-conversation.cs-people-wrp header>div.cs-people-header p a {
  font-size: 13px;
  font-weight: bold;
  color: #6699ff;
  text-decoration: none;
  margin: 0px 0px 0px 5px;
}

/*cs-my-conversations-filter css code start here ****************/
.cs-my-conversations-filter {
  width: 320px;
  height: fit-content;
  position: absolute;
  bottom: 0px;
  top: 0px;
  left: 0px;
  border: 1px solid var(--border-color);
  border-width: 0px 1px 0px 0px;
  z-index: 1;
  background-color: var(--white-color);
}

.cs-my-conversations-filter>div>svg:first-child {
  margin-right: 10px;
}

.cs-my-conversations-filter>div>svg.search-black-icon {
  width: 14px;
  height: 14px;
  display: inline-block;
}

.cs-my-conversations-filter svg.search-close-icon {
  padding: 5px;
  border-radius: 100%;
  background-color: #f0f2f5;
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-left: auto;
}

.cs-my-conversations-filter>p.no-found {
  text-align: center;
  font-size: 14px;
  color: #8f99ad;
  margin: 30px 0px;
}

.cs-my-conversations-filter>div>strong {
  display: inline-block;
  font-size: 15px;
  color: var(--txt-gray-color);
  margin-right: 10px;
  font-weight: normal !important;
}

.cs-my-conversations-filter>div {
  padding: 22px 20px 24px 20px;
  display: flex;
  align-items: center;
}

.cs-my-conversations-filter>div>input {
  margin-right: 10px;
  border: 0px;
  font-size: 15px;
  color: var(--txt-black-color);
  display: inline-block;
}

.filter-icon + strong + input {
  width: 105px;
}

.filter-tag-icon + strong + input {
  width: 160px;
}

.cs-filter-list {
  margin: 10px 0px 0px;
  overflow: auto; 
  height: auto;
  padding: 0px 20px;
  box-shadow: -1px 8px 7px 0 rgba(0, 0, 0, 0.06);
  padding-bottom: 5px;
}

.cs-filter-list > div {
  margin-bottom: 32px;
}

.cs-filter-list h5 {
  font-size: 14px;
  color: var(--txt-gray-color);
  margin: 32px 0px 20px;
}

.cs-filter-list h5:first-child {
  margin: 0px 0px 15px;
}

.cs-filter-list label {
  font-size: 14px;
  line-height: 1.43;
  color: var(--txt-black-color);
  display: block;
  margin: 10px 0px;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cs-filter-list label span {
  color: var(--txt-gray-color);
}

.cs-filter-list svg {
  width: 12px;
  height: 12px;
  fill: var(--txt-gray-color);
  margin: 0px 10px -1px 0px;
  display: inline-block;
}

.translateY-1 {
  transform: translateY(1px);
}

.cs-filter-list ul li {
  list-style: none;
  color: var(--menu-bgcolor);
  font-size: 14px;
  margin-bottom: 20px;
  cursor: pointer;
}

.cs-filter-list small {
  font-size: 10px;
  border-radius: 11px;
  color: var(--txt-black-color);
  border: 1px solid var(--txt-gray-color);
  padding: 5px 8px 6px;
  display: inline-block;
  margin: 0px 4px 5px 0px;
  cursor: pointer;
  line-height: normal;
}

/*cs-conversation css code start here ****************/
.cs-conversation {
  position: absolute;
  left: 320px;
  bottom: 0px;
  right: 0px;
  top: 0px;
  background-color: var(--white-color);
  height: calc(100vh - 0px);
  width: calc(100% - 320px);
}

.cs-conversation>header {
  position: relative;
  border: 1px solid var(--border-color);
  border-width: 0px 0px 1px 0px;
  padding: 24px 24px 24px 76px;
  height: 89px;
}

.cs-conversation>header figure {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  position: absolute;
  left: 24px;
  background-image: url("../images/user-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
  background-color: var(--user-bgcolor);
}

.cs-conversation>header figure.cs-chat-group {
  background-image: url("../images/group-active-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 23px;
  background-color: var(--group-bgcolor);
}

.cs-conversation>header figure img {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.cs-conversation>header figure i {
  position: absolute;
  width: 22px;
  height: 22px;
  border: 2px solid #ffffff;
  background-color: #ff6836;
  border-radius: 100%;
  text-align: center;
  right: -9px;
  bottom: -4px;
}

.cs-conversation>header figure i svg {
  width: 8px;
  height: 8px;
  margin: 6px 0px 0px -1px;
}

.cs-conversation>header cite {
  padding: 3.5px;
  border-radius: 100%;
  z-index: 1;
  position: absolute;
  top: 55px;
  left: 53px;
  border: 1px solid var(--white-color);
}

.cs-conversation>header>label,
.cs-conversation>header>p {
  font-size: 18px;
  line-height: normal;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: var(--txt-black-color);
  font-weight: 500;
}

.cs-conversation>header>p {
  font-size: 14px;
  line-height: 1.36;
  font-weight: 600;
  color: var(--txt-gray-color);
}

.cs-conversation>header>p a {
  font-size: 13px;
  font-weight: bold;
  color: #6699ff;
  text-decoration: none;
  margin: 0px 0px 0px 5px;
}

.cs-conversation>header > code{
  padding: 5px 6px;
    background: rgba(255, 255, 255, 0.9) url('../images/backArrow-icon.svg') center center no-repeat;
    position: absolute;
    right: 20px;
    bottom: 33px;
    z-index: 4;
    cursor: pointer;
    width: 32px;
    height: 32px;
    top: 50%;
    left: 16px;
    margin: -16px 0px 0px 0px;
    background-size: 7px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    border-radius: 100%;
}

.cs-conversation>header > svg{
  width: 50px;
  height: 50px;
  position: absolute;
  left: 19px;
  z-index: 1;
  top: 50%;
  margin: -24px 0px 0px 0px;
}

.cs-conversation header div.cs-map-location {
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0px;
  width: 270px;
  right: 0px;
}

.cs-conversation header div.cs-map-location:before {
  position: absolute;
  content: "";
  width: 130px;
  height: 88px;
  z-index: 1; 
  background-image: linear-gradient(89deg, white, transparent)
}

/*cs-user-conversation css code start here ****************/
.cs-user-conversation {
  position: absolute;
  right: 322px;
  bottom: 112px;
  left: 0px;
  padding: 20px 0px 0px;
  max-height: calc(100vh - 201px);
  overflow: auto;
}

.cs-user-conversation.scroll:before {
  content: "";
  position: sticky;
  display: inline-block;
  width: 100%;
  top: -20px;
  left: 0px;
  height: 20px;
  background-image: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0));
  z-index: 1;
  pointer-events: none;
}

.cs-user-conversation.scroll-top:before {
  display: none;
}

.cs-user-conversation.scroll:after {
  content: "";
  position: fixed;
  bottom: 112px;
  right: 322px;
  left: 384px;
  height: 20px;
  background-image: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0));
  z-index: 0;
  pointer-events: none;
}

.cs-user-conversation.scroll-bottom:after {
  display: none;
}

.cs-user-conversation .conversation-msg {
  padding: 0px 24px 26px 66px;
  position: relative;

}

.message-wrapper > iframe {
  width: calc(100% - 70px);
  height: 300px;
  margin-top: 10px;
  border-radius: 15px;
}

.cs-user-conversation .conversation-msg>figure {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  position: absolute;
  left: 24px;
  background-image: url("../images/user-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 12px;
  background-color: var(--user-bgcolor); 
}

.cs-user-conversation .conversation-msg>figure.proactive-icon {
  background-image: url("../images/proactive-icon.svg");
  background-size: 14px;
}

.cs-user-conversation .conversation-msg>figure img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  object-fit: cover;
}

.cs-user-conversation .conversation-msg label,
.cs-user-conversation .conversation-msg p {
  font-size: 15px;
  line-height: normal;
  display: block;
  color: var(--txt-black-color);
  font-weight: 600;
}

.cs-user-conversation .conversation-msg label:not(.edit-tag label) {
  font-weight: bold;
  text-transform: capitalize;
}

.cs-user-conversation .conversation-msg p {
  font-weight: normal;
  line-height: 1.6;
  margin: 4px 0px 0px 0px;
  padding: 0px 70px 0px 0px;
  word-break: break-word;
}

.cs-user-conversation .conversation-msg small {
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  right: 24px;
  top: 4px;
  color: #aeb7c3;
  z-index: 1;
}

.cs-user-conversation .conversation-msg>svg {
  position: absolute;
  width: 9px;
  height: 7px;
  right: 24px;
  top: 30px;
  fill: var(--txt-gray-color);
}

.cs-user-conversation .conversation-msg.delivered-status>svg {
  fill: #40495b;
}

.cs-user-conversation .conversation-msg.read-status>svg {
  fill: var(--BlueAW);
}

/* detailed campaign styling */

.detailed-campaign-header {
  padding: 35px 25px;
  border-bottom: 1px solid var(--border-color);
  position: relative;
  z-index: 2;
}

.detailed-campaign-header .my-team-more-btn {
  top: 30px;
}

.detailed-campaign-header > a {
  display: flex;
  align-items: center;
  gap: 12px;
}

.detailed-campaign-header > div:last-child {
  margin-top: 35px;
}

.detailed-campaign-header > a > p {
  font-size: 18px;
  font-weight: 600;
  color: #2B333D;
  font-family: "Lato", sans-serif;
}

.detailed-campaign-header > a > cite {
  display: flex;
}

.detailed-campaign-header .filter-checkbox-dwn > label:first-child {
  padding: 0;
  border: none;
  font-weight: 600;
} 

/*notes-msg css code start here ****************/
.notes-msg {
  background-color: #f8fafb;
  margin: 0px 10px 26px;
  border-radius: 4px;
  padding: 16px 24px 16px 60px !important;
}

.notes-msg:before {
  content: "";
  background: url("../images/notes-circle-icon.svg") center no-repeat;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 14px;
  border-radius: 100%;
  z-index: 1;
}

.notes-msg figure {
  left: 40px !important;
}

.notes-msg small {
  top: 14px !important;
  right: 14px !important;
}

.notes-msg label {
  color: var(--txt-gray-color) !important;
  margin: 8px 0px 0px 20px;
  font-weight: 500 !important;
}

.notes-typing-area {
  padding: 0px 0px 0px 40px !important;
}

.notes-typing-area:before {
  content: "";
  background: url("../images/notes-circle-icon.svg") center no-repeat;
  width: 26px;
  height: 26px;
  position: absolute;
  left: 24px;
  top: 20px;
  border-radius: 100%;
  z-index: 1;
}

/*asign-to-msg css code start here ****************/
.asign-to-msg {
  background-color: #f8fafb;
  margin: 0px 10px 26px;
  border-radius: 4px;
  padding: 18px 24px 18px 60px !important;
}

.asign-to-msg figure {
  left: 40px !important;
}

.asign-to-msg figure img:last-child {
  position: absolute;
  left: -26px;
}

.asign-to-msg label {
  color: var(--txt-gray-color) !important;
  margin: 6px 0px 6px 20px;
  font-weight: 500 !important;
}

.asign-to-msg label b {
  padding: 0px 6px 0px 0px;
}

.cs-user-conversation .conversation-msg.asign-to-msg small {
  top: 26px;
  right: 14px;
}

.tag-msg {
  text-align: center;
  padding: 12px 24px 38px 24px !important;
}

.tag-msg span {
  font-size: 12px;
  font-weight: 500;
  color: #181818;
  border-radius: 18px;
  padding: 5px 20px;
  background-color: #f0f2f5;
}

.tag-new-msg span {
  color: #1d428f;
  background-color: #f0f5ff;
}

.tag-date {
  text-align: center;
  position: absolute;
  left: 0px;
  right: 322px;
  padding: 0px 24px;
  top: 105px;
}

.tag-date span {
  font-size: 12px;
  font-weight: 500;
  color: #181818;
  border-radius: 18px;
  padding: 5px 20px;
  background-color: #f0f2f5;
  z-index: 1;
  position: relative;
}

.new-msg span {
  color: #1d428f;
  background-color: #e0ebff;
}

/* tag css code starts here */

.message-wrapper {
  position: relative;
}



.message-wrapper.webapp:hover {
  background-color:rgba(243, 246, 252,0.7);
  box-shadow: 0 0 1pt 3pt rgba(243, 246, 252,0.7); 
} 

.message-wrapper.webapp:hover > .message-tags > li {
  background-color: var(--white-color);
  outline: var(--border-color) solid 1px;
}

.message-tags > li:hover {
  outline: var(--PaleBlueAW) solid 1px !important;
}

.message-options {
  position: absolute;
  right: 15px;
  top: -5px;
  padding: 6px 20px;
  border: 1px solid var(--border-color);
  background: white;
  border-radius: 25px;
  z-index: 1;
  display: inline-block;
  cursor: pointer;
}

.message-options.open {
  z-index: 2;
}

.message-options::before {
  content: "";
  position: absolute;
  width: 210px;
  background: lightgrey;
  height: 70px;
  opacity: 0;
  left: -149px;
  top: -19px;
  z-index: -1;
  cursor: initial;
}

.tag-message-icon {
  fill: black;
}

.tag-message-icon:hover {
  fill: var(--BlueAW);
}

.tag-message-icon.active {
  fill: var(--BlueAW);
}

.tag-search-container {
  position: relative;
  z-index: 5;
  background: var(--white-color);
  padding: 5px 17px 17px 17px;
}


.tag-search {
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 40px;
  padding: 0 10px 0 17px;
}

.tag-search input {
  border: none;
  width: 90%;
  font-size: 12px;
}

.tag-search input::placeholder {
  color: #ACB7CC;
}

.tag-message-dropdown {
  display: inline-block;
  position: fixed;
  width: 195px;
  background-color: var(--white-color);
  border: 1px solid var(--border-color);
  border-radius: 5px;
  z-index: 10;
  box-shadow: 0 0 14px -8px rgba(0,0,0,0.5);
  padding: 12px 0;
}

.tag-message-tags {
  max-height: 120px;
  overflow-y: auto;
}

.tag-message-tags > li {
  background: url("../images/tag-icon-black.svg") no-repeat left 17px bottom 11px;
  padding: 10px 17px 10px 43px;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tag-message-tags > li.edit {
  background: url("../images/tag-icon-blue.svg") no-repeat left 18px top 18px;
}

.tag-message-tags > li.edit > span,.tag-message-tags > li.edit:hover > span:nth-of-type(2) {
  display: none;
}

.tag-message-tags > li > span,.message-tags li > span, .conversation-tags li > span {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: auto;
}

.tag-message-tags > li > span {
  max-width: 100px;
}

.message-tags li > span, .conversation-tags li > span {
  max-width: 150px;
  margin-bottom: -10px;
  padding-bottom: 10px;
}

.tag-message-tags > li > svg {
  display: none;
  margin-right: -5px;
}

.tag-message-tags > li:hover,.tag-message-tags > li.selected,.tag-message-add-tag:not(.add):hover {
  background-color: var(--chat-listhover-bgcolor);
}

.tag-message-tags > li.edit:hover {
  background-color: var(--white-color);
}

.tag-message-tags > li.selected > svg {
  display: inline-block;
}

.tag-message-add-tag > div {
  padding: 10px 17px;
}

.tag-message-add-tag.add > span {
  display: none;
}

.tag-message-add-tag span {
  color: var(--BlueAW);
  cursor: pointer;
  font-size: 14px;
}

.tag-message-more {
  border: 1px solid var(--border-color);
  position: absolute;
  background: var(--white-color);
  display: flex;
  right: -17px;
  top: -35px;
  border-radius: 5px;
  box-shadow: 0 0 17px -8px rgba(0,0,0,0.5);
  z-index: 7;
}

.tag-message-more li {
  padding: 13px
}

/* message tags css */
.message-tags,.conversation-tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  gap: 10px;
}

.conversation-tags {
 padding-left: 10px;
}

.message-tags {
  padding-left: 20px;
  border-left: 2px solid #D3D9E5;
}

.message-tags li,.conversation-tags li{
  font-size: 13px;
  padding: 10px;
  background-color: var(--chat-listhover-bgcolor);
  border-radius: 5px;
  color: var(--txt-black-color);
  position: relative;
  vertical-align: text-top;
  display: flex;
  align-items: center;
}

.message-tags li > svg,.conversation-tags li > svg {
  margin-right: 11px;
}

.cs-user-detail-history .message-tags li > .message-tags-remove,
.history-conversation-wrp .message-tags li > .message-tags-remove {
  background-color: var(--chat-listhover-bgcolor);
}

.message-tags li:hover > .message-tags-remove {
  display: block;
}

/* edit message css */
.edit-tag,.add-new-tag {
  display: flex;
  flex-direction: column;
}

.edit-tag > div:first-child,.add-new-tag > div:first-child {
  display: flex;
  align-items: center;
  gap: 12px;
}

.edit-tag > div:first-child > span,.add-new-tag > div:first-child > span {
  color: var(--BlueAW);
  font-size: 14px;
  line-height: 24px;
}

.edit-tag input,.add-new-tag input {
  width: 134px;
  padding: 5px 0;
  border: none;
  border-bottom: 1px solid var(--BlueAW);
  align-self: flex-end;
}

.add-new-tag.invalid input {
  border-bottom: 1px solid var(--busy-color);
}

.error-message {
  align-items: center;
  display: none;
  margin-left: 23px;
  margin-top: 10px;
}

.add-new-tag.invalid .error-message {
  display: flex;
}

.error-message span {
  font-size: 12px;
  color: var(--busy-color);
  margin-left: 5px;
} 

.error-message svg {
  fill: var(--busy-color);
}


.edit-tag button,.add-new-tag button {
  border: none;
  padding: 7px 22px;
  outline: none;
  font-size: 13px;
  align-self: end;
  margin-top: 22px;
}

.add-new-tag.invalid .error-message + button {
  margin-top: 15px;
}

/* delete message css */

.delete-message-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.delete-message-bg .delete-message {
  max-width: 660px;
  background-color: var(--white-color);
  border-radius: 10px;
}

.delete-message header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
}

.delete-message header,.delete-message-body,.delete-message footer {
  padding: 30px;
}

.delete-message header svg:nth-of-type(1) {
  margin-right: 17px;
}

.delete-message header svg:nth-of-type(2) {
  margin-left: auto;
}

.delete-message footer {
  display: flex;
  justify-content: end;
  align-items: center;
}

.delete-message footer button {
  margin-left: 40px;
  padding: 9px 35px;
  border: none;
  border-radius: 40px;
}

.delete-message footer a{
  font-size: 16px !important;
}

.delete-message footer a, .close-delete-message,.delete-message footer button {
  cursor: pointer;
}





/*file-chat css code start here ****************/
.conversation-msg.file-chat > label {
  display: block;
  margin-bottom: 6px;
}


.file-chat > div > div:not(.message-options)  {
  border: 1px solid var(--border-color);
  border-radius: 3px;
  padding: 20px 20px 50px;
  min-width: 250px;
  max-width: 600px;
  display: inline-block;
  margin: 4px 0;
  position: relative;
  min-height: 148px;
}

.file-chat>div>div section {
  padding: 0px 0px 20px 60px;
  position: relative;
  word-break: break-all;
  word-wrap: break-word;
}

.file-chat>div>div section:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 41px;
  height: 50px;
}

.file-chat>div section.xls-file-icon:before {
  background: url("../images/xls-file-icon.svg") no-repeat;
  background-size: 44px;
}

.file-chat>div section.docx-file-icon:before {
  background: url("../images/docx-file-icon.svg") no-repeat;
  background-size: 44px;
}

.file-chat>div section.pdf-file-icon:before {
  background: url("../images/pdf-file-icon.svg") no-repeat;
  background-size: 44px;
}

.file-chat>div section.zip-file-icon:before {
  background: url("../images/zip-file-icon.svg") no-repeat;
  background-size: 41px;
}

.file-chat>div section.video-file-icon:before {
  background: url("../images/video-file-icon.svg") no-repeat;
  background-size: 44px;
}

.file-chat>div section.music-file-icon:before {
  background: url("../images/music-file-icon.svg") no-repeat;
  background-size: 44px;
}

.file-chat>div section.other-file-icon:before {
  background: url("../images/other-file-icon.svg") no-repeat;
  background-size: 44px;
}

.file-chat>div section.screen-share-link:before,
.file-chat>div section.video-share-link:before {
  background: url("../images/screen-share-link-icon.svg") no-repeat;
  width: 46px;
  height: 46px;
  left: 12px;
  top: 10px;
}

.file-chat>div section.video-share-link:before {
  background: url("../images/teleport-video-link-icon.svg") no-repeat !important;
}

.file-chat>div>div section strong {
  font-size: 16px;
  font-weight: 600;
  padding: 0px 0px 5px;
  color: var(--txt-black-color);
  display: block;
  line-height: 22px;
}

.file-chat>div>div>section strong code svg {
  width: 20px;
  height: 9px;
  cursor: pointer;
  margin: 0px 0px 0px 5px;
  fill: var(--txt-gray-color);
  display: none;
}

.file-chat>div>div:hover>section strong code svg {
  display: inline-block;
}

.file-chat>div>div>section strong code svg:hover {
  fill: var(--txt-black-color);
}

.file-chat>div>div section p {
  font-size: 13px !important;
  color: var(--txt-black-color) !important;
  margin: 0px !important;
  padding: 0px !important;
  /*text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;*/
}

.file-chat>div>div section span {
  font-size: 13px;
  color: var(--txt-gray-color);
}

.file-chat>div>div>div:not(.tag-message-dropdown) {
  border: 1px solid var(--border-color);
  border-width: 1px 0px 0px;
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 20px;
  padding: 12px 0px 0px;
}

.file-chat>div>div a {
  text-decoration: none;
  color: var(--txt-gray-color);
  font-size: 12px;
  font-weight: bold;
}

.file-chat>div>div a:before {
  content: "-";
  margin: 0px 5px 0px 0px;
}

.file-chat>div>div>section.preview-file-icon {
  padding: 0px 0px 0px 170px;
  min-height: 124px;
}

.file-chat>div>div>section.preview-file-icon:before,
.file-chat>div>div>div a:first-child:before {
  display: none;
}

.file-chat>div>div>section.preview-file-icon img {
  object-fit: cover;
  width: 150px;
  height: 104px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.file-chat>div>div>section.preview-file-icon span,
.file-chat>div>div>section.link-file-icon span {
  margin: 6px 0px 20px;
  display: inline-block;
  word-break: break-word;
  line-height: normal;
}

.file-chat>div>div>section.link-file-icon {
  padding: 0px;
}

.file-chat>div>div>section.screen-share-link,
.file-chat>div>div>section.video-share-link {
  padding: 0px 0px 0px 90px;
}

.file-chat>div>div>section.screen-share-link strong,
.file-chat>div>div>section.video-share-link strong {
  padding: 0px 0px 10px !important;
}

.file-chat>div>div>section.screen-share-link p,
.file-chat>div>div>section.video-share-link p {
  padding: 0px 0px 20px !important;
}

/*cs-typing css code start here ****************/
.cs-typing {
  position: absolute;
  bottom: 0px;
  right: 322px;
  left: 0px;
  padding: 24px;
  border: 1px solid var(--border-color);
  border-width: 1px 0px 0px 0px;
  z-index: 0;
  background-color: var(--white-color);
  cursor: text;
}

.cs-typing textarea,
.cs-typing-area {
  border: 0px;
  resize: none;
  font-size: 16px;
  width: 100%;
  color: var(--txt-black-color);
  letter-spacing: 0.19px;
  padding: 0px;
  margin: 0px 0px 10px 0px;
  min-height: 30px;
  max-height: 200px;
  line-height: 1.6;
}

.public-DraftEditorPlaceholder-hasFocus,
.public-DraftEditorPlaceholder-root {
  color: #acb7cc;
  font-size: 16px;
  font-weight: normal;
  top: 20px;
}

/*cs-typing-footer css code start here ****************/
.cs-typing-footer {
  height: 22px;
  margin: 0px;
  display: inline-block;
  /*width: 100%;*/
}

.cs-typing-footer .cs-typing-attachment svg {
  position: absolute;
  width: 9px;
  height: 17px;
  left: 24px;
  bottom: 25px;
  fill: var(--txt-gray-color);
  cursor: pointer;
}

.cs-typing-footer .cs-typing-attachment:hover svg {
  fill: #40495b;
}

.cs-typing-footer .cs-typing-attachment input[type="file"],
.cs-typing-footer .cs-typing-attachment input[type="file"]::-webkit-file-upload-button {
  -webkit-appearance: button;
  opacity: 0;
  position: absolute;
  width: 8px;
  height: 16px;
  right: 0px;
  left: 24px;
  bottom: 26px;
  cursor: pointer;
  outline: 0;
}

.cs-typing-footer>svg {
  position: absolute;
  width: 15px;
  height: 15px;
  right: 0px;
  left: 50px;
  bottom: 26px;
  cursor: pointer;
  outline: 0;
  fill: var(--txt-gray-color);
}

.cs-typing-footer>svg:hover,
.cs-typing-footer>svg.active,
.cs-typing-footer>div svg:hover,
.cs-typing-footer>div svg.active {
  fill: #40495b;
}

.cs-typing-footer svg.screen-share-icon-btn {
  left: 83px;
  /* display: none */
}

.cs-typing-footer svg.video-icon {
  width: 17px;
  left: 83px;
}

/* .cs-typing-footer svg.resolve-icon {
  left: 83px;
} */

.cs-typing-footer svg.more-icon-btn {
  width: 12px;
  height: 12px;
  position: absolute;
  fill: var(--txt-gray-color);
}

.cs-typing-footer .sent-icon-btn {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  left: inherit;
  background-image: url("https://storage.googleapis.com/livesupport/chat/images/send-btn.svg");
  background-repeat: no-repeat;
  background-position: center 10px;
  background-color: #d3d9e5;
  cursor: pointer;
  border: none;
  outline: 0;
}

.cs-typing-footer .sent-icon-btn.enable { background-color: var(--PaleBlueAW); }
.cs-typing-footer .sent-icon-btn.enable:focus,
.cs-typing-footer .sent-icon-btn.enable:hover { 
  background-color: var(--BlueAw-hover); 
}

/*at-icon-btn-dwn code start here ****************/
.at-icon-btn-dwn {
  position: absolute;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.25);
  width: 240px;
  bottom: 57px;
  left: 29px;
  padding: 2px 0px;
  background-color: var(--white-color);
  border-radius: 10px;
  z-index: 1;
}

.at-icon-btn-dwn input[type="text"] {
  height: 32px;
  margin: 18px 20px 0px;
  padding: 0px 10px 0px 30px;
  font-size: 14px;
  line-height: 1.43;
  font-family: "Lato", sans-serif;
  display: block;
  width: 200px;
  border: 1px solid var(--border-color);
  border-radius: 3px;
}

.at-icon-btn-dwn code {
  content: "";
  width: 12px;
  height: 12px;
  position: absolute;
  z-index: 1;
  left: 31px;
  top: 31px;
  background: url("../images/search-black-icon.svg") center no-repeat;
  background-size: 12px;
  opacity: 0.6;
}

.at-icon-btn-dwn ul:before,
.at-icon-btn-dwn ul:after {
  content: "";
  width: 0;
  height: 0;
  border: 8px solid;
  position: absolute;
  margin-left: -8px;
  z-index: 1;
  left: 28px;
}

.at-icon-btn-dwn ul:before {
  border-color: #ebeced transparent transparent;
  bottom: -16px;
}

.at-icon-btn-dwn ul:after {
  border-color: #ffffff transparent transparent;
  bottom: -15px;
}

.at-icon-btn-dwn ul {
  padding: 8px 0px 10px;
  min-height: auto;
  max-height: 133px;
  overflow: auto;
}

.at-icon-btn-dwn ul li {
  padding: 10px 30px 10px 52px;
  font-size: 14px;
  line-height: 1.43;
  color: var(--txt-black-color);
  cursor: pointer;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
}

.at-icon-btn-dwn ul li:hover {
  background-color: var(--chat-listhover-bgcolor);
}

.at-icon-btn-dwn ul li.active:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: url('../images/tick-blue-icon.svg') no-repeat;
  right: 22px;
  top: 17px;
}

.at-icon-btn-dwn ul li figure {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  position: absolute;
  left: 20px;
  background-image: url("../images/user-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 12px;
  background-color: var(--user-bgcolor);
}

.at-icon-btn-dwn ul li figure img {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  object-fit: cover;
}

.at-icon-btn-dwn ul li cite{
  padding: 3px;
  border-radius: 100%;
  z-index: 1;
  position: absolute;
  top: 24px;
  left: 36px;
  border: 1px solid var(--white-color);
}

/*more-icon-btn-dwn code start here ****************/
.more-icon-dwn .more-icon-btn-dwn,
.resolve-btn .resolve-btn-dwn {
  display: none;
}

.more-icon-dwn.open .more-icon-btn-dwn,
.resolve-btn.open .resolve-btn-dwn {
  display: block;
}

.more-icon-dwn.open svg {
  fill: #40495b;
}

.more-icon-dwn {
  left: 114px;
  width: 12px;
  height: 12px;
  bottom: 28px;
  position: absolute;
  cursor: pointer;
}

.more-icon-btn-dwn,
.resolve-btn-dwn {
  position: absolute;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.25);
  width: 240px;
  bottom: 30px;
  left: -22px;
  padding: 2px 0px;
  background-color: var(--white-color);
  border-radius: 10px;
  z-index: 1;
}

.more-icon-btn-dwn ul,
.resolve-btn-dwn ul {
  padding: 10px 0px;
}

.more-icon-btn-dwn ul:before,
.more-icon-btn-dwn ul:after,
.resolve-btn-dwn ul:before,
.resolve-btn-dwn ul:after {
  content: "";
  width: 0;
  height: 0;
  border: 8px solid;
  position: absolute;
  margin-left: -8px;
  z-index: 1;
  left: 28px;
}

.more-icon-btn-dwn ul:before,
.resolve-btn-dwn ul:before {
  border-color: #ebeced transparent transparent;
  bottom: -16px;
}

.more-icon-btn-dwn ul:after,
.resolve-btn-dwn ul:after {
  border-color: #ffffff transparent transparent;
  bottom: -15px;
}

.more-icon-btn-dwn ul li,
.resolve-btn-dwn ul li {
  padding: 10px 20px 10px 20px;
  font-size: 14px;
  line-height: 1.43;
  color: var(--txt-black-color);
  cursor: pointer;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.more-icon-btn-dwn ul li:hover,
.resolve-btn-dwn ul li:hover {
  background-color: var(--chat-listhover-bgcolor);
}

.more-icon-btn-dwn ul li svg,
.resolve-btn-dwn ul li svg {
  display: inline-block;
  fill: var(--txt-gray-color);
}

.more-icon-btn-dwn ul li svg.resolve-icon {
  width: 16px;
  height: 16px;
  margin: 0px 16px -3px 0px;
}

.more-icon-btn-dwn ul li svg.video-icon {
  width: 17px;
  height: 17px;
  margin: 0px 16px -4px 0px;
}

.more-icon-btn-dwn ul li svg.email-icon {
  width: 14px;
  height: 14px;
  margin: 0px 18px -2px 0px;
}

.more-icon-btn-dwn ul li svg.notes-icon {
  width: 13px;
  height: 13px;
  margin: 0px 20px -2px 1px;
}

.resolve-btn-dwn ul li svg.resolve-icon,
.resolve-btn-dwn ul li svg.pending-icon {
  width: 17px;
  height: 17px;
  margin: 0px 10px -3px 0px;
}

.resolve-btn-dwn ul li svg.resolve-icon {
  width: 15px;
  height: 15px;
}
.resolve-btn-dwn ul li svg.pending-icon{ margin: 0px 10px -3px -1px; }

.resolve-btn{
	position: absolute;
  width: 15px;
  height: 15px;
  right: 0px;
  left: 87px;
  bottom: 26px;
  cursor: pointer;
  outline: 0;
  fill: var(--txt-gray-color);
}

.resolve-btn > svg{
	position: absolute;
  width: 13px;
  height: 13px;
  fill: var(--txt-gray-color);
}

.resolve-btn.open > svg{
	fill: #4684ff;
}

.resolve-btn.open .resolve-btn-dwn{
	left:-23px;
}

/*tag-input css code start here ****************/
.tag-input {
  position: relative;
}

.tag-input.open div {
  display: block;
}

.tag-input div {
  position: absolute;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.25);
  width: 210px;
  bottom: 35px;
  padding: 2px 0px;
  background-color: var(--white-color);
  border-radius: 10px;
  z-index: 1;
  display: none;
}

.tag-input div ul {
  padding: 5px 0px;
  display: block !important;
  margin: 0px !important;
}

.tag-input div ul:before,
.tag-input div ul:after {
  content: "";
  width: 0;
  height: 0;
  border: 8px solid;
  position: absolute;
  margin-left: -8px;
  z-index: 1;
  left: 44px;
}

.tag-input div ul:before {
  border-color: #ebeced transparent transparent;
  bottom: -16px;
}

.tag-input div ul:after {
  border-color: #ffffff transparent transparent;
  bottom: -15px;
}

.tag-input div li {
  padding: 10px 20px 10px 20px !important;
  font-size: 14px;
  line-height: 1.43;
  color: var(--txt-black-color);
  cursor: pointer;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
}

.tag-input div li:hover {
  background-color: var(--chat-listhover-bgcolor);
}

.tag-input div li cite {
  width: 15px;
  height: 18px;
  margin: 0px 14px -4px 0px;
  display: inline-block;
}

/*cs-user-info css code start here ****************/
.cs-user-info {
  position: absolute;
  width: 322px;
  height: calc(100vh - 89px);
  border: 1px solid var(--border-color);
  border-width: 0px 0px 0px 1px;
  right: 0px;
}

cite.cs-user-info-btn {
  background: url("../images/arrow-icon.svg") center center no-repeat;
  width: 25px;
  height: 25px;
  z-index: 1;
  position: absolute;
  top: 125px;
  right: 310px;
  border-radius: 100%;
  border: 1px solid var(--border-color);
  background-size: 10px;
  background-color: var(--white-color);
  cursor: pointer;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
}

cite.cs-user-info-btn.open {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
}

.cs-user-info-tab {
  padding: 18px 0px 0px 20px;
  border: 1px solid var(--border-color);
  border-width: 0px 0px 1px;
}

.cs-user-info-tab button {
  line-height: 1.36;
  color: var(--txt-gray-color);
  font-size: 14px;
  font-weight: 600;
  padding: 6px 12px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  border: var(--white-color);
  background-color: var(--white-color);
}

.cs-user-info-tab button.active {
  font-weight: 600;
  color: var(--txt-black-color);
}

.cs-user-info-tab button.active:after {
  content: "";
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  height: 1px;
  background-color: var(--BlueAW);
}

.cs-user-details-block {
  float: right;
  margin: -3px 124px 0 0;
}

/*cs-user-details-status css code start here ****************/
.cs-user-details {
  padding: 0px 22px;
  overflow: auto;
  height: calc(100vh - 145px);
}

.cs-user-details-status {
  padding: 24px 24px 24px 52px;
  height: 89px;
  position: relative;
}

.cs-user-details-status figure {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  position: absolute;
  left: 0px;
  background-image: url("../images/user-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
  background-color: var(--user-bgcolor);
}

.cs-user-details-status img {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.cs-user-details-status label,
.cs-user-details-status input[type="text"] {
  font-size: 15px !important;
  line-height: normal;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: var(--txt-black-color) !important;
  font-weight: 600;
  margin: 12px 0px;
  padding: 0px 50px 0px 0px !important;
}

.cs-user-details-status cite {
  padding: 3.5px;
  border-radius: 100%;
  z-index: 1;
  position: absolute;
  top: 54px;
  left: 29px;
  border: 1px solid var(--white-color);
}

.cs-user-details input[type="text"] {
  border: 0px;
  padding: 0px 10px 12px;
  font-size: 14px;
  color: var(--txt-black-color);
  outline: 0;
  width: 230px;
  background-color: var(--white-color);
  text-transform: capitalize;
  font-family: "Lato", sans-serif;
}

.cs-user-details input.txt-wrp {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cs-user-details div.email-input input[type="text"] {
  text-transform: none;
}

.cs-user-details div.email-input svg {
  margin: 0px 0px -2px 10px;
}

.user-info-input svg {
  width: 12px;
  height: 12px;
  display: inline-block;
  margin: 0px 0px -1px 10px;
  fill: var(--txt-gray-color);
}

.user-info-input.active svg {
  fill: var(--txt-black-color);
}

.user-info-input svg.copy-icon,
.cs-user-details-status svg.copy-icon{
  width: 10px;
  height: 12px;
  display: none;
  fill: var(--txt-gray-color);
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 4px;
}

.user-info-input:hover svg.copy-icon,
.cs-user-details-status:hover svg.copy-icon{
  display: block ;
}

.user-info-input svg.copy-icon:hover,
.cs-user-details-status svg.copy-icon:hover{
  fill: var(--txt-black-color);
  cursor: pointer;
}

.user-info-input svg.building-icon {
  width: 12px;
  height: 14px;
  margin: 0px 0px -2px 10px;
}

.cs-user-details .user-info-input,
.cs-user-details-status{
  position: relative;
}

.cs-user-details-status svg.copy-icon{
  top: 40px;
}

/*cs-user-details-list css code start here ****************/
.cs-user-details-list {
  border: 1px solid var(--border-color);
  border-width: 1px 0px 0px;
  margin: 20px 0px 0px 0px;
}

.cs-user-details-list>li {
  border: 1px solid var(--border-color);
  border-width: 0px 0px 1px;
}

.cs-user-details-list>li.active:last-child {
  border-width: 0px;
}

.cs-user-details-list li a {
  font-size: 15px;
  padding: 24px 10px;
  text-decoration: none;
  color: var(--txt-black-color);
  display: block;
  position: relative;
}

.cs-user-details-list li a:after {
  content: "";
  padding: 5px 6px;
  background: url(../images/arrow-icon.svg) center center no-repeat;
  position: absolute;
  right: 20px;
  bottom: 26px;
  z-index: 0;
  cursor: pointer;
  background-size: 10px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.cs-user-details-list li.active a:after,.cs-user-details-list li.tags-active a:after {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.cs-user-details-list li.tags-active>ul,
.cs-user-details-list li.active>div.cs-tag-wrp {
  display: flex;
}

.cs-user-details-list li ul {
  display: none;
  position: relative;
  margin: -8px 0px 18px;
}

.cs-user-details-list li.active>ul, .cs-user-details-list li.active>div.cs-tag-wrp {
  display: block;
}

.cs-user-details-list li ul:not(.conversation-tags) li {
  position: relative;
  padding: 6px 0px 6px 10px;
}

.cs-user-details-list li ul li label {
  font-size: 14px;
  color: var(--txt-gray-color);
  margin: 0px 8px 0px 0px;
  line-height: normal;
  user-select: none;
}

.cs-user-details-list li:not(:nth-of-type(2)) ul li span {
  font-size: 14px;
  color: var(--txt-black-color);
  line-height: normal;
  margin: 0px 0px -3px 0px;
}

.cs-user-details-list li ul li.txt-wrp {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 6px 40px 6px 10px;
}

.cs-user-details-list li ul li span.txt-line {
  margin: 4px 0px 0px 0px;
  display: block;
  line-height: 1.57;
}

.cs-user-details-list li:not(:nth-of-type(2)) ul li svg {
  fill: var(--txt-gray-color);
  width: 20px;
  height: 11px;
  margin: 0px 6px -2px 0px;
}

.cs-user-details-list li ul li svg.calander-icon {
  width: 15px;
  height: 14px;
  margin: 0px 4px -1px -3px;
}

.cs-user-details-list li ul li svg.windows-icon {
  width: 15px;
  height: 12px;
  margin: 0px 4px -1px 0px;
}

.cs-user-details-list li ul li svg.ios-icon {
  width: 16px;
  height: 16px;
  margin: 0px 2px -2px 10px;
}

.cs-user-details-list li ul li svg.android-icon {
  width: 20px;
  height: 13px;
  margin: 0px 3px -1px 10px;
}

.cs-user-details-list li ul li svg.copy-icon {
  width: 10px;
  height: 12px;
  display: none;
  fill: var(--txt-gray-color);
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 10px;
}

.cs-user-details-list li ul li:hover svg {
  display: inline-block;
}

.cs-user-details-list li ul li:hover svg.copy-icon {
  display: block !important;
}

.cs-user-details-list li ul li svg.copy-icon:hover {
  fill: var(--txt-black-color);
  cursor: pointer;
}

.cs-user-details-list li ul li span cite {
  padding: 0px 10px;
  margin: 0px 5px 0px 0px;
}

.cs-user-details-list>li ul li:last-child span cite {
  padding: 0px 10px;
}

.cs-user-details-list>li:first-child ul li label {
  display: inline-block;
  width: 98px;
}

.cs-user-details-list>li:first-child ul li span small {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100px;
  display: inline-block;
  direction: rtl;
}

/*cs-incoming-chat css code start here ****************/
.cs-user-details-list li div.cs-tag-wrp {
  margin: -8px 10px 24px;
  display: none;
}

.cs-user-details-list li div.cs-tag-wrp span {
  border-radius: 3px;
  font-size: 10px;
  font-weight: 600;
  background-color: rgba(211, 217, 229, 0.3);
  padding: 6px 24px 6px 7px;
  text-transform: uppercase;
  position: relative;
  color: var(--txt-black-color);
  margin: 0px 3px 5px 0px;
  display: inline-block;
}

.cs-user-details-list li div.cs-tag-wrp span cite {
  font-size: 13px;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 24px;
  height: 22px;
  text-align: center;
  line-height: 22px;
  font-family: "Lato", sans-serif;
  cursor: pointer;
}

.cs-user-details-list li div.cs-tag-wrp font {
  font-size: 10px;
  color: var(--BlueAW);
  font-weight: 600;
  text-transform: uppercase;
  margin: 8px 0px 0px 0px;
  display: inline-block;
  cursor: pointer;
  padding: 6px 7px;
  border: 1px dashed #d3d9e5;
  border-radius: 3px;
  min-width: 86px;
}

.cs-user-details-list li div.cs-tag-wrp font:hover {
  border: 1px dashed var(--txt-gray-color);
}

.cs-user-details-list li div.cs-tag-wrp input[type="text"] {
  font-size: 10px;
  color: #354052;
  font-weight: 600;
  text-transform: uppercase;
  margin: 8px 0px 0px 0px;
  display: inline-block;
  cursor: pointer;
  padding: 5px 7px;
  border: 1px dashed var(--txt-gray-color);
  border-radius: 3px;
  width: 86px;
  font-family: "Lato", sans-serif;
  /*display: none;*/
}

/*cs-user-history css code start here ****************/
.cs-user-history {
  padding: 20px 22px;
  overflow: auto;
  height: calc(100vh - 145px);
}

.cs-user-history>ul.user-history-list li {
  padding: 12px 28px;
  position: relative;
  border: 1px solid var(--border-color);
  border-width: 1px 0px 0px;
  cursor: pointer;
}

.cs-user-history>ul.user-history-list li svg {
  position: absolute;
  width: 12px;
  height: 12px;
  left: 8px;
  top: 15px;
}

.cs-user-history>ul.user-history-list li label {
  font-size: 13px;
  font-weight: bold;
  color: var(--txt-black-color);
  line-height: normal;
}

.cs-user-history>ul.user-history-list li p {
  font-size: 13px;
  color: var(--txt-black-color);
  line-height: normal;
  margin: 3px 0px 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0px 27px 0px 0px;
}

.cs-user-history>ul.user-history-list li span {
  font-size: 13px;
  font-weight: 600;
  color: var(--txt-gray-color);
  position: absolute;
  right: 0px;
  top: 17px;
}

.cs-user-history>ul.user-history-list li:first-child {
  border-width: 0px;
}

.cs-user-history>a {
  line-height: 1.71;
  color: #4684ff;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  margin: 20px 9px 0px;
  display: inline-block;
}

/*cs-user-detail-history css code start here ****************/
.cs-user-detail-history {
  position: absolute;
  width: 322px;
  height: calc(100vh - 89px);
  border: 1px solid var(--border-color);
  background-color: var(--white-color);
  border-width: 0px 0px 0px 1px;
  right: 0px;
  top: 0px;
}

.cs-user-detail-history header {
  padding: 23px 20px 7px;
  border: 1px solid var(--border-color);
  border-width: 0px 0px 1px;
  position: relative;
}

.cs-user-detail-history header label {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.36;
  color: var(--txt-black-color);
}

.cs-user-detail-history header span {
  position: absolute;
  right: 20px;
  font-size: 13px;
  font-weight: 600;
  color: var(--txt-gray-color);
  top: 27px;
  padding: 0px 35px 0px 0px;
}

.cs-user-detail-history header span cite.history-close-icon {
  padding: 10px;
  position: absolute;
  border-radius: 100%;
  font-size: 15px;
  background-image: url("../images/remove-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-color: #f0f2f5;
  cursor: pointer;
  right: 0px;
  bottom: -2px;
}

/*history detail conversation list **********/
.cs-user-detail-history div.cs-user-conversation {
  right: 0px;
  bottom: 0px;
  max-height: calc(100vh - 140px);
}

.cs-user-detail-history div.cs-user-conversation div.conversation-msg {
  padding: 16px 20px 16px 62px;
}

.cs-user-detail-history div.cs-user-conversation div.conversation-msg figure {
  left: 20px;
}

.cs-user-detail-history div.cs-user-conversation div.conversation-msg p {
  padding: 0px !important;
  margin: 0px;
}

/*history detail conversation files list **********/
.cs-user-detail-history div.file-chat>.message-wrapper>div {
  padding: 15px 15px 30px;
  max-width: 240px;
  min-width: 240px;
  min-height: 110px;
}

.cs-user-detail-history div.file-chat>.message-wrapper>div section {
  padding: 0px 0px 20px 34px;
}

.cs-user-detail-history div.file-chat>.message-wrapper>div section strong {
  line-height: normal;
  font-size: 14px;
  font-weight: 500;
  padding: 0px 0px 3px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cs-user-detail-history div.file-chat>.message-wrapper>div section.screen-share-link strong,
.cs-user-detail-history div.file-chat>.message-wrapper>div section.video-share-link strong {
  padding: 0px !important;
  font-weight: 600;
}

.cs-user-detail-history div.file-chat>.message-wrapper>div section:before {
  width: 24px;
  height: 28px;
  background-size: 24px !important;
  top: 5px;
  left: 0px;
}

.cs-user-detail-history div.file-chat>.message-wrapper>div>div {
  left: 15px;
  bottom: 15px;
  right: 15px;
  padding: 10px 0px 0px;
}

.cs-user-detail-history div.file-chat>.message-wrapper>div>section.link-file-icon {
  padding: 0px;
}

.cs-user-detail-history div.file-chat>.message-wrapper>div>section p {
  display: none;
}

.cs-user-detail-history div.file-chat>.message-wrapper>div section.screen-share-link p,
.cs-user-detail-history div.file-chat>.message-wrapper>div section.video-share-link p,
.cs-user-detail-history div.file-chat>.message-wrapper>div>section.link-file-icon p {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cs-user-detail-history div.notes-msg,
.cs-user-detail-history div.asign-to-msg {
  margin: 0px 0px 26px;
}

.cs-user-detail-history div.notes-msg:before {
  left: 20px;
}

.cs-user-detail-history div.notes-msg figure {
  left: 46px !important;
}

.cs-user-detail-history div.notes-msg label {
  margin: 8px 0px 0px 28px;
}

.cs-user-detail-history div.notes-msg p {
  margin: 14px 0px 0px 0px;
}

.cs-user-detail-history div.asign-to-msg label {
  margin: 6px 48px 6px 24px;
  word-break: break-word;
}

.cs-user-detail-history div.asign-to-msg figure img:last-child {
  left: -22px;
}

.cs-user-detail-history div.asign-to-msg figure {
  left: 42px !important;
}

.cs-user-detail-history div.cs-user-conversation div.conversation-msg small {
  top: 20px;
  right: 20px;
}

.cs-user-detail-history div.notes-msg small,
.cs-user-detail-history div.asign-to-msg small {
  top: 28px !important;
  right: 24px !important;
}

/*cs-incoming-chat css code start here ****************/
.cs-incoming-chat {
  position: absolute;
  top: 50%;
  left: 50%;
  /*background-color: var(--white-color);*/
  z-index: 130;
  padding: 45px;
  border-radius: 21px;
  text-align: center;
  width: 330px;
  margin: -235px 0px 0px -165px;
  height: 470px;
  background-image: linear-gradient(to top, #101229, rgba(0, 0, 0, 0)),
    linear-gradient(to bottom, #1f252d, #1f252d);
}

/*.cs-incoming-chat h3{
  font-size: 18px;
  color: var(--txt-black-color);
  font-weight: 900;
  line-height: 1.33;
}*/

.cs-incoming-chat p {
  font-size: 16px;
  color: var(--white-color);
  padding: 9px 0px 0px;
  display: block;
  line-height: 1;
  font-weight: 500;
}

.cs-incoming-chat h4 {
  font-size: 22px;
  color: var(--white-color);
  font-weight: 500;
  line-height: normal;
}

.cs-incoming-chat div>button {
  width: 57px;
  height: 57px;
  border: 0px;
  background-color: transparent;
  border-radius: 100%;
  cursor: pointer;
  margin: 86px 0px 0px 0px;
  position: relative;
  font-family: "Lato", sans-serif;
}

.cs-incoming-chat div>button.recption-btn {
  margin-right: 70px;
  background: url("../images/reception-icon.svg") 17px center no-repeat;
  background-color: rgba(255, 255, 255, 0.11);
}

.cs-incoming-chat div>button.accept-btn {
  color: var(--white-color);
  background: url("../images/chat-white-icon.svg") center center no-repeat;
  background-color: #00df51;
}

.cs-incoming-chat div>button.recption-btn:before {
  content: "Reception";
  position: absolute;
  color: var(--white-color);
  font-size: 15px;
  opacity: 0.8;
  left: 50%;
  bottom: -25px;
  margin: 0px 0px 0px -33px;
  font-family: "Lato", sans-serif;
}

.cs-incoming-chat div>button.accept-btn:before {
  content: "Accept";
  position: absolute;
  color: var(--white-color);
  font-size: 15px;
  opacity: 0.8;
  left: 50%;
  bottom: -25px;
  margin: 0px 0px 0px -22px;
  font-family: "Lato", sans-serif;
}

/*cs-incoming-chat Ripple Effect css code start here ****************/
.incoming-user {
  padding: 45px;
  border-radius: 100%;
  display: inline-block;
  margin: 27px 0px 22px 0px;
  background-image: url("../images/incoming-user-icon.svg");
  background-position: center 17px;
  background-repeat: no-repeat;
  background-color: var(--user-bgcolor);
  background-size: 50px;
  position: relative;
}

.incoming-user img {
  position: absolute;
  width: 90px;
  height: 90px;
  border-radius: 100%;
  left: 0px;
  top: 0px;
  object-fit: cover;
}

/*.incoming-user .circle {
  position: absolute;
  height: 65px;
  width: 65px;
  top: -2px;
  left: -2px;
  border: 2px solid #cad7e3; 
  border-radius: 50%;
  animation: wave 1.5s infinite linear;
  -webkit-animation: wave 1.5s infinite linear;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
}
.incoming-user .circle:nth-child(2) {
  animation-delay: .5s;
  -webkit-animation-delay: .5s;
}

.incoming-user .circle:nth-child(3) {
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
}


@keyframes wave{
  0% {
    transform: scale(0.8);
    opacity: 0.0;
  }
  25%{
    opacity:0.0;
  }
  50%{
    opacity:1.0;
  }
  75%{
    opacity:1.0;
  }
  100%{
    transform:scale(1.4);
    opacity: 0.0;
  }
}*/
/*cs-no-visitor css code start here ****************/
.cs-no-visitor {
  position: absolute;
  width: 270px;
  text-align: center;
  left: 50%;
  top: 50%;
  margin: -32px 0px 0px -135px;
}

.cs-no-visitor svg {
  width: 53px;
  height: 63px;
  display: block;
  margin: 0px auto;
}

.cs-no-visitor strong {
  font-size: 15px;
  font-weight: bold;
  color: var(--txt-black-color);
  margin: 28px 0px 10px;
  display: block;
  line-height: normal;
}

.cs-no-visitor p {
  font-size: 13px;
  line-height: 1.38;
  color: var(--txt-gray-color);
}

/*cs-proactive-list css code start here ****************/
.cs-proactive:after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 50px;
  background-image: linear-gradient(to top,
      var(--white-color),
      rgba(255, 255, 255, 0));
  z-index: 1;
  pointer-events: none;
}

.cs-proactive:before {
  content: "";
  position: absolute;
  top: 142px;
  left: 0px;
  right: 0px;
  height: 22px;
  background-image: linear-gradient(to bottom,
      var(--white-color),
      rgba(255, 255, 255, 0));
  z-index: 1;
  pointer-events: none;
}

.cs-proactive>header {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
  margin: 24px 24px 0px;
  height: 46px;
  padding: 0px;
  text-align: left;
  display: none;
}

.cs-proactive>header label {
  font-size: 13px;
  font-weight: 600;
  color: var(--txt-gray-color);
  text-transform: uppercase;
  float: left;
  margin: 15px 0px 15px 32px;
}

.cs-proactive-list {
  margin: 0 24px;
  height: calc(100vh - 142px);
  overflow: auto;
}

.cs-proactive-list li {
  font-size: 15px;
  color: var(--txt-black-color);
  width: 100%;
  padding: 15px 0px;
  height: 102px;
  position: relative;
}


.cs-proactive-list li span {
  width: 15%;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  line-height: normal;
  padding: 0px 0px 9px 0px;
}

.cs-proactive-list li span:nth-child(1) {
  width: 70%;
  text-align: left;
  padding: 0px 0px 0px 32px;
}

.cs-proactive-list-head label:nth-child(1) {
  width: 70%;
  text-align: left;
  padding: 10px 0px 10px 32px;
}

.cs-proactive-list li span:last-child {
  color: var(--BlueAW);
  cursor: pointer;
  user-select: none;
}

.cs-proactive-list li span:last-child:hover {
  text-decoration: underline;
}

.cs-proactive-list li span cite {
  border-radius: 100%;
  margin: 7px 0px 0px -20px;
  float: left;
  padding: 3px;
  position: absolute;
}

.cs-proactive-list li span a {
  text-decoration: none;
  color: var(--txt-gray-color);
  display: block;
  margin: 5px 0px 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cs-proactive-list li span.queue-info{ padding: 0px 0px 0px 80px; }

.cs-proactive-list li span.queue-info figure{
  width: 34px;
  height: 34px;
  border-radius: 100%;
  background-color: red;
  position: absolute;
  left: 32px;
  top: 15px;
}

.cs-proactive-list li span.queue-info figure img{
  width: 34px;
  height: 34px;
  border-radius: 100%;
  object-fit: cover;
}

.cs-proactive-list li span.queue-info p.queue-msg{
  font-size: 13px;
  line-height: normal;
  margin: 6px 0px 0px;
}

.cs-proactive-list li span.queue-info code{
  padding: 3px;
  border-radius: 100%;
  z-index: 1;
  position: absolute;
  top: 40px;
  left: 58px;
  border: 1px solid var(--white-color);
}

.cs-proactive-list li span.queue-info code.available{
  background-color: var(--available-color);
}

.cs-proactive-list-head {
  margin: 20px 24px 12px;
  height: 33px;
  position: relative;
}

.cs-proactive-list-head:after {
  content: "";
  position: absolute;
  height: 1px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background-color: var(--border-color);
}

.cs-proactive-list-head label {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  color: var(--txt-gray-color);
  width: 15%;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 10px 0px;
  text-align: center;
}

/*cs-dropdown css code start here ****************/
.cs-dropdown {
  margin: 15px;
  float: left;
}

.cs-dropdown label {
  font-size: 14px;
  color: var(--txt-gray-color);
  display: inline-block;
  margin: 0px 10px 0px 0px;
}

.cs-dropdown p {
  font-size: 14px;
  color: var(--txt-black-color);
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
}

.cs-dropdown p:after {
  content: "";
  position: absolute;
  padding: 5px 6px;
  background: url("../images/arrow-icon.svg") center center no-repeat;
  background-size: 10px;
  margin: 3px 0px 0px 7px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.cs-dropdown>span {
  font-size: 12px;
  line-height: 1.33;
  font-weight: 600;
  padding: 6px 0px 0px 20px;
  position: absolute;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: none;
}

.cs-dropdown.cs-error-form>span {
  display: block;
}

.cs-dropdown.cs-error-form>span {
  color: #df0d29;
}

.cs-dropdown.cs-error-form>span svg {
  fill: #df0d29;
}

.cs-dropdown.cs-error-form>p {
  border-color: #df0d29;
}

/* .cs-dropdwn-select{
    position: absolute;
    background-color: var(--white-color);
    top: 4px;
}

.cs-dropdwn-select span{
    padding: 5px 12px 5px 24px;
    border-radius: 20px;
    float: left;
    font-size: 14px;
    color: var(--txt-black-color);
    margin: 0px 5px 0px 0px;
    position: relative;
    background-color: var(--white-color);
}

.cs-dropdwn-select span:before{
    content: "";
    background-color: #2dccd2;
    opacity:0.1;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    border-radius: 20px;
}

.cs-dropdwn-select span:last-child{
    padding: 5px 10px;
    background-color: rgba(129, 129, 129, 0.1);   
}

.cs-dropdwn-select span cite{
    font-size: 49px;
    line-height: 0px;
    position: absolute;
    left: 8px;
    top: -4px;
    color: #2dccd2;
}

.cs-dropdwn-select span p{
    font-size: 14px;
    color: var(--txt-black-color);
    position: relative;
} */

/*cs-checkbox-field code start here ***************************/
.cs-checkbox-field {
  padding: 10px 0px;
}

.cs-checkbox-field label {
  padding: 0 0 0 30px;
  cursor: pointer;
  line-height: 1.43;
  font-size: 14px;
  color: #181818;
  position: relative;
}

.cs-checkbox-field label svg {
  position: absolute;
  width: 8px;
  height: 8px;
  left: 5px;
  top: 50%;
  z-index: 1;
}

.cs-checkbox-field label input {
  position: absolute;
  opacity: 0;
  left: 0px;
}

.cs-checkbox-field label cite {
  position: absolute;
  top: -1px;
  left: 0px;
  height: 16px;
  width: 16px;
  border: 1px solid #acb7cc;
  border-radius: 50%;
  transition: background-color 0.3s ease-out;
  -webkit-transition: background-color 0.3s ease-out;
  /* For Webkit browser(Chrome, Safari...)*/
  -moz-transition: background-color 0.3s ease-out;
  /* For Mozilla browser */
  -ms-transition: background-color 0.3s ease-out;
  /* For Opera browser */
  -o-transition: background-color 0.3s ease-out;
  /* For Microsoft browser */
}

.cs-checkbox-field label:hover cite {
  border-color: var(--PaleBlueAW);
}

.cs-checkbox-field input:checked~cite {
  background-color: var(--BlueAW);
  border-color: var(--BlueAW);
}

/*.cs-checkbox-field input:checked~cite:before{
    background-color: #6699ff;
}*/

.cs-checkbox-field input:disabled~cite:before {
  content: "";
  height: 1px;
  width: 6px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 0px 0px 0px -3px;
  z-index: 1;
  background-color: #acb7cc;
}

.cs-checkbox-field label:hover input:disabled~cite {
  border-color: #acb7cc;
}

/*typing-msg css code start here ****************/
.typing-msg {
  padding: 0px 24px 20px 58px !important;
}

.typing-msg figure,
.typing-msg figure img {
  width: 26px !important;
  height: 26px !important;
}

.typing-msg label {
  color: var(--txt-gray-color) !important;
  font-size: 14px !important;
  padding: 5px 0px 0px 0px;
  font-weight: normal !important;
}

/*tag-line-msg css code start here ****************/
.tag-line-msg {
  padding: 0px 24px 26px !important;
  text-align: center !important;
}

.tag-line-msg p {
  text-align: center;
  font-size: 14px !important;
  color: #acb7cc !important;
  padding: 0px !important;
  margin: 0px !important;
  display: inline-block !important;
  position:relative !important;
}

.tag-line-msg p code {
  position: absolute;
  height: 1px;
  width: 60px;
  background-color: #d3d9e5;
  margin: -9px 0px 0px -30px;
  left: 50%;
}

/* .g-data-tips:before,
.g-data-tips:after{
  position: absolute;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s ease-out;
  font-weight: 400;
  cursor: default;
}

.g-data-tips::before {
  content: "";
  width: 0;
  height: 0;
  border-width: 6px;
  border-style: solid;
  border-color: #000000 transparent transparent transparent;
}

.g-data-tips::after {
  content: attr(data-tips);
  padding: 6px 14px;
  font-size: 13px;
  line-height: 17px;
  color: #fff;
  background-color: #000000;
  border-radius: 3px;
  box-shadow: 0 -11px 34px 0 rgba(22,45,61,.09);
  white-space: nowrap;
}

.g-data-tips.right-tip::before {
  border-color: transparent #000000 transparent transparent;
}

.g-data-tips:hover::after, 
.g-data-tips:hover::before {
  opacity: 1;
  visibility: visible;
  bottom: calc(100% - 6px);
  left: 50%;
  transform: translateX(-50%);
}

.g-data-tips.right-tip:hover::after, 
.g-data-tips.right-tip:hover::before {
  left: 100%;
  top: 50%;
  bottom: unset;
  transform: translateY(-50%);
}

.g-data-tips.right-tip:hover::after {
  left: calc(100% + 12px);
}

.__react_component_tooltip.tooltip-tag {
  margin: 27px 0px 0px 148px;
  left: 50% !important;
  padding: 6px 12px !important;
}

.__react_component_tooltip.tooltip-tag:before,
.__react_component_tooltip.tooltip-tag:after{
  content: "";
  width: 0;
  height: 0;
  position: absolute;
}
.__react_component_tooltip.tooltip-tag:before{
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  left: -8px;
  top: 50%;
  margin-top: -5px;
  bottom: inherit;
}

.__react_component_tooltip.tooltip-tag:after{
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  left: -6px;
  top: 50%;
  margin-top: -6px;
}

.__react_component_tooltip.type-dark.tooltip-tag:after {
  border-right-color: #000000 !important;
  border-top-color: inherit;
}

.__react_component_tooltip.type-dark.tooltip-tag:after {
  border-right-color: #222;
  border-right-style: solid;
  border-right-width: 6px;
  border-top-color: inherit !important;
  bottom: inherit;
} */

/* .tag-line-msg p span.tooltip-time {
    position: absolute;
    padding: 5px 15px;
    max-width: 100%;
    min-width: 160px;
    background-color: #000;
    border-radius: 3px;
    color: #ffffff;
    opacity: 0;
    z-index: 100;
    visibility: hidden;
    transition: .3s ease-out;
    text-align: center;
    font-size: 12px;
    white-space: nowrap;
    margin: -3px 0px 0px 10px;
}

.tag-line-msg p span.tooltip-time:after{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-right: 6px solid #000;
    border-bottom: 6px solid transparent;
    left: -6px;
    top: 50%;
    transform: translateY(-50%);
}

.tag-line-msg p:hover span.tooltip-time{
  opacity: 1;
  visibility: visible;
} */

.tag-tooltip {
  margin-left: 20px !important;
  margin-top: -2px;
}

.cs-notification {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 88px;
  background-color: var(--BlueAW);
  z-index: 2;
  font-size: 14px;
  padding: 16px 24px;
  color: var(--white-color);
  display: none;
}

.cs-notification svg {
  width: 14px;
  height: 11px;
  fill: var(--white-color);
  margin: 0px 14px 0px 0px;
}

@-webkit-keyframes blinker {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 0;
  }
}

.blink {
  text-decoration: blink;
  -webkit-animation-name: blinker;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
}

.cs-user-conversation .conversation-msg>span.blink svg {
  position: absolute;
  width: 9px;
  height: 7px;
  right: 24px;
  top: 30px;
  fill: var(--txt-gray-color);
}

/*setting page code start from here*****************/
/*cs-navigation css code start here ****************/
.cs-navigation {
  width: 320px;
  position: fixed;
  bottom: 0px;
  top: 0px;
  left: 64px;
  border: 1px solid var(--border-color);
  border-width: 0px 1px 0px 0px;
  z-index: 0;
  background-color: var(--white-color);
  height: 100vh;
}

.cs-navigation.slide-nav,.cs-conversation-wrp.integration > section:first-child {
  position: fixed;
  height: auto;
  left: 64px;
}

.cs-navigation>h2 {
  font-size: 26px;
  font-weight: 800;
  color: var(--black-color);
  padding: 20px 20px 0px;
}

.cs-navigation label {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: var(--txt-black-color);
  margin: 22px 25px -8px 20px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cs-navigation label cite {
  display: inline-block;
  width: 20px;
  height: 20px;
  float: left;
  margin: 2px 15px 0px 0px;
  cursor: pointer;
  position: relative;
  /*background: url("../images/arrow-icon.svg") center 0px no-repeat;
    background-size: 15px;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);*/
}

.cs-navigation label cite svg {
  position: absolute;
  width: 9px;
  height: 16px;
  left: 6px;
  top: 2px;
}

.cs-navigation label p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cs-navigation-list {
  margin: 30px 0px 0px;
  overflow: auto;
  /* height: calc(100vh - 135px); */
  height: calc(100vh - 170px);
}

.cs-navigation-list>li,
.cs-nav-list>li {
  position: relative;
  padding: 19px 20px 19px 72px;
  cursor: pointer;
  font-size: 14px;
  color: var(--txt-black-color);
  min-height: 58px;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
}

.cs-navigation-list>li a,
.cs-nav-list>li a {
  text-decoration: none;
  color: var(--txt-black-color);
  height: 20px;
  line-height: normal;
  display: block;
}

.cs-navigation-list>li a em,
.cs-nav-list>li a em {
  width: 130px;
}

.cs-navigation-list li:hover,
.cs-navigation-list li.active,
.cs-nav-list li:hover,
.cs-nav-list li.active {
  background-color: var(--chat-listhover-bgcolor);
}

.cs-navigation-list li.active:after,
.cs-nav-list li.active:after {
  content: "";
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 2px;
  background-color: var(--PaleBlueAW);
}

.cs-navigation-list>li figure,
.cs-nav-list>li figure {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  line-height: 44px;
  position: absolute;
  left: 20px;
  top: 50%;
  margin: -18px 0px 0px 0px;
}

.cs-navigation-list>li figure em,
.cs-nav-list>li figure em {
  width: 36px;
  height: 36px;
  border-radius: 100%;
}

.cs-navigation-list.cs-my-team-list {
  margin: 0px;
  height: calc(100vh - 206px);
}

.cs-navigation-list.cs-my-team-list>li figure {
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
  color: var(--white-color);
  background-color: var(--white-color);
  text-align: center;
}

.cs-navigation-list.cs-my-team-list>li figure.invite-icon {
  background-color: transparent;
  background-image: url("../images/invite-people-icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 42px;
}

.cs-navigation-list.cs-my-team-list>li.active figure.invite-icon,
.cs-navigation-list.cs-my-team-list>li:hover figure.invite-icon {
  background-image: url("../images/invite-people-active-icon.png");
}

.cs-navigation-list.cs-my-team-list>li.invite-people:before {
  content: "Invite pending";
  position: absolute;
  top: 50%;
  right: 17px;
  font-size: 9px;
  text-transform: uppercase;
  color: #939eae;
  border: 1px solid #dbe0e7;
  border-radius: 3px;
  padding: 5px;
  margin: -10px 0px 0px;
  background-color: var(--white-color);
  font-weight: bold;
}

.cs-navigation-list>li figure img,
.cs-nav-list>li figure img {
  width: 36px;
  height: 36px;
  position: absolute;
  left: 0px;
  top: 0px;
  border-radius: 100%;
  object-fit: cover;
}

.cs-navigation-list>li svg,
.cs-nav-list>li svg {
  width: 36px;
  height: 36px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.cs-navigation-list>li figure svg:nth-child(2),
.cs-nav-list>li figure svg:nth-child(2) {
  opacity: 0;
  transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
}

.cs-navigation-list>li.active figure svg:nth-child(2),
.cs-navigation-list>li:hover figure svg:nth-child(2),
.cs-nav-list>li.active figure svg:nth-child(2),
.cs-nav-list>li:hover figure svg:nth-child(2) {
  opacity: 1;
}

.cs-navigation-list>li ul,
.cs-nav-list>li ul {
  display: none;
  margin: 15px -20px -15px -68px;
}

.cs-navigation-list>li.open ul,
.cs-nav-list>li.open ul {
  display: block;
}

.cs-navigation-list>li.open>figure {
  top: 25px;
  background-color: #f0f2f5 !important;
}

.cs-navigation-list>li.open,
.cs-navigation-list>li.open.active {
  background-color: var(--white-color) !important;
}

.cs-navigation-list>li ul li {
  display: block;
  padding: 15px 20px 15px 68px;
  background-color: var(--white-color);
  text-decoration: none;
  color: var(--txt-black-color);
  cursor: pointer;
  position: relative;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
}

.cs-navigation ul.tab-nav {
  margin: 13px 0px;
  padding: 0px 20px;
}

.cs-navigation ul.tab-nav>li>a {
  padding: 5px;
}

.cs-navigation ul.tab-nav>li {
  margin: 0px 10px 0px 0px;
}

.my-team-more-btn {
  position: absolute;
  right: 40px;
  top: 0px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  cursor: pointer;
  text-align: center;
  /* z-index: 1; */
}

.my-team-more-btn a>svg {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  width: 16px;
  height: 16px;
  fill: var(--txt-black-color);
  cursor: pointer;
  margin: 8px 0px 0px;
  pointer-events: none;
}

.my-team-more-btn ul.edit-delete-container {
  margin: 0px 0px 0px -100px;
  display: none;
}

.my-team-more-btn ul li {
  text-align: left;
  transition: all 0.3s;
}

.my-team-more-btn ul.edit-delete-container:before,
.my-team-more-btn ul.edit-delete-container:after {
  margin: 0px 0px 0px 30px;
}
.my-team-more-btn.open ul.edit-delete-container { display: block; }
.my-team-more-btn.whiteblackList-more-btn{ 
  right: 20px; 
  top:13px;
  box-shadow:none;
  display:none;
}

.cs-popup-model.popup-new .whiteblackList > ul > li:hover .my-team-more-btn.whiteblackList-more-btn{ display:block; }

.my-team-more-btn.whiteblackList-more-btn ul.edit-delete-container{ margin: -4px 0px 0px -100px; }

/*cs-profile-wrp css code start here ****************/
.cs-settings-wrp:not(.slide-col3) {
  overflow: auto;
  position: absolute;
  left: 320px;
  /* border: 1px solid var(--border-color);
  border-width: 0px 0px 0px 1px; */
  /* right: 0px; */
  width: calc(100% - 320px); 
}

.cs-settings-wrp:not(.campaign-wrp):not(.slide-col3) {
  height: 100vh;
}

.cs-profile-map {
  height: 124px;
  background-color: #ffffff;
  border: 1px solid var(--border-color);
  border-width: 0px 0px 1px 0px;
  position: relative;
}

.cs-profile-map img {
  height: 100%;
  float: right;
  object-fit: cover;
}

.cs-profile-map span {
  font-size: 12px;
  font-weight: 500;
  text-align: right;
  line-height: 18px;
  position: absolute;
  right: 20px;
  color: var(--txt-black-color);
  padding-right: 35px;
  top: 50%;
  margin: -18px 0px 0px 0px;
}

.cs-profile-map span p {
  margin: 0px -25px 0px 0px;
}

.cs-profile-map span.not-found {
  color: #000000;
  opacity: 0.5;
}

.cs-profile {
  padding: 0px 0px 0px 245px;
  margin: 0px;
  position: relative;
}

.cs-profile>figure {
  width: 160px;
  height: 160px;
  border-radius: 100%;
  position: absolute;
  z-index: 1;
  left: 55px;
  top: -86px;
  background-color: #b1b5bc;
  background-image: url("../images/user-white-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60px;
  border: 6px solid var(--white-color);
}

.cs-profile>figure cite {
  position: absolute;
  width: 148px;
  height: 148px;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 100%;
  text-align: center;
  font-size: 50px;
  z-index: 1;
  line-height: 148px;
  font-family: "Lato", sans-serif;
  color: var(--white-color);
}

.cs-profile>figure>em {
  height: 100%;
  border-radius: 100%;
}

.cs-profile>figure>svg {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 56px;
  fill: var(--white-color);
  height: 63px;
  margin: -32px 0px 0px -27px;
}

.cs-profile>figure img {
  width: 148px;
  height: 148px;
  object-fit: cover;
  border-radius: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: #ffffff;
}

.cs-profile>figure input[type="file"] {
  position: absolute;
  border-radius: 100%;
  opacity: 0;
  -webkit-appearance: none;
  cursor: pointer;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
}

.cs-profile h2 {
  font-size: 24px;
  font-weight: 500;
  color: var(--txt-black-color);
  margin: 22px 0px 8px 0px;
  min-height: 24px;
  line-height: normal;
}

.cs-profile p {
  font-size: 16px;
  color: var(--txt-gray-color);
  line-height: normal;
  min-height: 19px;
}

.cs-profile>a {
  font-size: 14px;
  line-height: normal;
  background-color: var(--white-color);
  color: var(--txt-black-color);
  border-radius: 30px;
  padding: 6px 20px;
  cursor: pointer;
  position: absolute;
  right: 60px;
  top: 4px;
  border: 1px solid var(--border-color);
  text-decoration: none;
}

.cs-profile figure ul {
  box-shadow: 0 3px 4px 0 rgba(10, 31, 68, 0.15),
    0 0 4px 0 rgba(10, 31, 68, 0.08);
  border: 1px solid #f0f2f5;
  padding: 10px 15px;
  border-radius: 3px;
  width: 132px;
  position: relative;
  left: 50%;
  margin: 0px 0px 0px -66px;
  bottom: -170px;
  display: none;
}

.cs-profile ul:before,
.cs-profile ul:after {
  content: "";
  width: 0;
  height: 0;
  border: 8px solid;
  position: absolute;
  margin-left: -8px;
  z-index: 1;
  left: 50%;
}

.cs-profile ul:before {
  border-color: transparent transparent #ebeced;
  top: -16px;
}

.cs-profile ul:after {
  border-color: transparent transparent #ffffff;
  top: -15px;
}

.cs-profile figure ul li svg {
  width: 13px;
  height: 13px;
  float: left;
  margin: 5px 16px 0px 0px;
}

.cs-profile figure ul li.delete-user-pic {
  color: #df0d29;
}

.cs-profile figure ul li {
  padding: 4px 6px;
  font-size: 14px;
  font-weight: 500;
  color: var(--txt-black-color);
  line-height: 1.71;
  position: relative;
  cursor: pointer;
}

.cs-profile.cs-profile-edit.open figure ul {
  display: block;
}

.cs-profile.cs-profile-edit>figure:hover:before,
.cs-profile-edit.open figure:before {
  content: "";
  position: absolute;
  width: 156px;
  height: 156px;
  border-radius: 100%;
  border: 2px dashed #acb7cc;
  left: -6px;
  background-color: rgba(255, 255, 255, 0.7);
  top: -6px;
  cursor: pointer;
  z-index: 1;
}

.cs-profile.cs-profile-edit>figure:hover:after,
.cs-profile-edit.open figure:after {
  content: "";
  position: absolute;
  /* background: url("../images/img-edit-icon.svg") no-repeat; */
  background: url("../images/img-edit-icon.svg") no-repeat;
  width: 30px;
  height: 24px;
  left: 50%;
  top: 50%;
  margin: -12px 0px 0px -15px;
  pointer-events: none;
  z-index: 1;
}

/*tab-nav css code start here ****************/
.tab-nav {
  margin: 40px 30px;
  border: 1px solid var(--border-color);
  border-width: 0px 0px 1px 0px;
  position: relative;
  padding: 0px 0px 0px 15px;
}

.tab-nav>li {
  display: inline-block;
}

.tab-nav>li>a {
  text-decoration: none;
  color: var(--txt-gray-color);
  line-height: 1.36;
  font-weight: 600;
  font-size: 14px;
  padding: 5px 10px;
  display: block;
  position: relative;
  margin: 0px 10px 0px 5px;
}

.tab-nav>li.active>a:before {
  content: "";
  position: absolute;
  right: 0px;
  bottom: -1px;
  left: 0px;
  height: 1px;
  background-color: var(--PaleBlueAW);
}

.tab-nav>li.active>a {
  color: var(--txt-black-color);
}

.tab-nav>li>div {
  position: absolute;
  left: 30px;
  display: none;
}

.tab-nav>li.active>div {
  display: block;
}

.tab-nav>li.active>div>label {
  padding-top: 24px;
}

.tab-nav>li>div label,
.tab-nav>li>div > div >label {
  font-size: 14px;
  color: var(--txt-gray-color);
  line-height: normal;
  padding: 7px 0px 2px;
  display: block;
}

.tab-nav>li>div aside.cs-project-wrp {
  position: relative !important;
  top: 8px !important;
  padding: 0px 0px 0px;
}

/* .tab-nav>li>div aside.cs-project-wrp .cs-porject-clip {
  margin: 0px 5px 15px 0px;
} */

.tab-nav>li>div aside.cs-project-wrp .cs-porject-clip span {
  max-width: 300px !important;
}

.tab-nav>li>div > p,
.tab-nav>li>div >div > p {
  font-size: 15px;
  color: var(--txt-black-color);
  line-height: normal;
  padding: 8px 0px 0px;
  display: block;
}

.tab-nav>li>div p.private:after,
.tab-nav>li>div p.email:after {
  content: "Private";
  font-size: 9px;
  color: var(--txt-gray-color);
  border: 1px solid var(--border-color);
  border-radius: 3px;
  padding: 4px 6px;
  text-transform: uppercase;
  margin: -2px 0px 0px 14px;
  position: absolute;
  font-weight: bold;
  display: none;
}

.tab-nav>li>div p.email:after {
  content: "Email";
}

.tab-nav>li>div p.first:after{
  content: "First";
  text-transform: uppercase;
  position: absolute;
  font-family: "Lato", sans-serif;
  font-size: 9px;
  padding: 4px 6px;
  border: 1px solid #dbe0e7;
  border-radius: 3px;
  color: #9da8b8;
  font-weight: bold;
  margin: 0px 0px 0px 14px;
  line-height:9px;
}

.tab-nav>li>div>div:not(.work-hours-wrapper) {
  margin: 25px 0px 0px;
  left: 0px;
  position: relative;
}

.tab-nav>li>div>div a {
  display: block;
  text-decoration: none;
  color: var(--txt-black-color);
  font-size: 15px;
  line-height: normal;
  margin: 0px 0px 12px;
}

.tab-nav>li>div>div a span {
  display: inline-block;
  min-width: 90px;
}

.tab-nav>li>div>div a.bold {
  font-weight: bold;
}

.tab-nav>li>div>a {
  line-height: 1.36;
  font-size: 14px;
  color: #226cff;
  margin: 8px 0px 0px 0px;
  text-decoration: none;
  display: inline-block;
}

.tab-nav>li>div>div i.up-arrow {
  padding: 5px 6px;
  background: url("../images/arrow-icon.svg") center center no-repeat;
  position: absolute;
  right: -30px;
  top: 5px;
  z-index: 1;
  cursor: pointer;
}

.dashboard-list-wrp .tab-nav > li > div{
  right:310px;
}

/*edit-profile css code start here ****************/
.edit-profile {
  margin: 0px 0px 30px 245px;
}

.edit-profile h3 {
  font-size: 20px;
  color: var(--txt-black-color);
  line-height: normal;
  margin: 60px 0px 8px;
}

.edit-profile>p {
  font-size: 16px;
  line-height: 1.25;
  color: var(--txt-gray-color);
  margin: 0px 0px 40px;
}

/*.edit-profile > a cite{
  font-size: 15px;
    border: 1px solid var(--txt-black-color);
    border-radius: 100%;
    width: 16px;
    height: 16px;
    line-height: 13px;
    display: inline-block;
    text-align: center;
    margin: 0px 5px 0px 0px;
    color: var(--txt-black-color);
    display:none;
}*/
@media screen and (max-width: 1280px) {
  .edit-profile .input-form-button {
    width: inherit !important;
  }
}

.edit-profile .input-form-button {
  margin: 46px 50px 80px 0px !important;
  width: 725px;
}

.edit-profile .input-form-button>a.open div {
  display: block;
}

.edit-profile .input-form-button>a div {
  width: 135px;
  height: 100px;
  position: absolute;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--white-color);
  bottom: 40px;
  left: 50%;
  margin: 0px 0px 0px -65px;
  display: none;
}

.edit-profile .input-form-button>a div:before,
.edit-profile .input-form-button>a div:after {
  content: "";
  width: 0;
  height: 0;
  border: 8px solid;
  position: absolute;
  z-index: 1;
  left: 50%;
  margin: 0px 0px 0px -8px;
}

.edit-profile .input-form-button>a div:before {
  border-color: #ebeced transparent transparent;
  bottom: -16px;
}

.edit-profile .input-form-button>a div:after {
  border-color: #ffffff transparent transparent;
  bottom: -15px;
}

.edit-profile .input-form-button>a div ul {
  padding: 10px 0px;
}

.edit-profile .input-form-button>a div li {
  padding: 12px 24px 12px 60px;
  text-align: left;
  font-size: 16px;
  font-weight: normal;
  color: var(--txt-black-color);
}

.edit-profile .input-form-button>a div li svg {
  position: absolute;
  left: 24px;
}

.edit-profile .input-form-button>a div li:hover {
  background-color: var(--PaleBlueAW);
  color: var(--white-color);
}

.edit-profile .input-form-button>a div li:hover svg {
  fill: var(--white-color);
}

.edit-workhours>div.cs-dropdwn {
  width: 330px;
}

.edit-workhours>div.cs-dropdwn>label {
  font-size: 16px;
  color: var(--txt-black-color);
  line-height: 1.5;
}

.edit-workhours>div.cs-working-hours-form {
  margin: -10px 0px 0px;
  position: relative;
}

.edit-workhours>div.cs-working-hours-form>a {
  position: absolute;
  left: 555px;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  color: #acb7cc;
  text-decoration: none;
  top: 9px;
  cursor: pointer;
}

.edit-workhours>div.cs-working-hours-form>ul li.disable div p {
  color: #acb7cc !important;
}

.edit-workhours>div.cs-working-hours-form>ul li div.cs-checkbox-field {
  /*display: inline-block;*/
  width: 180px;
  float: left;
  padding: 8px 0px;
  position: relative;
}

.edit-workhours>div.cs-working-hours-form>ul li div.cs-checkbox-field label {
  font-size: 16px;
}

.edit-workhours>div.cs-working-hours-form>ul li div.cs-checkbox-field label cite {
  width: 18px;
  height: 18px;
  top: 50%;
  margin: -9px 0px 0px 0px;
}

.edit-workhours>div.cs-working-hours-form>ul li div.cs-checkbox-field label svg {
  margin: -3px 0px 0px 0px;
}

.edit-workhours>div.cs-working-hours-form>ul li div.cs-dropdwn {
  display: inline-block;
  width: 150px;
  margin: 0px;
}

/*.edit-workhours > div.cs-working-hours-form > ul li div.cs-checkbox{
    font-size: 16px;
    line-height: 1.25;
    color: var(--txt-black-color);
    display: inline-block;
    width: 170px;
}

.edit-workhours > div.cs-working-hours-form > ul li div.cs-time-dwn{
    display: inline-block;
    width: 150px;
}

.edit-workhours > div.cs-working-hours-form > ul li div.cs-time-dwn p{
    font-size: 16px;
    line-height: 1.25;
    color: var(--txt-black-color);
    border:1px solid #d3d9e5;
    border-width: 0px 0px 1px;
    padding: 8px 0px;
    cursor: pointer;
}*/

.edit-workhours>div.cs-working-hours-form>ul>li {
  padding: 0px 0px 3px;
  height: 40px;
}

.edit-workhours>div.cs-working-hours-form>ul li>div:nth-child(2) {
  margin: 0px 55px 0px 0px;
}

.edit-workhours>div.cs-working-hours-form>ul li>div:nth-child(2):before {
  content: "To";
  font-size: 14px;
  color: #8f99ad;
  font-family: "Lato", sans-serif;
  position: absolute;
  right: -35px;
  top: 11px;
}

/*cs-input-form css code start here ****************/
.cs-input-form,
.inline-block-form {
  margin: 32px 0px 0px;
  position: relative;
}

.cs-input-form label {
  font-size: 14px;
  font-weight: 500;
  color: var(--txt-gray-color);
}

.cs-input-form input[type="text"] {
  font-size: 16px;
  color: var(--txt-black-color);
  display: block;
  border: 1px solid var(--border-color);
  border-width: 0px 0px 1px;
  padding: 12px 0px;
  width: 280px;
}

.cs-input-form.public input[type="text"] {
  padding: 8px 50px 8px 0px;
}

.cs-input-form.public:after {
  content: "public";
  position: absolute;
  right: 0px;
  font-size: 14px;
  color: var(--txt-gray-color);
  bottom: 14px;
}

.inline-block-form div.cs-input-form {
  display: inline-block;
  margin: 0px 40px 0px 0px;
}

.cs-input-form>span svg {
  position: absolute;
  bottom: 1px;
  width: 13px;
  left: 0px;
  height: 13px;
}

.cs-input-form>span {
  font-size: 12px;
  line-height: 1.33;
  font-weight: 600;
  padding: 6px 0px 0px 20px;
  position: absolute;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: none;
}

.cs-input-form.cs-error-form>span {
  display: block;
  color: #df0d29;
}

.cs-input-form.cs-error-form>span svg {
  fill: #df0d29;
}

.cs-input-form.cs-error-form input,
.cs-input-form.cs-error-form>p {
  border-color: #df0d29;
}

/*input-form-button css code start here ****************/
.input-form-button {
  margin: 0px 60px 0px 0px;
  display: flex;
  justify-content: flex-end;
  text-align: center;
  position: relative;
}

.input-form-button button.secondary-btn,
.input-form-button a.secondary-btn {
  color: var(--txt-gray-color);
}

.input-form-button button.disable,
.input-form-button a.disable {
  cursor: no-drop;
  opacity: 0.5;
}

.input-form-button a {
  font-size: 15px;
  text-decoration: none;
  color: var(--txt-black-color);
  font-weight: 600;
  margin: 10px 0px;
  /*display: inline-block;*/
  position: absolute;
  left: 0px;
}

.input-form-button a svg {
  width: 16px;
  height: 16px;
  margin: 0px 7px -2px 0px;
  fill: var(--txt-black-color);
}

/*widget-configuration css code start here ****************/
.disable-reply {
  display: flex;
  width: 400px;
  padding-bottom: 40px;
  justify-content: space-between;
  align-items: center;
}

.disable-reply > div:first-child {
  width: 280px;
}

.widget-configuration {
  margin: 60px 0px 0px 60px;
}

.widget-configuration .tab-nav {
  margin: -20px -20px 40px -30px;
}

.widget-configuration h3 {
  font-size: 20px;
  color: var(--txt-black-color);
  line-height: normal;
  margin: 0px 0px 8px;
  font-weight: 600;
}

.widget-configuration>p {
  font-size: 16px;
  line-height: 1.25;
  color: var(--txt-gray-color);
  padding: 0px 0px 60px;
}

.widget-configuration>section h3 {
  font-size: 16px;
  color: var(--txt-black-color);
  line-height: normal;
  margin: 0px 0px 8px;
  font-weight: 600;
}

.widget-configuration>section p {
  font-size: 16px;
  line-height: 1.25;
  color: var(--txt-gray-color);
  padding: 0px;
}

/*cs-widget-config css code start here ****************/
.cs-widget-config {
  margin: 0px 0px 40px 0px;
  position: relative;
}

.cs-widget-config>div>label,.disable-reply label {
  font-size: 16px;
  font-weight: 500;
  color: var(--txt-black-color);
  display: block;
}

.cs-widget-config>div>label {
  line-height: 1.5;
}  

.disable-reply label {
  margin-bottom: 7px;
}

.cs-widget-config>div>p,.disable-reply p{
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  color: var(--txt-gray-color);
}

.cs-widget-config>div {
  width: 360px;
  display: inline-block;
}

.cs-widget-config>div:nth-child(2) figure {
  width: 44px;
  height: 44px;
  background-color: #226cff;
  border-radius: 100%;
  margin: -12px 0px 0px -56px;
  float: left;
}

.cs-widget-config>div:nth-child(2) figure code {
  border: 2px solid #226cff;
  border-radius: 100%;
  position: absolute;
  width: 44px;
  height: 44px;
}

.cs-widget-config>div:nth-child(2) figure.chat-icon {
  background-image: url("../images/chatsupport-white-logo-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
}

.cs-widget-config>div:nth-child(2) figure img {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  object-fit: cover;
}

/*.cs-widget-config > div:nth-child(2){
  position: absolute;
  margin: -7px 0px 0px 0px;
    position: absolute;
    left: 477px;
    top: 50%;
}*/

/*.cs-widget-config > div:nth-child(2) a{
  text-decoration: none;
  font-weight: 600;
  color: #226cff;
  font-size: 14px;
}*/

.tick-circle-icon {
  width: 34px;
  height: 34px;
  border-radius: 100px;
  margin: 0px 0px 24px;
}

.cs-widget-config.cs-business-hrs>div.cs-dropdwn,
.cs-widget-config.cs-business-hrs>div.cs-input-field,
.cs-widget-config.cs-business-hrs>div.cs-radio-custom {
  position: absolute !important;
  left: 430px !important;
  top: 50% !important;
  margin: -18px 0px 0px !important;
  width: 330px !important;
}

.cs-widget-config.cs-business-hrs>div.cs-input-field {
  margin-bottom: 80px !important;
}

.cs-widget-config.cs-business-hrs>div.cs-switch-btn {
  position: absolute !important;
  left: 430px !important;
  top: 27px;
  margin: 0px;
  /* top: 50% !important;
  margin: -13px 0px 0px !important; */
}

.cs-widget-config>.cs-input-field {
  width: 330px !important;
  margin: 0px !important;
  position: absolute !important;
  left: 430px !important;
  top: 18px !important;
}

.cs-widget-config>div>.cs-input-field,
.cs-widget-config>div>.cs-dropdwn {
  width: 100% !important;
  margin: 0px !important;
}

.cs-widget-config.error>input[type="text"] {
  border-color: #dd2727;
}

.cs-widget-config div.dwn-arrow {
  padding: 8px 28px 8px 0px;
  font-size: 16px;
  color: var(--txt-black-color);
  border: 1px solid var(--border-color);
  border-width: 0px 0px 1px;
  width: 330px;
  margin: -20px 0px 0px 0px;
  position: absolute;
  left: 420px;
  top: 50%;
  cursor: pointer;
}

.cs-widget-config>div.dwn-arrow p:after {
  content: "";
  background: url("../images/arrow-icon.svg") center no-repeat;
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 5px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.cs-widget-config>div.dwn-arrow.open p:after {
  content: "";
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}

.cs-widget-config div.dwn-arrow p {
  color: var(--txt-black-color);
  font-size: 16px;
  line-height: normal;
}

.cs-widget-config>div.dwn-arrow.open div.cs-input-dwn {
  display: block;
}

.cs-widget-config>a {
  font-size: 14px;
  font-weight: 600;
  color: #226cff;
  margin: 0px 0px 0px 0px;
  position: absolute;
  left: 430px;
  top: 29px;
  text-decoration: none;
}

.cs-widget-config > a.cs-btn{
  color: #ffffff;
  position: inherit;
}

.copy-code-disabled {
  color: #8f99ad !important;
  cursor: not-allowed !important;
}

/*.cs-widget-config > div.cs-input-dwn{
  position: absolute;
    top: 48px;
    left: 420px;
    box-shadow: 0 3px 4px 0 rgba(10, 31, 68, 0.1), 0 0 1px 0 rgba(10, 31, 68, 0.08);
    width: 330px;
    padding: 2px 0px;
    background-color: var(--white-color);
    border-radius: 4px;
    z-index: 1;
    min-height: auto;
    max-height: 144px;
    overflow: auto;
    display: none;

}*/

.cs-widget-config.cs-business-hrs>span {
  position: absolute;
  left: 780px;
  top: 50%;
  text-decoration: none;
  font-size: 14px;
  margin: -11px 0px 0px 0px;
  color: var(--txt-black-color);
  line-height: 1.71;
  cursor: pointer;
}

/*cs-widget-style css code start here ****************/
.cs-widget-style {
  margin: 0px 0px 40px 0px;
  position: relative;
  display: inline-block;
}

.cs-widget-style label {
  margin: 0px 0px 14px;
  display: block;
  font-size: 14px;
  color: #727c8f;
}

.widget-style-color {
  position: relative;
}

.widget-style-color code {
  width: 30px;
  height: 30px;
  margin: 0px 12px 0px 0px;
  border-radius: 100%;
  background-color: #2355F3;
  float: left;
  cursor: pointer;
  position: relative;
}

.widget-style-color code:nth-child(2) {
  background-color: #03c599;
}

.widget-style-color code:nth-child(3) {
  background-color: #646178;
}

.widget-style-color code:nth-child(4) {
  background-color: #3c4859;
}

.widget-style-color code.active:before,
.widget-style-color code:hover:before,
.widget-style-color div.cs-widget-color:hover:before {
  content: "";
  border: 2px solid var(--PaleBlueAW);
  position: absolute;
  width: 34px;
  height: 34px;
  border-radius: 100%;
  left: -4px;
  top: -4px;
}

.widget-style-icon,
.widget-style-icon code {
  position: relative;
}

.widget-style-icon code {
  width: 40px;
  height: 40px;
  margin: 0px 12px 0px 0px;
  border-radius: 100%;
  background-color: var(--PaleBlueAW);
  float: left;
  cursor: pointer;
  position: relative;
  transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
}

.widget-style-icon code:nth-child(1) {
  background-image: url("https://assets.chatsupport.co/website/stag/images/chatsupport-icon-logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
}

.widget-style-icon code:nth-child(2) {
  background-color: #03c599;
}

.widget-style-icon code:nth-child(2) figure:after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  border: 1px solid #dbdbdb;
  border-radius: 100%;
}

.widget-style-icon code:nth-child(2) figure.active:after,
.widget-style-icon code.active:nth-child(2)>figure:after {
  border-color: transparent;
}

/* .widget-style-icon code:nth-child(1):hover:before,
.widget-style-icon code:nth-child(1).active:before,
.widget-style-icon code:nth-child(2):hover:before,
.widget-style-icon code:nth-child(2).active:before{
    width: 44px;
    height: 44px;
    left: -4px;
    top: -4px;
} */

.widget-style-icon code.active:before,
.widget-style-icon code:hover:before,
.widget-style-icon div.cs-widget-upload:hover:before {
  content: "";
  border: 2px solid var(--PaleBlueAW);
  position: absolute;
  width: 44px;
  height: 44px;
  border-radius: 100%;
  left: -4px;
  top: -4px;
}

.widget-style-icon code.active:after {
  content: "";
  border: 2px solid var(--white-color);
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  left: -2px;
  top: -2px;
}

.widget-style-icon code.widget-style-upload figure img {
  width: 42px;
  height: 42px;
  margin: 0px 0px 0px -1px;
}

/* .widget-style-icon code.widget-style-upload:hover:before,
.widget-style-icon code.widget-style-upload.active:before{
    width:42px;
    height:42px;
    left:-3px;
    top:-3px;
} */

.cs-widget-upload {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  position: relative;
}

.cs-widget-upload svg {
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
  height: 100%;
}

.cs-widget-upload input {
  -webkit-appearance: none;
  opacity: 0;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 1;
}

.cs-widget-color {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  position: relative;
  /*background-color: #f4f4f4;
    border:2px solid #dbdbdb;*/
  cursor: pointer;
}

.cs-widget-color svg {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.widget-default-pic>div {
  position: absolute;
  width: 330px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.12);
  border: solid 0.5px #ebeced;
  background-color: var(--white-color);
  border-radius: 3px;
  z-index: 1;
  padding: 0px 0px 22px 6px;
  left: -53px;
  top: 55px;
  display: none;
}

.widget-default-pic.active.open>div {
  display: block;
}

.widget-default-pic>div:before,
.widget-default-pic>div:after {
  content: "";
  width: 0;
  height: 0;
  border: 8px solid;
  position: absolute;
  margin-left: -8px;
  z-index: 1;
  left: 72px;
}

.widget-default-pic>div:before {
  border-color: transparent transparent #ebeced;
  top: -16px;
}

.widget-default-pic>div:after {
  border-color: transparent transparent #ffffff;
  top: -15px;
}

.widget-default-pic>div figure {
  display: inline-block;
  width: 48px;
  height: 48px;
  margin: 24px 0px 0px 25px;
  border-radius: 100%;
  position: relative;
}

.widget-default-pic>div img {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  object-fit: cover;
  /* border:1px solid #dbdbdb; */
}

.widget-default-pic>div figure.active:before,
.widget-default-pic>div figure:hover:before {
  content: "";
  border: 2px solid #216cff;
  position: absolute;
  width: 52px;
  height: 52px;
  border-radius: 100%;
  left: -4px;
  top: -4px;
}

.widget-default-pic>figure,
.widget-default-pic>figure img {
  border-radius: 100%;
  object-fit: cover;
  width: 40px;
  height: 40px;
}

.widget-default-pic.widget-style-upload>figure svg {
  position: absolute;
  width: 43px;
  height: 43px;
  top: -4px;
  left: -4px;
  z-index: 1;
}

.overflow .cs-widget-notification {
  left: 0px !important;
}

.cs-widget-notification {
  position: fixed;
  background-color: var(--PaleBlueAW);
  left: 384px;
  right: 0px;
  top: 0px;
  padding: 18px 0px;
  display: block;
  text-align: center;
  font-size: 15px;
  color: var(--white-color);
  z-index: 2;
  height: 51px;
}

.cs-widget-notification cite{
  position: absolute;
  right: 20px;
  font-size: 20px;
  top: 13px;
  cursor: pointer;
  font-weight: 400;
}

.cs-widget-notification.full{
  left: 200px;
}

.error-notification {
  background-color: #ffffff;
  color: #df0d29;
  box-shadow: 0 1px 1px 0 rgba(17, 38, 82, 0.22);
  text-align: center;
  padding: 18px 54px;
}

.error-notification>span>svg {
  margin: 0px 7px -1px 0px;
}

.top-88 {
  top: 89px;
}

.left-0 {
  left: 0px !important;
}

/*cs-form-col1 css code start here ****************/
.cs-form-col {
  margin: 0px 0px 40px 0px;
  position: relative;
}

.cs-form-col div:nth-child(1) label {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--txt-black-color);
  margin: 0px 0px 3px;
  display: inline-block;
}

.cs-form-col div:nth-child(1) p {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  color: var(--txt-gray-color);
}

.cs-form-col>div.col {
  width: 285px;
  display: inline-block;
}

.cs-form-col>div.cs-switch-btn {
  position: absolute;
  left: 390px;
  top: 27px;
  margin: 0px;
  /* top: 50%;
  margin: -13px 0px 0px 0px; */
}

.cs-form-col>div.col-dwn {
  position: absolute;
  left: 430px;
  top: 50%;
  margin: -25px 0px 0px 0px;
}

.cs-form-col>div.col-dwn p {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  color: var(--txt-gray-color);
}

.cs-form-col>div.col-dwn input {
  font-size: 16px;
  color: var(--txt-black-color);
  border: 1px solid var(--border-color);
  border-width: 0px 0px 1px;
  padding: 0px 0px 8px 0px;
  width: 330px;
  margin: 4px 0px 30px 0px;
  display: block;
}

.cs-form-col>div.col-dwn.open div.cs-search-dwn {
  display: block;
}

.cs-form-col .col-txt{
  position: absolute;
  font-size: 14px;
  line-height: 1.57;
  color: #727c8f;
  opacity: 0.5;
  top: 26px;
  left: 400px;
}

.cs-form-col .col-link{
  position: absolute;
  left: 540px;
  font-size: 14px;
  text-decoration: none;
  font-weight: 600;
  color: #226cff;
  top: 30px;
}

.cs-search-dwn {
  border-radius: 4px;
  box-shadow: 0 3px 4px 0 rgba(10, 31, 68, 0.15),
    0 0 4px 0 rgba(10, 31, 68, 0.08);
  border: solid 1px #f0f2f5;
  background-color: var(--white-color);
  overflow: auto;
  min-height: auto;
  max-height: 158px;
  padding: 10px 0px;
  position: absolute;
  z-index: 1;
  right: 0px;
  left: 0px;
  top: 50px;
  display: none;
}

.cs-search-dwn li {
  font-size: 14px;
  line-height: 1.43;
  color: var(--txt-black-color);
  padding: 13px 0px 13px 57px;
  cursor: pointer;
  position: relative;
}

.cs-search-dwn li>figure {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-image: url(../images/user-icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 14px;
  background-color: #374051;
  left: 17px;
  top: 9px;
}

.cs-search-dwn li>figure img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  object-fit: cover;
}

.cs-search-dwn li:hover {
  background-color: #f3f6fc;
}

.cs-form-col>div.col-dwn cite {
  padding: 7px 7px 7px 28px;
  border-radius: 20px;
  background-color: #f0f4fb;
  display: inline-block;
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  color: var(--txt-black-color);
  position: relative;
  cursor: pointer;
  margin: 0px 8px 8px 0px;
}

.cs-form-col>div.col-dwn cite figure {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-image: url("../images/user-white-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 8px;
  background-color: #b1b5bc;
  left: 4px;
  top: 50%;
  margin: -10px 0px 0px 0px;
}

.cs-form-col>div.col-dwn cite figure img {
  border-radius: 100%;
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.cs-form-col>div.col-dwn cite code {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: var(--BlueAW);
  left: 4px;
  top: 50%;
  margin: -10px 0px 0px 0px;
  text-align: center;
  color: var(--white-color);
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  display: none;
}

.cs-form-col>div.col-dwn cite:hover code {
  display: block;
}

.cs-form-col.cs-form-routing div.col {
  width: 560px;
}

.cs-form-col.cs-form-routing div.cs-switch-btn {
  left: 620px;
}

/*cs-input-dwn css code start here ****************/
.cs-input-dwn {
  position: absolute;
  top: 36px;
  left: 0px;
  box-shadow: 0 3px 4px 0 rgba(10, 31, 68, 0.1),
    0 0 1px 0 rgba(10, 31, 68, 0.08);
  width: 330px;
  padding: 2px 0px;
  background-color: var(--white-color);
  border-radius: 4px;
  z-index: 1;
  min-height: auto;
  max-height: 144px;
  overflow: auto;
  display: none;
}

.cs-input-dwn>input[type="text"] {
  font-size: 14px;
  color: var(--txt-black-color);
  padding: 6px 10px;
  margin: 20px 0px 0px 20px;
  width: 290px;
  border: 1px solid var(--border-color);
}

.cs-input-dwn>ul {
  padding: 10px 0px;
  overflow: auto;
  min-height: auto;
  max-height: 220px;
}

/*.cs-input-dwn > ul:before, 
.cs-input-dwn > ul:after {
    content: '';
    width: 0;
    height: 0;
    border: 8px solid;
    position: absolute;
    margin-left: -8px;
    z-index: 1;
    right: 10px;
}

.cs-input-dwn > ul:before {
    border-color: transparent transparent #ebeced;
    top: -16px;
}

.cs-input-dwn > ul:after {
    border-color: transparent transparent var(--white-color);
    top: -15px;
}*/

.cs-input-dwn>ul li {
  padding: 10px 20px 10px 20px;
  font-size: 14px;
  line-height: 1.43;
  color: var(--txt-black-color);
  cursor: pointer;
  transition: background-color color 0.5s;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color color 0.5s;
  -ms-transition: background-color color 0.5s;
  -o-transition: background-color color 0.5s;
}

.cs-input-dwn>ul li:hover {
  background-color: var(--BlueAW);
  color: var(--white-color);
}

/*your-widget css code start here ****************/
.your-widget,
.your-prompt {
  margin: 0px;
}

.your-widget li,
.your-prompt>li {
  width: 260px;
  height: 100px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(10, 31, 68, 0.1);
  border: solid 1px var(--border-color);
  background-color: var(--white-color);
  position: relative;
  padding: 29px 20px 29px 86px;
  cursor: pointer;
  margin: 0px 32px 32px 0px;
  float: left;
}

.your-prompt>li,
.your-widget>li.add-new {
  padding: 27px 20px 27px 20px;
}

.your-widget figure {
  position: absolute;
  width: 44px;
  height: 44px;
  border-radius: 100%;
  left: 24px;
  top: 50%;
  margin: -23px 0px 0px 0px;
  background-image: url("https://assets.chatsupport.co/website/stag/images/chatsupport-icon-logo.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #226cff;
  background-size: 16px;
}

.your-widget figure i {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  border: 2px solid rgb(102, 153, 255);
  border-radius: 100%;
}

.your-widget figure>em {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.your-widget figure code {
  border: 2px solid var(--PaleBlueAW);
  position: absolute;
  width: 44px;
  height: 44px;
  border-radius: 100%;
}

.your-widget figure img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

.your-widget li>div {
  display: table-cell;
  vertical-align: middle;
  height: 40px;
}

.your-widget li>div label,
.your-prompt label {
  font-weight: 600;
  font-size: 16px;
  color: var(--txt-black-color);
  line-height: normal;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  width: 150px;
}

.your-widget li>div label em,
.your-prompt label em {
  width: 85px;
}

.your-widget li>div p,
.your-prompt p {
  line-height: 1.43;
  font-size: 14px;
  color: var(--txt-gray-color);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 2px 0px 0px;
  /* display: inline-block; */
  display: block;
  min-width: auto;
  max-width: 160px;
}

.your-prompt p {
  margin: 7px 0px 0px;
}

.your-prompt>li>figure {
  position: absolute;
  right: 20px;
  bottom: 27px;
  width: 16px;
  height: 16px;
  display: none;
}

.your-prompt>li>figure>svg {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(90deg);
  width: 16px;
  height: 16px;
  fill: var(--txt-black-color);
  cursor: pointer;
}

.your-prompt>li a,
.your-widget>li a {
  font-size: 16px;
  color: var(--PaleBlueAW);
  font-weight: 600;
  text-decoration: none;
  display: block;
  text-align: center;
  padding: 13px 0px;
}

.your-prompt>li.add-new,
.your-widget>li.add-new {
  border-color: var(--PaleBlueAW);
  border-style: dashed;
  border-width: 2px;
}

.your-prompt>li>figure.open .edit-delete-container {
  display: block;
}

.your-prompt>li .edit-delete-container {
  left: -90px;
  top: 5px;
  z-index: 1;
  display: none;
}

.your-prompt>li .edit-delete-container:before,
.your-prompt>li .edit-delete-container:after {
  left: 97px;
}

.your-prompt>li:hover>figure,
.your-widget li.active>figure,
.your-prompt>li.active>figure {
  display: block;
}

/*cs-switch-btn code start here *****************/
.cs-switch-btn {
  width: 50px !important;
  height: 22px;
  background-color: var(--BlueAW);
  border-radius: 15px;
  cursor: pointer;
  position: relative;
}

.cs-switch-btn:before {
  content: "";
  position: absolute;
  background-color: var(--white-color);
  width: 26px;
  height: 18px;
  border-radius: 20px;
  left: 22px;
  top: 2px;
}

.cs-switch-btn.off {
  background-color: #d3d9e5;
}

.cs-switch-btn.off:before {
  left: 2px;
  background-color: var(--white-color);
}

/*.cs-switch-btn:after{
  content: "";
  position: absolute;
  left: 10px;
  top: 3px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  line-height: 21px;
  color: var(--BlueAW);
  font-family: "Lato", sans-serif;
}*/

/*.cs-switch-btn.on:after{
  content: "";
  color: #8f99ad;
  left: 32px;
}*/
.prechat-form-temp {
  /* background-image: url("../images/prechat-form-temp.png"); */
  background-image: url("https://assets.chatsupport.co/webapp/images/prechat-temp.svg");
  background-repeat: no-repeat;
  background-position: top;
  display: inline-block;
  position: absolute;
  width: 400px;
  height: 630px;
  background-size: 400px;
  top: 235px;
  left: 580px;
  /* left: 980px; */
  /* right:40px; */
  margin: 0px 0px 80px;
}

.prechat-form-temp span {
  font-size: 16px;
  color: var(--white-color);
  font-weight: 600;
  line-height: 24px;
  width: 80px;
  height: 44px;
  background-color: #6598fe;
  padding: 10px 0px;
  border-radius: 25px;
  position: absolute;
  right: 59px;
  bottom: 126px;
  text-align: center;
  font-family: "Lato", sans-serif;
  cursor: default;
  display: none;
}

.prechat-form-temp.post-chat {
  background-image: url("../images/emoji-nonselcted.svg") !important;
}

.prechat-form-temp.post-chatfeedback {
  background-image: url("../images/emoji-selcted.svg") !important;
}

/*cs-domain-whitelist code start here *****************/
.cs-domain-whitelist .cs-input-form input {
  width: 600px;
  font-size: 14px;
}

.cs-domain-whitelist>.cs-input-form {
  margin: 28px 0px 0px;
}

.cs-domain-whitelist>.cs-input-form.cs-active-field input,
.cs-input-form.cs-active-field input {
  border-color: var(--PaleBlueAW);
}

.cs-domain-whitelist>a {
  font-size: 14px;
  text-decoration: none;
  color: var(--BlueAW);
  font-weight: 600;
  margin: 32px 0px 0px;
  display: inline-block;
}

.cs-domain-whitelist>a svg {
  fill: var(--BlueAW);
  width: 15px;
  height: 15px;
  display: inline-block;
  margin: 0px 3px -2px 0px;
}

/*cs-whitelisted-url code start here **************/
.cs-whitelisted-url {
  width: 600px;
  margin: 28px 0px 0px;
}

.cs-whitelisted-url>ul>li {
  border: 1px solid var(--PaleBlueAW);
  border-width: 0px 0px 1px;
  position: relative;
  font-size: 14px;
  line-height: 1.43;
  padding: 12px 0px;
  min-height: 45px;
}

.cs-whitelisted-url>ul>li span {
  float: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: auto;
  max-width: 580px;
}

.cs-whitelisted-url>ul>li input {
  font-size: 14px;
  line-height: 1.43;
  width: 100%;
  border: 0px;
  padding: 0px;
  margin: 0px;
  font-family: "Lato", sans-serif;
}

.cs-whitelisted-url>ul>li.error {
  border: 1px solid #dd2727;
}

.cs-whitelisted-url>ul>li figure {
  /*position: absolute;
    right: 40px;
    top: 14px;
    width: 16px;
    height: 16px;*/
  display: none;
  position: relative;
  right: -10px;
  top: 5px;
  width: 16px;
  height: 16px;
  float: left;
}

.cs-whitelisted-url>ul>li:hover figure,
.cs-whitelisted-url>ul>li.active figure {
  display: inline-block;
}

.cs-whitelisted-url>ul>li>figure>svg {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(90deg);
  width: 16px;
  height: 16px;
  fill: var(--txt-black-color);
  cursor: pointer;
}

.cs-whitelisted-url>ul>li>figure>ul {
  position: absolute;
  z-index: 1;
  right: 0px;
  top: 24px;
  margin: 0px -56px 0px 0px;
  left: inherit;
  display: none;
}

.cs-whitelisted-url>ul>li>figure>ul:before,
.cs-whitelisted-url>ul>li>figure>ul:after {
  left: 50%;
}

.cs-whitelisted-url>ul>li>figure.open ul {
  display: block;
}

/*edit-delete-container code start here **************/
.edit-delete-container {
  box-shadow: 0 3px 4px 0 rgba(10, 31, 68, 0.15),
    0 0 4px 0 rgba(10, 31, 68, 0.08);
  border: 1px solid #f0f2f5;
  padding: 10px 0px;
  border-radius: 3px;
  width: 127px;
  position: relative;
  left: 50%;
  top: 16px;
  background-color: var(--white-color);
  z-index: 1;
  /*display: none;*/
}

.edit-delete-container:before,
.edit-delete-container:after
{
  content: "";
  width: 0;
  height: 0;
  border: 8px solid;
  position: absolute;
  margin-left: -8px;
  z-index: 1;
  left: 50%;
}

.detail-info:before,
.detail-info:after,
.tag-message-dropdown.bottom::before,
.tag-message-dropdown.top::before,
.tag-message-dropdown.bottom::after,
.tag-message-dropdown.top::after 
{
  content: "";
  width: 0;
  height: 0;
  border: 8px solid;
  position: absolute;
  z-index: 1;
}

.detail-info:before,
.detail-info:after {
  margin-left: 7px;
  left:72px;
}

.tag-message-dropdown.bottom::before,.tag-message-dropdown.bottom::after,.tag-message-dropdown.top::before,.tag-message-dropdown.top::after {
  right: 10px;
}


.edit-delete-container:before,
.detail-info:before, 
.tag-message-dropdown.bottom::before
{
  border-color: transparent transparent #ebeced;
  top: -16px;
}

.tag-message-dropdown.top::before {
  border-color: transparent transparent #ebeced;
  bottom: -16px;
  transform: rotate(180deg);
}

.edit-delete-container:after,
.detail-info:after,
.tag-message-dropdown.bottom::after {
  border-color: transparent transparent var(--white-color);
  top: -15px;
}
  
.tag-message-dropdown.top::after {
  border-color: transparent transparent var(--white-color);
  bottom: -15px;
  transform: rotate( 180deg);
}

.edit-delete-container>li {
  padding: 4px 15px;
  font-size: 14px;
  font-weight: 500;
  color: var(--txt-black-color);
  line-height: 1.71;
  position: relative;
  cursor: pointer;
  z-index: 1;
}

.edit-delete-container>li:hover {
  background-color: #f3f6fc;
}

.edit-delete-container>li>svg {
  width: 13px;
  height: 13px;
  float: left;
  margin: 5px 16px 0px 0px;
  pointer-events: none;
}

.edit-delete-container>li.delete {
  color: #df0d29;
}

.edit-delete-container>li.active .cs-dropdwn-checkbox-sub {
  display: block;
}

.edit-delete-container.sub-dropdown {
  padding: 10px 0px;
}

.edit-delete-container.sub-dropdown>li {
  padding: 8px 15px;
}

.edit-delete-container.sub-dropdown>li.active {
  background-color: #f0f2f5;
}

/*cs-radio-form code start form here ******************/
.cs-radio-form {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: #fdfefe;
  width: 600px;
  padding: 20px 20px 20px 48px;
  margin: 0px 0px 12px;
  position: relative;
  cursor: pointer;
  transition: border-color 0.5s;
  -webkit-transition: border-color 0.5s;
  -moz-transition: border-color 0.5s;
  -ms-transition: border-color 0.5s;
  -o-transition: border-color 0.5s;
}

.cs-radio-form:before,
.cs-radio-form.active:before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 1px solid #8a94a6;
  position: absolute;
  left: 17px;
}

.cs-radio-form:after,
.cs-radio-form.active:after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: var(--white-color);
  position: absolute;
  left: 22px;
  top: 25px;
}

.cs-radio-form.active:before,
.cs-radio-form.active,
.cs-radio-form:hover {
  border: 1px solid var(--BlueAW);
  background-color: #f3f7ff;
}

.cs-radio-form.active:after {
  background-color: var(--BlueAW);
}

.cs-radio-form label {
  font-size: 14px;
  line-height: 1.43;
  color: var(--txt-black-color);
  cursor: pointer;
  font-weight: 600;
  display: block;
  height: 18px;
  margin: -1px 0px 0px;
}

.cs-radio-form p {
  font-size: 14px;
  line-height: 1.71;
  color: var(--txt-gray-color);
  margin: 0px;
}

/*cs-radio-field code start here ***************************/
.cs-radio-field {
  padding: 10px 0px;
}

.cs-radio-field label {
  padding: 0 0 0 30px;
  cursor: pointer;
  line-height: 1.43;
  font-size: 14px;
  color: #181818;
  position: relative;
}

.cs-radio-field label input {
  position: absolute;
  opacity: 0;
  left: 0px;
}

.cs-radio-field label cite {
  position: absolute;
  top: -1px;
  left: 0px;
  height: 16px;
  width: 16px;
  border: 1px solid #acb7cc;
  border-radius: 50%;
  transition: border-color 0.3s ease-out;
  -webkit-transition: border-color 0.3s ease-out;
  /* For Webkit browser(Chrome, Safari...)*/
  -moz-transition: border-color 0.3s ease-out;
  /* For Mozilla browser */
  -ms-transition: border-color 0.3s ease-out;
  /* For Opera browser */
  -o-transition: border-color 0.3s ease-out;
  /* For Microsoft browser */
}

.cs-radio-field label cite:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -4px;
  transition: background-color 0.3s ease-out;
  -webkit-transition: background-color 0.3s ease-out;
  /* For Webkit browser(Chrome, Safari...)*/
  -moz-transition: background-color 0.3s ease-out;
  /* For Mozilla browser */
  -ms-transition: background-color 0.3s ease-out;
  /* For Opera browser */
  -o-transition: background-color 0.3s ease-out;
  /* For Microsoft browser*/
}

.cs-radio-field input:checked~cite,
.cs-radio-field label:hover cite {
  border-color: #4684ff;
}

.cs-radio-field input:checked~cite:before {
  background-color: #4684ff;
}

.cs-radio-field input:disabled~cite {
  border-color: #acb7cc;
}

.cs-radio-field input:disabled~cite:before {
  background-color: #acb7cc;
}

.cs-radio-custom .cs-radio-field {
  display: inline-block;
}

.cs-radio-custom .cs-radio-field label cite {
  top: 2px;
}

.cs-radio-custom .cs-radio-field:nth-child(1) {
  margin: 0px 60px 0px 0px;
}

/*cs-choos-widget code start here ********************/
.cs-choos-widget {
  border: 1px solid #e6e9f0;
  background-color: #fdfefe;
  border-radius: 4px;
  padding: 24px;
  margin: 0px 0px 24px;
  position: relative;
}

.cs-choos-widget.active {
  border: 1px solid var(--BlueAW);
}

/*cs-incoming-chat css code start here ****************/
.cs-incoming-chat {
  position: absolute;
  top: 50%;
  left: 50%;
  /*background-color: var(--white-color);*/
  z-index: 130;
  padding: 45px;
  border-radius: 21px;
  text-align: center;
  width: 330px;
  margin: -235px 0px 0px -165px;
  height: 472px;
  background-image: linear-gradient(to top, #101229, rgba(0, 0, 0, 0)),
    linear-gradient(to bottom, #1f252d, #1f252d);
}

.cs-choos-widget label {
  font-size: 15px;
  color: var(--txt-black-color);
  line-height: normal;
  padding: 0px 0px 4px 0px;
  display: inherit;
}

.cs-choos-widget p {
  font-size: 14px;
  line-height: 1.71;
  color: var(--txt-gray-color);
  width: 250px;
}

.cs-choos-widget li {
  display: inline-block;
  margin: 24px 77px 0px 0px;
  text-align: center;
}

.cs-choos-widget li:last-child {
  margin: 0px;
}

.cs-choos-widget li figure {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.cs-choos-widget li cite {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  border: 1px solid #acb7cc;
  display: inherit;
  margin: 7px 0px 0px;
  cursor: pointer;
  position: relative;
}

.cs-choos-widget li cite:hover {
  border-color: var(--BlueAW);
}

.cs-choos-widget li cite svg {
  width: 8px;
  height: 6px;
}

.cs-incoming-chat div>button.reject-btn {
  color: var(--white-color);
  background: url("../images/chat-white-icon.svg") center center no-repeat;
  background-color: #8e1735;
  margin: 0px 70px 0px 0px;
}

/* .cs-incoming-chat div > button.recption-btn:before{
    content: "Reception";
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -3px 0px 0px -4px;
    fill: var(--white-color);
} */

.cs-choos-widget li cite.active {
  background-color: var(--BlueAW);
  border-color: var(--BlueAW);
}

.cs-choos-widget a {
  font-size: 14px;
  color: #226cff;
  font-weight: 600;
  text-decoration: none;
  position: absolute;
  right: 40px;
  top: 50%;
  margin: -5px 0px 0px 0px;
  width: 100px;
}

.cs-choos-widget a input {
  -webkit-appearance: none;
  opacity: 0;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100px;
  cursor: pointer;
}

.cs-choos-widget a span {
  color: var(--txt-gray-color);
  display: block;
  margin: -10px 0px 10px 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.cs-incoming-chat div>button.reject-btn:before {
  content: "Reject";
  position: absolute;
  color: var(--white-color);
  font-size: 15px;
  opacity: 0.8;
  left: 50%;
  bottom: -25px;
  margin: 0px 0px 0px -21px;
  font-family: "Lato", sans-serif;
}

.color-picker {
  width: 150px;
  height: 40px;
  position: absolute;
  right: 0px;
  border: 1px solid #e6e9f0;
  border-radius: 25px;
  top: 50%;
  margin: -20px 48px 0px 0px;
  font-size: 14px;
  color: var(--txt-gray-color);
  line-height: 40px;
  padding: 0px 0px 0px 52px;
  background-color: #fdfefe;
  cursor: pointer;
}

.color-picker cite {
  position: absolute;
  width: 38px;
  height: 38px;
  border-radius: 100%;
  background-color: var(--BlueAW);
  left: 0px;
}

.color-picker .chrome-picker {
  position: absolute !important;
  left: 50% !important;
  bottom: 45px !important;
  margin: 0px 0px 0px -113px;
}

/*.cs-dwn code start here *************/
.widget-configuration>.cs-dwn {
  margin: 40px 0px 0px;
  position: relative;
  width: 270px;
}

.work-hours-wrapper {
  position:relative;
}

.cs-dwn cite {
  position: absolute;
  right: -30px;
  top: 11px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  background: url("../images/arrow-icon.svg") center center no-repeat;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  
}


.cs-dwn.open cite {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.cs-dwn label {
  font-size: 14px;
  font-weight: 500;
  color: var(--txt-gray-color);
}

.cs-dwn p {
  color: var(--txt-black-color);
  font-size: 15px;
  line-height: normal;
  cursor: pointer;
  padding: 8px 0px;
}

.cs-dwn.open p,
.cs-dwn ul {
  display: none;
}

.cs-dwn.open ul {
  display: block;
  margin: 6px 0px 12px;
}

.cs-dwn ul li {
  font-size: 15px;
  color: var(--txt-black-color);
  line-height: normal;
  padding: 5px 0px;
}

.cs-dwn ul li.active {
  font-weight: bold;
}

.cs-dwn ul li span:nth-child(1) {
  width: 90px;
  display: inline-block;
}

.cs-dwn a {
  line-height: 1.36;
  font-size: 14px;
  color: #226cff;
  text-decoration: none;
}

.showmore-link {
  margin: 20px 0px 40px;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  color: #6699ff;
  padding: 0px 20px 0px 0px;
  position: relative;
}

.showmore-link svg {
  position: absolute;
  width: 14px;
  height: 7px;
  right: 0px;
  top: 50%;
  margin: -3px 0px 0px 0px;
  stroke: #6699ff;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.showmore-link.open>svg {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

/*invite-people-wrp code start here ********************/
.invite-people-wrp {
  padding: 60px 60px 0px;
  background: url("../images/map-doted.png") right top no-repeat;
  background-size: 400px;
  position: relative;
  height: calc(100vh - 80px);
  overflow: auto;
}

.invite-people-wrp>svg {
  width: 47px;
  height: 34px;
  margin: 0px 0px 20px;
}

.invite-people-wrp>h3 {
  font-size: 26px;
  padding: 0px 0px 22px;
  line-height: normal;
}

.invite-people-wrp>h3 cite {
  font-size: 35px;
  line-height: 0;
  color: #6699ff;
}

.invite-people-wrp>p {
  font-size: 16px;
  line-height: 1.5;
  color: var(--txt-gray-color);
}

.invite-people-wrp>div {
  margin: 60px 0px 0px;
  display: inline-block;
}

.invite-people-wrp>div>a {
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  color: var(--BlueAW);
  margin: 20px 0px 0px;
  display: inline-block;
}

/* .invite-people-wrp > div > ul{
    display: block;
    min-height: 200px;
    max-height: calc(100vh - 214px);
    overflow: auto;
} */

.invite-people-wrp>div>ul>li {
  min-height: 52px;
  margin: 0px 0px 10px 0px;
  position: relative;
  padding: 0px 30px 0px 0px;
}

.invite-people-wrp>div>ul.col5>li div.cs-input-field,
.invite-people-wrp>div>ul.col5>li div.cs-dropdwn {
  width: 155px !important;
}

.invite-people-wrp>div>ul>li div.cs-input-field {
  display: inline-block;
  width: 240px !important;
  margin: 0px 30px 15px 0px !important;
}

.invite-people-wrp>div>ul>li div.cs-dropdwn {
  display: inline-block;
  width: 160px !important;
  margin: 0px 30px 15px 0px !important;
  bottom: -12px;
}

.invite-people-wrp>div>ul>li div.cs-dropdwn .cs-project-wrp {
  top: 21px;
}

.invite-people-wrp>div>ul>li div.cs-dropdwn aside.cs-dropdwn-select span {
  position: absolute;
  white-space: nowrap;
  bottom: -42px;
  z-index: 1;
  background-color: #e7e7e7;
}

.invite-people-wrp>div>ul>li>div:last-child {
  margin: 0px !important;
}

.invite-people-wrp>div>ul>li>code {
  font-size: 20px;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  text-align: center;
  /* background-color: #e7e7e7; */
  line-height: 19px;
  color: #595959;
  z-index: 1;
  right: 0px;
  top: 50%;
  cursor: pointer;
  display: none;
  margin: -2px 0px 0px;
}

.invite-people-wrp>div>ul>li:hover code {
  display: block;
}

.invite-btn-wrp {
  display: flex;
  justify-content: flex-end;
  margin: 40px 30px 40px 0px;
}

.invite-people-wrp footer {
  background: url("../images/tree-footer.jpg") 0px -10px repeat;
  background-size: 1000px;
  height: 64px;
  position: fixed;
  left: 385px;
  right: 0px;
  bottom: 0px;
  padding: 0px 0px 0px;
}

@media screen and (min-width: 1500px) {
  .invite-people-wrp>div>ul.col5>li div.cs-input-field {
    width: 240px !important;
  }

  .invite-people-wrp>div>ul.col5>li div.cs-dropdwn {
    width: 160px !important;
  }
}

@media screen and (max-width: 1240px) {
  .invite-people-wrp>div>ul.col5>li div.cs-input-field {
    width: 160px !important;
  }
}

.gm-style-cc:last-child {
  display: none !important;
}

.color-picker-wrp {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #ebeced;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 3px;
  width: 234px;
  /* position: relative */
  position: absolute;
  margin: 8px 0px 0px 0px;
  z-index: 2;
  right: -18px;
}

.color-picker-wrp:before,
.color-picker-wrp:after {
  content: "";
  width: 0;
  height: 0;
  border: 8px solid;
  position: absolute;
  margin-left: -8px;
  z-index: 1;
  left: 200px;
}

.color-picker-wrp:before {
  border-color: transparent transparent #ebeced;
  top: -16px;
}

.color-picker-wrp:after {
  border-color: transparent transparent #ffffff;
  top: -15px;
}

.color-picker-wrp>div:nth-child(1):before {
  content: "Pick a colour";
  font-size: 12px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  color: #181818;
  display: block;
  border: 1px solid #d3d9e5;
  border-width: 0px 0px 1px;
  margin: 0px -16px 10px;
  padding: 0px 16px 14px;
}

.color-picker-wrp>div:nth-child(2) {
  position: relative;
  width: 178px;
  height: 178px;
}

.color-picker-wrp>div:nth-child(3) {
  width: 116px;
  margin: 10px 0px 0px;
  border-radius: 3px;
  border: 1px solid #d3d9e5;
  display: inherit !important;
  position: relative;
}

.color-picker-wrp>div:nth-child(3) input {
  width: 100%;
  /* height:100%; */
  outline: none;
  font-size: 12px;
  color: #727c8f;
  font-family: "Lato", sans-serif;
  padding: 1px 0px 0px 37px;
  height: 28px;
  border: 0px;
  border-radius: 3px;
}

.color-picker-wrp>div:nth-child(3)>div:nth-child(2) {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  position: absolute;
  margin: 5px 0px 0px 10px;
  top: 0px;
}

.color-picker-wrp>div:nth-child(3)>div:nth-child(3) {
  min-width: 73px;
  max-width: 73px;
  height: 28px;
  font-size: 12px;
  font-family: "Lato", sans-serif;
  /* border: 1px solid #6699ff; */
  position: absolute;
  text-align: center;
  /* color: #6699ff; */
  border-radius: 3px;
  margin: 0px 0px 0px 126px;
  padding: 5px 0px;
  top: 0px;
  cursor: pointer;
  line-height: normal;
}

.color-bar {
  height: 178px;
  position: absolute;
  width: 10px;
  right: 16px;
  top: 53px;
}

.color-bar .hue-vertical>div>div {
  width: 14px !important;
  border-radius: 1px !important;
  height: 4px !important;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 0px 2px;
  transform: translateX(-2px);
  background-color: transparent !important;
  border: 1px solid #ffffff !important;
  margin-top: -5px !important;
}

.cs-iframe {
  width: 430px;
  height: 485px;
  position: absolute;
  top: 60px;
  left: 500px;
  /* border: 1px solid #f8f8f8; */
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.18);
  border-radius: 10px;
  padding: 24px 0px 0px;
  margin: 0px 0px 80px;
}

.cs-iframe:before {
  content: "";
  height: 24px;
  border-radius: 10px 10px 0px 0px;
  background-image: linear-gradient(to bottom, #e8e8e8, #f6f6f7);
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
}

.cs-iframe iframe {
  width: 100%;
  height: 100%;
}

.cs-iframe:after {
  content: "...";
  position: absolute;
  left: 9px;
  top: -4px;
  color: #cbcbcb;
  font-size: 50px;
  line-height: 0px;
  letter-spacing: -3px;
}

.cs-iframe>img {
  width: 100%;
  height: 100%;
  border-radius: 0px 0px 10px 10px;
}

/* @media screen and (max-width: 1366px) {
  .cs-iframe {
    left: inherit;
    right: 20px;
  }

  .preview-chat-panel {
    left: inherit;
    right: 35px;
  }

  .prechat-form-temp {
    left: inherit;
    right: 20px;
  }

  .cs-widget-config.cs-business-hrs>div.cs-dropdwn {
    width: 260px !important;
  }

  .cs-widget-config.cs-business-hrs>span {
    left: 710px;
  }

  .cs-form-col>div.cs-switch-btn {
    left: 320px;
  }
} */

.cs-project-wrp {
  position: absolute;
  background-color: var(--white-color);
  top: 1px;
}

.cs-porject-clip {
  position: relative;
  display: inline-block;
  height: 25px;
  margin: 0px 5px 5px 0px;
}

.cs-porject-clip i {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  left: 0px;
  background-color: #2dccd2;
  border-radius: 20px;
  height: 25px;
  opacity: 0.1;
}

.cs-porject-clip cite {
  position: absolute;
  left: 10px;
  top: 50%;
  /* color: #2dccd2; */
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: #2dccd2;
  margin: -3px 0px 0px;
}

.cs-porject-clip span {
  position: relative;
  font-size: 14px;
  color: var(--txt-black-color);
  line-height: 16px;
  /* display: inline-block; */
  margin: 4px 12px 5px 24px;
  float: left;
  min-width: auto;
  max-width: 107px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cs-porject-more {
  color: #181818;
  font-size: 14px;
  border-radius: 20px;
  background-color: rgba(129, 129, 129, 0.1);
  padding: 5px 10px 5px;
  line-height: 16px;
  height: 25px;
  position: relative;
  float: right;
  margin: 0px;
  cursor: pointer;
}

.cs-project-wrp-p{
  cursor: pointer;
  font-size: 16px;
  line-height: 32px;
}

.cs-porject-tooltip {
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(10, 31, 68, 0.1),
    0 0 1px 0 rgba(10, 31, 68, 0.08);
  background-color: #ffffff;
  padding: 20px 20px 8px 20px;
  position: absolute;
  right: 128px;
  bottom: 62px;
  display: none;
}

.cs-porject-more.open:before,
.cs-porject-more.open:after {
  content: "";
  width: 0;
  height: 0;
  border: 8px solid;
  position: absolute;
  margin-left: -8px;
  z-index: 1;
  left: 50%;
}

.cs-porject-more.open:before {
  border-color: #ebeced transparent transparent;
  bottom: 20px;
}

.cs-porject-more.open:after {
  border-color: #ffffff transparent transparent;
  bottom: 21px;
}

.cs-porject-tooltip>div {
  overflow: auto;
  min-width: auto;
  max-width: 350px;
  min-height: auto;
  max-height: 390px;
}

.cs-porject-tooltip .cs-porject-clip {
  display: inline-block;
  margin: 0px 5px 12px 0px;
}

/* firefox hack css code only *************************************/
@-moz-document url-prefix() {
  .color-picker-wrp>div:nth-child(3) input {
    padding: 3px 0px 0px 37px;
  }

  .color-picker-wrp>div:nth-child(3)>div:nth-child(3) {
    padding: 6px 0px;
  }

  /* .cs-porject-clip cite{
        top:1px;
    }    */
  /* .cs-porject-clip span{
        margin:6px 13px 5px 25px;
    } */
}

.bottom-space {
  margin-bottom: 80px !important;
}

/*cs-accordian-menu code start here ********************/
.cs-accordian-menu {
  margin: 8px 0px 0px;
  overflow: auto;
  height: calc(100vh - 240px);
}

.cs-accordian-menu>li>div {
  position: relative;
  padding: 19px 20px 19px 72px;
  cursor: pointer;
  font-size: 14px;
  color: var(--txt-black-color);
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
}

.cs-accordian-menu>li>div:hover,
.cs-accordian-menu>li.active>div,
.cs-accordian-menu>li>ul>li:hover,
.cs-accordian-menu>li>ul>li.active {
  background-color: var(--chat-listhover-bgcolor);
}

.cs-accordian-menu>li.active>div:after,
.cs-accordian-menu>li>ul>li.active:after {
  content: "";
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 2px;
  background-color: var(--PaleBlueAW);
}

.cs-accordian-menu>li>div>figure {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  line-height: 44px;
  position: absolute;
  left: 17px;
  top: 50%;
  margin: -22px 0px 0px 0px;
}

.cs-accordian-menu>li>div>figure>em {
  width: 36px;
  height: 36px;
  border-radius: 100%;
}

.cs-accordian-menu>li>div>figure svg {
  width: 44px;
  height: 44px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.cs-accordian-menu>li>div>figure svg:nth-child(2) {
  opacity: 0;
  transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
}

.cs-accordian-menu>li.active>div>figure svg:nth-child(2),
.cs-accordian-menu>li>div:hover>figure svg:nth-child(2) {
  opacity: 1;
}

.cs-accordian-menu>li>div>a {
  text-decoration: none;
  color: var(--txt-black-color);
  position: relative;
}

.cs-accordian-menu>li>div>a>em {
  width: 160px;
}

.cs-accordian-menu>li>div>a.arrow:after {
  content: "";
  padding: 4px 5px;
  background: url("../images/arrow-icon.svg") center center no-repeat;
  z-index: 1;
  cursor: pointer;
  margin: -3px 0px 0px 0px;
  background-size: 10px;
  position: absolute;
  right: -16px;
  top: 50%;
}

.cs-accordian-menu>li>div>a.arrow:after {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.cs-accordian-menu>li.open>div>a.arrow:after {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.cs-accordian-menu>li>div>span,
.cs-accordian-menu>li>ul>li>span {
  opacity: 0.5;
  position: absolute;
  right: 0px;
  top: 50%;
  margin: -7px 24px 0px 0px;
}

.cs-accordian-menu>li>ul {
  display: none;
}

.cs-accordian-menu>li.open>ul {
  display: block;
}

.cs-accordian-menu>li>ul>li {
  display: block;
  cursor: pointer;
  font-size: 14px;
  padding: 15px 20px 15px 72px;
  background-color: var(--white-color);
  text-decoration: none;
  color: var(--txt-black-color);
  position: relative;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
}

.cs-accordian-menu>li>ul>li a {
  text-decoration: none;
  color: var(--txt-black-color);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  padding: 0px 60px 0px 0px;
  line-height: normal;
}

.cs-accordian-menu>li>ul>li.segment {
  margin: 20px 0px 0px;
}

.cs-accordian-menu>li>ul>li.segment:before {
  content: "";
  width: 190px;
  height: 1px;
  background-color: #efefef;
  position: absolute;
  margin: -25px 0px 0px;
}

.run-btn {
  padding: 0px 14px 0px 33px !important;
  position: absolute;
  right: 80px;
  top: 30px;
  font-weight: bold !important;
  font-size: 13px !important;
}

.run-btn>svg {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  margin: -5px 0px 0px;
  left: 11px;
}

.cs-conversation.cs-people-wrp.open .people-grid-wrp>div {
  height: calc(100vh - 183px);
}

.people-grid-wrp,
.campaigns-grid-wrp {
  padding: 0px 0px 0px 24px;
  position: relative;
  z-index: 0;
}

.campaigns-grid-wrp:after,.people-grid-wrp::after {
  content: "";
  position: absolute;
  right: 0px;
  left: 0px;
  height: 15px;
  background-image: linear-gradient(to bottom,
      rgba(255, 255, 255, 0),
      #ffffff 82%);
  bottom: 0px;
}

.people-grid-wrp.scroll:before,
.campaigns-grid-wrp.scroll:before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 24px;
  background-image: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0));
  z-index: 2;
  pointer-events: none;
  width: 20px;
  top: 0px;
}

.people-grid-wrp.scroll:after,
.campaigns-grid-wrp.scroll:after {
  content: "";
  position: absolute;
  bottom: 0px;
  right: 24px;
  background-image: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));
  z-index: 2;
  pointer-events: none;
  width: 20px;
  top: 0px;
}

.people-grid-wrp>div,
.campaigns-grid-wrp>div {
  overflow-x: auto;
  width: 100%;
  white-space: nowrap;
}

.people-grid-head,
.campaigns-grid-head {
  border: 1px solid #e6eaee;
  border-width: 0px 0px 1px;
  height: 51px;
  margin-bottom: 10px;
  position: sticky;
  z-index: 1;
  top: 0;
}

/* .campaigns-grid-head {
  margin-bottom: 10px;
} */

.people-grid-head>label,
.campaigns-grid-head>label {
  /* width: 150px; */
  /* display: inline-block; */
  padding: 20px 16px 17px 0px;
  text-transform: capitalize;
  color: #8f99ad;
  font-size: 14px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  float: left;
}

.people-grid-list,
.campaigns-grid-list {
  cursor: pointer;
  height: 50px;
  position: relative;
}

.people-grid-list>div,
.campaigns-grid-list>div {
  font-size: 15px;
  color: #181818;
  padding: 16px 16px 16px 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* display: inline-block; */
  float: left;
  position: relative;
}

.campaigns-grid-list>div {
  width: calc( (100% - 300px) / 6);
}

.campaigns-grid-list>div:not(:nth-child(-n+2)) {
  cursor: default;
} 

.people-grid-list>div {
  width: 150px; 
}

.people-grid-list>div>em,
.campaigns-grid-list>div>em {
  width: 90px;
}

.campaigns-grid-list>aside {
  margin: 9px 0px 0px;
  right: 20px;
  display: none;
}

.campaigns-grid-list:hover>aside,
.campaigns-grid-list>aside.open {
  display: block;
  box-shadow: none;
  z-index: inherit;
}

.campaigns-grid-list:hover>aside>ul,
.campaigns-grid-list>aside.open>ul {
  top: 10px;
  left: 13px;
  z-index: 1;
}

.campaigns-grid-list:hover>aside a>svg,
.campaigns-grid-list>aside.open a>svg {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.people-grid-list>div.txt-center {
  padding: 16px 0px 16px 32px;
}

.people-grid-list>div>figure,
.campaigns-grid-list>div>figure {
  width: 28px;
  height: 28px;
  border-radius: 100%;
  background-color: #5183b0;
  color: #ffffff;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  position: absolute;
  left: 0px;
  top: 50%;
  margin: -14px 0px 0px;
  line-height: 28px;
}

.people-grid-list>div>figure>em,
.campaigns-grid-list>div>figure>em {
  width: 28px;
  height: 28px;
  border-radius: 100%;
}

.people-grid-list>div>figure img,
.campaigns-grid-list>div>figure img {
  border-radius: 100%;
  width: 28px;
  height: 28px;
  position: absolute;
  left: 0px;
  top: 0px;
  object-fit: cover;
}

.people-grid-list>div:first-child>cite.available {
  width: 11px;
  height: 11px;
  border-radius: 100%;
  border: 2px solid #ffffff;
  z-index: 1;
  position: absolute;
  bottom: 6px;
  left: 19px;
  background-color: #35cf62;
}

.people-grid-list>div:first-child {
  padding: 16px 16px 16px 48px;
  width: 260px;
}

.campaigns-grid-list>div:first-child {
  padding: 16px 16px 16px 48px;
  /* width: 400px; */
  width: 300px;
}

.cs-conversation>header figure.campigaing-icon {
  background-image: none;
}

.people-grid-list>div:first-child>span,
.campaigns-grid-list>div:first-child>span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.people-grid-list>div:first-child>em,
.campaigns-grid-list>div:first-child>em {
  width: 160px;
}

.people-grid-head>label:first-child {
  width: 260px;
  padding: 20px 0px 17px 48px;
}

.people-grid-head>label {
  width: 150px;
}


.campigaing-icon {
  background-color: #202a3d !important;
  padding: 12px;
}

.campigaing-icon svg {
  width: 15px;
  height: 15px;
}

.campaigns-grid-list-wrp,.people-grid-list-wrp {
  height: calc(100vh - 222px);
  overflow: auto;
  position: relative;
}

.campaigns-grid-list>div>figure svg {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 12px;
  height: 12px;
  margin: -6px 0px 0px -6px;
}

.campaigns-grid-list>div>figure svg.chat-campaigns-icon {
  margin: -5px 0px 0px -6px;
}

.campaigns-grid-head>label:first-child {
  width: 300px;
  padding: 20px 0px 17px 48px;
}

.campaigns-grid-head>label {
  width: calc((100% - 300px) / 6)
}


.campaigns-grid-list>div>span {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: normal;
}

.campaigns-grid-list>div:not(:nth-child(-n+2))>span {
  width: 55px;
}

.cs-conversation.cs-people-wrp.open header {
  height: auto;
}

.cs-conversation.cs-people-wrp.open header .people-filter {
  display: block;
  margin: 0px 0px 0px -52px;
}

.cs-people-header {
  display: inline-block;
}

/* .cs-people-header > figure{
    background:none !important;
} */

.cs-people-header>figure>em {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.people-filter,
.cs-conversation.cs-people-wrp.open header>p a,
.cs-conversation.cs-people-wrp.open header>div.cs-people-header p a {
  display: none;
}

.people-filter>div:first-child {
  margin: 36px 0px 0px;
}

.people-filter>div>label {
  font-size: 13px;
  font-weight: 600;
  color: #8f99ad;
  text-transform: uppercase;
  /*float: left;*/
  padding: 3px 10px 0px 0px;
  display: inline-block;
}

.people-filter>div>p {
  font-size: 18px;
  color: #000000;
  padding: 3px 0px;
  display: inline-block;
  line-height: 30px;
}

.people-filter div.filter-dwn,
.people-filter>div>div.checkbox-dwn-wrp div.filter-checkbox-dwn,
.people-filter>div>div.checkbox-dwn-wrp {
  display: inline-block;
}

.people-filter>div>div.checkbox-dwn-wrp div.filter-checkbox-dwn,
.people-filter>div>div.checkbox-dwn-wrp div.filter-dwn {
  height: 30px;
  margin: 0px 0px 10px 0px;
}

.people-filter>div>div.checkbox-dwn-wrp {
  margin: -15px 60px 0px 52px;
  display: block;
}

.filter-dwn>a {
  font-size: 14px;
  font-weight: bold;
  color: #6699ff;
  text-decoration: none;
}

.filter-dwn>div>div {
  padding: 20px 16px 10px;
  position: relative;
}

.filter-dwn>div>div>input {
  font-size: 12px;
  line-height: 1.67;
  border-radius: 16px;
  border: 1px solid #e6e9f0;
  padding: 6px 34px 6px 14px;
  width: 100%;
}

.filter-dwn>div>div>svg {
  position: absolute;
  right: 27px;
  top: 30px;
  cursor: pointer;
  width: 13px;
  height: 13px;
}

.filter-dwn>div>p {
  display: none;
}

.filter-dwn>div>p.no-found {
  font-size: 12px;
  line-height: 1.67;
  color: #181818;
  padding: 0px 16px;
  text-align: center;
  opacity: 0.5;
  display: block;
}

.filter-dwn>div>ul li a,
.filter-checkbox-dwn>div>ul>li>a {
  display: block;
  font-size: 14px;
  color: #181818;
  text-decoration: none;
  padding: 10px 24px;
  position: relative;
}

.filter-dwn>div>ul>li>a:hover {
  background-color: #6699ff;
  color: #ffffff;
}

.filter-checkbox-dwn>div>ul>li>a {
  padding: 10px 24px 10px 54px;
}

.filter-checkbox-dwn>div>ul>li {
  position: relative;
}

.filter-checkbox-dwn>div>ul li a:hover {
  background-color: #f0f2f5;
}

.filter-checkbox-dwn>div>ul li.active a:hover {
  background-color: #ffffff;
}

.filter-checkbox-dwn>div>ul li a>cite {
  position: absolute;
  width: 16px;
  height: 16px;
  left: 24px;
  top: 50%;
  margin: -8px 0px 0px;
  border-radius: 100%;
  background-color: var(--white-color);
  border: 1px solid #d3d9e5;
}

.filter-checkbox-dwn>div>ul li a>cite>svg {
  width: 8px;
  height: 9px;
  position: absolute;
  left: 3px;
  top: 3px;
  fill: var(--white-color);
}

.filter-checkbox-dwn>div>ul li.active>a cite {
  background-color: #6699ff;
  border-color: #6699ff;
}

.filter-checkbox-dwn>div>ul li.active>a {
  color: #6699ff;
}

.filter-checkbox-dwn>div>ul li.number-field input {
  width: 100px;
  display: none;
  margin-right: 0px;
}

.filter-checkbox-dwn>div>ul li.number-field span {
  font-size: 12px;
  opacity: 0.5;
  margin: -33px 0px 0px 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 76px;
  display: none;
}

.filter-checkbox-dwn>div>ul li.active.number-field input,
.filter-checkbox-dwn>div>ul li.active.number-field span {
  display: inline-block !important;
}

.filter-checkbox-dwn>div>ul li>input,
.filter-checkbox-dwn>div>ul li>div>input {
  font-size: 16px;
  padding: 0px 0px 8px;
  margin: 0px 24px 10px 54px;
  border: 1px solid #6699ff;
  border-width: 0px 0px 1px;
  width: 176px;
  display: none;
}

.filter-checkbox-dwn>div>ul li>div.date-field {
  position: relative;
  height: 27px;
  display: none;
}

.filter-checkbox-dwn>div>ul li>div>div.flatpickr-calendar {
  left: 262px !important;
  top: -35px !important;
}

.filter-checkbox-dwn>div>ul li>div.left-flatpickr>div.flatpickr-calendar {
  left: 302px !important;
}

.filter-checkbox-dwn>div>ul li>span,
.filter-checkbox-dwn>div>ul li>div>div.flatpickr-calendar:before,
.filter-checkbox-dwn>div>ul li>div>div.flatpickr-calendar:after {
  display: none;
}

.filter-checkbox-dwn>div>ul li.active.tag-field .cs-dropdwn-checkbox-sub {
  display: block;
  left: 262px;
}

.filter-checkbox-dwn>div>ul li.active.tag-field .cs-dropdwn-checkbox-sub>ul {
  max-height: 160px !important;
}

.filter-checkbox-dwn>div>ul li.active.tag-field .cs-dropdwn-checkbox-sub li>a {
  padding: 10px 20px 10px 20px;
}

.filter-checkbox-dwn>div>ul li.active.tag-field .cs-dropdwn-checkbox-sub li>a:hover {
  background-color: #f0f2f5;
}

.filter-checkbox-dwn>div>ul li.active>input,
.filter-checkbox-dwn>div>ul li.active>div>input,
.filter-dwn.open>div,
.filter-checkbox-dwn.open>div,
.filter-checkbox-dwn>div>ul li.active>div {
  display: block;
}

.filter-checkbox-dwn>div>ul li>input.cs-error-field {
  border-color: #df0d29;
}

.filter-checkbox-dwn.open>label,
.filter-checkbox-dwn>label:hover,
{
  border-color: #6699ff !important;
}

.filter-checkbox-dwn>div>label {
  font-size: 12px;
  line-height: 1.67;
  color: #181818;
  text-transform: uppercase;
  margin: 20px 24px 0px 24px;
  display: block;
}

.filter-dwn>div,
.filter-checkbox-dwn>div {
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0 3px 4px 0 rgba(10, 31, 68, 0.1),
    0 0 1px 0 rgba(10, 31, 68, 0.08);
  background-color: var(--white-color);
  z-index: 2;
  position: absolute;
  margin: 15px 0px 10px 0px;
  border: 1px solid var(--BlueAW);
  border-width: 1px 0px 0px;
  display: none;
}

.filter-checkbox-dwn>div>button {
  margin: 20px;
}

.cs-announcment-preview.cs-campaign-preview.quick-preview:after {
  content: "";
  position: absolute;
  width: 40px;
  height: 10px;
  top: 12px;
  left: 16px;
  background: white url("https://storage.googleapis.com/livesupport/webapp/images/windows-close-icon.svg") -96px -102px no-repeat;
}

.filter-dwn>div ul {
  width: 260px;
  max-height: 290px;
  min-height: auto;
  margin: 0px 0px 8px;
  overflow: auto;
}

.filter-checkbox-dwn>div>ul {
  width: 260px;
  min-height: auto;
  margin: 8px 0px;
}

.filter-checkbox-dwn>label {
  border-radius: 4px;
  font-weight: 600;
  border: 1px solid #dbe0e7;
  font-size: 13px;
  padding: 6px 10px;
  margin: 0px 10px 0px 0px;
  color: #8f99ad;
  position: relative;
}

.filter-checkbox-dwn>label span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: auto;
  max-width: 150px;
  display: inline-block;
  margin: 0px 0px -2px 5px;
  color: #181818;
}

.filter-checkbox-dwn>label>i,.message-tags-remove{
  font-size: 16px;
  color: #6699ff;
  font-style: normal;
  font-weight: 700;
  position: absolute;
  width: 22px;
  height: 26px;
  right: 1px;
  top: 50%;
  margin: -13px 0px 0px 0px;
  text-align: center;
  background-color: #ffffff;
  line-height: 25px;
  cursor: pointer;
  border-radius: 0px 3px 3px 0px;
  display: none;
}

.filter-checkbox-dwn>label>i:before {
  content: "";
  background-image: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));
  position: absolute;
  left: -10px;
  width: 10px;
  height: 26px;
}

.filter-checkbox-dwn>label:hover>i {
  display: block;
}

.filter-checkbox-dwn.open>label:hover>i,
.filter-checkbox-dwn.open>label>i {
  display: none !important;
}

.fr {
  float: right;
}

.inline-block {
  display: inline-block;
}

@-moz-document url-prefix() {
  .filter-checkbox-dwn>label>i {
    height: 23px;
    margin: -12px 0px 0px 0px;
  }

  .filter-checkbox-dwn>label>i::before {
    height: 24px;
  }

  .filter-checkbox-dwn>label span {
    margin: 0px 0px -3px 5px;
  }
}

/* popup new code start here ************/
.cs-popup-model.popup-new header h2 {
  font-size: 16px;
  line-height: 2.25;
  color: #181818;
  font-weight: 600;
}

.cs-popup-model.popup-new header cite {
  background: transparent;
  width: 14px;
  height: 14px;
  font-size: 25px;
  line-height: 12px;
  color: #acb7cc;
  top: 12px;
}

.cs-popup-model.popup-new header {
  margin: 20px 0px 20px;
  border-radius: 8px 8px 0px 0px;
  background: transparent;
  padding: 0px;
}

.cs-popup-model.popup-new header:before {
  content: "";
  position: absolute;
  right: 0px;
  left: 0px;
  height: 18px;
  background-image: linear-gradient( to top, rgba(255, 255, 255, 0), #ffffff 82% );
  bottom: -88px;
  z-index: 1;
  display: none;
}

.cs-popup-model.popup-new header p{
  font-size: 16px;
  line-height: 1.44;
  color: #727c8f;
  margin: 30px 30px;
  display: block;
}

.cs-popup-model.popup-new {
  border-radius: 8px;
}

.cs-popup-model.popup-new header h2 svg {
  width: 36px;
  height: 36px;
  float: left;
  margin: 0px 14px 0px 0px;
}

.popup-new-content {
  padding: 10px 30px 30px;
}

.popup-new-content .wrapper {
  position: relative;
}

.cs-popup-model.popup-new.htmlCode-popup .popupBtnHolder {
  border: none;
  background: white;
  padding: 25px 30px;
}

.cs-popup-model.popup-new.htmlCode-popup .cs-btn {
  padding: 8px 41px 9px;
}

.cs-popup-model.popup-new.htmlCode-popup header {
  margin: 20px 0px 0;
}

.cs-popup-model.popup-new.htmlCode-popup .popup-new-content {
  padding: 15px 30px 0px;
}

.popup-new-content .edit-html-code {
    width: 90%;
    height: 88%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;  
    display: flex;
    z-index: 1;
    outline: solid 15px #f6f6f6;
    font-size: 14px;
}

/* .popup-new-content .edit-html-code::after {
  content: 'You cannot return to rich text editor once you switch to HTML.';
  position: absolute;
  bottom: -83px;
  font-size: 12px;
  color: #363636;
  left: -25px;
  width: 230px;
  line-height: 16px;
  background: url("data:image/svg+xml,%3Csvg width='13' height='14' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.5 0.828125C3.02148 0.828125 0.203125 3.67188 0.203125 7.125C0.203125 10.6035 3.02148 13.4219 6.5 13.4219C9.95312 13.4219 12.7969 10.6035 12.7969 7.125C12.7969 3.67188 9.95312 0.828125 6.5 0.828125ZM6.5 3.62109C7.08398 3.62109 7.56641 4.10352 7.56641 4.6875C7.56641 5.29688 7.08398 5.75391 6.5 5.75391C5.89062 5.75391 5.43359 5.29688 5.43359 4.6875C5.43359 4.10352 5.89062 3.62109 6.5 3.62109ZM7.92188 10.0703C7.92188 10.248 7.76953 10.375 7.61719 10.375H5.38281C5.20508 10.375 5.07812 10.248 5.07812 10.0703V9.46094C5.07812 9.30859 5.20508 9.15625 5.38281 9.15625H5.6875V7.53125H5.38281C5.20508 7.53125 5.07812 7.4043 5.07812 7.22656V6.61719C5.07812 6.46484 5.20508 6.3125 5.38281 6.3125H7.00781C7.16016 6.3125 7.3125 6.46484 7.3125 6.61719V9.15625H7.61719C7.76953 9.15625 7.92188 9.30859 7.92188 9.46094V10.0703Z' fill='%23BDBDBD'/%3E%3C/svg%3E%0A") no-repeat left top 2px;
  padding-left: 20px;
} */

.popup-new-content .edit-html-code,.cs-normalCodeEditor .edit-hover-div {
  position: absolute;
  border: 2px dashed;
  border-radius: 10px;
  cursor: pointer;
  border-color: var(--BlueAW);
  color: var(--BlueAW);
  justify-content: center;
  align-items: center;
}

.cs-normalCodeEditor {
  position: relative;
}

.cs-normalCodeEditor .edit-hover-div {
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(255,255,255,0.9);
  display: none;
}

.cs-normalCodeEditor:hover > .edit-hover-div {
  display: flex;
}


.popup-new-content h3 {
  margin: 0px 0px 30px;
  font-size: 16px;
  line-height: 1.5;
  color: #727c8f;
}

.popup-new-content>div .cs-radio-form {
  width: 430px;
}

.popup-new-content>div .cs-radio-form.disbled,
.popup-new-content>div .cs-radio-form.disbled label {
  opacity: 0.4;
  cursor: not-allowed;
}

.cs-popup-model.popup-new .whiteblackList{ 
  padding: 0px 30px;
  position: relative;
}
.cs-popup-model.popup-new .whiteblackList > ul{ 
  margin: 0px 0px 32px;
  overflow: auto;
  min-height: 280px;
  max-height: 236px;
}
.cs-popup-model.popup-new .whiteblackList > ul > li{
  padding: 16px 0px;
  border: 1px solid #f0f2f5;
  border-width: 0px 0px 1px;
  margin: 10px 0px 0px;
  position: relative;
}

.cs-popup-model.popup-new .whiteblackList > ul > li > span{
  font-size: 14px;
  line-height: 1.43;
  color: #181818;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px 100px 0px 0px;
  width: 500px;
  display: block;
}

.cs-popup-model.popup-new .whiteblackList a.add-link{
  display: inline-block;
  font-size: 14px;
  text-decoration: none;
  color: var(--BlueAW);
  font-weight: 600;
  position: absolute;
  bottom: -87px;
  z-index:1;
}

.cs-popup-model.popup-new .whiteblackList a.add-link svg{ 
  fill: var(--BlueAW);
  width: 15px;
  height: 15px;
  display: inline-block;
  margin: 0px 3px -2px 0px;
}

.cs-popup-model.popup-new .whiteblackList > ul > li.cs-input-edit{
  border-color: #ffffff;
  padding: 16px 0px 0px;
}

.cs-popup-model.popup-new .whiteblackList > ul > li.cs-input-edit div.cs-input-form input[type="text"]{ 
  font-size:14px; 
  line-height: 1.43;
  color: #181818;
  font-family: "Lato", sans-serif;
}

.cs-popup-model.popup-new .whiteblackList > ul > li div.cs-input-form{ margin: 0px 0px 12px 0px; }

.cs-popup-model.popup-new .whiteblackList > ul > li div.cs-input-form input[type="text"]{
  padding: 0px 0px 12px;
  width: 100%;
}

.cs-popup-model.popup-new .whiteblackList > ul > li div.cs-input-form span{
  line-height: 1.25;
  font-weight: normal;
  padding: 12px 0px 0px 0px;
  color: #8495b1;
  font-size: 12px;
  display: block;
}

.cs-popup-model.popup-new .whiteblackList > ul > li div.cs-input-form.cs-error-form span{
  padding: 12px 0px 0px 20px;
  color: #df0d29;
}

.cs-popup-model.popup-new .whiteblackList:before,
.cs-popup-model.popup-new .whiteblackList:after{
  content: "";
  position: absolute;
  right: 0px;
  left: 0px;
  height: 20px;
  z-index: 1;
  pointer-events: none;
}

.cs-popup-model.popup-new .whiteblackList:before{
  content: "";
  top: 0px;
  background-image: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0));
}

.cs-popup-model.popup-new .whiteblackList:after{
  content: "";
  bottom:-8px;
  background-image: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0));
}

.cs-popup-model.popup-new.whitelist-popup{ 
  min-width:490px; 
  max-width:490px;
}

/* campaign-popup code start here  ***********/

.preview-temp {
  width: 400px;
  margin: -25px -30px 0px;
}

.cs-campaign-preview {
  position: fixed;
  width: 350px;
  /* height: 600px; */
  background-color: #ffffff;
  /* top: 65px; */
  bottom: 100px;
  right: 50px;
  box-shadow: 0 6px 18px 0 rgba(219, 227, 233, 0.5);
  border-radius: 8px;
  margin: 0px 0px 50px;
  border: 1px solid #f2f4f7;
  z-index: 2;
}

.cs-campaign-preview.quick-preview:before {
  content: "Quick Preview";
  text-align: center;
  position: absolute;
  z-index: 1;
  left: 0px;
  right: 0px;
  padding: 8px 0px;
  border-radius: 8px 8px 0px 0px;
  background-color: #8f99ad;
  top: -32px;
  font-family: "Lato", sans-serif !important;
  color: #ffffff;
  font-size: 16px;
}

.cs-campaign-preview.cs-email-preview.quick-preview:before {
  padding: 8px 0px 15px;
}

.cs-campaign-preview>div {
  background-color: #ffffff;
  border-radius: 8px;
}

.cs-campaign-preview.quick-preview>div {
  background-color: #8f99ad;
  border-radius: 0px 0px 8px 8px;
}

.cs-campaign-preview header {
  position: relative;
  height: 110px;
  color: #000000;
  -webkit-text-fill-color: #000000;
  border: 1px solid #eef0f1;
  border-width: 0px 0px 1px 0px;
  background: #ffffff url(https://storage.googleapis.com/livesupport/chat/images/widget-tree.svg) 96% bottom no-repeat;
  border-radius: 6px 6px 0px 0px;
}

.cs-campaign-preview header h2 {
  font-size: 26px;
  padding: 0;
  font-family: "Lato", sans-serif !important;
  line-height: 1.35;
  margin: 24px 0 15px 20px !important;
  display: inline-block;
  font-weight: 700;
  color: #181818;
  -webkit-text-fill-color: #181818;
  position: relative;
  border-radius: 8px 8px 0px 0px;
}

.cs-campaign-preview header h2 span {
  font-size: 38px;
  line-height: 0px;
  color: #69f;
  -webkit-text-fill-color: #69f;
  margin: 0px 0px 0px 1px;
  display: inline-block;
}

.cs-campaign-preview header code {
  position: absolute;
  cursor: pointer;
  top: 22px;
  right: 20px;
  font-size: 26px;
  line-height: 16px;
  color: #a1a1a1;
  -webkit-text-fill-color: #a1a1a1;
  font-family: "Lato", sans-serif !important;
}

.cs-campaign-preview footer {
  height: 90px;
  border-radius: 0px 0px 6px 6px;
  border: 1px solid #eef0f1;
  border-width: 1px 0px 0px;
  position: relative;
  background-color: #ffffff;
}

.cs-campaign-preview footer input {
  width: 100%;
  border: 0;
  padding: 20px;
  font-size: 16px;
  font-family: "Lato", sans-serif !important;
  background-color: #ffffff;
  cursor: not-allowed;
}

.cs-campaign-preview footer i.attachment-icon-btn {
  position: absolute;
  left: 20px;
  margin: 0px 0 0;
  cursor: not-allowed;
  background: url(https://storage.googleapis.com/livesupport/chat/images/chat-support-attachment-icon-new.svg) center no-repeat;
  width: 11px;
  height: 18px;
  background-size: 9px !important;
  bottom: 15px;
}

.cs-campaign-preview footer i.send-icon-btn {
  position: absolute;
  right: 10px;
  width: 30px;
  height: 30px;
  margin: 0px 10px -4px 0px;
  border-radius: 100%;
  cursor: not-allowed;
  background: #6499fe url(https://storage.googleapis.com/livesupport/chat/images/send-btn.svg) center 9px no-repeat;
  background-size: 16px !important;
  bottom: 15px;
}

.cs-campaign-preview div.widget-camp-cont {
  /* height:390px; */
  overflow: auto;
  background-color: #ffffff;
  height: calc(100vh - 455px);
}

.widget-camp-cont > section {
  height: 100%;
}


.cs-campaign-preview.cs-email-preview .word-break {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.cs-campaign-preview.cs-email-preview .word-break > div {
  padding-bottom: 20px;
}

.cs-campaign-preview.cs-email-preview iframe {
  width: 100%;
  flex-grow: 1;
}

.cs-announcment-preview iframe {
  width: 100%;
  height: 100%;
}

.preview-popup iframe {
  width: 100%;
  height: 500px;
}

.cs-campaign-preview div.widget-camp-cont>div {
  background-color: #f6f6f6;
  border-radius: 20px 20px 20px 0px;
  padding: 16px 16px 0px;
  min-width: 256px;
  max-width: 256px;
  margin: 20px 0px 20px 70px;
  position: relative;
  display: inline-block;
  word-break: break-word;
  font-size: 14px;
  line-height: 1.43;
  color: #181818;
}

.cs-campaign-preview div.widget-camp-cont>div:before {
  content: "";
  width: 15px;
  height: 12px;
  background-color: #f5f6f7;
  position: absolute;
  left: -7px;
  bottom: 0;
}

.cs-campaign-preview div.widget-camp-cont>div:after {
  content: "";
  width: 14px;
  height: 17px;
  background-color: #fff;
  position: absolute;
  left: -14px;
  bottom: -2px;
  border-radius: 0 0 50px 0;
}

.cs-campaign-preview div.widget-camp-cont>div>figure {
  width: 36px;
  height: 36px;
  position: absolute;
  background-color: #8f99ad;
  bottom: 0px;
  left: -50px;
  border-radius: 100%;
  background-image: url("../images/user-white-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 12px;
}

.cs-campaign-preview div.widget-camp-cont>div>figure img {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  object-fit: cover;
}

.cs-campaign-preview div.widget-camp-cont>div p {
  font-size: 14px;
  line-height: 20px;
  color: #181818;
  margin: 0px 0px 10px;
}

.cs-campaign-preview div.widget-camp-cont>div ul,
.cs-campaign-preview div.widget-camp-cont>div ol,
.cs-campaign-preview div.widget-camp-cont>section>div ul,
.cs-campaign-preview div.widget-camp-cont>section>div ol {
  list-style: inherit;
  font-size: 14px;
  line-height: 1.43;
  color: #181818;
  margin: 10px 0px 12px 20px;
}

.cs-campaign-preview div.widget-camp-cont>div ol,
.cs-campaign-preview div.widget-camp-cont>section>div ol {
  list-style: decimal;
}

.cs-campaign-preview.announcment-preview div.widget-camp-cont>section>div ul,
.cs-campaign-preview.announcment-preview div.widget-camp-cont>section>div ol {
  font-size: 16px;
  line-height: 1.5;
}

.cs-campaign-preview div.widget-camp-cont>div p em,
.cs-email-preview div.widget-camp-cont>section>div p em,
.cs-email-preview div.widget-camp-cont>section>div>div h2 em,
.cs-email-preview div.widget-camp-cont>section>div>div h1 em,
.cs-campaign-preview div.widget-camp-cont>div ul li em,
.cs-campaign-preview div.widget-camp-cont>div ol li em,
.cs-campaign-preview div.widget-camp-cont>section>div ul li em,
.cs-campaign-preview div.widget-camp-cont>section>div ol li em {
  font-style: italic !important;
}

.cs-campaign-preview div.widget-camp-cont>div figure>img,
.cs-email-preview div.widget-camp-cont>section>div>div figure>img {
  min-width: auto;
  max-width: 100%;
  object-fit: cover;
  margin: 0px 0px 10px;
  height: 100%;
  width: 100%;
}

.cs-campaign-preview div.widget-camp-cont>div h1,
.cs-email-preview div.widget-camp-cont>section>div>div h1 {
  font-size: 18px;
  font-weight: bold;
  line-height: 25px;
  margin: 0px 0px 12px;
}

.cs-campaign-preview div.widget-camp-cont>div h2,
.cs-email-preview div.widget-camp-cont>section>div>div h2 {
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
  margin: 0px 0px 12px;
}

.cs-email-preview div.widget-camp-cont>section>div>div h1,
.cs-email-preview div.widget-camp-cont>section>div>div h2 {
  margin: 10px 0px 0px !important;
}

.cs-campaign-preview div.widget-camp-cont>div a {
  text-decoration: underline;
}

.cs-campaign-preview div.widget-camp-cont>div p img {
  min-width: auto;
  max-width: 100%;
}

/* .cs-campaign-preview div.widget-camp-cont>div p span {
  font-size: 12px;
  line-height: 1.67;
  color: #181818;
  opacity: 0.5;
  display: block;
  margin: 10px 0px 0px;
} */

/* .cs-campaign-preview div.widget-camp-cont > div p:last-child {
  margin: 0px;
} */

.cs-campaign-preview.cs-email-preview.desktop div.widget-camp-cont:before {
  content: "";
  width: 154px;
  height: 315px;
  left: 20px;
  z-index: 0;
  float: left;
  margin: 40px 0px 0px 0px;
  background: url("https://storage.googleapis.com/livesupport/webapp/images/email-temp-placeholder.svg") center no-repeat;
}

.cs-campaign-preview.cs-email-preview.desktop div.widget-camp-cont>section {
  margin: 0px 0px 0px 160px;
}

.compaign-content {
  width: 400px;
  border-radius: 6px;
  background-color: #f6f6f6;
  margin: 16px 0px 0px;
  padding: 22px;
}

.compaign-content>p {
  margin: 0px 0px 12px;
  font-size: 14px;
  line-height: 1.43;
  color: #181818;
}

.compaign-content>p img.full-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.compaign-content>p span {
  display: block;
  font-size: 12px;
  opacity: 0.5;
  line-height: 1.67;
}

.compaign-content>p cite {
  font-size: 13px;
  border: 1px solid #d3d9e5;
  border-radius: 20px;
  padding: 0px 8px 0px 20px;
  line-height: 1.44;
  background-color: #ffffff;
  position: relative;
  display: inline-block;
  margin: 5px 0px 0px;
}

.compaign-content>p cite svg {
  position: absolute;
  left: 8px;
  width: 6px;
  top: 2px;
  opacity: 0.7;
}

.compaign-content>p cite.fall-back {
  border-color: #ff9400;
  cursor: pointer;
}

.compaign-content>p cite.fall-back svg {
  height: 20px;
  width: 10px;
  opacity: 1;
  top: -1px;
  left: 5px;
}

.compaign-content>code {
  position: absolute;
  width: 261px;
  box-shadow: 0 3px 4px 0 rgba(10, 31, 68, 0.15),
    0 0 4px 0 rgba(10, 31, 68, 0.08);
  border: solid 1px #f0f2f5;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 15px 15px;
  font-family: "Lato", sans-serif !important;
  z-index: 3;
  margin: 37px 0px 0px 0px;
}

.compaign-content>code:before,
.compaign-content>code:after {
  content: "";
  width: 0;
  height: 0;
  border: 8px solid;
  position: absolute;
  margin-top: -8px;
  z-index: 1;
  left: 0;
  top: 50%;
}

.compaign-content>code:before {
  border-color: transparent #ebeced transparent transparent;
  left: -16px;
}

.compaign-content>code:after {
  border-color: transparent var(--white-color) transparent transparent;
  left: -15px;
}

.compaign-content>code>span {
  font-size: 12px;
  color: #727c8f;
  font-weight: 500;
  line-height: normal;
  opacity: 1;
}

.compaign-content>code>input {
  padding: 6px 0px;
  border: 1px solid #e6e9f0;
  border-width: 0px 0px 1px;
  width: 100%;
  font-size: 14px;
  color: #181818;
}

.compaign-content>code>input.fallback-error {
  border: 1px solid #df0d29;
  border-width: 0px 0px 1px 0px;
}

.compaign-content .upload-btn {
  border: 1.5px solid #4684ff;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  color: #4684ff;
  text-align: center;
  padding: 38px 0px;
  border-style: dashed;
  cursor: pointer;
  margin: 0px 0px 12px;
  position: relative;
}

.compaign-content-text {
  border-radius: 21px;
  background-color: #ffffff;
  padding: 15px 20px 13px;
  position: relative;
  margin: 40px 0px 0px 0px;
}

.compaign-content-text:before {
  content: " ";
  height: 1px;
  position: absolute;
  left: -20px;
  right: -20px;
  background-color: #18181818;
  top: -22px;
}

.compaign-content-text>div {
  position: relative;
  width: 13px;
  height: 15px;
  display: inline-block;
  /* margin: 0px 15px 0px 0px; */
  margin: 0px 17px 0px 0px;
}

.compaign-content-text>div.divid-bar:before {
  content: "";
  height: 15px;
  width: 1px;
  background-color: #e6e9f0;
  position: absolute;
  margin: 0px 0px 0px 5px;
}

.compaign-content-text>div:last-child {
  margin: 0px;
}

.compaign-content-text>div>svg {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -7px 0px 0px -7px;
  /* width: 16px;
    height: 10px;
    margin: -5px 0px 0px -8px; */
  cursor: pointer;
}

.compaign-content-text>div.img-center>svg {
  margin: -6px 0px 0px -4px;
}

.compaign-content-text>div.b-tag {
  /* margin: 0px 8px 0px 0px; */
  margin: 0px 15px 0px 0px;
}

.compaign-content-text>div>svg:nth-child(2) {
  opacity: 0;
}

.compaign-content-text>div:hover>svg:nth-child(1),
.compaign-content-text>div.active>svg:nth-child(1) {
  opacity: 0;
}

.compaign-content-text>div:hover>svg:nth-child(2),
.compaign-content-text>div.active>svg:nth-child(2) {
  opacity: 1;
}

.compaign-content-text>div.disabled>svg {
  opacity: 0.3 !important;
  cursor: not-allowed;
}

.compaign-content-text>div.disabled>svg:nth-child(2) {
  opacity: 0 !important;
}

.compaign-content-text>svg {
  width: 13px;
  height: 13px;
  border: 1px solid red;
  cursor: pointer;
  margin: 0px 10px 0px 0px;
  text-align: center;
}

.compaign-content-text>div.active .child-dwn {
  display: block;
}

.compaign-content-text>div.align-dwn.active .child-dwn {
  width: 110px;
  margin: 0px 0px 0px -54px;
}

.compaign-content-text>div.align-dwn.active .child-dwn a svg {
  margin: 0px 10px -1px 0px;
}

.child-dwn {
  box-shadow: 0 3px 4px 0 rgba(10, 31, 68, 0.15),
    0 0 4px 0 rgba(10, 31, 68, 0.08);
  border: 1px solid #f0f2f5;
  padding: 5px 0px;
  border-radius: 3px;
  width: 136px;
  position: absolute;
  left: 50%;
  background-color: var(--white-color);
  bottom: 40px;
  margin: 0px 0px 0px -68px;
  display: none;
  z-index: 0;
}

.child-dwn:before,
.child-dwn:after {
  content: "";
  width: 0;
  height: 0;
  border: 8px solid;
  position: absolute;
  margin-left: -8px;
  z-index: 1;
  left: 50%;
}

.child-dwn:before {
  border-color: #ebeced transparent transparent;
  bottom: -16px;
}

.child-dwn:after {
  border-color: var(--white-color) transparent transparent;
  bottom: -15px;
}

.child-dwn>ul {
  min-height: auto;
  max-height: 154px;
  overflow: auto;
}

.child-dwn li a {
  padding: 4px 15px;
  font-size: 14px;
  font-weight: 500;
  color: var(--txt-black-color);
  line-height: 1.71;
  position: relative;
  cursor: pointer;
  z-index: 1;
  text-decoration: none;
  display: block;
}

.child-dwn li a:hover {
  background-color: #f3f6fc;
}

.child-dwn.link-child {
  width: 230px;
  margin: 0px 0px 0px -115px;
  padding: 16px;
  display: block;
}

.child-dwn.link-child>p {
  font-size: 12px;
  font-weight: 500;
  color: #727c8f;
  line-height: normal;
}

.child-dwn.link-child>input {
  font-size: 14px;
  border: 1px solid #e6e9f0;
  border-width: 0px 0px 1px;
  padding: 6px 0px;
  display: block;
  margin: 6px 0px 0px 0px;
  width: 100%;
}

.child-dwn.link-child>input:focus,
.compaign-content>code>input:focus {
  border-color: var(--PaleBlueAW);
}

.campaign-btn-wrp {
  padding: 30px 52px 90px !important;
  text-align: right;
  left: 1000px;
}

.cs-accordian-menu.cs-campaigns-menu>li>div>figure {
  width: 36px;
  height: 36px;
  margin: -18px 0px 0px 0px;
}

.cs-accordian-menu.cs-campaigns-menu>li>div>figure svg {
  width: 36px;
  height: 36px;
}

/* @media screen and (min-width: 1600px) {
    .campaign-btn-wrp{ 
      width: 1000px;
    }   
  } */

/* .compaign-content-text > div > span{
    cursor: pointer;
    width: 15px;
    height: 15px;
    position: relative;
  } */

.compaign-content-text>div>input {
  -webkit-appearance: none;
  opacity: 0;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 100%;
  z-index: 1;
  cursor: pointer;
}

.cs-accordian-menu>li.active>div>figure svg:nth-child(2),
.cs-accordian-menu>li>div:hover>figure svg:nth-child(2),
.cs-accordian-menu>li.open>div>figure svg:nth-child(2) {
  opacity: 1;
}

.slide-nav {
  width: 80px;
}

.slide-nav .cs-navigation-list>li {
  padding: 19px 20px 19px 59px;
}

.slide-nav .cs-navigation-list>li a {
  display: none;
}

.slide-col3 {
  margin: 0px 0px 0px 144px;
}

.slide-col3 .cs-btn-bottom {
  left: 900px;
}

.slide-col3 .cs-btn-bottom:before {
  left: -756px;
}

.cs-announcment-preview header,
.cs-announcment-preview footer {
  display: none;
}

.cs-announcment-preview.quick-preview>div {
  /* background-color: transparent; */
}

.cs-campaign-preview.desktop {
  width: 615px;
}

.cs-campaign-preview>code {
  position: absolute;
  top: -76px;
  background-color: #f6f6f6;
  border-radius: 17px;
  padding: 10px;
  width: 90px;
  text-align: center;
  height: 34px;
  right: 0px;
}

.cs-campaign-preview>code:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 16px;
  background-color: #c4d0d9;
  left: 48px;
}

.cs-campaign-preview>code>i {
  float: left;
  width: 18px;
  height: 16px;
  position: relative;
  cursor: pointer;
}

.cs-campaign-preview>code>i:nth-child(1) {
  margin: 0px 0px 0px 8px;
}

.cs-campaign-preview>code>i:nth-child(2) {
  margin: 0px 0px 0px 21px;
}

.cs-campaign-preview>code>i:nth-child(1) svg {
  width: 18px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -8px 0px 0px -9px;
}

.cs-campaign-preview>code>i:nth-child(2) svg {
  width: 12px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -8px 0px 0px -5px;
}

.cs-campaign-preview>code>i svg:nth-child(2),
.cs-campaign-preview>code>i.active svg:nth-child(1) {
  opacity: 0;
}

.cs-campaign-preview>code>i.active svg:nth-child(2) {
  opacity: 1;
}

/* .cs-campaign-preview > code > svg:nth-child(1){
    width: 18px;
    height: 16px;
    margin: 0px 15px 0px 0px;
    cursor: pointer;
}

.cs-campaign-preview > code > svg:nth-child(2){
    width: 11px;
    height: 16px;
    cursor: pointer;
} */

/* .cs-campaign-preview > code > svg{
  fill: #40495b;
  opacity:0.5;
}

.cs-campaign-preview > code > svg.active{
  fill: #4684ff;
  opacity:1;
} */

.cs-announcment-preview div.widget-camp-cont {
  /* min-height: auto;
  max-height: calc(100vh - 195px); */
  border-radius: 8px;
  background-color: #747b87;
  /* overflow: auto; */
  overflow: hidden;
  /* height: inherit; */
  height: calc(100vh - 255px);
}

.cs-announcment-preview div.widget-camp-cont:before {
  content: "";
  position: absolute;
  height: 40px;
  left: 0px;
  right: 0px;
  background-color: #ffffff;
  border-radius: 8px 8px 0px 0px;
}

.cs-announcment-preview div.widget-camp-cont:after {
  content: "";
  position: absolute;
  height: 30px;
  left: 0px;
  right: 0px;
  top: 30px;
  background-color: #747b87;
  border-radius: 8px 8px 0px 0px;
}

.cs-announcment-preview div.widget-camp-cont>section {
  /* height: 100%;
  padding: 70px 24px 34px;
   */
  position: absolute;
  left: 50%;
  top: 50px;
  transform: translateX(-50%);
  width: 300px;
  padding: 0px;
  min-height: auto;
  max-height: calc(100vh - 360px);
  border-radius: 0px 0px 8px 8px;
}

.cs-announcment-preview div.widget-camp-cont>section>div:first-child {
  border-radius: 6px;
  padding: 20px 24px;
  /* margin: 0px; */
  /* height: 100%; */
  /* height: calc(100vh - 359px); */
  min-height: auto;
  max-height: calc(100vh - 400px);
  overflow: auto;
  /* position: absolute; */
  /* left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
  /* width: 300px; */
  word-break: break-word;
  margin: 12px 0px 0px 0px;
}

.cs-announcment-preview.iframe div.widget-camp-cont>section>div:first-child {
  height: 100%;
}

.cs-announcment-preview.desktop div.widget-camp-cont>section,
.cs-campaign-preview.desktop .prview-typing {
  width: 550px !important;
}

/* .cs-announcment-preview.desktop div.widget-camp-cont > section > div:first-child{
  margin: 24px 0px 0px 0px;
} */

.cs-announcment-preview div.widget-camp-cont>section>div>cite {
  color: #bababa;
  font-size: 20px;
  float: right;
  cursor: pointer;
  position: absolute;
  right: 20px;
  width: 15px;
  height: 15px;
  text-align: center;
  line-height: 12px;
  background-color: #ffffff;
}

.cs-announcment-preview div.widget-camp-cont>section>div h4 {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  color: #181818;
}

.cs-announcment-preview div.widget-camp-cont>section>div p {
  font-size: 16px;
  line-height: 1.5;
  margin: 0px 0px 12px;
}

.cs-announcment-preview div.widget-camp-cont>section>div p em {
  font-style: italic;
}

.cs-announcment-preview div.widget-camp-cont>section>div h1 {
  font-size: 24px;
  font-weight: bold;
  line-height: 25px;
  margin: 0px 0px 12px;
}

.cs-announcment-preview div.widget-camp-cont>section>div h2 {
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  margin: 0px 0px 12px;
}

.cs-announcment-preview div.widget-camp-cont>section>div a {
  text-decoration: underline;
}

.cs-announcment-preview div.widget-camp-cont>section>div figure>img {
  min-width: auto;
  max-width: 100%;
  object-fit: cover;
}

.preview-sender {
  position: relative;
  padding: 10px 0px 0px 46px;
}

.preview-sender>figure {
  left: 0px;
  top: 12px;
  width: 36px;
  height: 36px;
  position: absolute;
  background-color: #8f99ad;
  bottom: 0px;
  border-radius: 100%;
  background-image: url(../images/user-white-icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 12px;
}

.preview-sender>figure>img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

.preview-sender>label {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.64;
  color: #181818;
}

.preview-sender>span {
  display: block;
  font-size: 12px;
  line-height: 1.64;
  color: #727c8f;
}

.prview-typing {
  height: 48px;
  position: relative;
  background-color: #f5f5f5;
  border-radius: 0px 0px 8px 8px;
  width: 300px;
  cursor: not-allowed;
}

.prview-typing:before {
  content: "Write a reply...";
  font-size: 14px;
  color: #aeb7c3;
  left: 24px;
  top: 16px;
  position: absolute;
}

.prview-typing:after {
  content: "";
  position: absolute;
  right: 0px;
  left: 0px;
  height: 15px;
  background-image: linear-gradient(to bottom,
      rgba(255, 255, 255, 0),
      #ffffff 82%);
  bottom: 48px;
}

.prview-typing i:nth-child(1) {
  position: absolute;
  background: url("https://storage.googleapis.com/livesupport/chat/images/chat-support-attachment-icon-new.svg") center no-repeat;
  width: 20px;
  height: 24px;
  opacity: 0.5;
  right: 60px;
  top: 50%;
  margin: -11px 0px 0px 0px;
  bottom: 48px;
}

/* .cs-announcment-preview div.widget-camp-cont > section .prview-typing:after {
} */

.prview-typing i:nth-child(1) {
  position: absolute;
  background: url("https://storage.googleapis.com/livesupport/chat/images/chat-support-attachment-icon-new.svg") center no-repeat;
  width: 20px;
  height: 24px;
  opacity: 0.5;
  right: 60px;
  top: 50%;
  margin: -11px 0px 0px 0px;
}

.prview-typing i:nth-child(2) {
  position: absolute;
  right: 0;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  background: #4684ff url("https://storage.googleapis.com/livesupport/chat/images/send-btn.svg") center 9px no-repeat;
  top: 50%;
  background-size: 12px;
  margin: -11px 24px 0px 0px;
}

.preview-popup {
  padding: 42px;
}

.preview-popup cite {
  background: #ffffff;
  width: 14px;
  height: 14px;
  font-size: 25px;
  line-height: 12px;
  color: #acb7cc;
  top: 22px;
  position: absolute;
  right: 22px;
  cursor: pointer;
}

.preview-popup h4 {
  font-size: 24px;
  font-weight: bold;
  color: #181818;
  line-height: 0.88;
  margin: 0px 0px 24px;
}

.preview-popup p {
  font-size: 16px;
  line-height: 1.5;
  margin: 0px 0px 16px;
}

.preview-popup p em {
  font-style: italic;
}

.preview-popup h1 {
  font-size: 24px;
  font-weight: bold;
  line-height: 25px;
  margin: 0px 0px 16px;
}

.preview-popup h2 {
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  margin: 0px 0px 16px;
}

.preview-popup figure img {
  min-width: auto;
  max-width: 100%;
  object-fit: cover;
  margin: 0px 0px 16px;
}

.preview-popup .prview-typing {
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 72px;
  position: absolute;
  width: 100%;
}

.preview-popup .prview-typing:before {
  content: "Write a reply…";
  font-size: 16px;
  line-height: 1.5;
  top: 25px;
  left: 42px;
}

.preview-popup .prview-typing:after {
  bottom: 72px;
}

.preview-popup .prview-typing i:nth-child(2) {
  margin: -12px 42px 0px 0px;
}

.preview-popup .prview-typing i:nth-child(1) {
  margin: -11px 24px 0px 0px;
}

.preview-popup .preview-sender>label {
  font-size: 18px;
  line-height: 1.33;
}

.preview-popup .preview-sender>span {
  font-size: 16px;
  line-height: 1.5;
}

.preview-popup .preview-sender>figure {
  width: 42px;
  height: 42px;
  top: 22px;
}

.preview-popup .preview-sender {
  padding: 20px 0px 60px 56px;
}

.preview-popup.disabled-reply .preview-sender {
  padding: 20px 0px 0 56px;
}

.cs-campaign-preview.cs-email-preview>div:after {
  content: "";
  position: absolute;
  right: 0px;
  left: 0px;
  height: 40px;
  background-image: linear-gradient(to bottom,
      rgba(255, 255, 255, 0),
      #ffffff 82%);
  bottom: 0px;
  border-radius: 0px 0px 8px 8px;
}

.cs-campaign-preview.cs-email-preview div.widget-camp-cont {
  height: calc(100vh - 255px);
  border-radius: 0px 0px 8px 8px;
  padding: 20px;
  overflow: initial;
  overflow-y: auto;
}

.cs-campaign-preview.cs-email-preview div.widget-camp-cont:after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  height: 30px;
  background-color: #ffffff;
  top: 0px;
  z-index: 1;
  border-radius: 8px 8px 0px 0px;
}

.cs-campaign-preview.cs-email-preview>div:before {
  content: "";
  position: absolute;
  width: 40px;
  height: 10px;
  top: 12px;
  left: 7px;
  background: white url("https://storage.googleapis.com/livesupport/webapp/images/windows-close-icon.svg") -96px -102px no-repeat;
  z-index: 2;
}

.cs-email-preview div.widget-camp-cont>section>div h4 {
  color: #181818;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  padding: 20px 0px 0px;
  position: relative;
}

.cs-email-preview div.widget-camp-cont>section>div h4:nth-child(1),
.cs-email-preview div.widget-camp-cont>section>div h4:nth-child(2) {
  padding: 12px 0px;
}

.cs-email-preview div.widget-camp-cont>section>div h4:nth-child(1) {
  margin: 0px 0px 0px -10px;
}

.cs-email-preview div.widget-camp-cont>section>div h4:nth-child(1):before,
.cs-email-preview div.widget-camp-cont>section>div h4:nth-child(2):before {
  content: "";
  height: 1px;
  background-color: #dadce0;
  position: absolute;
  bottom: 0px;
  left: -10px;
  right: -10px;
  opacity: 0.6;
}

.cs-email-preview div.widget-camp-cont>section>div h4:nth-child(1):before {
  left: 0px;
}

.cs-email-preview div.widget-camp-cont>section>div h4 code {
  background-color: rgba(216, 216, 216, 0.2);
  border-radius: 4px;
  padding: 4px 10px;
  font-family: "Lato", sans-serif;
  color: rgba(24, 24, 24, 0.5);
  font-size: 14px;
  line-height: 1.43;
  display: block;
}

/* .cs-email-preview div.widget-camp-cont>section>div h4 code:before {
  content: "";
  position: absolute;
  height: 5px;
  width: 100px;
  background-color: #d8d8d8;
  border-radius: 5px;
  margin: 8px 0px 0px 45px;
} */

.cs-email-preview div.widget-camp-cont>section>div p {
  font-size: 14px;
  line-height: 1.43;
  color: #181818;
  margin: 10px 0px 0px;
}

.cs-email-preview div.widget-camp-cont>section>div label {
  font-size: 14px;
  color: #181818;
  line-height: 1.43;
  margin: 30px 0px 0px 0px;
  display: block;
}

.cs-email-preview div.widget-camp-cont>section>div label small {
  font-size: 12px;
  opacity: 0.5;
  display: block;
}

.compaign-content .DraftEditor-root {
  font-size: 14px;
  line-height: 20px;
  color: #181818;
}

.compaign-content.announcement-content .DraftEditor-root {
  font-size: 16px;
}

.compaign-content .public-DraftEditor-content {
  max-height: 100%;
  min-height: 176px;
}

.compaign-content .DraftEditor-editorContainer {
  z-index: 0;
}

.compaign-content .public-DraftEditor-content>div>div,
.compaign-content .public-DraftEditor-content>div>h1,
.compaign-content .public-DraftEditor-content>div>h2 {
  margin: 0px 0px 12px;
  line-height: 1.43;
}

.compaign-content .public-DraftEditor-content>div>figure {
  position: relative;
}

.compaign-content .public-DraftEditor-content>div>figure>img {
  min-width: auto;
  max-width: 100%;
  object-fit: cover;
  margin: 0px 0px 12px;
  box-shadow: none;
  border: 1px dashed #f6f6f6;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.compaign-content .public-DraftEditor-content>div>figure>img:hover,
.draftJsFocusPlugin__focused__3Mksn {
  border: 1px dashed #4684ff !important;
}

.compaign-content .public-DraftEditor-content>div>figure:last-child>img {
  margin: 0px;
}

.filter-checkbox-dwn>div>ul li.active.tag-field .cs-dropdwn-checkbox-sub>p {
  text-transform: uppercase;
  padding: 16px 16px 0px;
  font-size: 13px;
  font-weight: 600;
  color: #8f99ad;
}

.compaign-content .public-DraftEditor-content>div>div:last-child>div figure {
  left: 0px;
  top: 0px;
  width: 36px;
  height: 36px;
  position: absolute;
  background-color: #8f99ad;
  bottom: 0px;
  border-radius: 100%;
  background-image: url(../images/user-white-icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 12px;
}

.compaign-content .public-DraftEditor-content>div>div:last-child>div figure > img {
  object-fit: cover;
}

.compaign-content .public-DraftEditor-content>div>h1 {
  font-size: 18px;
  font-weight: bold;
  line-height: 25px;
}

.compaign-content .public-DraftEditor-content>div>h2 {
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
}

.compaign-content.announcement-content .public-DraftEditor-content>div>h1 {
  font-size: 24px;
  font-weight: bold;
  line-height: 25px;
}

.compaign-content.announcement-content .public-DraftEditor-content>div>h2 {
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
}

.compaign-content .public-DraftEditor-content>div a {
  text-decoration: underline;
}

.compaign-content .public-DraftEditor-content {
  overflow: inherit;
}

.ide,
.fallback {
  font-size: 13px;
  border: 1px solid rgb(211, 217, 229);
  border-radius: 20px;
  padding: 0px 8px 0px 20px;
  line-height: 1.44;
  background-color: rgb(255, 255, 255);
  position: relative;
  display: inline-block;
  margin: 5px 0px 0px;
  cursor: pointer;
}

.ide>svg,
.fallback>svg {
  position: absolute;
  left: 8px;
  width: 6px;
  top: 2px;
  opacity: 0.7;
}

.fallback {
  border: 1px solid #ff9400;
}

.preview-popup-content {
  /* min-height: 320px; */
  min-height: auto;
  max-height: calc(100vh - 250px);
  overflow: auto;
}

.cs-project-wrp.segment-dwn .cs-porject-clip>span {
  max-width: 190px;
}

.email-camp-list {
  position: relative;
}

.email-camp-list>code {
  font-size: 20px;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  text-align: center;
  line-height: 19px;
  color: #595959;
  z-index: 1;
  right: 40px;
  top: 50%;
  cursor: pointer;
  display: none;
}

.email-camp-list:hover>code {
  display: block;
}

@media screen and (max-height: 640px) {
  .preview-popup-content {
    min-height: auto;
    max-height: 320px;
    overflow: auto !important;
  }
}

@media screen and (min-height: 800px) {
  .cs-campaign-preview div.widget-camp-cont {
    height: 350px;
  }

  .cs-campaign-preview.cs-email-preview div.widget-camp-cont {
    height: 550px;
  }

  .cs-announcment-preview div.widget-camp-cont {
    height: 550px;
  }

  .cs-announcment-preview div.widget-camp-cont>section>div:first-child {
    max-height: 400px;
    margin: 24px 0px 0px 0px;
  }
}

@media screen and (min-width: 1540px) {

  /* .cs-campaign-preview{ 
      left: 1050px;
      right: inherit;
    }    */
    
  .cs-dropdwn .date-field .flatpickr-calendar {
    margin: -42px 0px 0px 335px;
  }
}

@media screen and (max-height: 600px) {
  .cs-dropdwn.end-date .date-field .flatpickr-calendar {
    margin-top: -230px !important;
  }
}

.people-grid-list>div>span {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: normal;
}

.people-grid-list>div>span.enabled {
  cursor: pointer;
  color: var(--BlueAW);
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: 16px;
}

.red {
  color: "red";
  display: inline-block !important;
}

.cs-dropdwn.cs-placeholder-txt p {
  color: #acb7cc !important;
}

.cs-link-add {
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  color: var(--BlueAW);
  display: inline-block;
}

.publish-count {
  color: #727c8f !important;
}

.flatpickr-disabled {
  color: rgba(72, 72, 72, 0.3) !important;
}

.flatpickr-disabled:hover {
  color: rgba(72, 72, 72, 0.3) !important;
  cursor: default;
}

.alignmentTool {
  left: 50%;
  transform: translate(-50%) scale(0);
  position: absolute;
  border: 1px solid #111;
  background: #333;
  border-radius: 4px;
  box-shadow: 0px 1px 3px 0px rgba(220, 220, 220, 1);
  z-index: 2;
  box-sizing: border-box;
}

.alignmentTool:after,
.alignmentTool:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.alignmentTool:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #333;
  border-width: 4px;
  margin-left: -4px;
}

.alignmentTool:before {
  border-color: rgba(221, 221, 221, 0);
  border-top-color: #111;
  border-width: 6px;
  margin-left: -6px;
}

.buttonWrapper {
  display: inline-block;
}

.button {
  background: #333;
  color: #ddd;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
  border-radius: 4px;
}

.button svg {
  fill: #ddd;
}

.button:hover,
.button:focus {
  background: #444;
  outline: 0;
  /* reset for :focus */
}

.active {
  color: #6a9cc9;
}

.active > svg {
  fill: #6a9cc9;
}

.edit-delete-container > li > svg{
  width: 13px;
    height: 13px;
    float: left;
    margin: 4px 16px 0px 0px;
    pointer-events:none;
}

.edit-delete-container > li.delete{
  color: #df0d29;
}

.edit-delete-container > li.import > svg{
  width:16px;
  height:16px;
  margin:4px 14px 0px 0px;
}

.edit-delete-container > li.import > input[type=file]{
  -webkit-appearance: none;
  opacity: 0;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  z-index: 2;
  cursor: pointer;
}

/* .draftJsEmojiPlugin__alignmentTool__2mkQr {
  display: none !important;
} */

.edit-delete-container.sub-dropdown>li.bar-line {
  margin: 12px 0px 0px;
}

.edit-delete-container.sub-dropdown>li.bar-line:before {
  content: "";
  position: absolute;
  height: 1px;
  background-color: #e6e9f0;
  left: 16px;
  right: 16px;
  top: -6px;
}

.word-break {
  word-break: break-word;
}

/* Changes for focus plugin css */
.draftJsFocusPlugin__unfocused__1Wvrs:hover {
  cursor: default;
  border-radius: 2px;
  box-shadow: 0 0 0 3px #d2e3f7;
}

.draftJsFocusPlugin__focused__3Mksn {
  cursor: default;
  border-radius: 2px;
  box-shadow: 0 0 0 3px #accef7;
}

/* focus plugin css ends */

.draftJsEmojiPlugin__alignmentTool__2mkQr {
  left: 50%;
  -webkit-transform: translate(-50%) scale(0);
  transform: translate(-50%) scale(0);
  position: absolute;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(220, 220, 220, 1);
  z-index: 2;
  box-sizing: border-box;
  display: none !important;
}

.draftJsEmojiPlugin__alignmentTool__2mkQr:after,
.draftJsEmojiPlugin__alignmentTool__2mkQr:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  display: none !important;
}

.draftJsEmojiPlugin__alignmentTool__2mkQr:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 4px;
  margin-left: -4px;
}

.draftJsEmojiPlugin__alignmentTool__2mkQr:before {
  border-color: rgba(221, 221, 221, 0);
  border-top-color: #ddd;
  border-width: 6px;
  margin-left: -6px;
}

.draftJsEmojiPlugin__buttonWrapper__1Dmqh {
  display: inline-block;
}

.draftJsEmojiPlugin__button__qi1gf {
  background: #fbfbfb;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
}

.draftJsEmojiPlugin__button__qi1gf svg {
  fill: #888;
}

.draftJsEmojiPlugin__button__qi1gf:hover,
.draftJsEmojiPlugin__button__qi1gf:focus {
  background: #f3f3f3;
  outline: 0;
  /* reset for :focus */
}

.draftJsEmojiPlugin__active__3qcpF {
  background: #efefef;
  color: #444;
}

.draftJsEmojiPlugin__active__3qcpF svg {
  fill: #444;
}

.right div {
  text-align: right;
  display: block;
}

.center div {
  text-align: center;
  display: block;
}

.left div {
  text-align: left;
  display: block;
}

#app-integrations-wrapper>div{
  height: 100vh !important;
}

.cs-integrations-wrp {
  margin: 0px 0px 0px 383px;
}

/* campaign-placeholder code start here ****************/
.campaign-placeholder{ 
  padding: 60px 60px 110px 60px;
  height: calc(100vh - 89px);
  overflow: auto;
  position: relative;
}
.campaign-placeholder > div{
  width: 480px;
  display: inline-block;
}
.campaign-placeholder > div > h3{
  font-size: 16px;
  font-weight: bold;
  line-height: 1.31;
  color: #181818;
}

.campaign-placeholder > div > p{
  font-size: 16px;
  line-height: 1.5;
  color: #727c8f;
  margin: 10px 0px 45px;
}

.campaign-placeholder div.cs-radio-form {
  min-width: 480px;
  max-width: 100%;
  width: inherit;
}

.campaign-placeholder > img{
  width: 443px;
  position: absolute;
  left: 580px;
  top:27px;
}

.campaign-placeholder > footer{
  position: fixed;
  bottom: 0px;
  right: 0px;
  padding: 30px;
  background-color: #ffffff;
  left: 384px;
  text-align: right;
} 

.campaign-placeholder > footer:after{
  content: "";
  position: fixed;
  bottom: 95px;
  right: 0;
  left: 384px;
  height: 20px;
  background-image: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0));
  z-index: 1;
  pointer-events: none;
}

.cs-radio-form p.min-screen{ display:block; }
.cs-radio-form p.max-screen{ display:none; }

/* upgrade-notify  ****************/
.upgrade-notify{
  position: fixed;
  top: 0px;
  left: 64px;
  right: 0px;
  height: 45px;
  background-color: #6699ff;
  z-index: 1;
  text-align: center;
}

.upgrade-notify p{
  font-size: 15px;
  font-weight: 600;
  color: #ffffff;
  line-height: 40px;
}

.upgrade-notify p a{
  background-color: #ffffff;
  border-radius: 20px;
  font-size: 14px;
  color: #181818;
  text-decoration: none;
  padding: 6px 15px 6px;
  line-height: 46px;
  margin: 0px 0px 0px 20px;
}

.upgrade-notify cite{
  position: absolute;
  right: 20px;
  font-size: 24px;
  top: 2px;
  cursor: pointer;
  font-weight: 400;
}
.cs-conversation-wrp:not(.cs-conversation-wrp.min-screen,.cs-conversation-wrp.integration){
  position: relative;
  width: 100%;
  height: 100%;
}

.cs-conversation-wrp.update-header .cs-my-conversations,
.cs-conversation-wrp.update-header .cs-conversation,
.cs-conversation-wrp.update-header .cs-navigation,
.cs-conversation-wrp.update-header .cs-my-conversations-filter{
  top:45px;
}

.cs-conversation-wrp.update-header .cs-settings-wrp{ margin-top:45px; }

.cs-conversation-wrp.update-header .cs-my-conversations .cs-chat-list{ height: calc(100vh - 250px); }
/* .cs-conversation-wrp.update-header .cs-conversation .cs-user-conversation{ height: calc(100vh - 247px); } */
.cs-conversation-wrp.update-header .cs-conversation .cs-user-conversation.scroll:before{ top:133px; }

.cs-conversation-wrp.update-header .cs-conversation .cs-user-info{ height:calc(100vh - 134px);}
.cs-conversation-wrp.update-header .cs-conversation .cs-user-details{ height:calc(100vh - 190px);}

.cs-conversation-wrp.update-header .cs-navigation .cs-navigation-list.cs-my-team-list{ height:calc(100vh - 251px); }

.cs-conversation-wrp.update-header .cs-my-conversations.people-search .cs-accordian-menu{ height: calc(100vh - 285px); }

.cs-conversation-wrp.update-header .cs-conversation.cs-people-wrp .campaigns-grid-wrp>div{ height: calc(100vh - 215px); }

.cs-conversation-wrp.update-header .cs-conversation .cs-proactive-list{ height: calc(100vh - 199px); }

.cs-conversation-wrp.update-header .cs-my-conversations-filter .cs-filter-list{ height: calc(100vh - 260px); }

.cs-conversation-wrp.update-header .cs-conversation .campaign-placeholder{ height: calc(100vh - 134px); }

.cs-conversation-wrp.update-header .widget-configuration{ margin-top:105px; }

#at-icon{
  width:20px !important;
}
.cs-conversation-wrp.update-header .cs-my-conversations .cs-chat-list.scroll:before{ top:144px;}

.cs-conversation-wrp.update-header .cs-dropdwn-checkbox-sub ul{
  max-height: calc(100vh - 255px);
}



/* pricing popup code started form here ******************* */

/*EuclidCircularB font added code here -----------------------------------------------------------------*/
@font-face {
  font-family: 'EuclidCircularB';
  src:url('https://assets.chatsupport.co/website/font/EuclidCircularB-Regular-WebS.woff2') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EuclidCircularB';
  src:url('https://assets.chatsupport.co/website/font/EuclidCircularB-Semibold-WebS.woff2') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

.dot{
  position: relative;
  margin: 0;
  color: #4684ff;
  font-family: initial;
  line-height: normal;
}

.pricing-wrp{
  font-family: "EuclidCircularB", sans-serif;
  z-index: 999;
  position: absolute;
  width: 830px;
  height: 590px;
  left: 50%;
  top: 50%;
  margin: -295px 0px 0px -415px;
  border-radius: 8px;
  background-color:#ffffff;
  padding:20px;
}

.pricing-wrp > cite{
    position: absolute;
    right: 20px;
    font-size: 38px;
    font-weight: 500;
    color: #181818;
    opacity: 0.2;
    top: 10px;
    font-family: "Lato", sans-serif;
    cursor: pointer;
}

.pricing-wrp > h1{
  font-size:38px;
  line-height: 1.18;
  letter-spacing: -0.5px;
  font-weight: 600;
  color: #181818;
  text-align:center;
  margin: 20px 0px 30px;
}

.pricing-wrp > div{ 
  overflow: auto;
  height: 468px;
  padding: 0px 0px 20px;
}

.pricing-wrp > div:before{
  content: "";
  position: absolute;
  background-color: #eef2f5;
  left: 30px;
  right: 30px;
  bottom: 0px;
  top: 320px;
  border-radius: 8px 8px 0px 0px;
} 

.pricing-wrp .pricing-list:nth-child(1){ 
    margin: 0px 30px 0px 50px;
}

.pricing-wrp .pricing-list:nth-child(2):before{
    content: "";
    width: 0;
    height: 0;
    border: 50px solid;
    position: absolute;
    margin-left: 22px;
    z-index: 1;
    border-color: #4684ff #4684ff transparent transparent;
    right: 0px;
    top: 0px;
}

.pricing-wrp .pricing-list:nth-child(2):after{
    content: "";
    width: 0;
    height: 0;
    border: 30px solid;
    position: absolute;
    margin-left: 22px;
    z-index: 1;
    border-color: #ffffff #ffffff transparent transparent;
    right: 0px;
    top: 0px;
}

.pricing-list{
    width: 330px;
    background: #fff;
    padding: 30px 24px;
    text-align: left;
    -webkit-box-shadow: 0 0 10px 0 rgba(217,225,235,.54);
    box-shadow: 0 0 10px 0 rgba(217,225,235,.54);
    float: left;
    position: relative;
    border:1px solid #f3f3f3;
}

.pricing-list cite{
    position: absolute;
    font-size: 13px;
    color: #ffffff;
    text-transform: inherit;
    letter-spacing: normal;
    right: 0px;
    font-weight: 600;
    z-index: 1;
    left: 247px;
    top: 35px;
    transform: rotate(45deg); /* W3C */
    -webkit-transform: rotate(45deg); /* Safari & Chrome */
    -moz-transform: rotate(45deg); /* Firefox */
    -ms-transform: rotate(45deg); /* Internet Explorer */
    -o-transform: rotate(45deg); /* Opera */
}

.pricing-list .button{
    width: 100%;
    line-height: 39px;
}

.pricing-list h2,
.pricing-list h3{
    font-size: 32px;
    font-weight: 600;
    line-height: 1.13;
    letter-spacing: -0.35px;
    color: #334e68;
}
.pricing-list h3{ 
    color: #4684ff; 
    margin: 0px 0px 24px;
}
.pricing-list h3 span{
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: -0.2px;
    color: #4684ff;
    font-weight: normal;
}
.pricing-list p{
    font-size: 18px;
    line-height: 1.78;
    letter-spacing: -0.2px;
    color: #181818;
    margin: 0px 0px 16px;
}

.pricing-list > span{
  cursor: pointer;
  font-size: 14px;
  color: #4684ff;
  letter-spacing: -0.2px;
  margin: 18px 0px 0px 0px;
  display: inline-block;
}

.pricing-list label,
.pricing-list ul li{
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: -0.2px;
    color: #181818;
}

.pricing-list ul li{
    color: #334e68;
    padding: 0px 0px 0px 25px;
    line-height: 34px;
    position: relative;
}
.pricing-list ul li > svg{
    position: absolute;
    width: 14px;
    height: 14px;
    left: 0px;
    top: 10px;
}
.pro-text{
    font-size: 14px;
    opacity: 0.7;
    color: #334e68;
    line-height: 1.71;
}
.info-tooltip{
    position: absolute;
    width: 20px;
    height: 20px;
    right: 0px;
    top: 8px;
    cursor: pointer;
}
.info-tooltip svg{
    width: 15px;
    height: 15px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -8px;
}
.info-tooltip p{
    position: absolute;
    padding: 10px 16px;
    white-space: normal;
    width: 226px;
    text-align: left;
    background-color: #000;
    border-radius: 3px;
    color: #fff;
    opacity: 0;
    z-index: 100;
    visibility: hidden;
    transition: .3s ease-out;
    font-size: 14px;
    line-height: 1.57;
    bottom: 15px;
    right: -10px;
}

.info-tooltip p:after{
    content: "";
    bottom: -6px;
    display: block;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #000;
    position: absolute;
    right: 15px;
}

.info-tooltip:hover p{
    opacity: 1;
    visibility: visible;
}

.pro-btn{
    font-size: 18px;
    text-decoration: none;
    color: #ffffff;
    border-radius: 32px;
    padding: 15px 35px;
    display: block;
    text-align: center;
    margin: 0px 0px 32px;
    transition: background-color 0.3s ease-out;
    -webkit-transition: background-color 0.3s ease-out;
    -moz-transition: background-color 0.3s ease-out;
    -ms-transition: background-color 0.3s ease-out;
    -o-transition: background-color 0.3s ease-out;
  }
      
  

.pro-btn.pro-diable-btn{
   background-color: rgba(32, 42, 61, 0.07);
   color: #181818;
   cursor: not-allowed;
}
.embed-code{
  background-color: #f6f6f6;
  border: solid 1px rgba(216, 220, 225, 0.5);
  width: 530px;
  font-family: "americantypewriter", sans-serif;
  line-height: 1.71;
  font-size: 14px;
  opacity: 0.7;
  padding: 14px 50px 14px 16px;
  border-radius: 4px;
  word-break: break-all;
  display: inline-block;
  position: absolute;
  left: 430px;
  top:4px;
}

.embed-code i.copy-icon::before {
  content: "\F0C5";
  font-family: "Font Awesome 5 Solid", sans-serif;
  opacity: 0.3;
  position: absolute;
  font-style: normal;
  right: 16px;
  cursor: pointer;
  top: 16px;
  color: #354052;
}

.overflow-scroll{ overflow: auto; }
.email-inputid{ margin: 70px 0px 50px; }
.cs-or{ margin: 70px 0px 50px; }
.figure-porgress{
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-color:#f4f4f4;
}

/* no data placholder  code start here ******************/
.people-grid-wrp .placeholder-wrp{
  left: 0px !important;
}

.placeholder-wrp{
  position: absolute;
  left: 320px;
  bottom: 0px;
  right: 0px;
  top: 0px;
  background-color: var(--white-color);
  z-index: 1;
  /* height: calc(100vh - 0px); */
}

.placeholder-wrp > div.placeholder-content{
  width: 500px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.placeholder-wrp > div.placeholder-content.zero-stats {
  transform: translate(-82%,-50%);
}

/* .placeholder-wrp > div.empty-chat{
  width: 412px !important;
  margin: -160px 0px 0px -206px !important;
} */

.placeholder-wrp > div.placeholder-content > label{
  font-size: 15px;
  font-weight: bold;
  color: #181818;
  font-family: "Lato";
  margin: 20px 0px 10px;
  display: block;
}

.placeholder-wrp > div.placeholder-content > p{
  font-size: 13px;
  line-height: 1.38;
  color: #5c6470;
  padding: 0px;
}

.placeholder-wrp > div.placeholder-content.empty-chat > p{
  padding: 0px 0px 14px !important;
}

.placeholder-wrp > div.placeholder-content.empty-chat > label{
  margin: 12px 0px 10px !important; 
}

.steps-links{
  border-radius: 7px;
  border: 1px solid #d9e1ee;
  padding: 30px 16px 30px 102px;
  text-align: left;
  position: relative;
  height: 94px;
  display: block;
  width: 412px;
  margin: 10px 0px 0px;
  text-decoration: none;
}

.steps-links > svg{
  position: absolute;
  width: 62px;
  height: 62px;
  left: 16px;
  top: 16px;
}

.steps-links > strong{
  font-size: 14px;
  margin: 0px 0px 3px;
  display: block;
  font-weight: 600 !important;
  color:#181818
}

.steps-links > span{
  font-size: 13px;
  line-height: 1.38;
  color: #5c6470;
  text-decoration: none;
}

.nochat-list{
  opacity: 0.2;
  font-size: 15px;
  color: #181818;
  text-align: center;
  display: block;
  margin: 26px 0px;
  font-weight: bold;
}

.mention-list{
  padding: 10px 20px 10px 52px;
  font-size: 14px;
  line-height: 1.43;
  color: var(--txt-black-color);
  cursor: pointer;
  position: relative;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
}

.mention-list figure{
  width: 22px;
  height: 22px;
  border-radius: 100%;
  position: absolute;
  left: 20px;
  background-image: url("../images/user-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 12px;
  background-color: var(--user-bgcolor);
}

.mention-list figure img{
  width: 22px;
  height: 22px;
  border-radius: 100%;
  object-fit: cover;
}

.mention-list cite{
  padding: 3px;
  border-radius: 100%;
  z-index: 1;
  position: absolute;
  top: 24px;
  left: 36px;
  border: 1px solid var(--white-color);
}

.mention-list:hover{
  background-color: var(--chat-listhover-bgcolor);
}

/* .placeholder-wrp.no-chats > div.placeholder-content.empty-chat{
  margin-top: -32px !important;
} */

.placeholder-wrp.no-chats > div.placeholder-content.empty-chat > p,
.placeholder-wrp.no-chats > div.placeholder-content.empty-chat a.steps-links{
  display: none !important;
}

.top-right .widget-icon{
  top:-70px !important;
}

.top-right .preview-chat{
  position: absolute;
  -webkit-box-shadow: 0 5px 40px rgba(0,0,0,.16)!important;
  box-shadow: 0 5px 40px rgba(0,0,0,.16)!important;
  border-radius: 6px !important;
  top: -70px !important;
  left: 15px;
  width: 200px;
  background-color: #ffffff;
}

.bottom-left .widget-icon{
  left: -185px !important;
}

.bottom-left .preview-chat{
  position: absolute;
    -webkit-box-shadow: 0 5px 40px rgba(0,0,0,.16)!important;
    box-shadow: 0 5px 40px rgba(0,0,0,.16)!important;
    border-radius: 6px !important;
    right: inherit;
    width: 200px;
    left: -185px;
    background-color: #ffffff;
}

.bottom-left .widget-icon span,
.top-left .widget-icon span{
    right: inherit;
    left: 65px;
    background: url(https://storage.googleapis.com/livesupport/chat/images/bubble-middle-lf.svg) 0 -18px repeat-x !important;
    background-size: 20px !important;
    padding: 0px 0px 0px 0px;
}

.bottom-left .widget-icon span:before,
.top-left .widget-icon span:before{
  content: "";
  position: absolute;
  background: url(https://storage.googleapis.com/livesupport/chat/images/bubble-left-lf.svg) 0px -18px no-repeat;
  background-size: 20px;
  width: 30px;
  height: 40px;
  left: -20px;
}

.bottom-left .widget-icon span:after,
.top-left .widget-icon span:after{
  content: "";
  position: absolute;
  background: url(https://storage.googleapis.com/livesupport/chat/images/bubble-right-lf.svg) 0 -18px no-repeat;
  background-size: 20px;
  width: 30px;
  height: 40px;
  right: -30px;
}

.bottom-left .widget-icon span b,
.top-left .widget-icon span b{
  padding-right: 4px;
}

.top-left .widget-icon{
  top: -70px !important;
  left: -185px !important;
}

.bottom-right .preview-chat{
  position: absolute;
  -webkit-box-shadow: 0 5px 40px rgba(0,0,0,.16)!important;
  box-shadow: 0 5px 40px rgba(0,0,0,.16)!important;
  border-radius: 6px !important;
  right: inherit;
  width: 200px;
  left: 15px;
  background-color: #ffffff;
}

.overflow-col3 .chat-unread {
  right: 0px;
}

.chat-unread{
  position: fixed;
  width: 50px;
  height: 30px;
  background-color: #60677a;
  text-align: right;
  padding: 0px 10px 0px 0px;
  line-height: 30px;
  font-size: 15px;
  font-weight: 600;    
  color: #ffffff;  
  bottom: 150px; 
  right: 322px; 
  border-radius: 100px 0px 0px 100px;
  background: #60677a url("https://assets.chatsupport.co/webapp/images/arrow-down-white.svg") 13px 13px;
  background-repeat: no-repeat;
  cursor: pointer;
  z-index: 2;
  }

.top-left .preview-chat{
  position: absolute;
  -webkit-box-shadow: 0 5px 40px rgba(0,0,0,.16)!important;
  box-shadow: 0 5px 40px rgba(0,0,0,.16)!important;
  border-radius: 6px !important;
  right: inherit;
  width: 200px;
  left: -185px;
  top:-70px !important;
  background-color: #ffffff;
}

.widget-type{
  width: 165px;
  height: 85px;
  border: 2px solid #f0f2f5;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(10, 31, 68, 0.1);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  text-align: center;
  vertical-align: bottom;
  display: inline-block;
  padding: 60px 0px 0px;
}

.default-icon{
  background-image: url("https://staging.chatsupport.co/images/default-widget.svg");
  margin: 0px 10px 0px 0px;
  background-size: 80px;
}

.normal-icon{
  background-image: url("https://staging.chatsupport.co/images/icon-widget.svg");
}

.widget-type small{
  font-size: 10px;
  text-decoration: none;
  color: #727c8f;
  line-height: 2;
}

.widget-type:hover,
.widget-type.selected{
  border: 2px solid #6699ff;
}

.feedback-tag,
.feedback-msg{
  padding: 8px 24px 26px !important;
  text-align: center !important;
  position: relative;
}

.feedback-tag:before{
  content: "";
  position: absolute;
  height: 1px;
  width: 60px;
  background-color: #d3d9e5;
  top: 0px;
  left: 49%;
  margin: 0px 0px 0px -25px;
}

.feedback-tag span{
  font-size: 14px;
  line-height: 1.6;
  color: #acb7cc;
  font-style: italic;
}

.feedback-tag p {
  display: inline-block;
}

.feedback-tag cite{
  padding: 14px 12px 12px;
    background-color: #f6f8fc;
    border-radius: 20px;
    width: 40px;
    height: 26px;
    line-height: 0;
    display: block;
    margin: 10px 0px 0px -20px;
    position: relative;
    left: 50%;
}

.feedback-tag cite img{
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px 0px 0px -10px;
}

.feedback-tag code{
  font-size: 14px;
  font-weight: 500;
  color: var(--txt-black-color);
  font-style: italic;
  display:block;
  margin:15px 0px 20px 0px;
}

.pre-post-switch{
  background-color: #f6f8fc;
  position: absolute;
  left: 800px;
  font-size: 12px;
  line-height: 1.63;
  color: #556784;
  top: 750px;
  border-radius: 50px;
  padding: 2px 0px;
  width: 148px;
  height: 24px;
}

.pre-post-switch:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 24px;
  background-color: #ffffff;
  left: 70px;
  top: 0px;
}

.pre-post-switch span{
  padding: 2px 12px;
  line-height: 1.63;
  cursor: pointer;
}

.pre-post-switch span.active{
  cursor: default;
  color: #6285ff;
}

.cs-form-col .cs-checkbox-field{
  padding: 30px 0px 0px 0px;
  display: flex;
}

.cs-form-col .cs-checkbox-field label{
  padding: 0px 0px 0px 25px;
  position: relative;
  display: inline-block;
  top: -2px;
}

.cs-form-col .cs-checkbox-field small {
  font-size: 14px;
}

.cs-form-col .cs-checkbox-field label svg{
  top: 6px;
  left: 4px;
}

.cs-form-col .cs-checkbox-field label cite{
  border-radius: 2px;
  top: 1px;
}

.progress-chat figure {
  background-color: transparent !important;
}

.progress-chat figure em{
  width:30px;
  height:30px;
  border-radius:100%;
  }
  .progress-chat label em{ width:200px; }
     
  .progress-chat p em{ 
     width: 100px;
     display: inline-block;
  }

  .progress-chat-md p em{ 
     width: 200px;
     display: inline-block;
  }

@media screen and (min-width: 1600px) {
  .campaign-placeholder div.cs-radio-form{ min-width:600px !important; }
  .campaign-placeholder > img{ left:760px; }
  .cs-radio-form p.max-screen{ display:block; }
  .cs-radio-form p.min-screen{ display:none; }
  .embed-code{width: 700px;}
  .email-inputid{ margin: 0px 0px 40px 0px; }
  .cs-or{ margin: 0px 0px 40px 0px; }
}

.import-popup .import-grid-wrp{
  min-height: auto;
  /* min-height: 320px; */
  max-height: calc(100vh - 300px);
}

.import-popup header > label{
  font-size: 16px;
  font-weight: 600;
  color: #181818;
  padding: 0px 30px;
  display: block;
  margin: 40px 0px 0px;
}

.import-grid-wrp > div{
  overflow-x: auto;
  width: 100%;
  min-height:auto;
  /* min-height: 320px; */
  max-height: calc(100vh - 368px);
}

.import-grid-wrp > div .import-grid-head{
  border: 1px solid #E6EAEE;
  border-width: 0px 0px 1px;
  height: 40px;
  padding: 12px 0px;
}

.import-grid-wrp > div .import-grid-head > div{
  float:left;
  width:180px;
  font-size: 14px;
  font-weight: 500;
  color: #727c8f;
  cursor: pointer;
  position: relative;
}

.import-grid-wrp > div .import-grid-head > div:first-child{
  padding: 0px 0px 0px 30px;
  position: relative;
}

/* .feedback-tag:before { */
@media screen and (min-width: 1600px) {
  .campaign-placeholder div.cs-radio-form{ min-width:600px !important; }
  .campaign-placeholder > img{ left:760px; }
  .cs-radio-form p.max-screen{ display:block; }
  .cs-radio-form p.min-screen{ display:none; }
  .embed-code{width: 700px;}
  .email-inputid{ margin: 0px 0px 40px 0px; }
  .cs-or{ margin: 0px 0px 40px 0px; }
}
/* } */

.import-grid-wrp > div .import-grid-head > div:first-child > cite{
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0px;
  top: 50%;
  margin: -8px 0px 0px;
  border-radius: 100%;
  background-color: var(--white-color);
  border: 1px solid #d3d9e5;
}

.import-grid-wrp > div .import-grid-head > div:first-child > cite svg{
  width: 8px;
  height: 9px;
  position: absolute;
  left: 3px;
  top: 3px;
  fill: var(--white-color);
}

.import-grid-wrp > div .import-grid-head > div:first-child > cite:before{
  content: "";
  position: absolute;
  height: 1px;
  width: 6px;
  background-color: #d3d9e5;
  left: 50%;
  top: 50%;
  margin: 0px 0px 0px -3px;
  z-index: 1;
}

.import-grid-wrp > div .import-grid-head > div:first-child > cite.noselect:before{ display:none; }

.feedback-tag span{
  font-size: 14px;
  line-height: 1.6;
  color: #acb7cc;
  font-style: italic;
  left: 50%;
  top: 50%;
  margin: 0px 0px 0px -3px;
  z-index: 1;
}

.import-grid-wrp > div .import-grid-head > div:first-child > cite.active{
  background-color: #6699ff;
  border-color: #6699ff;
}

.import-grid-wrp > div .import-grid-head > div:first-child > cite.active:before{
  display: none;
}

.import-grid-wrp > div .import-grid-head > div:first-child cite.active:before{
  display: none;
}

.import-grid-wrp > div .import-grid-head > div.error span{
  color:#dd2727;
}

.import-grid-wrp > div .import-grid-head > div span{
  position: relative;
  padding: 0px 32px 0px 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* display: inline-block; */
  float: left;
  min-width:auto;
  max-width:100%;
  user-select: none;
  -moz-user-select: none;
}

.import-grid-wrp > div .import-grid-head > div span::after{
  content: "";
  padding: 4px 5px;
  background: url("../images/arrow-icon.svg") center center no-repeat;
  z-index: 1;
  cursor: pointer;
  margin: -3px 0px 0px 0px;
  background-size: 10px;
  position: absolute;
  right: 16px;
  top: 50%;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.import-grid-wrp >  div .import-grid-head > div.open span::after{
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.import-grid-wrp > div .import-grid-head > div.filter-checkbox-dwn > div{
  margin: 27px 0px 10px -40px;
  left:0;
}

.import-grid-wrp>div .import-grid-head>div.filter-checkbox-dwn:first-child>div {
  margin-left: 2px;
}

.import-grid-wrp>div .import-grid-head>div.filter-checkbox-dwn:last-child>div {
  left: unset;
  right: 2px;
}

.import-grid-wrp>div .import-grid-head>div.filter-checkbox-dwn>div:before,
.import-grid-wrp>div .import-grid-head>div.filter-checkbox-dwn>div:after {
    content: "";
    clear: both;
    position: absolute;
    bottom: 100%;
    left: 50px;
    display: block;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid var(--BlueAW);
    margin-left: -7px;
}

.import-grid-wrp>div .import-grid-head>div.filter-checkbox-dwn:last-child>div:before,
.import-grid-wrp>div .import-grid-head>div.filter-checkbox-dwn:last-child>div:after {
    left: 150px;
}

.import-grid-wrp>div .import-grid-head>div.filter-checkbox-dwn>div:after {
    border-bottom: 6px solid #fff;
    border-width: 6px;
    margin-left: -6px;
}

.import-grid-wrp .filter-checkbox-dwn>div>ul li>input,
.import-grid-wrp .filter-checkbox-dwn>div>ul li>div>input {
    font-size: 14px;
}

.import-grid-wrp >  div .import-grid-head > div.open span::after{
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.import-grid-wrp > div .import-grid-head > div.filter-checkbox-dwn > div{
  margin: 27px 0px 10px -80px;
}

.import-grid-wrp > div .import-grid-head > div.filter-checkbox-dwn > div > div.search-field{
  padding: 20px 16px 0px;
  position: relative;
}

.import-grid-wrp > div .import-grid-head > div.filter-checkbox-dwn > div > div.search-field > input{
  font-size: 12px;
  line-height: 1.67;
  border-radius: 16px;
  border: 1px solid #e6e9f0;
  padding: 6px 34px 6px 14px;
  width: 100%;
}

.import-grid-wrp > div .import-grid-head > div.filter-checkbox-dwn > div > div.search-field > svg{
  position: absolute;
  right: 27px;
  top: 30px;
  cursor: pointer;
  width: 13px;
  height: 13px;
}

.import-grid-wrp > div .import-grid-head > div.filter-checkbox-dwn > div > ul{
  min-height: auto;
  max-height: 114px;
  overflow: auto;
}

.import-grid-wrp > div .import-grid-head > div.filter-checkbox-dwn > div > ul.normal-dwn{
  /* height: 180px; */
  min-height: inherit;
  max-height: 180px;
}

.import-grid-wrp > div .import-grid-list-wrp{
  overflow: auto;
  position: relative;
  max-height: calc(100vh - 408px);
  min-height: auto;
}

.import-grid-wrp > div .import-grid-list-wrp .import-grid-list{
  border: 1px solid #f0f2f5;
  border-width: 0px 0px 1px;
  height: 40px;
  cursor: pointer;
}

.import-grid-wrp > div .import-grid-list-wrp .import-grid-list > div{
  float: left;
  width: 180px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 11px 16px 11px 0px;
  line-height: normal;
  font-size: 14px;
  display: block;
  position: relative;
}

.import-grid-wrp > div .import-grid-list-wrp .import-grid-list > div:first-child{
  padding: 11px 16px 11px 30px;
}

.import-grid-wrp > div .import-grid-list-wrp .import-grid-list > div:first-child cite{
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0px;
  top: 50%;
  margin: -8px 0px 0px;
  border-radius: 100%;
  background-color: var(--white-color);
  border: 1px solid #d3d9e5;
}

.import-grid-wrp > div .import-grid-list-wrp .import-grid-list > div:first-child cite svg{
  width: 8px;
  height: 9px;
  position: absolute;
  left: 3px;
  top: 3px;
  fill: var(--white-color);
}

.import-grid-wrp > div .import-grid-list-wrp .import-grid-list > div.active:first-child cite{
  background-color: #6699ff;
  border-color: #6699ff;
}

.import-grid-wrp > div .import-grid-list-wrp .import-grid-list > div span{
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color:#181818;
}

.import-grid-wrp > div .import-grid-list-wrp .import-grid-list > div span.error-txt{
  color:#dd2727;
}

.import-popup.cs-popup-model footer:before {
  content: "";
  position: absolute;
  right: 0px;
  left: 0px;
  height: 18px;
  background-image: linear-gradient( to bottom, rgba(255, 255, 255, 0), #ffffff 82% );
  bottom: 126px;
}

.unmaped-list{
  font-size: 14px;
  line-height: 1.43;
  position: absolute;
  left: 30px;
  color: #181818;
}

.unmaped-list svg{
  width: 16px;
  height: 16px;
  fill: #181818;
  float: left;
  margin: 2px 12px 0px 0px;
}

.unmaped-list.error svg,
.unmaped-list.error{
  fill: #dd2727;
  color: #dd2727;
}

#error-bound{
  height: 100%;
}

.unmaped-list.errors-list{
  color:#dd2727;
  margin-top:-5px;
  z-index: 1;
  position: relative;
}
.unmaped-list.errors-list svg{
  fill: #dd2727;
  margin-left: 21px;
}
.file-exists {
  border-bottom: 1px solid #dd2727 !important;
}
.cs-navigation > strong {
    font-size: 27px;
    line-height: 32px;
    color: #181818;
    padding: 30px 25px;
    display: block;
}

.cs-navigation div.settings-left-menu {
    overflow: auto;
    max-height: calc(100vh - 195px);
}
.sub-title-menu{
    font-size: 14px;
    line-height: 17px;
    color: #8495B1;
    margin: 36px 0px 10px 26px;
    display: block;
}

.advance-link{
    display: inline-block;
    text-decoration: none;
    font-size: 14px;
    margin: 0px 0px 40px;
    color: #3c60da;
}

.widget-customise > div{
  display:none;
}

.widget-customise.show > div{
  display:block;
}

/* dashboard icon */
.dashboard-list-wrp{ padding: 0px 40px;}
.dashboard-list-wrp .dashboard-list-title{
	padding: 0px 0px 0px 23px; 
	position: relative;
}

.dashboard-list-wrp .dashboard-list-title > figure{
	width: 90px;
    height: 90px;
    background-color: #ececec;
    border-radius: 100%;
    font-size: 44px;
    color: #ffffff;
    text-align: center;
    line-height: 86px;
    border: 2px solid #ffffff;
    margin: -20px 0px 0px;
    z-index: 1;
    position: relative;
}

.dashboard-list-wrp .dashboard-list-title > figure img{
  object-fit: cover;
  width:100%;
  height:100%;
  border-radius:100%;
}

.dashboard-list-wrp .dashboard-list-title > figure cite{ display:none; }
.dashboard-list-wrp .dashboard-list-title > figure.online cite{
    width: 12px;
    height: 12px;
    background-color: #35cf62;
    position: absolute;
    border-radius: 100%;
    bottom: 6px;
    right: 5px;
    border: 2px solid #ffffff;
    display:block;
}

.dashboard-list-wrp .dashboard-list-title > label{
    font-size: 20px;
    color: #181818;
    position: absolute;
    left: 130px;
    top: 38px;
    font-weight: 500;
}

.dashboard-list-wrp .dashboard-list-title > cite{
  font-size: 12px;
  line-height: 1.58;
  color: #191919;
  border: 1px solid #e6e9f0;
  padding: 6px 18px;
  border-radius: 15px;
  position: absolute;
  right: 0px;
  top: 40px;
  cursor: pointer;
}

.dashboard-list-wrp .dashboard-list-title > a{
    float: right;
    position: relative;
    margin: -50px -10px 0px;
}

.dashboard-list-wrp .dashboard-list-title > a svg{
    width: 30px;
    height: 30px;
    background-color:#ececec;
    border-radius: 100%;
}

.dashboard-list-wrp .dashboard-list-title > a:nth-child(4){ right: 46px; }
.dashboard-list-wrp .dashboard-list-title > a:nth-child(5){ right: 92px; }

.dashboard-list-wrp .tab-nav{ margin: 40px 0px 40px 0px; }
.dashboard-list-wrp .tab-nav li.active > div.activity_list{ 
  right:0px;
  height: calc(100vh - 230px);  
  overflow: auto;
}

.dashboard-list-wrp .tab-nav li.active > div.activity_list label{ color: #181818;}
.dashboard-list-wrp .tab-nav li.active > div.activity_list p{
    color: #727c8f;
    font-size: 14px;
    line-height: 1.71;
    padding: 20px 100px 0px 40px;
    position: relative;
}
.dashboard-list-wrp .tab-nav li.active > div.activity_list p svg{
	width: 24px;
  height: 24px;
  margin: -1px 12px 0px 0px;
  position: absolute;
  left:0px;
}

.dashboard-list-wrp .tab-nav li.active > div.activity_list p span{
  color: #4684ff;
  max-width: 350px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  margin: -7px 5px;
}

.dashboard-list-wrp .tab-nav li.active > div.activity_list p b{
  margin: 0px 5px;
  color: #181818;
}
.dashboard-list-wrp .tab-nav li.active > div.activity_list p small{ 
  position: absolute;
  right: 0px;
  top: 20px;
 }

/* .conversation-history-wrp{
  width:635px;
  border:1px solid #e6e9f0;
  border-radius:8px;
  padding:0px 30px;
  left:0px !important;
  left: -30px !important;
  margin: 30px 0px 0px !important;
} */

.activity_list {
   max-height: calc(100vh - 228px);
   overflow: auto;
 }

.conversation-history-wrp{
  border:1px solid #e6e9f0;
  border-radius:8px;
  padding:0px 30px;
  left: -30px !important;
  margin: 30px 0px 0px 0px !important;
}
.conversation-history-wrp > code{
  font-size: 16px;
  color: #1c1c1c;
  font-weight: 500;
  margin: 20px 0px;
  display: inherit;
  cursor:pointer;
}
.conversation-history-list{
  margin: 0px 0px 0px;
  min-height: auto;
  max-height: calc(100vh - 340px);
  overflow: auto;
  border: 1px solid #e6e9f0;
  border-width: 1px 0px 0px;
}

.conversation-history-list li{
  position:relative;
  padding:27px 0px 20px;
  border: 1px solid #e6e9f0;
  border-width:0px 0px 1px;
  cursor:pointer;
}

.conversation-history-list li:last-child{ border-color:#ffffff; }
.conversation-history-list li figure{
  width:30px;
  height:30px;
  border-radius:100%;
  background-color:#ececec;
  position: absolute;
}

.conversation-history-list li figure img{
  font-size: 15px;
  color: #ffffff;
  font-weight: 600;
  line-height: 30px;
  background-image: url("../images/user-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 12px;
}

.conversation-history-list li figure img{ 
  border-radius:100%; 
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.conversation-history-list li figure em{
  width: 30px;
  height: 30px;
}

.conversation-history-list li label{
  font-size: 14px;
  color: #556784;
  line-height:normal;
  margin: 0px 0px 0px 44px;
}

.conversation-history-list li p{
  font-size: 14px;
  color:#181818;
  /* font-weight:600; */
  margin: 6px 0px 0px 44px;
  line-height: 1.71;
}

.conversation-history-list li label em {
  width: 200px;
  display: inline-block;
}

.conversation-history-list li p em { width: 150px; }

.conversation-history-list li span{
  font-size:12px;
  color:#8495b1;
  position:absolute;
  top:27px;
  right:0px;
}

.segment-list{
  position: absolute;
  right: -310px;
  width: 260px;
  top: 30px;
}

.segment-list label{
  font-size:14px;
  line-height:1.36;
  color:#181818;
  font-weight:600;
  padding:0px 0px 15px;
  display:block;
  border:1px solid #e6ebf1;
  border-width:0px 0px 1px;
}

.segment-list a{
  font-size: 14px;
  color: #191919;
  line-height: 1.36;
  text-decoration: none;
  display: block;
  margin: 26px 0px;
  position: relative;
  padding: 0px 0px 0px 40px;
}

.segment-list a svg{
  width: 26px;
  height: 26px;
  background-color: #ececec;
  cursor: default;
}

.segment-list a figure{
  width: 26px;
  height: 26px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  margin: -13px 0px 0px;
  left: 0px;
}

.segment-list a figure img{
  width: 26px;
  height: 26px;
}

.segment-list a figure i{
  position: absolute;
  width: 16px;
  height: 16px;
  border: 2px solid #ffffff;
  border-radius: 100%;
  text-align: center;
  right: -6px;
  bottom: -4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.segment-list a figure i svg{
  background: none;
  width: 6px;
  height: 6px;
  display: block;
}

.detail-info{
  position: absolute;
  width: 323px;
  background-color: #ffffff;
  z-index: 9;
  box-shadow: 0 3px 4px 0 rgb(10 31 68 / 15%), 0 0 4px 0 rgb(10 31 68 / 8%);
  border-radius: 5px;
  padding: 25px;
  left: 10px;
  /* left:48px;
  top:-57px; */
  cursor: default;
  margin:46px 0px 0px 0px;
}
.detail-info > div{
   position: relative;
   padding: 10px 0px 9px 71px;
}
.detail-info > div figure{
  width: 58px;
  height: 58px;
  border-radius: 100%;
  background-color: #5183b0;
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 23px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  line-height: 58px;
}

.detail-info > div figure img{
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}
.detail-info
.detail-info > div figure cite{ display:none; }
.detail-info > div figure.online cite{
  width: 12px;
  height: 12px;
  background-color: #35cf62;
  position: absolute;
  border-radius: 100%;
  bottom: 0px;
  right: 3px;
  border: 2px solid #ffffff;
  display:block;
}

.detail-info > div > label{
  font-size: 16px;
  line-height: 1.19;
  color: #1a1a1a;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.detail-info > div > p{
  font-size: 12px;
  color: #727c8f;
  line-height: 1.58;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.detail-info > ul{ margin: 24px 0px 0px; }
.detail-info > ul li{ margin: 6px 0px 0px; }
.detail-info > ul li a{
  text-decoration: none;
  font-size: 14px;
  line-height: 1.71;
  color: #181818;
  position: relative;
  padding: 0px 0px 0px 28px;
}

.detail-info > ul li a svg{
  width: 15px;
  height: 15px;
  position: absolute;
  left: 0px;
  top: 1px;
}

.info-detail{
  height: calc(100vh - 230px);
  overflow: auto;
  padding: 0px 0px 30px;
}

.history-conversation-wrp{
  /* position: absolute; */
  /* width: 635px; */
  padding: 0;
  border: 1px solid #e6e9f0;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  z-index: 1;
  height: calc(100vh - 300px);
  margin: 30px 0px 0px -30px;
  position: relative;
}

.history-conversation-wrp > h4{
  position: absolute;
  font-size: 16px;
  color: #191919;
  font-weight: 500;
  left: 25px;
  right: 25px;
  padding: 23px 0px 23px 45px;
  border: 1px solid #e6e9f0;
  border-width: 0px 0px 1px;
}

.history-conversation-wrp > h4 code{
  border-radius: 100%;
  padding: 5px 6px;
  background: rgba(232, 232, 232, 0.5) url(../images/backArrow-icon.svg) center center no-repeat;
  position: absolute;
  bottom: 33px;
  z-index: 1;
  cursor: pointer;
  width: 28px;
  height: 28px;
  top: 50%;
  left: 5px;
  margin: -14px 0px 0px 0px;
}

.widget-customise a.advance-link svg {
  pointer-events: none;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  width: 20px;
  height: 8px;
  display: inline-block;
  margin: 0px 0px 0px 3px;
}

.widget-customise.show a.advance-link svg {
  pointer-events: none;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}


.widget-configuration > div.customize-scroll{
  overflow: auto;
  height: calc(100vh - 172px);
  padding: 0px 0px 0px 10px;
  margin: 0px 0px 0px -10px;
  background-size: 4px;
}

.history-conversation-wrp .cs-user-conversation{
  /* position: relative; */
  right: 0px;
  max-height: calc(100vh - 475px);
  /* top: 0px; */
  margin: 60px 0px 0px;
  padding: 0px 6px;
}

.history-conversation-wrp .cs-user-conversation.scroll:before,
.history-conversation-wrp .cs-user-conversation.scroll:after{ display:none; }

.history-conversation-wrp .cs-typing{ 
  border-radius: 0px 0px 8px 8px;
  left: 25px;
  bottom: 1px;
  right: 25px !important;
  padding: 24px 14px;
}

.history-conversation-wrp .cs-typing-footer .cs-typing-attachment svg,
.history-conversation-wrp .cs-typing-footer .cs-typing-attachment input[type="file"]{
   left:14px; 
}

.history-conversation-wrp .cs-typing-footer>svg{
  left:40px;
}

.history-conversation-wrp .cs-typing-footer>svg.resolve-icon{
  left:78px;
}

.history-conversation-wrp .cs-typing-footer .more-icon-dwn{
  bottom:27px;
  left:108px;
}
.history-conversation-wrp .cs-typing-footer.cs-typing-stop .more-icon-dwn{ left:73px; }
.history-conversation-wrp .cs-typing-footer .sent-icon-btn{ right:10px; }

.cs-conversations-filter{
  width: 320px;
  position: absolute;
  top: 0px;
  left: 0px;
  border: 1px solid var(--border-color);
  border-width: 0px 1px 0px 0px;
  z-index: 1;
  background-color: var(--white-color);
  box-shadow: -1px 8px 7px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 20px;
  text-align: center; 
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.cs-conversations-filter.open {
  opacity: 1;
  visibility: visible;
}  

.cs-conversations-filter > p{
  font-size: 16px;
  line-height: 1.43;
  color: var(--txt-black-color);
  display: block;
  margin: 36px 0px 10px;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 600;
  text-align:left;
}

.cs-conversations-filter > label{
  font-size: 14px;
  line-height: 1.43;
  color: var(--txt-black-color);
  display: block;
  margin: 20px 0px;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0px 0px 0px 30px;
  position: relative;
  text-align:left;
}

.cs-conversations-filter > label cite,
.cs-conversations-filter > p cite{
  float:right;
}

.cs-conversations-filter > a{
  color:#3c60da;
  font-size:13px;
  font-weight:500;
  text-align:center;
  text-decoration: none;
}

.cs-conversations-filter > a svg{
  width: 16px;
  height: 5px;
}

.cs-conversations-filter > label svg{
  width:20px;
  height:15px;
  position:absolute;
  left: 0px;
  top:2px;
}

.cs-conversations-filter > input{
  font-size: 15px;
  display: block;
  border: 0;
  width: 240px;
}

.cs-conversations-filter > code{
  width: 25px;
  height: 25px;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

/* .cs-conversations-filter > code svg{
  width: 25px;
  height: 25px;
  display:none;
}

.cs-conversations-filter > code svg:nth-child(2),
.cs-conversations-filter > code.open svg:nth-child(1){ display:none; }
.cs-conversations-filter > code svg:nth-child(1){ display:block; } */
.cs-conversations-filter > code svg{
  width: 25px;
  height: 25px;
  display:block;
}

.cs-chat-lineup li{ 
  padding: 15px 20px 15px 68px; 
  cursor: pointer;
  position: relative;
  z-index: 0;
}
.cs-chat-lineup li label{
  font-size: 15px;
  font-weight: 600;
  line-height: 1.27;
  color: #181818;
  margin: 0px 0px 6px;
  display: block;
  cursor: pointer;
}
.cs-chat-lineup li p{
  font-size: 13px;
  color: #8f99ad;
  line-height: normal;
}

.cs-chat-lineup li svg{
  width: 40px;
  height: 40px;
  border-radius: 100%;
  position: absolute;
  left: 17px;
}

.cs-my-conversations header > code{
  width: 25px;
  height: 25px;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.cs-my-conversations header > code svg{
  width: 25px;
  height: 25px;
  display: block;
}

.cs-conversation>header > code{
  padding: 5px 6px;
  background: #e8e8e8 url(../images/backArrow-icon.svg) center center no-repeat;
  position: absolute;
  right: 20px;
  bottom: 33px;
  z-index: 3;
  cursor: pointer;
  width: 32px;
  height: 32px;
  top: 50%;
  left: 16px;
  margin: -16px 0px 0px 0px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  border-radius: 100%;
  background-size: 7px;
  opacity: 0.9;
}

.cs-conversation>header > svg{
  width: 50px;
  height: 50px;
  position: absolute;
  left: 19px;
  z-index: 1;
  top: 50%;
  margin: -24px 0px 0px 0px;
}

.cs-proactive-list li span.queue-info{  padding: 0px 0px 0px 80px;
  position: relative; }

.cs-proactive-list li span.queue-info figure{
  width: 34px;
  height: 34px;
  border-radius: 100%;
  position: absolute;
  left: 32px;
  top: 0px;
}

.cs-proactive-list li span.queue-info figure img{
  width: 34px;
  height: 34px;
  border-radius: 100%;
  object-fit: cover;
}

.cs-proactive-list li span.queue-info p.queue-msg{
  font-size: 13px;
  line-height: normal;
  margin: 6px 0px 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.cs-proactive-list li span.queue-info code{
  padding: 3px;
  border-radius: 100%;
  z-index: 1;
  position: absolute;
  top: 25px;
  left: 58px;
  border: 1px solid var(--white-color);
}

.cs-proactive-list li span.queue-info code.available{
  background-color: var(--available-color);
}

.cs-proactive-list li span.queue-info code.offline{
  background-color: var(--offline-color);
}

.tab-nav>li>div p.first:after{
  content: "First";
  text-transform: uppercase;
  position: absolute;
  font-family: "Lato", sans-serif;
  font-size: 9px;
  padding: 4px 6px;
  border: 1px solid #dbe0e7;
  border-radius: 3px;
  color: #9da8b8;
  font-weight: bold;
  margin: 0px 0px 0px 14px;
  line-height:9px;
}
.no-interaction {
  text-align: center;
  margin: 100px 0px !important;
  position: absolute;
  left: 0px;
  right: 0px;
}

.no-interaction strong {
  font-size: 15px;
  color: var(--txt-black-color);
  margin: 28px 0px 10px;
  display: block;
  line-height: normal;
}

.no-interaction p{
  font-size: 13px;
  line-height: 1.38;
  color: var(--txt-gray-color);
}

.no-interaction button {
  margin: 30px 0px 0px;
  border-radius: 20px;
  padding: 0px 18px;
}

.dashboard-list-wrp .tab-nav li.active > div.activity_list p figure{
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  left: 0px;
}

.dashboard-list-wrp .tab-nav li.active > div.activity_list p figure em{
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  top: -3px;
}

.dashboard-list-wrp .tab-nav li.active > div.activity_list p > em{ width:200px; }

.people-grid-wrp{ padding :0px; }
.people-grid-head{ margin-left: 24px; }
.people-grid-list{ padding-left: 24px; }

.people-grid-list-wrp.people-scroll-height > div > div,
.people-grid-list-wrp.people-scroll-height > div > div > div{
  height: calc(100vh - 151px) !important;
  max-height: calc(100vh - 151px) !important;
  min-height: calc(100vh - 151px) !important;
  overflow: inherit !important;
}

.cs-dashboard-map{
  position: absolute;
  height: 89px;
  top: 0px;
  left: 0px;
  right: 0px;
}

.cs-my-conversations .cs-chat-list{
  margin: 0px;
  overflow: auto;
  height: calc(100vh - 193px);
}

.segment-list > div{
  height: auto;
  max-height: calc(100vh - 293px);
  overflow: auto;
}

.people-grid-list.card-top .detail-info{ margin: -185px 0px 0px 0px; }
.people-grid-list.card-top .detail-info:before{
  border-color: #ebeced transparent transparent;
  top: 187px;
}

.people-grid-list.card-top .detail-info:after{
  border-color: var(--white-color) transparent transparent;
  top: 185px;
}

.cs-setup-returning{
  padding: 80px 0;
  width: 900px;
  position: absolute;
  left: 200px;
}

#acknowledge-container,
#success_steps
{
  height: auto;
  position: fixed;
  left: 200px;
  top: 50%;
  transform: translateY(-50%);
}

.gmnoprint div {
    background:none !important;
}

.cs-typing-footer .sent-icon-btn.send-mail-btn{
  color: #ffffff;
  width: 120px;
  border-radius: 20px;
  background-position: 12px;
  line-height: 2.3;
  font-size: 14px;
  text-align: right;
  padding: 0px 15px 0px 0px;
  font-weight: 600;
}

.cs-user-conversation .conversation-msg > svg.email-icon{
  width: 15px;
  height: 15px;
  fill: #AEB7C3;
}


.cs-email-reply-attachment{
  color: #181818;
  font-size: 13px;
  background-color: #f8f9fa;
  font-family: 'Lato', sans-serif;
  width: 300px;
  border-radius: 4px;
  cursor: default;
  position: relative;
  padding: 10px 43px 10px 10px;
  height: 36px;
  margin: 0px 0px 10px;
}

.cs-email-reply-attachment img{
  width: 16px;
  height: 19px;
  float: left;
  margin: 0px 7px 0px 0px;
  object-fit: cover;
}

.cs-email-reply-attachment svg{
  position: absolute;
  right: 13px;
  top: 50%;
  margin: -3px 0px 0px 0px;
  cursor: pointer;
}

.cs-email-reply-attachment span{
  display: inline-block;
  width: 220px;
  white-space: nowrap;
  overflow: hidden;
}

.cs-email-reply-attachment i.loading-progress{
  position: absolute;
  height: 4px;
  border-radius: 34px;
  background-color: #EBEBEB;
  width: 110px;
  top: 50%;
  right: 42px;
  display: none;
}

.cs-email-reply-attachment i.loading-progress cite{
  position: absolute;
  height: 4px;
  width: 11%;
  background-color: #4684FF;
  border-radius: 34px;
}

.cs-email-reply-attachment.loading i.loading-progress{ display:block; }
.cs-email-reply-attachment.loading span{ width:110px; }

.cs-email-reply-wrp{
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  height: 20px;
  background-image: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0));
  z-index: 1;
  pointer-events: none;
}
.cs-setup-returning .sc-cHjxUU.fymlgf{
  position: fixed;
  top: 50%;
  transform: translate(0px, -50%);
}

.resolve-btn-dwn ul li:hover svg.resolve-icon, 
.resolve-btn-dwn ul li:hover svg.pending-icon{
  fill:#40495b;
}

.disabled-pending {
  pointer-events: none;
  opacity: 0.6;
}

.cs-dashboard .cs-conversation-wrp:not(.cs-conversation-wrp.min-screen,.cs-conversation-wrp.integration) {
  left: 64px;
  width: calc(100% - 64px);
  height: calc(100vh);
}

.cs-dashboard .cs-widget-notification{
  left: 384px;
}

.cs-dashboard .cs-widget-notification.full{
  left: 64px;
}

main.float-widget ~ div .chatsupport-wrp .chatsupport-button {
  bottom: 120px;
}

main.onboard ~ div .chatsupport-wrp .chatsupport-button {
  bottom: 70px;
  right: 45px;
}

@media screen and (max-width: 1200px) {
  .cs-navigation{
    width: 80px;
  }

  .cs-integrations-wrp {
    margin: 0 0 0 144px;
  }

  .cs-navigation-list>li a, 
  .cs-nav-list>li a,
  .sub-title-menu,
  .cs-navigation > strong,
  .cs-navigation > h2,
  .cs-navigation ul.tab-nav,
  .cs-navigation-list.cs-my-team-list.scroll:before{
    display: none;
  }
  .cs-navigation label cite svg{
    left: 14px;
  }
  .cs-settings-wrp:not(.slide-col3){
    left: 80px;
    width: calc(100% - 80px) ;
  }
  .cs-navigation-list.cs-my-team-list{
    height: calc(100vh - 110px);
  }
  .cs-navigation-list>li, .cs-nav-list>li{
    padding: 19px 20px 19px 59px;
  }
  .cs-dashboard .cs-widget-notification{
    left: 144px;
  }
}
/* codemirror  */ 

.popup-new-content .CodeMirror-sizer {
  padding: 20px;
  padding-right: 40px !important;
  background: #f6f6f6;
}

.compaign-content .CodeMirror-sizer {
  margin-top: 0px;
  width: 90%;
  background: #E7E7E7;
  padding: 10px;
}

.CodeMirror {
  border-radius: 10px;
}

span[class^="cm-"],span[role=presentation] {
  color: #484848 !important;
  line-height: 23px;
}

.CodeMirror-sizer {
  position: relative;
  border-right: none;
  height: 100%;
  border-radius: 10px;
  z-index: 0;
}

/* pricing popup styling */

.pricing-popup {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  top: 0;
  position: fixed;
  z-index: 120;
  width: 100%;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.pricing-popup .cs-popupbg {
  opacity: 0.8;
}

.blue-dot {
  color: #2355F3;
}

.pricing-popup .wrapper {
  display: flex;
  gap: 15px; 
  margin-top: 80px;
  color: #111827;
}

.pricing-popup > strong {
  color: #F6FBFD;
  font-size: 42px;
  font-weight: 600;
  margin-bottom: 20px;
}

.pricing-popup > p {
  color: #F6FBFD;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
}

.pricing-popup > p a {
  padding-bottom: 3px;
  border-bottom: 0.5px solid white;
  cursor: pointer;
}

.pricing-popup .pricing-box {
  background: white;
}

.pricing-box {
  padding: 35px 55px;
  border-radius: 10px;
  position: relative;
  
}

.pricing-box::before {
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg width='154' height='177' viewBox='0 0 154 177' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_24_2999)'%3E%3Cpath d='M24.3945 9H129.265V72.8759V136.752H90.1772V119.252H111.787V72.8759V26.5002H41.873V74.1409L24.3945 86.7853V9Z' fill='url(%23paint0_linear_24_2999)'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d_24_2999' x='0.24245' y='0.737446' width='153.175' height='176.056' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeMorphology radius='7.62697' operator='erode' in='SourceAlpha' result='effect1_dropShadow_24_2999'/%3E%3CfeOffset dy='15.8895'/%3E%3CfeGaussianBlur stdDeviation='15.8895'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_24_2999'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_24_2999' result='shape'/%3E%3C/filter%3E%3ClinearGradient id='paint0_linear_24_2999' x1='24.3945' y1='9' x2='121.309' y2='139.896' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2363A6FA'/%3E%3Cstop offset='1' stop-color='%232648C0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  position: absolute;
  top: -53px;
  right: -40px;
  height: 130px;
  width: 130px;
}


.pricing-popup > *:not(:first-child):not(:nth-child(2)) {
  position: relative;
  z-index: 5;
}

.pricing-popup > svg {
  position: absolute;
  z-index: 5;
  top: 50px;
  right: 40px;
  cursor: pointer;
}

.pricing-box h3,.pricing-box .cost,.pricing-popup > strong{
 font-family: "Work Sans", sans-serif;
}

.pricing-box h3 {
  font-size: 19px;
  font-weight: 700;
  margin-bottom: 5px;
}

.pricing-box p {
  font-size: 10px;
  color: #6B7280;
  margin-bottom: 10px;
}

.pricing-box > span {
  position: absolute;
    top: -31px;
    background: black;
    color: white;
    font-size: 11px;
    padding: 10px 25px;
    border-radius: 6px 6px 0 0;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 700;
}

.pricing-box .cost {
  width: 120px;
  border-bottom: 0.5px solid #D4D4D4;
  padding: 7px 0;
  margin-bottom: 17px;
}

.pricing-box .cost span {
  vertical-align: super;
}

.pricing-box .cost > span {
  font-size: 15px;
  font-weight: 700;
}

.pricing-box .cost > strong {
  font-size: 30px;
  font-weight: 600;
}

.pricing-box .cost > small {
  font-size: 15px;
  font-weight: 500;
}

.pricing-box li {
  position: relative;
  padding-left: 20px;
  font-size: 11px;
}

.pricing-box ul li:not(:last-child) {
  margin-bottom: 20px;
}

.pricing-box li::before {
  content: '';
  background: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.09752 0.971338C9.09752 1.15009 9.01808 1.30899 8.89891 1.42816L3.81426 6.51281C3.69509 6.63198 3.53619 6.69157 3.37729 6.69157C3.19854 6.69157 3.03964 6.63198 2.92047 6.51281L0.378147 3.97049C0.258975 3.85131 0.19939 3.69242 0.19939 3.51366C0.19939 3.15615 0.477456 2.87808 0.834971 2.87808C0.993866 2.87808 1.15276 2.95753 1.27193 3.0767L3.37729 5.1622L8.00512 0.534376C8.12429 0.415204 8.28319 0.335757 8.46194 0.335757C8.7996 0.335757 9.09752 0.613823 9.09752 0.971338Z' fill='%232355F3'/%3E%3C/svg%3E%0A") no-repeat left center; 
  position: absolute;
  height: 100%;
  width: 15px;
  left: 0;
}

.pricing-popup .wrapper .pricing-box:not(:last-child) button {
 background: #4A88FC;
}

.pricing-popup .wrapper .pricing-box:last-child button,
.pricing-popup .wrapper .pricing-box:not(:last-child) button:hover {
  background: #2355F3;
}

.pricing-popup .wrapper .pricing-box:last-child button:hover {
  background: #1F43DC;
}


.pricing-box button {
  outline: none;
  border: none;
  width: 100%;
  padding: 9px 0;
  border-radius: 20px;
  margin-top: 25px;
  font-size: 14px;
  transition: background-color .3s;
  font-weight: 700;
}

.pricing-box button:hover {
  background-color: #1F43DC
}
	
/* css for billing section */

#aw-billing {
  padding-top: 30px !important;
}

.fullscreen .cs-conversation-wrp {
  position: relative;
  width: calc(100% - 64px);
  left: 64px;
}