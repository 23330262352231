@charset "utf-8";
/*EuclidCircularB font added code here -----------------------------------------------------------------*/
@font-face {
  font-family: "EuclidCircularB";
  src: url("https://assets.chatsupport.co/website/font/EuclidCircularB-Regular-WebS.woff2")
    format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "EuclidCircularB";
  src: url("https://assets.chatsupport.co/website/font/EuclidCircularB-Semibold-WebS.woff2")
    format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
.popup h3 small {
  display: block;
  font-size: 14px;
  color: #94989e;
  font-family: "Lato", sans-serif;
  padding: 10px 0px 0px;
}
@keyframes myAnimate {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    transform: translateX(100px);
  }
}

@-webkit-keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-moz-keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-o-keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes wave {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

/* .font-ss-pro
    {
    	font-family: 'Source Sans Pro', sans-serif !important;
    } */

/* --- Typing status chat bubble animation css added by siva --- */
.typing-indicator span {
  position: relative;
  -webkit-animation: 2s bulge infinite ease-out;
  animation: 2s bulge infinite ease-out;
}

@-webkit-keyframes bulge {
  50% {
    -webkit-transform: scale(1.07);
    transform: scale(1.07);
  }
}
@keyframes bulge {
  50% {
    -webkit-transform: scale(1.07);
    transform: scale(1.07);
  }
}

strong { font-weight: bold !important; }

.typing-indicator span i {
  height: 5px;
  width: 5px;
  float: left;
  margin: 0 1px;  
  background-color: #9e9ea1;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}

.typing-indicator span i:nth-of-type(1) {
  -webkit-animation: 1s blink infinite 1s;
  animation: 1s blink infinite 1s;
}

.typing-indicator span i:nth-of-type(2) {
  -webkit-animation: 1s blink infinite 1s;
  animation: 1s blink infinite 1s;
}

.typing-indicator span i:nth-of-type(3) {
  -webkit-animation: 1s blink infinite 2s;
  animation: 1s blink infinite 2s;
}
.chat-from.typing-indicator span i {
  background-color: #ffffff;
}
@-webkit-keyframes blink {
  50% {
    opacity: 1;
  }
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}
/* --- Typing status css and animation ends here --- */

/* To Remove Google map copyrights text in chat header map section  */
.gmnoprint {
  display: none !important;
}

/* @font-face {
        font-family: 'Effra-light';
        font-style: normal;
        src:url("../fonts/Effra/Light.ttf");
        font-weight: 300;
    }
    
    @font-face {
        font-family: 'Effra';
        font-style: normal;
        src:url("../fonts/Regular.ttf");
        font-weight: 400;
    }
    
    @font-face {
        font-family: 'Effra-medium';
        font-style: normal;
        src:url("../fonts/Effra/Medium.ttf");
        font-weight: 500;
    } */

/* @font-face {
        font-family: 'Font Awesome\ 5 Brands';
        font-style: normal;
        src:url("../fonts/fa-brands-400.ttf");
    } */

@font-face {
  font-family: "Lato";
  font-style: normal;
  src: url("../fonts/Lato-Regular.ttf");
}

@font-face {
  font-family: "Font Awesome 5 Regular";
  font-style: normal;
  src: url("../fonts/fontawesome-pro-regular-400.ttf");
}

@font-face {
  font-family: "Font Awesome 5 Solid";
  font-style: normal;
  src: url("../fonts/fontawesome-pro-solid-900.ttf");
}

@font-face {
  font-family: "FontAwesome";
  font-style: normal;
  src: url("../fonts/fontawesome-webfont.ttf");
}


* {
  /*-webkit-font-smoothing: antialiased;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  outline: none;
  /*font-family: "Effra";*/
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

/*Placeholder code here -----------------------------------------------------------------*/
::-webkit-input-placeholder {
  /* Chrome */
  color: #c5d0de;
  /*color:rgba(41, 53, 65, 0.7);*/
  font-family: "Lato", sans-serif;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #c5d0de;
  /*color:rgba(41, 53, 65, 0.7);*/
  font-family: "Lato", sans-serif;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #c5d0de;
  /*color:rgba(41, 53, 65, 0.7);*/
  opacity: 1;
  font-family: "Lato", sans-serif;
}
:-moz-placeholder {
  /* Firefox 4 - 18 */
  color: #c5d0de;
  /*color:rgba(41, 53, 65, 0.7);*/
  opacity: 1;
  /* :-ms-input-placeholder */
}

main {
  font-family: "Lato", sans-serif;
  /* background-color: #FAFBFC; */
  /* height: 100vh; */
}

.show {
  display: block;
}
.hide {
  display: none;
}
.db {
  display: block !important;
}

.dn {
  display: none !important;
}

.bgWhite {
  background-color: #ffffff;
}

.clear {
  clear: both;
}

.clear-after:after {
  content: "";
  display: table;
  clear: both;
}

.inlineBlock,
.inline-block {
  display: inline-block;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.disable {
  opacity: 0.5;
  -webkit-transition: opacity 0.4s ease;
  -o-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
}

.enable {
  opacity: 1;
  -webkit-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}

.btn_disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

code {
  font-family: "Lato", sans-serif;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: "Lato", sans-serif;
  /*background-color: #F2F7FB;*/
  overflow-x: hidden;
  /* font-weight: 400; */
}

hr {
  padding: 0px;
  margin: 0px 0px 0px;
  border: 1px solid #f0f3f8;
  border-width: 1px 0px 0px;
  opacity: 0.5;
}

/* transprentBg code here -----------------------------------------------------------------*/
.transprentBg {
  display: none;
  background-color: #000;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 99;
  opacity: 0.3;
}

/*popup code here -----------------------------------------------------------------*/
.popup {
  background-color: #ffffff;
  z-index: 999;
  border-radius: 5px;
  -moz-box-shadow: 0 0 15px 0 rgba(43, 51, 61, 0.2);
  -webkit-box-shadow: 0 0 15px 0 rgba(43, 51, 61, 0.2);
  box-shadow: 0 0 15px 0 rgba(43, 51, 61, 0.2);
  position: relative;
  max-width: 600px;
  min-width: auto;
}

.popup code.popupClose {
  position: absolute;
  right: 19px;
  top: 13px;
  color: #354052;
  font-size: 28px;
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: opacity 0.4s ease;
  -o-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
}

.popup code.popupClose:hover {
  opacity: 0.6;
}

.popup h3 {
  font-size: 16px;
  color: #354052;
  padding: 16px 20px;
  border: 1px solid #e6eaee;
  border-width: 0px 0px 1px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  line-height: 19px;
  background-image: linear-gradient(to top, #f4f7fa, #ffffff);
  border-radius: 5px 5px 0px 0px;
}

button {
  /* font-size: 14px; */
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  padding: 6px 10px;
  margin: 0px;
  /* font-weight: 600;
  line-height: 1.36; */
  /* font-family: "Lato", sans-serif; */
}

/* Draft CSS */
.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
  height: inherit;
  text-align: initial;
}

.public-DraftEditor-content {
  overflow: auto;
  max-height: 100px;
  min-height: auto;
  line-height: 18px;
}

.public-DraftEditor-content[contenteditable="true"] {
  -webkit-user-modify: read-write-plaintext-only;
}


/*chip code here -----------------------------------------------------------------*/
.chip {
  border: 1px solid #bfc7d1;
  padding: 5px 25px 4px 5px;
  border-radius: 3px;
  position: relative;
  font-size: 11px !important;
  margin: 10px 5px 0px 0px;
  color: #354052 !important;
  font-weight: 600;
  max-width: 100%;
  min-width: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.DraftEditor-editorContainer {
  background-color: rgba(255, 255, 255, 0);
  border-left: 0.1px solid transparent;
  position: relative;
  z-index: 1;
}

.public-DraftEditor-block {
  position: relative;
}

.DraftEditor-alignLeft .public-DraftStyleDefault-block {
  text-align: left;
}

.DraftEditor-alignLeft .public-DraftEditorPlaceholder-root {
  left: 0;
  text-align: left;
}

.DraftEditor-alignCenter .public-DraftStyleDefault-block {
  text-align: center;
}

.DraftEditor-alignCenter .public-DraftEditorPlaceholder-root {
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.DraftEditor-alignRight .public-DraftStyleDefault-block {
  text-align: right;
}

.DraftEditor-alignRight .public-DraftEditorPlaceholder-root {
  right: 0;
  text-align: right;
}

.public-DraftEditorPlaceholder-root {
  color: #9197a3;
  position: absolute;
  z-index: 1;
  font-weight: 600;
  font-size: 14px;
}

.public-DraftEditorPlaceholder-hasFocus {
  color: #bdc1c9;
}

.DraftEditorPlaceholder-hidden {
  display: none;
}

.public-DraftStyleDefault-block {
  position: relative;
  white-space: pre-wrap;
}

.public-DraftStyleDefault-ltr {
  direction: ltr;
  text-align: left;
}

.public-DraftStyleDefault-rtl {
  direction: rtl;
  text-align: right;
}

.public-DraftStyleDefault-listLTR {
  direction: ltr;
}

.public-DraftStyleDefault-listRTL {
  direction: rtl;
}

.public-DraftStyleDefault-ol,
.public-DraftStyleDefault-ul {
  margin: 16px 0;
  padding: 0;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
  margin-left: 1.5em;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listRTL {
  margin-right: 1.5em;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
  margin-left: 3em;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listRTL {
  margin-right: 3em;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
  margin-left: 4.5em;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listRTL {
  margin-right: 4.5em;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR {
  margin-left: 6em;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listRTL {
  margin-right: 6em;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR {
  margin-left: 7.5em;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listRTL {
  margin-right: 7.5em;
}

.public-DraftStyleDefault-unorderedListItem {
  list-style-type: square;
  position: relative;
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
  list-style-type: disc;
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1 {
  list-style-type: circle;
}

.public-DraftStyleDefault-orderedListItem {
  list-style-type: none;
  position: relative;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  left: -36px;
  position: absolute;
  text-align: right;
  width: 30px;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listRTL:before {
  position: absolute;
  right: -36px;
  text-align: left;
  width: 30px;
}

.public-DraftStyleDefault-orderedListItem:before {
  content: counter(ol0) ". ";
  counter-increment: ol0;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1:before {
  content: counter(ol1) ". ";
  counter-increment: ol1;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2:before {
  content: counter(ol2) ". ";
  counter-increment: ol2;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth3:before {
  content: counter(ol3) ". ";
  counter-increment: ol3;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth4:before {
  content: counter(ol4) ". ";
  counter-increment: ol4;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-reset {
  counter-reset: ol0;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-reset {
  counter-reset: ol1;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-reset {
  counter-reset: ol2;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-reset {
  counter-reset: ol3;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-reset {
  counter-reset: ol4;
}

.font-zero {
  font-size: 0 !important;
}

.chat-user-details .map iframe {
  display: none;
}
.user-info-wrp a {
  text-decoration: none;
}
.scope-list li {
  margin: 24px 0px;
  font-size: 14px;
}

.scope-list li a {
  display: inline-block;
  max-width: 95px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  float: left;
  color: #354052;
  text-decoration: none;
  font-size: 14px;
  min-width: 95px;
}

.scope-list li span {
  display: inline-block;
  margin: -8px 0px 0px 16px;
  font-size: 12px;
  color: #354052;
  opacity: 0.5;
}
.scope-list li a input {
  margin: 0px 7px 0px 0px;
}

.choose-broadcast a input[type="file"] {
  opacity: 0;
  width: 68px;
  position: absolute;
  margin: -8px 0px 0px -56px;
  height: 30px;
  border-radius: 3px;
}

.chat-mail-sub {
  border: 1px solid #e1e4e6;
  font-size: 12px;
  border-width: 1px 0px 0px;
  padding: 10px 20px;
}

.chat-mail-sub input {
  border: 0px;
  width: 90%;
  padding: 5px 0 5px 10px;
  font-size: 14px;
  color: #354052;
  display: inline-block;
}

.email-wrp {
  position: relative;
  color: #354052;
}

.email-wrp div.quil-cont {
  height: 330px;
}
.email-wrp div.ql-toolbar {
  position: absolute;
  bottom: 10px;
  border: 1px solid #e1e4e6;
  border-radius: 3px;
  left: 20px;
  z-index: 1;
}

.chat-mail-popup {
  width: 1000px;
}

.chat-mail-popup iframe {
  border: 1px solid rgb(216, 220, 225);
  width: 50%;
  float: left;
  margin: 0px;
  height: 450px;
  border-radius: 0px !important;
  border-width: 1px 0 0 !important;
  padding: 10px;
  margin: 0px !important;
}

.chat-mail-popup .email-wrp {
  float: left;
  width: 50%;
}

.chat-mail-popup .CodeMirror {
  height: 411px;
}
.CodeMirror-sizer {
  margin-left: 29px;
  min-width: 3px;
  padding-bottom: 0px;
  margin-bottom: -5px;
  border-right-width: 25px;
  min-height: 218px;
}

[contenteditable]:empty::before {
  content: attr(placeholder);
  color: #d8dce1;
  cursor: text;
}

.inputTxt [contenteditable] {
  border: 1px solid #d8dce1;
  border-radius: 4px;
  font-size: 14px;
  padding: 10px;
  margin: 0px 0px 0px 0px;
  width: 360px;
  line-height: 20px;
  box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.05);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.inputTxt [contenteditable] img {
  padding: 10px;
  width: 100%;
}

.cs-typing-footer.cs-typing-stop .more-icon-dwn {
  left: 83px;
}
.cs-typing-footer.cs-typing-overflow .more-icon-dwn {
  left: 48px;
}

/*inputTxt code here -----------------------------------------------------------------*/
.inputTxt {
  margin: 15px 0px 15px 0px;
  position: relative;
}

.inputTxt.inlineBlock {
  margin: 0px 10px 12px 0px;
}

.inputTxt label {
  font-size: 12px;
  display: block;
  margin: 0px 0px 6px;
  text-transform: uppercase;
  color: #7f8796;
  /*font-family: "Effra-medium"*/
}

.inputTxt textarea {
  padding: 5px 10px !important;
  resize: none;
  width: 340px !important;
  height: 60px !important;
}

.inputTxt input,
.inputTxt textarea {
  border: 1px solid #d8dce1;
  border-radius: 4px;
  font-size: 14px;
  padding: 0px 10px;
  margin: 0px 0px 0px 0px;
  width: 260px;
  height: 40px;
  box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.05);

  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  font-family: "Lato", sans-serif;
}

.inputTxt input:focus,
.inputTxt textarea:focus {
  border: 1px solid #9ca6b8;
  box-shadow: inset 0px 0px 2px #d8dce1;
}

/*.inputTxt input.errorField{
        border: 1px solid #f9c9bf;
        box-shadow: 0 0 8px #ffffff;
        background-color: #fcf4f2;
        color: red;
    }*/

.inputTxt.errorField input,
.inputTxt.errorField textarea {
  border: 1px solid #f9c9bf;
  box-shadow: 0 0 8px #ffffff;
  background-color: #fcf4f2;
  color: red;
}

.inputTxt.errorField p {
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: #f7694e;
  margin: 3px 0px 0px;
  line-height: 1.62;
}

.inputButton {
  margin: -13px 0px 0px 0px;
  position: relative;
}

.inputButton input {
  width: 226px;
}

/*button code here -----------------------------------------------------------------*/
.popupBtnHolder {
  text-align: right;
  padding: 12px 20px;
  border: 1px solid #e1e4e6;
  border-width: 1px 0px 0px;
  background-color: #fafbfc;
  border-radius: 0px 0px 5px 5px;
}

.blueBtn {
  border: 1px solid #4695d9;
  background-color: #4695d9;
}

/* show visitor details */

.showVisitorDetails {
  border: 1px solid #aeaeae;
  border-radius: 75px 0px 0px 75px;
  cursor: pointer;
  z-index: 1;
  width: 22px;
  padding: 5px;
  top: 50px;
  right: 0px;
  position: absolute;
  display: inline-block;
  color: #aeaeae;
  font-size: 17px;
  background-color: #ffffff;
}
.showVisitorDetails:after {
  content: "\F104";
  font-family: "FontAwesome";
}

/*formField code here -----------------------------------------------------------------*/
.formField h5 {
  font-size: 16px;
  margin: 0px 0px 5px;
  color: #2b333d;
  /*font-family: "Effra-medium"*/
}
.formField {
  margin: 0px 35px;
  padding: 30px 0px 15px;
  border: 1px solid #dee2e7;
  border-width: 0px 0px 1px;
}
.popup .formField {
  border: 0px;
  padding: 30px 0px;
}
.formField input[type="file"] {
  padding: 10px 0px;
  width: 75px;
}
.formField p {
  font-size: 14px;
  line-height: 22px;
  color: #7f8796;
}

.formField p.pagTxt {
  margin: 0px 0px 15px 0px;
}

.formHead {
  width: 200px;
}

.formContent,
.formHead {
  display: inline-block;
  vertical-align: top;
}

.formContent .redBtn {
  margin: 0px 0px 15px;
}
.popupContent .formContent {
  width: 300px;
}

.formContent .inputTxt {
  margin: 20px 0px;
  width: 100%;
  height: 130px;
}
.popupContent .formContent .inputTxt input[type="text"],
.popupContent .formContent .inputTxt select {
  width: 100%;
  height: 40px;
  font-size: 14px;
}

